import { NewResearchConfig } from 'types/config'

// ! You need to clear the browser cache after the change
const setting: NewResearchConfig = {
  options: {
    type: ['news', 'research'],
    view: ['default', 'all']
  },
  types: [
    {
      name: 'news',
      icon: '/assets/images/icon_news_research.svg'
    },
    {
      name: 'research',
      icon: '/assets/images/icon_search_new_reseach.svg'
    }
  ],
  layouts: ['default', 'all-new'],
  layoutContentTabs: [
    {
      title: 'Lastest',
      type: 'first'
    },
    {
      title: 'Featured',
      type: 'second'
    },
    {
      title: 'More In Crypto World',
      type: 'third'
    }
  ],
  dropdownSearch: {
    news: [
      { title: 'Assets', keyData: 'assets' },
      { title: 'Source', keyData: 'source' }
    ],
    research: [
      { title: 'Tag', keyData: 'tags' },
      { title: 'Assets', keyData: 'assets' },
      { title: 'Author', keyData: 'author' }
    ]
  },
  getDataDropDownByKeyData: {
    assets: 'asyncDataAssets',
    author: 'asyncDataAuthor',
    tags: 'asyncDataTag',
    source: 'asyncDataSource'
  },
  colTableNews: [
    {
      key: 'numberIndex',
      titleTable: '#',
      dataIndex: 'index'
    },
    {
      key: 'dateNews',
      titleTable: 'Date',
      dataIndex: 'created_at'
    },
    {
      key: 'titleNews',
      titleTable: 'Title',
      dataIndex: 'title'
    },
    {
      key: 'tags',
      titleTable: 'Assets',
      dataIndex: 'tags'
    },
    {
      key: 'source',
      titleTable: 'Source',
      dataIndex: 'source_name'
    }
  ],
  colTableResearch: [
    {
      key: 'numberIndex',
      titleTable: '#',
      dataIndex: 'index'
    },
    {
      key: 'dateNews',
      titleTable: 'Date',
      dataIndex: 'created_at'
    },
    {
      key: 'titleNews',
      titleTable: 'Title',
      dataIndex: 'title'
    },
    {
      key: 'tags',
      titleTable: 'Tag',
      dataIndex: 'tags'
    },
    {
      key: 'assetsNews',
      titleTable: 'Assets',
      dataIndex: 'assets'
    },
    {
      key: 'author',
      titleTable: 'Author',
      dataIndex: 'author'
    }
  ]
}

export default setting
