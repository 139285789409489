const setting = {
  routerNoFooter: [
    '/signin',
    '/signup',
    '/directory/projects',
    '/directory/people',
    '/directory/organizations',
    '/directory/fundraising',
    '/people',
    '/organization',
    '/project',
    '/forgot-password',
    '/user_profile',
    '/ai-chat'
  ]
}

export default setting
