import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeviceType } from './useDeviceType'
import ModalViewSupport from '@src/components/common/ModalViewSupport'

const useCheckViewModal = (router, arrRouterCheck = []) => {
  const device = useDeviceType()
  const [showModal, setShowModal] = useState(false)
  const [checkkLocal, setCheckLocal] = useState(false)
  const navigate = useNavigate()

  const saveToLocalStorage = (key, value) => {
    const now = new Date().getTime()
    const item = {
      value: value,
      expiry: now + 30 * 60 * 1000 // 30 phút
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const getFromLocalStorage = (key) => {
    const itemStr = localStorage.getItem(key)
    // Nếu không có dữ liệu hoặc dữ liệu đã hết hạn, trả về null
    if (!itemStr) {
      setCheckLocal(false)
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date().getTime()
    // Kiểm tra xem dữ liệu đã hết hạn chưa
    if (now > item.expiry) {
      localStorage.removeItem(key) // Xóa dữ liệu khi hết hạn
      setCheckLocal(false)
      return null
    }
    setCheckLocal(true)
    return item.value
  }

  const handleComtinue = () => {
    saveToLocalStorage('showMobileBlock', 'false')
    setShowModal(false)
  }
  const handleOnBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    getFromLocalStorage('showMobileBlock')
  }, [])

  useEffect(() => {
    if (arrRouterCheck.includes(router) && !checkkLocal && device === 'Mobile') {
      setShowModal(true)
    } else {
      setShowModal(false)
    }
  }, [router, device])

  const modalContent = showModal ? (
    <ModalViewSupport onBack={handleOnBack} onContinue={handleComtinue} visible={showModal} />
  ) : null

  return modalContent
}

export default useCheckViewModal
