import React from 'react'
import LoginStyles from './Login.module.css'
import MetaInfo from '../MetaInfo'
import { getRouteMetaInfo } from '@src/config/routes.config'
import FormAuth from './contentRight/FormAuth'
import { Link } from 'react-router-dom'
import SvgCommon from '../common/SvgCommon'
import ImageCommon from '../common/ImageCommon/ImageCommon'
import { renderMetaDescription, renderMetaTitle } from '@src/config/seo.meta.config'
import LeftLoginImg from '@src/assets/LeftImg.svg'

const Login = ({
  type,
  previousPathname,
  closeModal,
  closeModalWithX,
  handleSignUp,
  handleBackSignin,
  handleToPopupResetPass
}) => {
  console.log('previousPathname', previousPathname)
  return (
    <>
      <MetaInfo
        {...getRouteMetaInfo('Login')}
        description={renderMetaDescription('Login')}
        title={renderMetaTitle('Login')}
      />
      <div className={type === 'popup' ? LoginStyles.containerPopUp : LoginStyles.Logincontainer}>
        <div className={LoginStyles.containerLogin} style={type === 'popup' ? { height: '100%' } : {}}>
          {type === 'popup' ? null : (
            <div className={LoginStyles.leftLoginContainer}>
              <ImageCommon
                borderRadius='0px'
                height='100%'
                src={LeftLoginImg}
                styleContainer={{ width: '100%', height: '100%' }}
                width='100%'
              />
              <div className={LoginStyles.formLogo}>
                <Link className={LoginStyles.ImgContainer} to='/'>
                  <SvgCommon height={32} src='/assets/images/Login/logo.svg' width={32} />
                </Link>
              </div>
            </div>
          )}
          <div className={type === 'popup' ? LoginStyles.formLoginPopup : LoginStyles.formLogin}>
            <div className={type === 'popup' ? LoginStyles.formLogoTabletPopup : LoginStyles.formLogoTablet}>
              <Link className={LoginStyles.ImgContainer} to='/'>
                <SvgCommon height='100%' src='/assets/images/Login/logo.svg' width='100%' />
              </Link>
            </div>
            <div
              className={type === 'popup' ? LoginStyles.formLoginChildPopup : LoginStyles.formLoginChild}
              style={type === 'popup' ? { width: '100%', height: '100%' } : {}}
            >
              <FormAuth
                closeModal={closeModal}
                closeModalWithX={closeModalWithX}
                handleBackSignin={handleBackSignin}
                handleSignUp={handleSignUp}
                handleToPopupResetPass={handleToPopupResetPass}
                previousPathname={previousPathname}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login
