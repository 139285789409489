import moment from 'moment'
const FormatCommon = {
  dateFull: (value) => {
    const date = moment(value).format('DD/MM/YYYY')
    return date // 01/01/2023
  },
  dateOnlyYear: (value) => {
    const date = moment(value).format('YYYY')
    return date // 2023
  },
  dateRelativeTime: (value) => {
    const date = moment(value).startOf('day').fromNow()
    return date // 2 hours ago
  },

  dateRelativeTimeShort: (value) => {
    const date = moment(moment.utc(value).toISOString()).fromNow()
    const dateShort = date
      .replace('hours', 'h')
      .replace('hour', 'h')
      .replace('minutes', 'm')
      .replace('minute', 'm')
      .replace('seconds', 's')
      .replace('second', 's')
    return dateShort // 2h ago
  },

  monthDayYear: (value) => {
    const date = moment(value).format('MMM DD, YY')
    return date // Jan 01, 23
  },

  dayDateMonthYear: (value) => {
    if (!moment(value).isValid()) {
      return '-'
    }
    const date = moment(value).format('MMM D, YYYY')
    return date // Aug 31th, 2021
  },

  dayYear: (value) => {
    const date = moment(value).format('MM/YYYY')
    return date // 01 2023
  },

  dateMonthYear: (value) => {
    const date = moment(value).format('DD MMM YYYY')
    return date // 01 Jan 2023
  },

  monthDay: (value) => {
    const date = moment(value).format('MMM DD')
    return date // Jan 01
  },

  formatCurrency: (value, typeCurrent = '') => {
    if (value == undefined) return value
    if (typeof value !== 'string') {
      value = value.toString()
    }
    if (value == '-') return value
    if (!typeCurrent) typeCurrent = ''
    typeCurrent = typeCurrent.toLowerCase()
    let numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
    let preUnit = ''
    let preUnitDefault = '$'
    let nextUnit = ''

    if (typeCurrent === 'usd') {
      preUnit = '$'
    } else if (typeCurrent === 'euro') {
      preUnit = '€'
    } else if (typeCurrent === 'cnd') {
      preUnit = 'C$'
    } else {
      preUnit = preUnitDefault
    }

    if (numericValue >= 1000000000) {
      numericValue /= 1000000000
      nextUnit = 'B'
    } else if (numericValue >= 1000000) {
      numericValue /= 1000000
      nextUnit = 'M'
    } else if (numericValue >= 1000) {
      numericValue /= 1000
      nextUnit = 'K'
    }

    const formattedMoney = preUnit + numericValue.toLocaleString(undefined, { maximumFractionDigits: 2 }) + nextUnit
    return formattedMoney // 123456789 => $123,46M
  },
  formatCurrencyFullNumber: (value, typeCurrent = '') => {
    if (value === undefined) return value
    if (typeof value !== 'string') {
      value = value.toString()
    }
    if (value == '-') return value
    if (!typeCurrent) typeCurrent = ''
    typeCurrent = typeCurrent.toLowerCase()
    let numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
    let preUnit = ''
    let preUnitDefault = '$'

    if (typeCurrent === 'usd') {
      preUnit = '$'
    } else if (typeCurrent === 'euro') {
      preUnit = '€'
    } else if (typeCurrent === 'cnd') {
      preUnit = 'C$'
    } else {
      preUnit = preUnitDefault
    }

    const formattedMoney = preUnit + numericValue.toLocaleString(undefined, { maximumFractionDigits: 2 })
    return formattedMoney // 123456789 => $123,46M
  },

  formatPhoneNumber: (value) => {
    const formattedNumber = value.replace(/(\d{2})(\d{4})(\d+)/, '$1-$2-$3')
    return formattedNumber // +84-6459-4898
  },

  formatAddressWallet: (value) => {
    const formattedAddress = value.replace(/(\w{8})(\w+)(\w{5})/, '$1...$3')
    return formattedAddress //db1b918370bf7f354098576144095b56fc467856a6f2672c029b378b5c6a3bed => db1b91837...a3bed
  },

  //formatNumber exp '12000' => '12k', '1200000' => '1.2M'
  formatNumber: (value) => {
    if (value === undefined) return value

    let numericValue

    if (typeof value === 'string') {
      numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
    } else if (typeof value === 'number') {
      numericValue = value
    } else {
      return value
    }

    let nextUnit = ''

    if (numericValue >= 1000000000) {
      numericValue /= 1000000000
      nextUnit = 'B'
    } else if (numericValue >= 1000000) {
      numericValue /= 1000000
      nextUnit = 'M'
    } else if (numericValue >= 1000) {
      numericValue /= 1000
      nextUnit = 'K'
    }

    let formattedNumber = ''

    if (Number.isInteger(numericValue)) {
      formattedNumber = numericValue.toLocaleString()
    } else {
      formattedNumber = numericValue.toFixed(2)
    }

    formattedNumber += nextUnit
    return formattedNumber
  },

  // ex 120000000 => 12,000k
  formatNumberAddCommas: (value) => {
    if (value === undefined) return value

    let numericValue

    if (typeof value === 'string') {
      numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
    } else if (typeof value === 'number') {
      numericValue = value
    } else {
      return value
    }

    let nextUnit = ''

    if (numericValue >= 1000000000) {
      numericValue /= 1000000000
      nextUnit = 'B'
    } else if (numericValue >= 1000000) {
      numericValue /= 1000000
      nextUnit = 'M'
    } else if (numericValue >= 1000) {
      numericValue /= 1000
      nextUnit = 'K'
    }

    let formattedNumber = ''

    if (Number.isInteger(numericValue)) {
      formattedNumber = numericValue.toLocaleString('en-US', { maximumFractionDigits: 0 })
    } else {
      formattedNumber = numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 })
    }

    formattedNumber += nextUnit
    return formattedNumber
  },

  //thêm dấu phảy hàng nghìn
  formatAddCommas: (value) => {
    if (value === undefined) return value

    // Chuyển đổi giá trị sang chuỗi nếu không phải là chuỗi
    if (typeof value !== 'string') {
      value = value.toString()
    }

    // Loại bỏ dấu phẩy hiện có
    const sanitizedValue = value.replace(/,/g, '')

    // Tách phần nguyên và phần thập phân
    const parts = sanitizedValue.split('.')
    let integerPart = parts[0]
    let decimalPart = parts[1]

    // Thêm dấu phẩy cho phần nguyên
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Kết hợp lại phần nguyên và phần thập phân (nếu có)
    let formattedValue = integerPart
    if (decimalPart !== undefined) {
      formattedValue += '.' + decimalPart
    }

    return formattedValue
  },

  //thêm dấu phảy hàng nghìn
  removeCommas: (formattedValue) => {
    return formattedValue.replace(/,/g, '')
  },

  //formatTimeAgo
  formatTimeAgo: (timestamp) => {
    if (timestamp === undefined) return timestamp
    const now = moment()
    const then = moment(timestamp)
    const duration = moment.duration(now.diff(then))

    if (duration.asSeconds() < 60) {
      return `${Math.floor(duration.asSeconds())} second ago`
    } else if (duration.asMinutes() < 60) {
      return `${Math.floor(duration.asMinutes())} minute ago`
    } else if (duration.asHours() < 24) {
      return `${Math.floor(duration.asHours())} hour ago`
    } else if (duration.asDays() < 30) {
      return `${Math.floor(duration.asDays())} day ago`
    } else if (duration.asMonths() < 12) {
      return `${Math.floor(duration.asMonths())} month ago`
    } else {
      return `${Math.floor(duration.asYears())} year ago`
    }
  },

  //format change value
  formatChangeValue: (value) => {
    if (value == undefined) return value
    if (typeof value !== 'string') {
      value = value.toString()
    }
    const numericValue = Number(value.replace(/[^0-9.-]+/g, ''))
    const formattedValue = numericValue.toLocaleString(undefined, { maximumFractionDigits: 2 })
    return formattedValue // -1.706590353899153 > -1,71
  },

  //format change percent
  formatChangePercent: (value, maximum = 2) => {
    if (value === undefined) return value
    const numericValue = Number(value.toString().replace(/[^0-9.-]+/g, ''))
    const formattedValue = numericValue.toFixed(maximum)
    return formattedValue.replace(/^-/, '') // -1.706590353899153 > 1,71%
  },

  formatNumberZero: (value, numberRounding = 3, noSpan = false) => {
    if (value === undefined) return value
    if (typeof value !== 'string') {
      value = value.toString()
    }
    const segments = value.split('.')
    const valueFirst = `${segments[0]}.0`
    const numericValue = segments[segments.length - 1]

    const strippedNumber = parseInt(numericValue, 10).toString()
    let countLeadingZeros = 0

    for (var i = 0; i < numericValue.length; i++) {
      if (numericValue[i] !== '0') {
        break
      }
      countLeadingZeros++
    }

    const [integerPart, decimalPart] = value.split('.')

    const formattedIntegerPart = parseInt(integerPart).toLocaleString('en-US')

    // Chỉ lấy 4 số ở phần thập phân
    let formattedDecimalPart = ''
    if (decimalPart) {
      // Nếu phần thập phân có ít hơn 4 số, chỉ lấy số lượng đó
      formattedDecimalPart = parseFloat(`0.${decimalPart}`).toFixed(4).slice(2)
      // Loại bỏ các số 0 ở cuối
      formattedDecimalPart = formattedDecimalPart.replace(/0+$/, '')
    }

    // Kết hợp lại
    const formattedValue = `${formattedIntegerPart}.${formattedDecimalPart}`

    return (
      <span>
        {countLeadingZeros < numberRounding ? (
          Number(value) ? (
            FormatCommon.formatAddCommas(value)
          ) : (
            formattedValue
          )
        ) : (
          <>
            {valueFirst}
            <sub>{countLeadingZeros}</sub>
            {strippedNumber.slice(0, 4)}
          </>
        )}
      </span>
    )
  },

  ///new formnat master
  numberChange: (value) => {
    const parsedValue = parseFloat(value)
    if (!isNaN(parsedValue)) {
      value = parsedValue
    }

    if (typeof value !== 'number') {
      return 'Invalid input: must be a number'
    }
    if (Number.isInteger(value)) {
      return value.toLocaleString('en-US')
    } else {
      return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  numberCurrency: (value) => {
    const parsedValue = parseFloat(value)
    if (!isNaN(parsedValue)) {
      value = parsedValue
    }

    if (typeof value !== 'number') {
      return 'Invalid input: must be a number'
    }

    const absNum = Math.abs(value)
    let result
    if (absNum < 1e6) {
      result = absNum.toLocaleString('en-US', { maximumFractionDigits: 2 }) + 'K'
    } else if (absNum >= 1e6 && absNum < 1e9) {
      result = (absNum / 1e6).toLocaleString('en-US', { maximumFractionDigits: 2 }) + 'M'
    } else {
      result = (absNum / 1e9).toLocaleString('en-US', { maximumFractionDigits: 2 }) + 'B'
    }
    return result
  },
  numberCurrencyFull: (value) => {
    return value.toLocaleString('en-US', { maximumFractionDigits: 2 })
  }
}
export default FormatCommon
