/* eslint-disable react/jsx-max-depth */
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { createRoot } from 'react-dom/client'
import App from './App'
import ScrollToTop from './components/ScrollToTop'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'
import { ConfigProvider } from 'antd'
import 'antd/dist/reset.css'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { NewsResearchContextProvider } from './pages/News&Research/components/context/NewsResearchContextProvider'
import { AuthProvider } from './ContextAPI/AuthProvider'
import WebFontLoader from './WebFontLoader'
import { ModalProvider } from './ContextAPI/ModalContext'

const webFontConfig = {
  custom: {
    families: ['Nunito', 'InterTight'],
    urls: [
      './src/fonts/Nunito/Nunito-Black.ttf',
      './src/fonts/Nunito/Nunito-BlackItalic.ttf',
      './src/fonts/Nunito/Nunito-Bold.ttf',
      './src/fonts/Nunito/Nunito-BoldItalic.ttf',
      './src/fonts/Nunito/Nunito-ExtraBold.ttf',
      './src/fonts/Nunito/Nunito-ExtraBoldItalic.ttf',
      './src/fonts/Nunito/Nunito-ExtraLight.ttf',
      './src/fonts/Nunito/Nunito-ExtraLightItalic.ttf',
      './src/fonts/Nunito/Nunito-Italic.ttf',
      './src/fonts/Nunito/Nunito-Light.ttf',
      './src/fonts/Nunito/Nunito-LightItalic.ttf',
      './src/fonts/Nunito/Nunito-Medium.ttf',
      './src/fonts/Nunito/Nunito-MediumItalic.ttf',
      './src/fonts/Nunito/Nunito-Regular.ttf',
      './src/fonts/Nunito/Nunito-SemiBold.ttf',
      './src/fonts/Nunito/Nunito-SemiBoldItalic.ttf',
      './src/fonts/inter-tight/InterTight-Medium.ttf'
    ]
  }
}

const root = createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <WebFontLoader config={webFontConfig} />
    <ConfigProvider componentSize='middle' input={{ autoComplete: 'off' }}>
      <Router>
        <HelmetProvider>
          <ScrollToTop />
          <Provider store={store}>
            <NewsResearchContextProvider>
              <AuthProvider>
                <ModalProvider>
                  <App />
                </ModalProvider>
              </AuthProvider>
            </NewsResearchContextProvider>
          </Provider>
        </HelmetProvider>
      </Router>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
