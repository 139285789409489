import { createSlice } from '@reduxjs/toolkit'

const passwordSlice = createSlice({
  name: 'password',
  initialState: {
    loading: false,
    statusCode: null,
    check: null
  },
  reducers: {
    passwordChangeRequested: (state) => {
      state.loading = true
      state.statusCode = null
      state.check = null
    },
    passwordChangeSuccess: (state, action) => {
      state.loading = false
      state.statusCode = action.payload
      state.check = true
    },
    passwordChangeFailed: (state, action) => {
      state.loading = false
      state.statusCode = action.payload
      state.check = false
    }
  }
})

export const { passwordChangeRequested, passwordChangeSuccess, passwordChangeFailed } = passwordSlice.actions

export default passwordSlice.reducer
