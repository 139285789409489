import React from 'react'
import ResetStyles from './ResetPass.module.css'
import clsx from 'clsx'
import MetaInfo from '../MetaInfo'
import { getRouteMetaInfo } from '@src/config/routes.config'

import FormAuth from './contentRight/FormAuth'
import { useNavigate } from 'react-router-dom'
import SvgCommon from '../common/SvgCommon'
import ImageCommon from '../common/ImageCommon/ImageCommon'
import LeftLoginImg from '@src/assets/LeftImg.svg'
const ResetPass = ({ type, popupEmail = '', handleBackPopupResetpass, closeModal }) => {
  const navigate = useNavigate()
  const handleClickLogo = () => {
    navigate('/')
  }
  return (
    <>
      <MetaInfo {...getRouteMetaInfo('Login')} />
      <div
        className={ResetStyles.Logincontainer}
        style={type === 'popup' ? { height: '100%', borderRadius: '16px' } : {}}
      >
        <div
          className={clsx(ResetStyles.containerLogin, 'container')}
          style={type === 'popup' ? { height: '100%', backgroundColor: '#ffffff' } : {}}
        >
          {type === 'popup' ? null : (
            <div className={ResetStyles.leftLoginContainer}>
              <div className={ResetStyles.formLogo}>
                <div className={ResetStyles.ImgContainer} onClick={handleClickLogo}>
                  <SvgCommon height={32} src='/assets/images/Login/logo.svg' width={32} />
                </div>
              </div>
              <ImageCommon
                borderRadius='0px'
                height='100%'
                src={LeftLoginImg}
                styleContainer={{ width: '100%', height: '100%' }}
                width='100%'
              />
            </div>
          )}

          <div className={type === 'popup' ? ResetStyles.formLoginPopup : ResetStyles.formLogin}>
            <div className={ResetStyles.formLogoTablet} style={type === 'popup' ? { display: 'none' } : {}}>
              <div className={ResetStyles.ImgContainer} onClick={handleClickLogo}>
                <SvgCommon alt='logo' height='100%' src='/assets/images/Login/logo.svg' width='100%' />
              </div>
            </div>
            <div
              className={type === 'popup' ? ResetStyles.formLoginChildPopup : ResetStyles.formLoginChild}
              style={type === 'popup' ? { width: '100%' } : {}}
            >
              <FormAuth
                closeModal={closeModal}
                handleBackPopupResetpass={handleBackPopupResetpass}
                popupEmail={popupEmail}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPass
