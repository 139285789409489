import React, { useEffect, useState, useMemo } from 'react'
import { motion } from 'framer-motion'
import SignUpStyles from './ContentRight2.module.css'
import Input from '@src/components/InputSigninSignup/inputSignup'
// import Input from '../component/Input'
import { useLocation } from 'react-router-dom'
import {
  useLazyResetPassQuery,
  useLazySendCodeResetPassQuery,
  useLazyCheckExistQuery
} from '@src/redux-toolkit/Signin&SignUp/SigninAndSignUp.service'
import ButtonStatistr from '@src/components/ButtonStatistr'
import ImageCommon from '@src/components/common/ImageCommon/ImageCommon'
import Countdown from '@src/components/Countdown/Countdown'
import iconArrow from '@src/assets/ArrowBack.svg'
import SvgCommon from '@src/components/common/SvgCommon'
import clsx from 'clsx'
import { notification } from 'antd'
import { Cookies } from 'react-cookie'

const SecondView = ({ handleBack, onConfirm, type, popupEmail = '', handleBackPopupResetpass }) => {
  let { state } = useLocation()

  const cookies = new Cookies()

  const date = new Date()
  const reset_code_time_cookies = cookies.get('statistr_reset_code_time_stamp')
  const reset_code_time = new Date(reset_code_time_cookies)
  const time_first_countdown = (reset_code_time.getTime() - date.getTime()) / 1000
  // console.log('reset_code_time',(reset_code_time.getTime() - date.getTime())/1000)
  const [dataForm, setDataForm] = useState(type === 'popup' ? { email: popupEmail } : { ...state })
  // const [isDisableResend, setIsDisableResend] = useState(false)
  const [validationError, setValidationError] = useState({
    password: 'true',
    confirmPassword: 'true',
    reset_password_code: 'true'
  })
  const [isValidation, setIsValidation] = useState(false)
  const [isSend, setIsSend] = useState(true)
  // const [isShowMessSendCode, setIsShowMessSendCode] = useState(false)
  const [sendCodeResetPass] = useLazySendCodeResetPassQuery()
  const [resetPass, { isSuccess: resetSuccess, isLoading, error }] = useLazyResetPassQuery()
  const [checkExist, { data: dataAccount }] = useLazyCheckExistQuery()
  const [isSendCodeSuccess, setIsSendCodeSuccess] = useState(false)
  const [isSendCodeError, setIsSendCodeError] = useState(false)
  // const isShowMessSendCode = useRef(false)

  const hasValidationError = useMemo(() => {
    return Object.values(validationError).some((value) => value)
  }, [validationError])

  const handleSendCode = () => {
    const reset_email = type === 'popup' ? popupEmail : state.email
    // type === 'popup' ? sendCodeResetPass(popupEmail) : sendCodeResetPass(state.email)
    sendCodeResetPass(reset_email)
      .unwrap()
      .then(() => {
        setIsSendCodeSuccess(true)
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Code sending failed, please try again'
        })
        setIsSendCodeError(true)
      })

    setIsSend(true)
    // setIsShowMessSendCode(false)
    // setSendCodeError(false)
    // clearTimeout(resendCodeStamp)
    // isShowMessSendCode.current = false
  }
  // console.log('isValidation', isValidation)
  const getDataSignUp = (e) => {
    setIsValidation(false)
    // setValidationError({ ...validationError, [e.target.name]: '' })
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
  }

  const handleGetConfirmPassword = () => {
    setIsValidation(false)
    // setValidationError({ ...validationError, [e.target.name]: '' })
  }

  const handleConfirm = () => {
    // console.log('aaaaa')
    setIsValidation(true)
    if (!hasValidationError) {
      resetPass({ param: dataForm })
    }

    // onConfirm && onConfirm(isSuccess)
  }

  const handlePwError = (name, error) => {
    setValidationError((pre) => ({
      ...pre,
      [name]: error
    }))
    setIsValidation(false)
  }

  const handleFinishCountDown = () => {
    setIsSendCodeSuccess(false)
    setIsSendCodeError(false)
    setIsSend(false)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleConfirm()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  // useEffect(() => {
  //   if (!hasValidationError && isValidation) {
  //     resetPass({ param: dataForm })
  //   }
  // }, [hasValidationError, isValidation])

  useEffect(() => {
    if (resetSuccess) {
      onConfirm && onConfirm(resetSuccess)
    }
  }, [resetSuccess])

  // useEffect(() => {
  //   if (sendCodeSuccess) {
  //     setIsShowMessSendCode(true)
  //     resendCodeStamp = setTimeout(() => {
  //       setIsShowMessSendCode(false)
  //     }, 5000)
  //   } else {
  //     if (isError) {
  //       setSendCodeError(true)
  //     }
  //   }
  // }, [isSend])

  // useEffect(() => {
  //   if (sendCodeSuccess) {
  //     // isShowMessSendCode.current = true
  //     setIsDisableResend(true)
  //     // resendCodeStamp = setTimeout(() => {
  //     //   isShowMessSendCode.current = false
  //     // }, 5000)
  //   }
  // }, [sendCodeSuccess])

  useEffect(() => {
    if (state) {
      checkExist({ param: { email: state.email } })
      return
    }
  }, [])

  useEffect(() => {
    if (popupEmail) {
      checkExist({ param: { email: popupEmail } })
      return
    }
  }, [])
  return (
    <div className={SignUpStyles.formResetContainer}>
      <div
        className={SignUpStyles.arrowBack}
        onClick={type === 'popup' ? handleBackPopupResetpass : handleBack}
        style={type === 'popup' ? { left: '6px', top: '35px' } : null}
      >
        <SvgCommon alt='arrow' src={iconArrow} />
      </div>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        className={SignUpStyles.formReset}
        exit={{ opacity: 0, y: 20 }}
        initial={{ opacity: 0, y: -20 }}
        layout
        transition={{ duration: 0.2 }}
      >
        <div className={SignUpStyles.formContainer} style={type === 'popup' ? { backgroundColor: '#ffffff' } : {}}>
          <span className={SignUpStyles.formLoginTitle}>Update your password</span>
          <div style={{ width: '100%' }}>
            <div className={SignUpStyles.form}>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.accInfoContainer}>
                  <div className={SignUpStyles.avatarContainer}>
                    <ImageCommon
                      alt='avatar'
                      borderRadius='48px'
                      height='100%'
                      src={dataAccount ? dataAccount[1]?.face : null}
                      styleContainer={{ width: '100%', height: '100%' }}
                      width='100%'
                    />
                  </div>
                  <div className={SignUpStyles.textInfoContainer}>
                    <span className={SignUpStyles.BoldTextInfo}>{dataAccount ? dataAccount[1]?.username : null}</span>
                    <span className={SignUpStyles.TextInfo}>{type === 'popup' ? popupEmail : state?.email}</span>
                  </div>
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputBox}>
                  <Input
                    inputTitle='New Password'
                    isValidation={isValidation}
                    name='password'
                    onChange={getDataSignUp}
                    onError={handlePwError}
                    placeholder='Type your password'
                    rule={[
                      { required: true, message: 'This field cannot be empty' },
                      {
                        'between-length': true,
                        options: { from: 8, to: 255 },
                        message: 'Password need at least 8 characters'
                      }
                    ]}
                    showEyes={true}
                    type='password'
                  />
                </div>

                <div className={SignUpStyles.inputBox} style={{ marginTop: 24 }}>
                  <Input
                    inputNeedToConfirmValue={dataForm?.password}
                    inputTitle='Confirm Password'
                    isValidation={isValidation}
                    name='confirmPassword'
                    onChange={handleGetConfirmPassword}
                    onError={handlePwError}
                    placeholder='Retype your password'
                    rule={[
                      { required: true, message: 'This field cannot be empty' },
                      {
                        duplicate: true,
                        options: {
                          correlateValue: dataForm.password
                        },
                        message: 'Passwords do not match'
                      }
                    ]}
                    showEyes={true}
                    type='password'
                  />
                  {/* {console.log(dataForm.password)} */}
                </div>
                <div className={SignUpStyles.inputCodeBox}>
                  {/* <div className={SignUpStyles.inputTypeTextContainer}>
                    <span className={SignUpStyles.inputTypeText}>Reset code</span>
                  </div> */}
                  <Input
                    inputCode={true}
                    inputTitle='Reset Code'
                    isValidation={isValidation}
                    maxLength='6'
                    name='reset_password_code'
                    onChange={getDataSignUp}
                    onError={handlePwError}
                    otherError={error ? 'Invalid code' : ''}
                    rule={[{ required: true, message: 'This field cannot be empty' }]}
                  />
                  {/* <InputCode name='reset_password_code' onChange={getDataSignUp} /> */}
                  {/* {error && error?.data.description !== '' ? (
                    <div className={SignUpStyles.errorCode}>{error?.data.description}</div>
                  ) : null} */}
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <ButtonStatistr
                  height='50px'
                  loading={isLoading}
                  onClick={handleConfirm}
                  textButton='Confirm'
                  type='primary'
                  width='100%'
                />
              </div>
              <div className={SignUpStyles.signIn}>
                <span className={SignUpStyles.notReceivedText}>Didn’t receive code?</span>
                {isSend && (isSendCodeSuccess || isSendCodeError || !isNaN(time_first_countdown)) ? (
                  <>
                    <div>
                      <span className={SignUpStyles.disabledReSendText}> Resend Code</span>
                    </div>
                    <Countdown
                      counting={isSend}
                      initialTime={
                        isSendCodeError
                          ? 5
                          : reset_code_time && !isNaN((reset_code_time.getTime() - date.getTime()) / 1000)
                          ? Math.floor((reset_code_time.getTime() - date.getTime()) / 1000)
                          : 59
                      }
                      onFinish={handleFinishCountDown}
                    />
                    {/* <Countdown
                      format='mm:ss'
                      onFinish={() => {
                        setIsDisableResend(false)
                        setIsSend(false)
                      }}
                      value={Date.now() + 60 * 1000}
                      valueStyle={{
                        width: '37px',
                        height: '19px',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '19px',
                        color: '#36373999'
                      }}
                    /> */}
                  </>
                ) : (
                  <div onClick={handleSendCode}>
                    <span className={SignUpStyles.reSendText}> Resend Code</span>
                  </div>
                )}
              </div>
              {isSend && (isSendCodeSuccess || !isNaN(time_first_countdown)) ? (
                <span className={clsx(SignUpStyles.sendCodeMess, SignUpStyles.sendCodeMessPopup)}>
                  {` Code has been sent, please check your mail`}
                </span>
              ) : null}
              {/* {isError && !sendCodeSuccess ? (
                <span className={SignUpStyles.errorText}>Can not send code. Please try later</span>
              ) : null} */}
              {/* {isSuccess ? (
                <span className={SignUpStyles.sendCodeMess}> Code has been sent again, please check your mail</span>
              ) : null} */}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default SecondView
