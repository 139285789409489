import React, { useEffect, useState } from 'react'
import styles from './InputTypeCheckBox.module.css'
import SvgCommon from '../common/SvgCommon'
import checkbox from '@src/assets/checkBox.svg'
const InputTypeCheckBox = ({ onCheck, isValidation = false, ...stylesCheckBox }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [showError, setShowError] = useState(false)
  const handleCheckboxClick = () => {
    setIsChecked(!isChecked)
    setShowError(false)
    onCheck && onCheck(!isChecked)
  }

  useEffect(() => {
    if (isValidation) {
      setShowError(true)
    }
  }, [isValidation])

  return (
    <div
      className={styles.checkboxWrapper}
      onClick={handleCheckboxClick}
      style={{
        borderColor: !isChecked && showError ? '#D03131' : 'rgba(54, 55, 57, 0.2)',
        backgroundColor: !isChecked && showError ? '#FAF0F0' : '#fafafa',
        ...stylesCheckBox
      }}
    >
      {isChecked ? <SvgCommon height='100%' src={checkbox} width='100%' /> : null}
    </div>
  )
}

export default InputTypeCheckBox
