import copy from 'copy-to-clipboard'
import { message } from 'antd'
import { masterPageSids, masterWatchlistKey, KEY_MASTER, KEY_MERGE_DATA_MASTER } from '@src/constants'
import { structMasterPeoples, structMasterVentures, structMasterProjects } from './Struct'

// export const copyTextToClipboard = async (text) => {
//   if ('clipboard' in navigator) {
//     return await navigator.clipboard.writeText(text)
//   } else {
//     return copy(text)
//   }
// }

export const copyTextToClipboard = async (text) => {
  try {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text)
      console.log('text', text)
      message.success('Text copied to clipboard successfully!')
    } else {
      const successful = copy(text)
      if (successful) {
        console.log(text)
        message.success('Text copied to clipboard successfully!')
      } else {
        message.error('Copying to clipboard failed.')
      }
    }
  } catch (error) {
    console.error('Error copying text to clipboard:', error)
    message.error('An error occurred while copying text to clipboard.')
  }
}

export function getItem(label, key, icon, children, type, className) {
  return {
    label,
    key,
    icon,
    children,
    type,
    className
  }
}

export const parseDataWatchlist = (key, data) => {
  let tmpData = []

  switch (key) {
    case KEY_MASTER.projects: {
      const objParseProject = {
        [structMasterProjects.targetNameData]: data
      }
      tmpData.push(objParseProject)
      break
    }
    case KEY_MASTER.people: {
      const objParsePeople = {
        [structMasterPeoples.targetNameData]: data
      }
      tmpData.push(objParsePeople)
      break
    }
    case KEY_MASTER.organizations: {
      const objParseVenture = {
        [structMasterVentures.targetNameData]: data
      }
      tmpData.push(objParseVenture)
      break
    }
    default:
      break
  }
  return tmpData
}

export const parseKeyDataStruct = (key) => {
  let tmpKey = ''

  switch (key) {
    case KEY_MASTER.projects:
      tmpKey = KEY_MERGE_DATA_MASTER[KEY_MASTER.projects]
      break
    case KEY_MASTER.people:
      tmpKey = KEY_MERGE_DATA_MASTER[KEY_MASTER.people]
      break
    case KEY_MASTER.organizations:
      tmpKey = KEY_MERGE_DATA_MASTER[KEY_MASTER.organizations]
      break
    case KEY_MASTER.fundraising:
      tmpKey = KEY_MERGE_DATA_MASTER[KEY_MASTER.fundraising]
      break
    default:
      break
  }
  return tmpKey
}

export const getSidByMasterPage = (name) => {
  return masterPageSids[name] || ''
}

export const getKeyWatchList = (name) => {
  return masterWatchlistKey[name] || ''
}

export const convertKeyToLabel = (key) => {
  if (!key) return ''
  return key
    .split('_')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(' ')
}
