import React from 'react'
import style from './index.module.css'
import Modal from '@src/components/contributeComponent/Modal/Modal'
import ButtonStatistr from '@src/components/ButtonStatistr'

const ModalViewSupport = ({ visible, onContinue, onBack }) => {
  return (
    <Modal
      backdropFilter='blur(10px)'
      style={{
        width: '100%',
        padding: '0 12px',
        display: 'flex',
        justifyContent: 'center'
      }}
      visible={visible}
    >
      <div className={style.container_view_sp}>
        <div className={style.back} onClick={onBack}>
          <span style={{ width: 15, height: 15 }}>
            <svg viewBox='0 0 448 512' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z'
                fill='#2869e6'
              />
            </svg>
          </span>
          <span>Back to previous page</span>
        </div>

        <div className={style.image_area}>
          <span style={{ width: 65 }}>
            <svg viewBox='0 0 576 512' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64H240l-10.7 32H160c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H346.7L336 416H512c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM512 64V352H64V64H512z'
                fill='#262626'
              />
            </svg>
          </span>
        </div>

        <span className={style.notification_text}>
          For optimal viewing, please use a laptop, desktop or tablet to access this page.
        </span>

        <div className={style.button_area}>
          <ButtonStatistr onClick={onContinue} type='primary'>
            <span
              style={{
                fontSize: '18px',
                color: '#fff',
                fontWeight: '500'
              }}
            >
              Continue
            </span>
          </ButtonStatistr>
        </div>
      </div>
    </Modal>
  )
}

export default ModalViewSupport
