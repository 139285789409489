import React from 'react'
import ReactDOM from 'react-dom'
import style from './Modal.module.css'
import { motion } from 'framer-motion'

const Modal = ({ visible, onClose, children, backdropFilter, ...styleProps }) => {
  return visible
    ? ReactDOM.createPortal(
        <>
          <motion.div
            animate={{ opacity: 1 }}
            className={style.modal_mask}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          />
          <motion.div
            animate={{ opacity: 1, scale: 1 }}
            className={style.modal_wrap}
            exit={{ opacity: 0, scale: 0 }}
            initial={{ opacity: 0, scale: 0 }}
            tabIndex={-1}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div aria-labelledby=':rf:' className={style.dialog} role='dialog'>
              <div aria-hidden tabIndex={0} />
              <div {...styleProps} className={style.modal_content}>
                {children ? (
                  children
                ) : (
                  <div>
                    <div>Modal</div>
                    <button onClick={onClose} type='button'>
                      Close
                    </button>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </>,
        document.body
      )
    : null
}

export default Modal
