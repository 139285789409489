import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// import LoginStyles from '../Login.module.css'
// import { AnimatePresence } from 'framer-motion'
import FirstView from './FirstView'
import SecondView from './SecondView'
import { useAuth } from '@src/ContextAPI/AuthProvider'
// import useLoginPopup from '@src/hooks/useLoginPopup'

const FormAuth = ({
  type,
  // previousPathname,
  closeModal,

  handleSignUp,
  handleBackSignin,
  handleToPopupResetPass
}) => {
  const [dataForm, setDataForm] = useState({})
  const [currentOption, setCurrentOption] = useState('inputEmail')
  // const [, resetTimer] = useLoginPopup() // TODO: open login required in 60s
  const { loginAction, errors, auth, loading, isSuccess } = useAuth()
  let previousPath = localStorage.getItem('previousPath')
  const navigate = useNavigate()

  const onFinish = async (password) => {
    setDataForm({ ...dataForm, password: password })
    const formLogin = { email: dataForm.email, password: password }
    loginAction && (await loginAction(formLogin))
    // resetTimer() // TODO: open login required in 60s
  }

  const handleChangeView = () => {
    setCurrentOption('inputPass')
  }

  const handleClickNext = (email) => {
    setDataForm({ ...dataForm, email: email })
  }
  useEffect(() => {
    if (auth) {
      if (type !== 'popup') {
        if (previousPath !== '/signup' && previousPath !== '/forgot-password') {
          navigate(-1)
        } else {
          navigate('/')
        }
      } else {
        navigate(`${previousPath}`)
        closeModal && closeModal()
      }
    }
  }, [auth])

  // useEffect(() => {
  //   if (closeModal) {
  //     setCurrentOption('inputEmail')
  //     setDataForm({})
  //   }
  // }, [closeModal])

  return (
    <>
      {currentOption === 'inputEmail' ? (
        <FirstView
          closeModal={closeModal}
          email={dataForm.email}
          handleBackSignin={handleBackSignin}
          handleChangeView={handleChangeView}
          handleSignUp={handleSignUp}
          key='FirstView'
          onClickNext={handleClickNext}
          type={type}
        />
      ) : (
        <SecondView
          closeModal={closeModal}
          email={dataForm.email}
          handleToPopupResetPass={handleToPopupResetPass}
          isLoginSuccess={isSuccess}
          key='SecondView'
          loading={loading?.login}
          loginError={errors?.login}
          onChangeView={() => setCurrentOption('inputEmail')}
          onClick={onFinish}
          password={dataForm.password}
          type={type}
        />
      )}
    </>
  )
}

export default FormAuth
