import React from 'react'
import style from './EmptyComponent.module.css'
import SvgCommon from '../SvgCommon'

const stringText = `Don't fret – we'll give it another shot!`
const EmptyComponent = ({ noImage = false, message, type = '', option }) => {
  const renderEmpty = () => {
    let html = null
    switch (type) {
      case 'news':
        html = (
          <>
            {noImage ? null : <SvgCommon src='/assets/images/profile_token/icon_empty.svg' />}
            <span className={style.containerEmptyTitle} style={message ? { textAlign: 'center' } : {}}>
              {message || 'Oh no! We struck out on finding matching research'}
            </span>
            {message ? null : <span className={style.containerEmptyDesc}>{stringText}</span>}
          </>
        )
        break
      case 'research':
        html = (
          <>
            <SvgCommon src='/assets/images/Empty_reseach.svg' />
          </>
        )
        break
      case 'notification':
        html = (
          <>
            <SvgCommon src='/assets/images/Header/icon_empty_noti.svg' />
          </>
        )
        break
      default:
        html = <SvgCommon height={option?.height} src='/assets/images/Empty.svg' width={option?.width} />
    }
    return html
  }
  return <div className={style.containerEmpty}>{renderEmpty()}</div>
}
export default EmptyComponent
