import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
const ErrorPage = ({ status, title, subTitle }) => {
  const navigate = useNavigate()
  return (
    <Result
      extra={
        <Button onClick={() => (status === '404' ? navigate('/') : (window.location.href = '/'))} type='default'>
          Go Back Home
        </Button>
      }
      status={status}
      subTitle={subTitle}
      title={title}
    />
  )
}

export default ErrorPage
