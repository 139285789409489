import React, { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import FirstView from './FirstView'
import SecondView from './SecondView'
import ThirdView from './ThirdView'
import FourView from './FourView'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@src/ContextAPI/AuthProvider'
import SvgCommon from '@src/components/common/SvgCommon'
import LoginStyles from '../Signup.module.css'
import clsx from 'clsx'

const CURRENT_PAGE = {
  SIGN_UP: 'signup',
  GMAIL_SIGN_UP: 'ggSignUp',
  SIGN_UP_FORM: 'signUpForm',
  ENTER_CODE: 'enterCode'
}
const FormAuth = ({ type, handleSignupSuccess, currentOption, setCurrentOption }) => {
  const [gmailSignup, setGmailSignUp] = useState(false)

  const [tempData, SetTempData] = useState()
  const [referralID, setReferralID] = useState(null)
  const [dataForm, setDataForm] = useState()
  const { registerAction, signupSuccess, errors, loading } = useAuth()

  const navigate = useNavigate()
  // const [SignUp, { isSuccess, isLoading, error }] = useLazySignUpQuery()

  const getDataSignUp = (e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
  }

  const handleChangeDataForm = (e) => {
    getDataSignUp(e)
  }
  const handleGetForm = (data) => {
    console.log('handleGetForm', data)
    setDataForm({ ...dataForm, ...data })
    setCurrentOption && setCurrentOption(CURRENT_PAGE.ENTER_CODE)
  }

  const handleSignup = () => {
    registerAction && registerAction(dataForm)
  }

  const handleSignupGmail = () => {
    setGmailSignUp(true)
    setCurrentOption && setCurrentOption(CURRENT_PAGE.SIGN_UP_FORM)
  }

  const handleSignupEmail = () => {
    setGmailSignUp(false)
    setCurrentOption && setCurrentOption(CURRENT_PAGE.SIGN_UP_FORM)
  }

  const handleBackToSignUpForm = () => {
    SetTempData(dataForm)
    setCurrentOption && setCurrentOption(CURRENT_PAGE.SIGN_UP_FORM)
  }

  const handleClickLogo = () => {
    navigate('/')
  }

  useEffect(() => {
    if (signupSuccess) {
      type !== 'popup' ? navigate('/') : handleSignupSuccess()
    }
  }, [signupSuccess])

  const wrongCode = useMemo(() => {
    return errors?.signup?.data?.description
  }, [errors])

  useEffect(() => {
    const queryString = window.location.search

    // Tạo một đối tượng URLSearchParams từ query string
    const params = new URLSearchParams(queryString)
    const referralValue = params.get('referral')
    if (referralValue) {
      handleSignupEmail()
      setReferralID(referralValue)
    }
  }, [])
  return (
    <>
      <div
        className={clsx(LoginStyles.formLogoTablet, {
          [LoginStyles.formLogoSignUpFirstPage]: currentOption === CURRENT_PAGE.SIGN_UP
        })}
        style={type === 'popup' ? { display: 'none' } : {}}
      >
        <div className={LoginStyles.ImgContainer} onClick={handleClickLogo}>
          <SvgCommon alt='logo' height='100%' src='/assets/images/Login/logo.svg' width='100%' />
        </div>
      </div>
      <div
        className={currentOption === CURRENT_PAGE.ENTER_CODE ? LoginStyles.formWrapper : ''}
        style={
          type === 'popup'
            ? { height: '100%' }
            : {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#FAFAFA'
              }
        }
      >
        <AnimatePresence custom={currentOption} mode='wait'>
          {currentOption === CURRENT_PAGE.SIGN_UP ? (
            <FirstView
              handleChangeView={handleSignupEmail}
              handleViewGgSignUp={() => setCurrentOption && setCurrentOption(CURRENT_PAGE.GMAIL_SIGN_UP)}
              key='FirstView'
              onBacktoSignIn={handleSignupSuccess}
              type={type}
            />
          ) : null}
          {currentOption === CURRENT_PAGE.SIGN_UP_FORM ? (
            <SecondView
              beginData={tempData}
              gmailSignup={gmailSignup}
              handleBack={() => setCurrentOption && setCurrentOption(CURRENT_PAGE.SIGN_UP)}
              key='SecondView'
              onBacktoSignIn={handleSignupSuccess}
              onChangeView={handleGetForm}
              referralID={referralID}
              type={type}
            />
          ) : null}
          {currentOption === CURRENT_PAGE.ENTER_CODE ? (
            <ThirdView
              SignupError={errors}
              email={dataForm?.email}
              handleBack={handleBackToSignUpForm}
              handleCode={handleChangeDataForm}
              handleCofirm={handleSignup}
              hasError={wrongCode}
              key='ThirdView'
              loading={loading?.signup}
              type={type}
            />
          ) : null}
          {currentOption === CURRENT_PAGE.GMAIL_SIGN_UP ? (
            <FourView handleChangeView={handleSignupGmail} key='FourView' />
          ) : null}
        </AnimatePresence>
      </div>
    </>
  )
}

export default FormAuth
