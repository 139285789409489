import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import { rtkQueryErrorLogger } from './middleware'
import passReducer from './Redux/Slice/passwordSlice'
import projectMasterReducer from './redux-toolkit/project.master/project.master.slice'
import actionContentMasterReducer from './redux-toolkit/actionContentMaster/project.actionContentMaster.slice'
import newsAndResearchReducer from './redux-toolkit/new_research/newsAndResearch.slice'
import CareerPostJobReducer from './redux-toolkit/careerPostJob/careerPostJob.slice'
import { projectMasterApi } from './redux-toolkit/project.master/project.master.service'
import slideBarMasterReducer from './redux-toolkit/slideBar.master/slideBar.master.slice'
import ContributorReducer from './redux-toolkit/contributor/contributor.slice'
// ...

export const store = configureStore({
  reducer: {
    pass: passReducer,
    projectMaster: projectMasterReducer,
    slideProjectMaster: slideBarMasterReducer,
    actionContentMaster: actionContentMasterReducer,
    NewsAndResearch: newsAndResearchReducer,
    CareerPostJob: CareerPostJobReducer,
    Contributor: ContributorReducer,
    [projectMasterApi.reducerPath]: projectMasterApi.reducer
  },
  // Thêm api middleware để enable các tính năng như caching, invalidation, polling của rtk-query
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(projectMasterApi.middleware, rtkQueryErrorLogger)
})

// Optional, nhưng bắt buộc nếu dùng tính năng refetchOnFocus/refetchOnReconnect
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
