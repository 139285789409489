import { useRoutes } from 'react-router-dom'

import {
  Watchlist,
  // ProjectMaster,
  Dashboard,
  Home,
  About,
  ProfileToken,
  ProfileVenture,
  ProfilePeople,
  NewsResearch,
  PostDetail,
  // Careers,
  // Support,
  SupportHelp,
  // ContentTopic,
  CareerAllJobs,
  // TestRouter,
  CareersPostJob,
  Career,
  UserProfileViewerMode,
  ProfileSettings,
  Dao,
  AIStatistr,
  PageDirectory,
  AIHome,
  PageMasterDemo,
  QuestPoint,
  DaoHomePage
} from '../pages'

import { Login, Signup, ResetPass } from '../components'
import { AI_HOME_DESCRIPTION, AI_HOME_TITLE, AI_ROUTER_NAME } from './seo.meta.config'

const DESC_SUFFIX = `Join us as we collectively chart the course for tomorrow's data landscape.`

export const allPublicRouters = [
  {
    path: '/',
    name: 'Home',
    Component: Home,
    metaInfo: {
      title: 'Home',
      description: `${DESC_SUFFIX}`
    }
  },
  {
    path: '/signin',
    name: 'Signin',
    Component: Login,
    metaInfo: {
      title: 'Signin',
      description: `Signin`
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    Component: Signup,
    metaInfo: {
      title: 'Signup',
      description: `Signup`
    }
  },
  {
    path: '/forgot-password',
    name: 'ResetPass',
    Component: ResetPass,
    metaInfo: {
      title: 'ResetPass',
      description: `ResetPass`
    }
  },
  {
    path: `research/:slug`,
    name: 'PostDetail',
    Component: PostDetail,
    metaInfo: {
      title: 'PostDetail',
      description: `PostDetail`
    }
  },
  {
    path: `news/:slug`,
    name: 'PostDetail',
    Component: PostDetail,
    metaInfo: {
      title: 'PostDetail',
      description: `PostDetail`
    }
  },
  {
    path: '/researchs',
    name: 'Research',
    Component: NewsResearch,
    metaInfo: {
      title: 'Research',
      description: `Research`
    }
  },
  {
    path: 'observatory',
    name: 'Observatory',
    Component: Dashboard,
    metaInfo: {
      title: 'Observatory',
      description: `Observatory`
    }
  },
  // {
  //   path: `new/:slug`,
  //   name: 'PageDetail',
  //   Component: PageDetail,
  //   metaInfo: {
  //     title: 'PageDetail',
  //     description: `PageDetail`,
  //   },
  // },
  // {
  //   path: `research/:slug`,
  //   name: 'PageResearchDetail',
  //   Component: PageResearchDetail,
  //   metaInfo: {
  //     title: 'PageResearchDetail',
  //     description: `PageResearchDetail`,
  //   },
  // },
  // {
  // {
  //   path: '/contributor-person-new',
  //   name: 'ContributorNewPerson',
  //   Component: ContributorNewPerson,
  //   metaInfo: {
  //     title: 'ContributorNewPerson',
  //     description: `ContributorNewPerson`
  //   }
  // },
  // {
  //   path: '/contributor-venture-new',
  //   name: 'ContributorNewVenture',
  //   Component: ContributorNewVenture,
  //   metaInfo: {
  //     title: 'ContributorNewVenture',
  //     description: `ContributorNewVenture`
  //   }
  // },
  // {
  //   path: '/contributor-project-new',
  //   name: 'ContributorNewProject',
  //   Component: ContributorNewProject,
  //   metaInfo: {
  //     title: 'ContributorNewProject',
  //     description: `ContributorNewProject`
  //   }
  // },
  // {
  //   path: '/screener/master-project',
  //   name: 'Screener',
  //   Component: Screener,
  //   metaInfo: {
  //     title: 'Screener',
  //     description: `Screener`,
  //   },
  // },
  // {
  //   path: 'contributor',
  //   name: 'Contributor',
  //   Component: Contributor,
  //   metaInfo: {
  //     title: 'Contributor',
  //     description: `Contributor`,
  //   },
  // },
  {
    path: 'directory/:source',
    name: 'Master',
    Component: PageMasterDemo,
    metaInfo: {
      title: 'Master',
      description: `Master`
    }
  },
  {
    path: 'directory-demo/:source',
    name: 'PageDirectory',
    Component: PageDirectory,
    metaInfo: {
      title: 'PageDirectory',
      description: `PageDirectory`
    }
  },

  {
    path: 'career',
    name: 'Career',
    Component: Career,
    metaInfo: {
      title: 'Career',
      description: `Career`
    }
  },
  {
    path: 'career/:slug',
    name: 'Career',
    Component: Career,
    metaInfo: {
      title: 'Career',
      description: `Career`
    }
  },
  {
    path: 'crypto-jobs',
    name: 'Crypto Jobs',
    Component: CareerAllJobs,
    metaInfo: {
      title: 'Crypto Jobs',
      description: `Crypto Jobs`
    }
  },
  {
    path: 'crypto-jobs/:slug',
    name: 'Crypto Jobs',
    Component: CareerAllJobs,
    metaInfo: {
      title: 'Crypto Jobs',
      description: `Crypto Jobs`
    }
  },
  {
    path: 'career/post-job',
    name: 'CareersPostJob',
    Component: CareersPostJob,
    metaInfo: {
      title: 'CareersPostJob',
      description: `CareersPostJob`
    }
  },
  {
    path: 'support-help/help-center',
    name: 'Support',
    Component: SupportHelp,
    metaInfo: {
      title: 'Support',
      description: `Support`
    }
  },
  {
    path: 'support/:viewType',
    name: 'Support',
    Component: SupportHelp,
    metaInfo: {
      title: 'Support',
      description: `Support`
    }
  },
  {
    path: 'support/:viewType/:topicId',
    name: 'Support',
    Component: SupportHelp,
    metaInfo: {
      title: 'Support',
      description: `Support`
    }
  },
  {
    path: 'support/:viewType/:topicId/:slug',
    name: 'Support',
    Component: SupportHelp,
    metaInfo: {
      title: 'Support',
      description: `Support`
    }
  },
  {
    path: 'about',
    name: 'About',
    Component: About,
    metaInfo: {
      title: 'About',
      description: `About`
    }
  },
  {
    path: 'dao/:slug',
    name: 'Dao',
    Component: Dao,
    metaInfo: {
      title: 'Dao',
      description: `Dao`
    }
  },
  {
    path: 'dao',
    name: 'Dao',
    Component: Dao,
    metaInfo: {
      title: 'Dao',
      description: `Dao`
    }
  },
  {
    path: 'ai-chat',
    name: 'AIStatistr',
    Component: AIStatistr,
    metaInfo: {
      title: 'AIStatistr',
      description: `AIStatistr`
    }
  },
  {
    path: 'project/:slug',
    name: 'Profile Token',
    Component: ProfileToken,
    metaInfo: {
      title: 'ProfileToken',
      description: `ProfileToken`
    }
  },
  {
    path: 'project/:slug/:tab',
    name: 'Profile Token',
    Component: ProfileToken,
    metaInfo: {
      title: 'ProfileToken',
      description: `ProfileToken ${DESC_SUFFIX}`
    }
  },
  {
    path: 'organization/:slug',
    name: 'Profile Venture',
    Component: ProfileVenture,
    metaInfo: {
      title: 'ProfileVenture',
      description: `ProfileVenture`
    }
  },
  {
    path: 'organization/:slug/:tab',
    name: 'Profile Venture',
    Component: ProfileVenture,
    metaInfo: {
      title: 'ProfileVenture',
      description: `ProfileVenture ${DESC_SUFFIX}`
    }
  },
  {
    path: 'people/:slug',
    name: 'Profile people',
    Component: ProfilePeople,
    metaInfo: {
      title: 'ProfilePeople',
      description: `ProfilePeople`
    }
  },
  {
    path: 'people/:slug/:tab',
    name: 'Profile people',
    Component: ProfilePeople,
    metaInfo: {
      title: 'ProfilePeople',
      description: `ProfilePeople`
    }
  },

  // User Profile Dummy

  {
    path: 'news-research',
    name: 'NewsResearch',
    Component: NewsResearch,
    metaInfo: {
      title: 'NewsResearch',
      description: `NewsResearch`
    }
  },
  {
    path: 'ai-home',
    name: 'AIHome',
    Component: AIHome,
    metaInfo: {
      title: AI_HOME_TITLE,
      description: AI_HOME_DESCRIPTION
    }
  },

  // End Dummy Router Page Proflie
  {
    path: 'master-demo/:source',
    name: 'PageMasterDemo',
    Component: PageMasterDemo,
    metaInfo: {
      title: 'PageMasterDemo',
      description: `PageMasterDemo`
    }
  },
  {
    path: 'statistr_dao',
    name: 'DaoHomePage',
    Component: DaoHomePage,
    metaInfo: {
      title: 'Statistr DAO',
      description: `Statistr DAO`
    }
  }
]

export const allPrivateRouters = [
  {
    path: '/watchlist',
    name: 'Watchlist',
    Component: Watchlist,
    metaInfo: {
      title: 'Watch List',
      description: `Watchlist`
    }
  },
  {
    path: '/user_profile',
    name: 'User profile',
    Component: UserProfileViewerMode,
    metaInfo: {
      title: 'UserProfile',
      description: `UserProfile`
    }
  },
  {
    path: '6557',
    name: AI_ROUTER_NAME,
    Component: AIStatistr,
    metaInfo: {
      title: AI_HOME_TITLE,
      description: AI_HOME_DESCRIPTION
    }
  },
  {
    path: '/profile-settings',
    name: 'ProfileSettings',
    Component: ProfileSettings,
    metaInfo: {
      title: 'ProfileSettings',
      description: `ProfileSettings`
    }
  },
  {
    path: '/profile-settings/:activeTab',
    name: 'ProfileSettings',
    Component: ProfileSettings,
    metaInfo: {
      title: 'ProfileSettings',
      description: `ProfileSettings`
    }
  },
  {
    path: '/quest',
    name: 'QuestPoint',
    Component: QuestPoint,
    metaInfo: {
      title: 'QuestPoint',
      description: `QuestPoint`
    }
  },
  {
    path: '/quest/:page',
    name: 'QuestPoint',
    Component: QuestPoint,
    metaInfo: {
      title: 'QuestPoint',
      description: `QuestPoint`
    }
  }
]

export const getRouteMetaInfo = (name) => {
  const getRoute = (routes, nameCheck) => {
    for (const route of routes) {
      if (route.name === nameCheck) {
        return route
      }
      if (route.childrens) {
        const childRoute = getRoute(route.childrens, nameCheck)
        if (childRoute) return childRoute
      }
    }
    return null // Return null if route is not found
  }

  const listRouters = [allPublicRouters, allPrivateRouters]
  for (const singleRouters of listRouters) {
    const route = getRoute(singleRouters, name)
    if (route) return route.metaInfo
  }
  return {} // Return empty object if no meta info is found
}

export const RenderRouter = (routes) => {
  const element = useRoutes(routes)
  return element
}
