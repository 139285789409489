import { Routes, Route, useLocation } from 'react-router-dom'
import { allPublicRouters, allPrivateRouters } from '@src/config/routes.config'
import PrivateRouter from './PrivateRouter'
import { AnimatePresence } from 'framer-motion'
// import { Modal } from 'antd'

import ModalLogin from '../common/ModalLogin/ModalLogin'
import { useModal } from '@src/ContextAPI/ModalContext'
import ErrorPage from '../ErrorPage'
// import useLoginPopup from '@src/hooks/useLoginPopup'
import { notification } from 'antd'
import { isPrivatePage } from '@src/util/utils'
import { useAuth } from '@src/ContextAPI/AuthProvider'

// import { ProjectMaster } from '@src/pages'
const Router = ({ previousPathname }) => {
  const { auth } = useAuth()

  // #TODO: open login required in 60s
  // const [showPopup] = useLoginPopup()
  let showPopup = false

  const { isModalOpen, openModal, closeModal } = useModal()
  const location = useLocation()
  const handleOpenModal = () => {
    openModal('private_route')
  }
  const privatePage = isPrivatePage(location.pathname, allPrivateRouters)
  const handleCloseModal = () => {
    const storedTime = localStorage.getItem('timerStart')
    if (showPopup && !storedTime) {
      notification.warning({
        message: 'Please log in before accessing this page!'
      })
      return
    }
    // Determine the type of modal to close
    const modalType = showPopup ? 'showPopup' : 'private_route'

    // Close the modal based on the determined type
    closeModal(modalType)
  }
  return (
    <>
      <AnimatePresence mode='sync'>
        <Routes location={location}>
          {allPublicRouters.map((route) => (
            <Route element={<route.Component />} key={route.name} path={route.path}>
              {route.childrens?.map((child) => (
                <Route element={<child.Component />} key={child.name} path={child.path} />
              ))}
            </Route>
          ))}
          <Route element={<PrivateRouter openModal={handleOpenModal} previousPathname={previousPathname} />}>
            {allPrivateRouters.map((route) => (
              <Route element={<route.Component />} key={route.name} path={route.path} />
            ))}
          </Route>
          <Route
            element={
              <div
                style={{
                  width: '100%',
                  height: '100vh',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '50px',
                  fontWeight: 'bold',
                  color: '#c2c2c2'
                }}
              >
                <ErrorPage status='404' subTitle='Sorry, the page you visited does not exist.' title='404' />
              </div>
            }
            path='*'
          />
        </Routes>
      </AnimatePresence>
      {/* Hiển thị popup */}
      <ModalLogin
        onClose={handleCloseModal}
        // showPopupLogin={
        //   auth
        //     ? showPopup === true
        //       ? showPopup
        //       : isModalOpen?.private_route
        //     : privatePage || (showPopup === true ? showPopup : isModalOpen?.private_route)
        // }
        showPopupLogin={auth ? isModalOpen?.private_route : privatePage}
        showTextPanner={showPopup ? true : false}
        textConfirm='Sign in with Statistr Account'
        type={showPopup ? 'popup' : 'private'}
      />
    </>
  )
}

export default Router
