import React, { createContext, useContext, useState, useMemo, useEffect } from 'react'
import setting from '@src/settings/newResearchSetting'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'

const defaultValue = {
  type: setting.options.type[0],
  view: setting.options.view[0]
}

const NewsResearchContext = createContext(defaultValue)
const useNewsResearchContext = () => {
  const context = useContext(NewsResearchContext)
  if (context === undefined) {
    throw new Error('useValue must be used within a ValueProvider')
  }
  return context
}

const NewsResearchContextProvider = ({ children }) => {
  const [active, setActive] = useState(defaultValue)
  const navigate = useNavigate()

  const onChangeView = ({ type, view }) => {
    const newQueryParams = {
      type: type,
      view: view
    }
    const queryStringified = queryString.stringify(newQueryParams)
    navigate(`/news-research?${queryStringified}`)
    setActive(newQueryParams)
  }

  useEffect(() => {
    const param = window.location.href
    var url = new URL(param)
    var type = url.searchParams.get('type')
    var view = url.searchParams.get('view')
    if (type !== null && view !== null) {
      const newQueryParams = {
        type: type,
        view: view
      }
      setActive(newQueryParams)
    }
  }, [])

  const providerValue = useMemo(
    () => ({
      active,
      setActive,
      defaultValue,
      onChangeView
    }),
    [active, onChangeView]
  )

  return <NewsResearchContext.Provider value={providerValue}>{children}</NewsResearchContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export { NewsResearchContextProvider, useNewsResearchContext }
