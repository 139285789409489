import { api } from '../api'

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Auth'] })

export const signAndSignUp = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    Login: build.query<any, { param: any }>({
      query({ param }) {
        return {
          url: `/api/users/auth/login`,
          method: 'POST',
          body: param
        }
      },
      transformResponse: (response: any) => {
        return response
      }
    }),
    SignUp: build.query<any, { param: any }>({
      query({ param }) {
        return {
          url: `/api/users/auth/signup`,
          method: 'POST',
          body: param
        }
      }
    }),
    SendCode: build.query<any, { param: any }>({
      query(email: any) {
        return {
          url: `/api/users/auth/get-register-code?email=${encodeURIComponent(email)}`,
          method: 'GET'
        }
      }
    }),
    SendCodeResetPass: build.query<any, { param: any }>({
      query(email: any) {
        return {
          url: `/api/users/auth/get-reset-password-code?email=${encodeURIComponent(email)}`,
          method: 'GET'
        }
      }
    }),
    ResetPass: build.query<any, { param: any }>({
      query({ param }) {
        return {
          url: `/api/users/auth/reset-password`,
          method: 'POST',
          body: param
        }
      }
    }),
    CheckExist: build.query<any, { param: any; type: string }>({
      query({ param }) {
        return {
          url: `/api/users/user-base-info`,
          method: 'GET',
          params: param
        }
      },
      transformResponse: (response: any, meta, arg: any) => {
        if (arg.isExist) {
          return null
        }
        return response
      }
    }),
    getActionLogin: build.query<any, { param: any }>({
      query(param) {
        return {
          url: '/api/users/action-logs',
          method: 'POST',
          body: param
        }
      }
    })
  })
})

export const {
  useLazyLoginQuery,
  useLazySignUpQuery,
  useLazyGetActionLoginQuery,
  useLazySendCodeQuery,
  useLazySendCodeResetPassQuery,
  useLazyResetPassQuery,
  useLazyCheckExistQuery
} = signAndSignUp
