import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '@src/ContextAPI/AuthProvider'

const PrivateRouter = ({ previousPathname, openModal }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useAuth()

  useEffect(() => {
    if (!auth) {
      // Hiện popup
      openModal && openModal()

      // Chuyển hướng đến trang đăng nhập
      navigate(previousPathname, { replace: true, state: location })
    }
  }, [navigate, previousPathname])
  return auth ? <Outlet /> : null
}

export default PrivateRouter
