import { api } from '../api'

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['User'] })

export const userApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getUserInfo: build.query<any, { params: any }>({
      query(params) {
        return {
          url: `/api/users/user-info`,
          method: 'GET',
          params: params
        }
      },

      transformResponse: (response: any) => {
        return response[1]
      },
      providesTags: ['User']
    }),
    getOtherUserInfo: build.query<any, { params: any; type: string }>({
      query({ params }) {
        return {
          url: `/api/users/user-info`,
          method: 'GET',
          params: params
        }
      },

      transformResponse: (response: any, meta, arg: any) => {
        if (arg.isExist) {
          return null
        }
        return response[1]
      },
      providesTags: ['User']
    }),
    changePassword: build.mutation<any, { password: string; new_password: string }>({
      query({ password, new_password }) {
        return {
          url: '/api/users/auth/change-password',
          method: 'POST',
          body: {
            password,
            new_password
          }
        }
      }
    }),
    updateUserInfo: build.mutation<any, { body: any }>({
      query({ body }) {
        return {
          url: '/api/users/update-user-info',
          method: 'POST',
          body: body
        }
      },
      invalidatesTags: ['User']
    }),
    uploadFile: build.mutation<any, { file_data: any }>({
      query({ file_data }) {
        const formData = new FormData()
        formData.append('file_data', file_data)

        return {
          url: '/api/users/upload-data',
          method: 'POST',
          body: formData
        }
      },
      invalidatesTags: ['User']
    }),
    getUserScreener: build.query<any, { param: any }>({
      query({ param }) {
        return {
          url: `/api/users/storage/load`,
          method: 'GET',
          params: param
        }
      },
      transformResponse: (response: any) => {
        return response.data
      }
    })
  })
})

export const {
  useGetUserInfoQuery,
  useLazyGetUserInfoQuery,
  useLazyGetOtherUserInfoQuery,
  useChangePasswordMutation,
  useUpdateUserInfoMutation,
  useUploadFileMutation,
  useLazyGetUserScreenerQuery
} = userApi
