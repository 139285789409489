import { AUTH, SERVER_HOST } from '@src/constants'
import { match } from 'path-to-regexp'
import dayjs from 'dayjs'
import { LOGO_CONTRACTS } from '@src/constants'
import iconMaleDefault from '../assets/male_default.svg'
import iconFemaleDefault from '../assets/female_default.svg'
import iconFemaleSquare from '../assets/avata_vuong_female.svg'
import iconMaleSquare from '../assets/avata_vuong_male.svg'
// let processObj
// if (typeof process !== 'undefined') {
//   // Running in a Node.js environment
//   processObj = process
// } else {
//   // Running in a browser environment
//   processObj = window.process // or any other browser-specific handling
// }
// const development = !processObj.env.NODE_ENV || processObj.env.NODE_ENV === 'development'

// export default function isDev() {
//   return development
// }

export const eturnValue = (array) => {
  return array.map((value) => value)
}
//escape
export const escapeURI = (payload) => {
  if (typeof payload == 'string') {
    return encodeURIComponent(payload)
  }
  let resultPayload = undefined
  let isPayloadArray = Array.isArray(payload)
  const escapeString = (value) => {
    if (typeof value == 'string') {
      value = encodeURIComponent(value)
    }
    return value
  }
  if (isPayloadArray) {
    resultPayload = payload.map((value) => {
      return escapeString(value)
    })
  } else {
    resultPayload = { ...payload }
    for (const [key, value] of Object.entries(resultPayload)) {
      resultPayload[key] = escapeString(value)
    }
  }
  return resultPayload
}

export const numberFormat = (value) => {
  /**
   * value:  87498.2300
   * return: 87498.23
   */
  if (value == '' || value == undefined) {
    return value
  }
  if (!isNaN(value)) {
    value = String(value)
  }
  return value.replace(/[.]*0+$/, '')
}

export const formatNumberWithCommas = (value) => {
  /**
   * value:  87498.231232
   * return: 87,498.231,232
   */
  if (!value) return ''
  const formatNumber = numberFormat(value)
  return formatNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const LongstringHelper = (str) => {
  /**
   * str: "Long text"
   * LongstringHelper.truncate(7) => "Long te..."
   * LongstringHelper.truncate(7, true) => "Long..."
   */
  const sliceBoundary = (str) => str.substr(0, str.lastIndexOf(' '))
  const truncate = (quantyTruncate, useWordBoundary, wantValueFixed) => {
    const resultTruncate =
      str.length <= quantyTruncate
        ? str
        : `${useWordBoundary ? sliceBoundary(str.slice(0, quantyTruncate - 1)) : str.slice(0, quantyTruncate - 1)}...`
    if (wantValueFixed) {
      const fixed = !(str.length === resultTruncate.length)
      return {
        result: resultTruncate,
        fixed
      }
    }
    return resultTruncate
  }
  return { full: str, truncate }
}

export const buildUrl = (path, params) => {
  const url = new URL(path)
  Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value))
  return url.toString()
}

export const getValueFromObject = (object, key) => {
  const { [key]: value } = object
  return value
}

export const setReverseString = (str) => {
  const o = str.split('').reverse().join('')
  const s = o + AUTH.SUB_STRING
  return s
}

export const getReverseString = (str) => {
  const o = str?.replace(AUTH.SUB_STRING, '')
  const s = o?.split('').reverse().join('')
  return s
}

export function reverse(s) {
  var o = ''
  for (var i = s.length - 1; i >= 0; i--) o += s[i]
  return o
}

export const pullContact = (obj = {}, listContactConfig, color) => {
  let listContactDefault = ['twitter', 'email', 'website', 'github', 'telegram', 'linkedin', 'reddit']
  if (listContactConfig) listContactDefault = [...listContactConfig]

  const listContactIconSrc = {
    website: {
      blue: '/assets/images/web-blue.png',
      white: '/assets/images/web-white.png'
    },
    email: {
      blue: '/assets/images/email-blue.png',
      white: '/assets/images/email-white.png'
    },
    twitter: {
      blue: '/assets/images/icon_twitter.svg',
      white: '/assets/images/icon_twitter.svg'
    },
    github: {
      blue: '/assets/images/git_blue.png',
      white: '/assets/images/git_blue.png'
    },
    telegram: {
      blue: '/assets/images/telegram_blue.png',
      white: '/assets/images/telegram_blue.png'
    },
    linkedin: {
      blue: '/assets/images/mdi_linkedin.svg',
      white: '/assets/images/linkedin.png'
    },
    reddit: {
      blue: '/assets/images/reddit_blue.png',
      white: '/assets/images/reddit_blue.png'
    }
  }

  const resultContact = []
  listContactDefault.forEach((item) => {
    const { [item]: contactValue } = obj
    const { [item]: contactIconSrc } = listContactIconSrc
    contactValue &&
      resultContact.push({
        value: contactValue,
        icon_src: color ? contactIconSrc.white : contactIconSrc.blue,
        type: item
      })
  })
  return resultContact
}

export const getIdWithObject = (dataObject) => {
  const keyId = '_id_fake'
  const { [keyId]: result } = dataObject
  return result
}

export const getUrlMediaFileServer = (mediaFileName = '') => {
  return mediaFileName && mediaFileName?.includes('https://' || 'http://')
    ? mediaFileName
    : encodeURI(`${SERVER_HOST}${mediaFileName}`)
}

export const displayLengthArrField = (data, field = undefined, deafultNoneValue = 0) => {
  if (!field) return deafultNoneValue
  const { [field]: valueField = [] } = data
  return valueField.length
}

export const displayStripProtocol = (url) => {
  if (!url) return url
  url = url.replace(/^https?:\/\//i, '')
  url = url.replace(/\/$/, '')
  return url
}

//format date picker
export const formatDatePicker = (date) => {
  if (!date) return ''
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ss[Z]')
}

export const convertDatePicker = (date, format = 'YY-MM-DD') => {
  if (!date) return ''
  return dayjs(date).format(format)
}

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]'
}

export const isAddHttps = (value) => {
  let regex = /https:\/\//i
  if (regex.test(value)) {
    return value
  } else {
    return `https://${value}`
  }
}

export const FilterDataToMatchLogo = (dataContract) => {
  const Contracts = []

  dataContract &&
    Object.keys(dataContract).map((key) => {
      if (Object.prototype.hasOwnProperty.call(LOGO_CONTRACTS, key)) {
        LOGO_CONTRACTS[key] &&
          dataContract[key] &&
          Contracts.push({ icon: LOGO_CONTRACTS[key], address: dataContract[key] })
      }
    })

  return Contracts
}

export const formatScientificNotationToString = (numberValue) => {
  return numberValue && !Number.isNaN(numberValue)
    ? numberValue.toLocaleString('en-US', { maximumFractionDigits: 20 }).replaceAll(',', '')
    : numberValue
}

export const regexSummaryToken = (paragraph, name, symbol) => {
  const nameRegex = new RegExp(`\\b(${name}|${symbol ? symbol.toUpperCase() : symbol})\\b`, 'g')
  const modifiedParagraph = paragraph.replace(nameRegex, (match) => `<strong>${match}</strong>`)
  const regex = /[^.\n]+[?]/gm
  const wrappedParagraph = `<p>${modifiedParagraph.replace(regex, (match) => `<span>${match}</span>`)}</p>`
  return wrappedParagraph
}
export const capitalizeWords = (str) => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export const getImageSource = (item, type, style = 'cycle') => {
  if (item?.logo) return getUrlMediaFileServer(item.logo)
  if (item?.avatar) return getUrlMediaFileServer(item.avatar)
  if (item?.image) return item.image
  if (item?.project_logo) return getUrlMediaFileServer(item.project_logo)
  if (type === 'people')
    return style === 'square'
      ? item?.gender === 'Male'
        ? iconMaleSquare
        : iconFemaleSquare
      : item?.gender === 'Male'
      ? iconMaleDefault
      : iconFemaleDefault
  return '/assets/images/logo_image_default.png'
}

export const getNameDocumentTitle = (title) => {
  return title ? title : 'Statistr'
}

export const getSocialMediaHandlerUrl = (type, data = '', isTwitterConnect) => {
  let url = null
  switch (type) {
    case 'twitter':
      url = `https://twitter.com/${isTwitterConnect ? data?.attributes?.nickname : data}`
      break
    case 'linked':
      url = `https://www.linkedin.com/in/${data || ''}`
      break
    case 'telegram':
      url = `https://t.me/${data || ''}`
      break
    case 'github':
      url = `https://github.com/${data || ''}`
      break
    default:
      break
  }
  return url
}

export function isPrivatePage(path, routes) {
  return routes.some(({ path: routePath }) => {
    const matchRoute = match(routePath, { decode: decodeURIComponent })
    return matchRoute(path) !== false
  })
}
