// E_	ERROR
// I_	INFORMATION
// W_	WARNING

const ListMessages = {
  I_0001:
    'Google has raised a total of {%1} in funding over {%2} rounds. Their latest funding was raised on {%3} from {%4} round.',
  E_0001: '{%1} is required.',
  E_0002: 'The email address or password is invalid.',
  E_0003: 'Please enter a valid {%1} format!',
  E_0004: 'That email address already exists.',
  E_0005: 'Wrong code. Try again!',
  E_0006: 'Your internet is having problems. Please check the connection and try again',
  E_0007: 'Your email is not registered.',
  E_0008: '{%1} and {%2} does not match.',
  E_0009: 'Password must not contain spaces!',
  E_0010: 'Please enter a string with 8 to 16 characters!',
  E_0011: 'Please enter your {%1}!',
  E_0012: '{%1} can only enter letters.',
  E_0013: 'Incorrect password. Please try again.',
  E_0014: '{%1} already exists',
  E_0015: 'This field can not be empty'
}

const MessagesUtils = {
  get: (key, ...params) => {
    let message = ListMessages[key]
    if (message) {
      params = Array.isArray(params[0]) ? params[0] : params
      params.forEach((param, index) => {
        message = message.replace(`{%${index + 1}}`, param)
      })
    }
    return message
  }
}

// MessagesUtils.get('E_0001', '123'));
// MessagesUtils.get('E_0008', '456', '789'));
// MessagesUtils.get('E_0008', ['456', '789']));

export default MessagesUtils
