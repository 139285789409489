/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import { motion } from 'framer-motion'
import SignUpStyles from './ContentRight3.module.css'

import Button from '@src/components/common/SigninSignupCommon/Button'
import SvgCommon from '@src/components/common/SvgCommon'

const ThirdView = ({ handleChangeView, handleBack, type }) => {
  return (
    <div
      className={SignUpStyles.formResetContainer}
      style={type === 'popup' ? { height: '100%' } : { marginBottom: '140px' }}
    >
      <div
        className={SignUpStyles.arrowBack}
        onClick={handleBack}
        style={type === 'popup' ? { top: '10px', left: '10px' } : {}}
      >
        <SvgCommon src='assets\images\Login\ArrowBack.svg' />
      </div>
      <div className={SignUpStyles.PwDoneImgContainer}>
        <SvgCommon src='assets\images\Login\ResetPwDone.svg' />
      </div>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        initial={{ opacity: 0, y: -20 }}
        layout
        transition={{ duration: 0.2 }}
      >
        <div className={SignUpStyles.formContainer}>
          <span className={SignUpStyles.formLoginTitle}>Your password has been updated</span>

          <div className={SignUpStyles.form}>
            <span className={SignUpStyles.firstFormText}>
              For security purposes we've sent an email to your
              <br /> account confirming this change.
            </span>

            <Button onClick={handleChangeView} text='Done' />
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default ThirdView
