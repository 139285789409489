/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react'
import styleAccept from './AcceptDataConsent.module.css'
import { useCookies } from 'react-cookie'
import clsx from 'clsx'

const Consent = () => {
  const [cookies, setCookie] = useCookies(['user-consent'])
  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    // Check if the cookie consent has been set
    const consent = cookies['user-consent']
    // Show the banner only if there is no consent cookie
    setShowBanner(consent !== 'accepted')
  }, [cookies])

  const handleAcceptCookies = () => {
    // Set a cookie to remember the user's consent
    setCookie('user-consent', 'accepted', { path: '/', maxAge: 31536000 }) // Expires in 365 days
    // Hide the banner
    setShowBanner(false)
  }

  if (!showBanner) {
    return null
  }

  return (
    <div className={styleAccept.container_accept_overlay}>
      <div className={styleAccept.content_accept}>
        <div>
          <h2 className={styleAccept.title}>Statistr Cookie Notice</h2>
          <p className={styleAccept.desc}>
            We use cookies to enhance your browsing experience and provide personalized content on our website. By
            clicking "Accept All Cookies" you agree to the storing of cookies on your device to improve site navigation,
            analyze site usage, and assist in our marketing efforts. You can manage your preferences and find more
            information about our use of cookies in our Privacy Policy.
          </p>
        </div>
        <div className={styleAccept.btn_container}>
          <button className={clsx(styleAccept.btn, styleAccept.btn_accept)} onClick={handleAcceptCookies} type='button'>
            Accept All Cookies
          </button>
          <button
            className={clsx(styleAccept.btn, styleAccept.btn_reject)}
            onClick={() => setShowBanner(false)}
            type='button'
          >
            Reject All
          </button>
        </div>
      </div>
    </div>
  )
}

export default Consent
