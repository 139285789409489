import React from 'react'
import styled, { css } from 'styled-components'
import { configButton } from '@src/constants/designSystem'
import './ButtonStatistr.css'
import SvgCommon from '../SvgCommon'

const ButtonStatistrWrapper = styled.div`
  ${(props) => props.styleButton}
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: nowrap;
  &:hover {
    background: #3478fd;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  ${(props) =>
    !props.styleButton &&
    css`
      ${configButton.S_Primary}
    `}
  ${(props) =>
    props.disable &&
    css`
      cursor: not-allowed;
      background: #e9ebf0;
      color: rgba(54, 55, 57, 0.4);
      &:hover {
        background: #e9ebf0;
      }
    `}
`

const ButtonStatistr = ({
  text = 'Button',
  styleButton,
  beforeIcon,
  afterIcon,
  disable = false,
  onClick,
  iconSize,
  styleButtonContainer
}) => {
  return (
    <ButtonStatistrWrapper
      disable={disable}
      onClick={disable ? null : onClick}
      style={{
        ...styleButtonContainer
      }}
      styleButton={styleButton}
    >
      {beforeIcon ? (
        <div
          style={{
            marginRight: '8px'
          }}
        >
          <div
            className='beforeIcon'
            style={
              iconSize
                ? {
                    width: iconSize,
                    height: iconSize
                  }
                : null
            }
          >
            <SvgCommon alt='' color={disable ? 'rgba(54, 55, 57, 0.4)' : '#FFFFFF'} src={beforeIcon} />
          </div>
        </div>
      ) : null}
      <span>{text}</span>
      {afterIcon ? (
        <div
          style={{
            marginLeft: '8px'
          }}
        >
          <div className='afterIcon'>
            <SvgCommon alt='' src={afterIcon} />
          </div>
        </div>
      ) : null}
    </ButtonStatistrWrapper>
  )
}

export default ButtonStatistr
