import inputStyle from './inputSignup.module.css'
import { useEffect, useRef, useState } from 'react'
import { ValidateUtils } from '@src/util/Validate'
import SvgCommon from '../common/SvgCommon'
import iconElimate from '@src/assets/eliminate.svg'
import iconShowEyes from '@src/assets/iconShowHide.svg'
import iconHideEyes from '@src/assets/iconHide.svg'
import iconCheck from '@src/assets/green_tick.svg'
import clsx from 'clsx'
import { LoadingOutlined } from '@ant-design/icons'
const InputSigninSignup = ({
  showEyes,
  inputTitle,
  optional = '',
  inputCode = false,
  emailExist = '',
  rule = [],
  isValidation,
  onChange,
  onDeleteInput,
  onError,
  otherError = '',
  usernameError = '',
  inputNeedToConfirmValue = '',
  isValidUsername = false,
  isInPopup = false,
  beginValueInput = '',
  customClassNameInput,
  keepDeleteBox = false,
  isLoading,
  ...propsInput
}) => {
  const [isShowEyes, setIsShowEyes] = useState(!!showEyes)
  const [messageError, setMessageError] = useState('')
  const [showError, setShowError] = useState(false)
  const [inputValue, setInputValue] = useState(beginValueInput)
  // const [firstTime, setFirstTime] = useState(true)
  const isError = useRef(false)

  const handleHideShowEyes = () => {
    setIsShowEyes(!isShowEyes)
  }

  const validate = (field, value) => {
    const { messages } = ValidateUtils({
      rules: rule,
      value: value
    })

    if (messages) {
      setMessageError(messages)
      isError.current = true
    } else {
      setMessageError('')
      isError.current = false
    }

    onError && onError(field, isError.current)
    // field === 'confirmPassword' && console.log('messages', messages)
  }

  const handleInput = (e) => {
    setShowError(false)
    setInputValue(e.target.value)
    // firstTime && setFirstTime(false)
    // const { messages } = ValidateUtils({
    //   rules: rule,
    //   value: inputValue === '' ? '' : e.target.value
    // })
    // messages && onError && onError(propsInput.name, messages)
    validate(propsInput.name, e.target.value, 'changeinput')
    // setMessageError(messages)
    onChange && onChange(e)
  }

  const handleDeleteInput = () => {
    setInputValue('')
    validate(propsInput.name, '', 'changeinput')
    setShowError(false)
    onDeleteInput && onDeleteInput(propsInput.name)
  }

  useEffect(() => {
    if (isValidation) {
      setShowError(true)
      // firstTime && validate(propsInput.name, inputValue, 'isValidation')
    }
  }, [isValidation])

  useEffect(() => {
    validate(propsInput.name, inputValue)
  }, [])

  useEffect(() => {
    if (propsInput.name === 'confirmPassword') {
      validate(propsInput.name, inputValue)
    }
  }, [inputNeedToConfirmValue])
  // console.log('inputNeedToConfirmValue', inputNeedToConfirmValue,isConfirmPassWordInput)
  const renderMessageError = () => {
    return (
      <>
        {showError && messageError !== '' && messageError !== 'Passwords do not match' ? (
          <div
            className={inputStyle.errorText}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            {messageError}
          </div>
        ) : null}
        {showError && messageError === '' && otherError !== '' ? (
          <div
            className={inputStyle.errorText}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            {otherError}
          </div>
        ) : null}
        {showError && emailExist && emailExist !== '' ? (
          <div
            className={inputStyle.errorText}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            {emailExist}
          </div>
        ) : null}
        {usernameError && inputValue ? (
          <div
            className={inputStyle.errorText}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            {usernameError}
          </div>
        ) : null}
        {isValidUsername && inputValue && messageError === '' ? (
          <div
            className={inputStyle.validUser}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            <SvgCommon src={iconCheck} />
            Username available
          </div>
        ) : null}
        {messageError === 'Passwords do not match' ? (
          <div
            className={inputStyle.errorText}
            style={isInPopup ? { position: 'absolute', bottom: '-24px', left: '0', width: 'max-content' } : {}}
          >
            Passwords do not match
          </div>
        ) : null}
      </>
    )
  }
  return (
    <>
      {inputTitle ? (
        <div className={inputStyle.inputTypeTextContainer}>
          <span
            className={inputStyle.inputTypeText}
            style={
              messageError === 'Passwords do not match' ||
              (showError && (messageError !== '' || otherError !== '')) ||
              (usernameError !== '' && inputValue !== '')
                ? { color: '#D03131' }
                : {}
            }
          >
            {inputTitle}
            <span
              style={{ font: 'Nunito', fontWeight: '400', fontSize: '15px', lineHeight: '20.46px', color: '#36373999' }}
            >
              {optional ? ` (${optional})` : null}
            </span>
          </span>
        </div>
      ) : null}
      <div
        className={inputCode ? inputStyle.inputCodeWrapper : inputStyle.loginPass}
        style={
          messageError === 'Passwords do not match' ||
          (showError && (messageError !== '' || otherError !== '')) ||
          (usernameError !== '' && inputValue !== '')
            ? { borderColor: '#D03131' }
            : {}
        }
      >
        <input
          autoComplete='new-password'
          className={clsx(inputCode ? inputStyle.inputCode : inputStyle.inputLoginPass, customClassNameInput)}
          onChange={handleInput}
          value={inputValue}
          width={propsInput.width}
          {...propsInput}
          type={!isShowEyes && showEyes ? 'text' : propsInput.type}
        />

        {keepDeleteBox && !showEyes && inputValue === '' ? <div style={{ width: '16px', height: '16px' }} /> : null}
        {inputCode ? null : !showEyes && inputValue !== '' ? (
          isLoading ? (
            <LoadingOutlined />
          ) : (
            <div style={{ width: '16px', height: '16px' }}>
              <div className={inputStyle.eliminateIconWrapper} onClick={handleDeleteInput}>
                <SvgCommon src={iconElimate} />
              </div>
            </div>
          )
        ) : null}
        {showEyes ? (
          <div onClick={handleHideShowEyes}>
            <SvgCommon alt='eye' src={isShowEyes ? iconShowEyes : iconHideEyes} />
          </div>
        ) : null}
        {isInPopup ? renderMessageError() : null}
      </div>
      {isInPopup ? null : renderMessageError()}
    </>
  )
}
export default InputSigninSignup
