import React, { useEffect, useState } from 'react'
import LoginStyles from '../Login.module.css'
import InputTypeCheckBox from '@src/components/InputSigninSignup/InputTypeCheckBox'
import Input from '@src/components/InputSigninSignup/InputSigninSignup'
import iconArrow from '@src/assets/ArrowBack.svg'
import SvgCommon from '@src/components/common/SvgCommon'
import { useNavigate } from 'react-router-dom'
import { useLazySendCodeResetPassQuery } from '@src/redux-toolkit/Signin&SignUp/SigninAndSignUp.service'
import ButtonStatistr from '@src/components/ButtonStatistr'
import { Cookies } from 'react-cookie'

import { setReverseString, getReverseString } from '@src/util/utils'
import { notification } from 'antd'
// import { ValidateUtils } from '@src/util/Validate'

const LIST_MESS_ERROR_LOGIN = {
  403: 'Username or password was incorrect',
  422: 'Username or password was incorrect',
  429: 'Too many requests to our server. Please try again later!',
  404: 'We can not connect to our server. Please try again later!',
  FETCH_ERROR: 'There is a problem when connecting to our server. please check your internet connection'
}

const LIST_MESS_ERROR_SENDCODE = {
  403: 'User does not exist in the system'
}

const SecondView = ({
  email,
  onChangeView,
  onClick,
  loginError,
  loading,
  closeModal,
  type,
  password,
  isLoginSuccess = false,
  handleToPopupResetPass
}) => {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const timeStampCookie = new Cookies()
  const [isChecked, setIsChecked] = useState(false)
  const [hasNoValidationError, setHasNoValidationError] = useState(false)
  const [isSendCode, setIsSendCode] = useState(false)
  const [already_have_code, setAlready_have_code] = useState(false)
  const [valuePw, setValuePw] = useState(() =>
    password
      ? password
      : cookies.get('statistr_checkbox') &&
        cookies.get('statistr_password') &&
        cookies.get('statistr_password') !== 'undefined' &&
        cookies.get('statistr_isChangeEmail') === 'false'
      ? getReverseString(cookies.get('statistr_password'))
      : ''
  )

  const [isValidation, setIsValidation] = useState(false)
  const [sendCodeResetPass, { error: errorSendCode }] = useLazySendCodeResetPassQuery()
  const handleChangePw = (e) => {
    setIsValidation(false)
    setHasNoValidationError(false)
    const value = e.target.value
    setValuePw(value)
  }

  const handleBack = () => {
    setIsValidation(false)
    setIsSendCode(false)
    onChangeView && onChangeView()
  }

  const handleSignin = () => {
    if (loading) {
      return
    }
    setIsValidation(true)
    // setIsLogin(true)
    setIsSendCode(false)
    // if (isChecked) {
    //   const expiresDate = new Date()

    //   //expires token 1 day
    //   expiresDate.setDate(expiresDate.getDate() + 1)

    //   cookies.set('statistr_email', email, { path: '/' })
    //   cookies.set('statistr_password', setReverseString(valuePw), { path: '/', expires: expiresDate })
    //   cookies.set('statistr_checkbox', isChecked ? '1' : '', { path: '/' })
    //   cookies.set('statistr_isChangeEmail', false, { path: '/' })
    // }
  }

  const handleError = (name, error) => {
    error === '' && setHasNoValidationError(true)
    setIsValidation(false)
  }

  const NavigateToForgetPassWord = () => {
    if (type === 'popup') {
      handleToPopupResetPass && handleToPopupResetPass(email)
      return
    }
    closeModal && closeModal()
    navigate('/forgot-password', { state: { email: email } })
  }

  const handleSendResetCode = () => {
    setIsSendCode(true)
    setIsValidation(false)

    !already_have_code
      ? sendCodeResetPass(email)
          .unwrap()
          .then(() => {
            const expiresDate = new Date()
            expiresDate.setMinutes(expiresDate.getMinutes() + 1)
            timeStampCookie.set('statistr_reset_code_time_stamp', expiresDate, { expires: expiresDate })

            NavigateToForgetPassWord()
          })
          .catch((err) => {
            notification.error({
              message: 'Error',
              description:
                err.originalStatus === 429 ? LIST_MESS_ERROR_LOGIN[429] : 'Code sending failed, please try again'
            })
          })
      : NavigateToForgetPassWord()
  }

  const handleCheckBox = (isCheck) => {
    setIsChecked(isCheck)
  }
  // const renderError = (condition, errorMess, customContainerClassname) => {
  //   return condition ? (
  //     <div className={customContainerClassname ? customContainerClassname : ''} style={{ marginTop: '12px' }}>
  //       <span className={LoginStyles.errorText}>{errorMess}</span>
  //     </div>
  //   ) : null
  // }

  const renderEr = (type = '', customContainerClassname) => {
    let error = type === 'sendCode' ? errorSendCode?.status : loginError?.originalStatus || loginError?.status
    return (
      <>
        {type === 'sendCode' ? (
          <>
            {isSendCode && error === 403 && errorSendCode?.data?.description === 'User does not exist in the system' ? (
              <div className={customContainerClassname ? customContainerClassname : ''} style={{ marginTop: '12px' }}>
                <span className={LoginStyles.errorText}>{LIST_MESS_ERROR_SENDCODE[error]}</span>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {hasNoValidationError && loginError && error ? (
              <div className={customContainerClassname ? customContainerClassname : ''} style={{ marginTop: '12px' }}>
                <span className={LoginStyles.errorText}>{LIST_MESS_ERROR_LOGIN[error]}</span>
              </div>
            ) : null}
          </>
        )}
      </>
    )
  }

  // useEffect(() => {
  //   if (isSuccess) {

  // }, [isSuccess])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleSignin()
      }
    }
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [valuePw, isChecked])

  useEffect(() => {
    if (hasNoValidationError) {
      onClick && onClick(valuePw)
    }
  }, [hasNoValidationError, isValidation])

  useEffect(() => {
    if (isLoginSuccess) {
      if (isChecked) {
        const expiresDate = new Date()

        //expires token 1 day
        expiresDate.setDate(expiresDate.getDate() + 1)

        cookies.set('statistr_email', email, { path: '/' })
        cookies.set('statistr_password', setReverseString(valuePw), { path: '/', expires: expiresDate })
        cookies.set('statistr_checkbox', isChecked ? '1' : '', { path: '/' })
        cookies.set('statistr_isChangeEmail', false, { path: '/' })
      }
    }
  }, [isLoginSuccess])

  // Kiem tra xem da gui code hay chua neu da gui code trong 60s thì dat setTimeout để không cho gửi code cách lần gửi code trước 60s
  useEffect(() => {
    const date = new Date()
    const reset_code_time_cookies = cookies.get('statistr_reset_code_time_stamp')
    const reset_code_time = reset_code_time_cookies && new Date(reset_code_time_cookies)
    const time_remain = reset_code_time && reset_code_time.getTime() - date.getTime()
    if (!isNaN(time_remain) && time_remain > 0) {
      setAlready_have_code(true)
      setTimeout(() => {
        setAlready_have_code(false)
      }, [time_remain])
    }
  }, [])

  return (
    <>
      <div
        className={type === 'popup' ? LoginStyles.arrowBackPopup : LoginStyles.arrowBack}
        onClick={handleBack}
        // style={type === 'popup' ? { top: '75px' } : null}
      >
        <SvgCommon alt='arrow' src={iconArrow} />
      </div>
      <div style={type === 'popup' ? { height: '100%', width: '100%', maxWidth: 406 } : null}>
        <div className={LoginStyles.loginInputPassword} style={type === 'popup' ? { height: '100%' } : null}>
          <div
            className={LoginStyles.headerForm}
            style={
              type === 'popup'
                ? {
                    marginBottom: '24px',
                    height: 'fit-content',
                    marginTop: '32px',
                    width: '100%',
                    alignItems: 'center'
                  }
                : null
            }
          >
            <div className={LoginStyles.formLoginTitle} style={type === 'popup' ? { fontSize: '28px' } : null}>
              {type === 'popup'
                ? 'Welcome back'
                : isSendCode && errorSendCode && errorSendCode?.status === 403
                ? 'Welcome'
                : 'Welcome back!'}
            </div>
            {type !== 'popup' ? <div className={LoginStyles.emailText}>{email}</div> : null}
          </div>

          <div className={LoginStyles.formLoginPassword}>
            <div className={LoginStyles.pwContainer}>
              {/* <span className={LoginStyles.formLoginLabel} style={{ color: pwError !== '' ? '#ED2D4A' : null }}>
      Password
    </span> */}
              <Input
                beginValueInput={valuePw}
                inputTitle='Password'
                isValidation={isValidation}
                onChange={handleChangePw}
                onError={handleError}
                placeholder='Type your password'
                rule={[
                  { required: true, message: 'This field cannot be empty' },
                  {
                    'between-length': true,
                    options: { from: 8, to: 255 },
                    message: 'Password need at least 8 characters'
                  }
                ]}
                showEyes={true}
                type='password'
                value={valuePw}
              />

              {loginError?.status === 'FETCH_ERROR' ? null : renderEr()}

              {/* {renderError(
                hasNoValidationError && loginError && loginError?.status === 403,
                'Username or password was incorrect'
              )}

              {renderError(
                hasNoValidationError && loginError && loginError?.status === 422,
                'Username or password was incorrect'
              )}

              {renderError(
                hasNoValidationError && loginError && loginError?.originalStatus === 429,
                'There are too many requests to our server . Please try again later.'
              )}

              {renderError(
                hasNoValidationError && loginError && loginError?.originalStatus === 404,
                'We can not connect to our server . Please try again later.'
              )} */}
            </div>

            <div className={LoginStyles.signinContainer}>
              <ButtonStatistr
                height='50px'
                loading={loading}
                onClick={handleSignin}
                textButton='Sign in'
                type='primary'
                width='100%'
              />
            </div>
            <div className={LoginStyles.checkbox}>
              <InputTypeCheckBox onCheck={handleCheckBox} />
              {/* <input className={LoginStyles.checkBox} type='checkbox' /> */}
              <span className={LoginStyles.checkBoxText}>Remember me</span>
            </div>
          </div>

          <div
            className={LoginStyles.resetPassword}
            style={type === 'popup' ? { width: '100%', padding: '8px 0px 0px ' } : null}
          >
            <span className={LoginStyles.resetPasswordText} onClick={handleSendResetCode}>
              Reset password?
            </span>
          </div>
          {isSendCode ? renderEr('sendCode') : null}
          {loginError?.status === 'FETCH_ERROR' ? renderEr() : null}
          {/* {renderEr('sendCode')}
          {renderError(isSendCode && errorSendCode && errorSendCode?.status === 403, 'User does not exist in the system')}
          {renderError(
            isSendCode && errorSendCode && errorSendCode?.status !== 200 && errorSendCode?.status !== 403,
            'Can not send code. Please try later'
          )}
          {renderError(
            hasNoValidationError && loginError && loginError?.status === 'FETCH_ERROR',
            'There is a problem when connecting to our server. please check your internet connection'
          )}*/}
        </div>
      </div>
    </>
  )
}

export default SecondView
