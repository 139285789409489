import { useCallback, useRef, useEffect } from 'react'

const useDebounceFn = (callback, delay) => {
  const timeID = useRef()

  const debouncedCallback = useCallback(
    (...args) => {
      clearTimeout(timeID.current)
      const debounce = () => {
        timeID.current = setTimeout(() => {
          callback(...args)
        }, delay)
      }
      return debounce()
    },
    [callback, delay]
  )

  useEffect(() => {
    return () => clearTimeout(timeID.current)
  }, [])

  return debouncedCallback
}

export default useDebounceFn
