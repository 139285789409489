import { useEffect, useState } from 'react'

export const background = {
  home: '#FAFAFA',
  bgHeader: '/assets/images/home/bg.png',
  backgroundHeader: '/assets/images/home/bgHeader.png',
  backgroundAbout: '/assets/images/About/Group_2343.svg',
  background_futures: '#F1F2F6',
  background_questions: '#262D32',
  background_footer: '#131821',
  background_footer_copy: '#1C222E',
  background_left: '/assets/images/home/header_left.svg',
  background_right: '/assets/images/home/header_right.svg'
}

export const colorPalette = {
  white: '#F7F7F7',
  neutral: '#EFF3FA',
  deeps: '#1C222E',
  primary: '#2869E6',
  primary_dark: '#2D62C9',
  primary_light: '#63A9FA',
  white_blue: '#E4E8F0',
  white_blue_op70: '#E4E8F0B3',
  grey: '#36373999',
  black: '#1A1A1A',
  black_grey: '#363739'
}

export const colorEmphasis = {
  primary: '#363739',
  secondary: '#36373999',
  tertiary: '#36373966',
  hint_disabled: '#36373933'
}

export const configTag = {
  height: 52,
  full_height: 136,
  height_children: 32
}

export const otherColor = {
  color_one: '#4499fc',
  color_two: '#1a1a1a',
  color_three: '#E4E8F0',
  color_four: '#36373960',
  color_five: '#2869E6'
}

export const configText = {
  H1: {
    fontSize: '58px',
    fontWeight: 600,
    lineHeight: '70px',
    fontFamily: 'Nunito'
  },
  H2: {
    fontSize: '56px',
    fontWeight: 500,
    lineHeight: '64px',
    fontFamily: 'Nunito'
  },
  H3: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '56px',
    fontFamily: 'Nunito'
  },
  H4: {
    fontSize: 32,
    fontWeight: 500,
    lineHeight: '40px',
    fontFamily: 'Nunito'
  },
  titleLarge: {
    fontSize: 25,
    fontWeight: 600,
    lineHeight: '33px',
    fontFamily: 'Nunito'
  },
  titleMedium: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '28px',
    fontFamily: 'Nunito'
  },
  bodyLarge: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '26px',
    fontFamily: 'Nunito'
  },
  bodyMedium: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '23px',
    fontFamily: 'Nunito'
  },
  bodySmall: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '21px',
    fontFamily: 'Nunito'
  },
  tag: {},
  button: {},
  caption: {
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '21px',
    fontFamily: 'Nunito',
    letterSpacing: '2px'
  }
}

export const configButton = {
  S_Primary: {
    height: '40px',
    padding: '8px 24px',
    fontSize: '15px',
    fontWeight: 400,
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    backgroundColor: '#2869E6',
    borderRadius: '4px'
  },
  M_Primary: {
    height: '56px',
    padding: '8px 24px',
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    backgroundColor: '#2869E6',
    borderRadius: '6px'
  },
  S_Secondary: {},
  M_Secondary: {}
}

export const useResponsive = () => {
  //576px
  const [smallScreen, setSmallScreen] = useState(false)
  //768px
  const [mediumScreen, setMediumScreen] = useState(false)
  //992px
  const [largeScreen, setLargeScreen] = useState(false)
  //1200px
  const [extraLargeScreen, setExtraLargeScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (document.documentElement.clientWidth <= 576) {
        setSmallScreen(true)
        setMediumScreen(false)
        setLargeScreen(false)
        setExtraLargeScreen(false)
      } else if (document.documentElement.clientWidth <= 768) {
        setSmallScreen(false)
        setMediumScreen(true)
        setLargeScreen(false)
        setExtraLargeScreen(false)
      } else if (document.documentElement.clientWidth <= 992) {
        setSmallScreen(false)
        setMediumScreen(false)
        setLargeScreen(true)
        setExtraLargeScreen(false)
      } else if (document.documentElement.clientWidth <= 1200) {
        setSmallScreen(false)
        setMediumScreen(false)
        setLargeScreen(false)
        setExtraLargeScreen(true)
      } else {
        setSmallScreen(false)
        setMediumScreen(false)
        setLargeScreen(false)
        setExtraLargeScreen(false)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { smallScreen, mediumScreen, largeScreen, extraLargeScreen }
}
