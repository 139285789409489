type ParamsType = {
  paramsOfAPI: Array<string>
  defaultParams?: Object
  jsonObject?: Array<string>
  jsonArray?: Array<string>
}

type StructConfigType = {
  sid: string
  name: string
  constant?: Object
  targetNameData: string
  paramsOfAPI?: Array<string>
  targetsName?: Object
  targets?: { [key: string]: ParamsType }
  defaultParamsBuilder?: {}
  defaultParams?: {}
  getBody: Function
  getData: Function
}

const paramFilterBuilder = (paramsClient = {}, listParamsAppend = []) => {
  let paramsNew = {}
  const { wantFilter = false, filters = {}, ...otherParam } = paramsClient
  for (const [filterType, configFilter] of Object.entries(filters)) {
    if (Object.keys(configFilter) == 0) {
      paramsNew = {
        ...paramsNew,
        [`applyFilter${filterType}`]: 0
      }
      continue
    }
    paramsNew = {
      ...paramsNew,
      [`applyFilter${filterType}`]: 1
    }
    const { value: valueFilter, options: optionsFilter = {} } = configFilter

    if (Object.keys(configFilter).includes('value') && valueFilter != undefined) {
      paramsNew = {
        ...paramsNew,
        [`filter${filterType}Value`]: valueFilter,
        [`filter${filterType}OptionsUse`]: 0
      }
    } else if (Object.keys(optionsFilter) != 0) {
      Object.entries(optionsFilter).forEach(([optionType, optionValue]) => {
        paramsNew = {
          ...paramsNew,
          [`filter${filterType}${optionType}`]: optionValue,
          [`filter${filterType}OptionsUse`]: 1
        }
      })
    }
  }
  let result = {}
  Object.entries({ ...paramsNew, ...otherParam }).forEach(([param, value]) => {
    result = { ...result, ...(listParamsAppend.includes(param) ? { [param]: value } : {}) }
  })
  return result
}

const encodeBodyWithParamsArrayNew = (params: any = {}, listParamArrayOr: Array<string> = []) => {
  listParamArrayOr.forEach((param) => {
    const { [`filter${param}Value`]: valueOfParam, [`filter${param}`]: valueOfOptionParam } = params
    if (valueOfParam !== undefined && Array.isArray(valueOfParam)) {
      const useFilter = valueOfParam.length === 0 ? 0 : 1
      params = {
        ...params,
        [`filter${param}Value`]:
          useFilter === 0 ? `""` : valueOfParam.map((value) => (isNaN(value) ? `"${value}"` : value)).join(',')
      }
    }
    if (valueOfOptionParam !== undefined && Array.isArray(valueOfOptionParam)) {
      const useFilter = valueOfOptionParam.length === 0 ? 0 : 1
      params = {
        ...params,
        [`filter${param}`]:
          useFilter === 0 ? `""` : valueOfOptionParam.map((value) => (isNaN(value) ? `"${value}"` : value)).join(',')
      }
    }
  })
  return params
}

const encodeJsonArrayObject = (dataArray: any = [], listFieldStringJson: Array<string> = []) => {
  return dataArray.map((itemData: Object) => {
    return encodeJsonObject(itemData, listFieldStringJson)
  })
}

const encodeJsonObject = (dataObject: any = {}, listFieldStringJson: Array<string> = []) => {
  listFieldStringJson.forEach((field) => {
    if (dataObject?.hasOwnProperty(field)) {
      let dataJson = JSON.parse(dataObject[field])
      // Fillter for id of GroupBy = NULL
      if (Array.isArray(dataJson)) {
        dataJson = dataJson.filter((itemData) => itemData != null)
      }
      dataObject = {
        ...dataObject,
        [field]: dataJson
      }
    }
  })
  return dataObject
}

const encodeBodyWithParamsArray = (params: any = {}, listParamArrayOr: Array<string> = []) => {
  listParamArrayOr.forEach((param) => {
    const { [param]: valueOfParam = [] } = params
    if (valueOfParam !== undefined && Array.isArray(valueOfParam)) {
      const useFilter = valueOfParam.length === 0 ? 0 : 1
      params = {
        ...params,
        [`${param}Use`]: useFilter,
        [param]: useFilter === 0 ? `''` : valueOfParam.map((value) => `'${value}'`).join(',')
      }
    }
  })
  return params
}

const findData = (dataFromAPI: Array<{ [key: string]: any }>, targetNameData = '') => {
  let dataOfTarget =
    dataFromAPI.find((itemData, index) => {
      return itemData.hasOwnProperty(targetNameData)
    }) || {}

  let { [targetNameData]: result = undefined } = dataOfTarget
  return result
}

// export const structDashboard: StructConfigType = {
//   sid: '6576c33862b20c65d60f86b9',
//   name: 'dashboard',
//   targetNameData: '',
//   targetsName: {
//     DASHBOARD_PEOPLES: 'dashboard_peoples',
//     DASHBOARD_VENTURES: 'dashboard_ventures',
//     DASHBOARD_PROJECTS: 'dashboard_projects'
//   },
//   targets: {
//     dashboard_peoples: {
//       paramsOfAPI: ['limit'],
//       jsonArray: ['projects', 'jobs_title', 'organizations', 'social']
//     },
//     dashboard_ventures: {
//       paramsOfAPI: ['limit'],
//       jsonArray: ['founders', 'projects']
//     },
//     dashboard_projects: {
//       paramsOfAPI: ['limit'],
//       jsonArray: ['ventures', 'peoples']
//     }
//   },
//   getBody: function (params: Object) {
//     let newParams = Object.entries(params).map(([_, paramValue]) => {
//       return paramValue
//     })
//     return {
//       body: {
//         sid: this.sid,
//         params: newParams
//       }
//     }
//   },
//   getData: function (dataFromAPI: Array<any> = [], targetNameData = '') {
//     const listData = findData(dataFromAPI, targetNameData)
//     if (listData === undefined) return []
//     if (listData.length === 0) return {}
//     let targets = this.targets || {}
//     let { [targetNameData]: targetConfig } = targets
//     let { jsonArray = [], jsonObject = [] } = targetConfig
//     return targetConfig.hasOwnProperty('jsonArray')
//       ? encodeJsonArrayObject(listData, jsonArray)
//       : encodeJsonObject(listData, jsonObject)
//   }
// }

export const structDashboard: StructConfigType = {
  sid: '65dedfd2a0d62018940f8dd2',
  name: 'dashboard_demo',
  constant: {
    DEFAULT: 0,
    FILTER_SORT_CHANGE_24h: 1,
    FILTER_SORT_MARKET_CAP: 2,
    FILTER_SORT_TOTAL_FUNDRASING: 3,
    ASC: 'asc',
    DESC: 'desc',
    DEFAULT_SORT: ''
  },
  targetNameData: '',
  targetsName: {
    DASHBOARD_PEOPLES: 'dashboard_peoples',
    DASHBOARD_VENTURES: 'dashboard_ventures',
    DASHBOARD_PROJECTS: 'dashboard_projects',
    DASHBOARD_FUNDRASING: 'dashboard_fundrasing'
  },
  targets: {
    dashboard_peoples: {
      paramsOfAPI: ['limit'],
      jsonArray: ['projects', 'jobs_title', 'organizations', 'social']
    },
    dashboard_ventures: {
      paramsOfAPI: ['limit'],
      jsonArray: ['founders', 'projects']
    },
    dashboard_projects: {
      paramsOfAPI: ['limit', 'sortFilterType', 'sortType'],
      defaultParams: {
        limit: 8,
        sortFilterType: 0,
        sortType: 'DESC'
      },
      jsonArray: ['ventures', 'peoples']
    },
    dashboard_fundrasing: {
      paramsOfAPI: ['limit'],
      jsonArray: ['projects', 'ventures', 'funding_people']
    }
  },
  getBody: function (params: Object) {
    let newParams = Object.entries(params).map(([target, paramValue]) => {
      const {
        [target]: { defaultParams = {} }
      } = this.targets
      return { ...defaultParams, ...paramValue }
    })
    return {
      body: {
        sid: this.sid,
        params: newParams
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = [], targetNameData = '') {
    const listData = findData(dataFromAPI, targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return {}
    let targets = this.targets || {}
    let { [targetNameData]: targetConfig } = targets
    let { jsonArray = [], jsonObject = [] } = targetConfig
    return targetConfig.hasOwnProperty('jsonArray')
      ? encodeJsonArrayObject(listData, jsonArray)
      : encodeJsonObject(listData, jsonObject)
  }
}

export const structDashboardProjectsOnly: StructConfigType = {
  sid: '65defbce87bb7d9e220732b2',
  name: 'dashboard_projects_only',
  constant: {
    DEFAULT: 0,
    FILTER_SORT_CHANGE_24h: 1,
    FILTER_SORT_MARKET_CAP: 2,
    FILTER_SORT_TOTAL_FUNDRASING: 3,
    ASC: 'asc',
    DESC: 'desc',
    DEFAULT_SORT: ''
  },
  targetNameData: 'dashboard_projects_only',
  paramsOfAPI: ['limit', 'sortFilterType', 'sortType'],
  defaultParams: {
    limit: 8,
    sortFilterType: 0,
    sortType: ''
  },
  getBody: function (params: Object) {
    const paramsBuild = {
      ...this.defaultParams,
      ...params
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['ventures', 'peoples'])
  }
}

export const structDashboardFundRasingOnly: StructConfigType = {
  sid: '65e04643eba137495b0128b2',
  name: 'dashboard_fundrasing_only',
  targetNameData: 'dashboard_fundrasing_only',
  paramsOfAPI: ['limit'],
  defaultParams: {
    limit: 8
  },
  getBody: function (params: Object) {
    const paramsBuild = {
      ...this.defaultParams,
      ...params
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['projects'])
  }
}

export const structDashboardNewsOnly: StructConfigType = {
  sid: '65f266eb275ce4ae9c0cb512',
  name: 'dashboard_news_only',
  targetNameData: 'dashboard_news_only',
  paramsOfAPI: ['limit'],
  defaultParams: {
    limit: 8
  },
  getBody: function (params: Object) {
    const paramsBuild = {
      ...this.defaultParams,
      ...params
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['projects'])
  }
}

export const structVentureSumary: StructConfigType = {
  sid: '6571a21fba00ab91a102a355',
  name: 'details_venture_summary',
  targetNameData: 'venture_summary',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonObject(listData[0], ['related_token', 'social', 'employees', 'founder', 'recent_news', 'sector'])
  }
}

export const structPeopleSumary: StructConfigType = {
  sid: '657038fd4745ac413d046de9',
  name: 'details_people_summary',
  targetNameData: 'people_summary',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonObject(listData[0], [
      'primary_organization',
      'job_title',
      'social',
      'experience',
      'recent_news',
      'rel_tokens',
      'projects_join',
      'ventures',
      'founder',
      'portfolio_angel_investor',
      'sector'
    ])
  }
}

export const structPeoplePersonalInvestment: StructConfigType = {
  sid: '65a52491ff36288f6c0146a6',
  name: 'details_people_personal_investment',
  targetNameData: 'people_personal_investment',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonArrayObject(listData, [])
  }
}

export const structPeopleNewsActivities: StructConfigType = {
  sid: '65a524ebb5746c1a1d0b81e7',
  name: 'details_people_news_activities',
  targetNameData: 'people_news_activities',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonArrayObject(listData, [])
  }
}

export const structVentureFinancials: StructConfigType = {
  sid: '6572dbeaba4a443b8300116d',
  name: 'details_venture_financials',
  targetNameData: 'venture_financials',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonArrayObject(listData, ['projects'])
  }
}

export const structVentureFundsRaised: StructConfigType = {
  sid: '65a5f66f4a77ccf3170a1234',
  name: 'details_venture_financials',
  targetNameData: 'venture_funds_raised',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonObject(listData[0], ['funds_raise'])
  }
}

export const structHomeTotal: StructConfigType = {
  sid: '6579625a6663a36aee027e4a',
  name: 'home',
  targetNameData: 'home_total',
  paramsOfAPI: [],
  getBody: function () {
    return {
      body: {
        sid: this.sid,
        params: []
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonArrayObject(listData, [])
  }
}

export const structDetailsProjectSummary: StructConfigType = {
  sid: '65ab4fb4b065c160f3002554',
  name: 'details_project_summary',
  targetNameData: 'project_summary',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonObject(listData[0], [
      'founders',
      'funding_funds',
      'funding_peoples',
      'sector',
      'markets',
      'contracts',
      'news_proj',
      'all_member',
      'employees'
    ])
  }
}

export const structProjectDistribution: StructConfigType = {
  sid: '65a9df9d268fc9e16408bd37',
  name: 'details_project_distribution',
  targetNameData: 'project_distribution',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonArrayObject(listData, [])
  }
}

type ParamsMasterProjectType = {
  filterType: 'filFinancials'
  childFilterType: 'pastMonths' | 'custom'
  customFrom: string
  customTo: string
  pastMonths: number | string
  skip: number | string
  take: number | string
}
interface StructMasterProjectsConfigType extends StructConfigType {
  getBody: (params: ParamsMasterProjectType) => {}
}

export const structMasterProjects: StructMasterProjectsConfigType = {
  sid: '661615a7b0872f9e29026d6d',
  name: 'vt_master_projects',
  targetNameData: 'master_projects_demo',
  constant: {
    NO_TOKEN: 'NoToken',
    FILTER_FINANCIALS: 'Financials',
    TOTAL_FUNDRAISED: 'TotalFundRaised',
    TOKEN_RELEASE_SCHEDULE: 'TokenReleaseSchedule',
    EVENTS: 'Events',
    SECTOR: 'Sector',
    RELATED_PEOPLE_ID: 'RelatedPeopleID',
    RELATED_ORGANIZATION_ID: 'RelatedOrganizationID'
  },
  paramsOfAPI: [
    'searchAssetValue',
    'applyFilterFinancials',
    'applyFilterTotalFundRaised',
    'applyFilterSector',
    'applyFilterNoToken',
    'applyFilterRelatedPeopleID',
    'applyFilterRelatedOrganizationID',
    'filterFinancialsOptionsUse',
    'filterFinancialsCustomFrom',
    'filterFinancialsCustomTo',
    'filterFinancialsValue',
    'filterNoTokenValue',
    'filterTotalFundRaisedOptionsUse',
    'filterTotalFundRaisedCustomFrom',
    'filterTotalFundRaisedCustomTo',
    'filterSectorValue',
    'filterRelatedPeopleIDValue',
    'filterRelatedOrganizationIDValue',
    'skip',
    'take'
  ],
  defaultParamsBuilder: {
    searchAssetValue: '',
    skip: 0,
    take: 100,
    wantFilter: true,
    filters: {
      NoToken: {
        // value: 1
      },
      Financials: {
        // value: 'Financials',
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // },
      },
      TotalFundRaised: {
        // value: 'TotalFundRaised',
        // options: {
        //   CustomFrom: 0,
        //   CustomTo: 100_000_000
        // }
      },
      TokenReleaseSchedule: {
        // value: 'TokenReleaseSchedule'
      },
      Events: {
        // value: 'Events',
      },
      Sector: {
        // value: []
      },
      RelatePeopleID: {
        // value: [1, 2]
      },
      RelateOrganizationID: {
        // value: []
      }
    }
  },
  defaultParams: {
    searchAssetValue: '',
    applyFilterFinancials: 0,
    applyFilterSector: 0,
    applyFilterNoToken: 0,
    applyFilterRelatedPeopleID: 0,
    applyFilterRelatedOrganizationID: 0,
    filterFinancialsOptionsUse: 0,
    filterFinancialsCustomFrom: '',
    filterFinancialsCustomTo: '',
    filterFinancialsValue: 12,
    filterNoTokenValue: 1,
    filterTotalFundRaisedOptionsUse: 0,
    filterTotalFundRaisedCustomFrom: '',
    filterTotalFundRaisedCustomTo: '',
    filterSectorValue: [],
    filterRelatedPeopleIDValue: [],
    filterRelatedOrganizationIDValue: [],
    skip: 0,
    take: 100
  },

  getBody: function (params: { skip: string | number; take: string | number }) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [encodeBodyWithParamsArrayNew(paramsBuild, ['Sector', 'RelatedPeopleID', 'RelatedOrganizationID'])]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [
      'fundraising_projects',
      'organizations',
      'peoples',
      'angel_investor',
      'sector'
    ])
  }
}

export const structMasterPeoples: StructConfigType = {
  sid: '66166195f786bf82590531e2',
  name: 'vt_master_people',
  targetNameData: 'master_peoples_demo',
  constant: {
    CURRENT_ORGANIZATION_ID: 'CurrentOrganizationID',
    GENDER: 'Gender',
    TYPE_OF_INVESTOR: 'TypeOfInvestor',
    JOBTITLE: 'Jobtitle',
    SECTOR: 'Sector',
    RELATED_ORGANIZATION_ID: 'RelatedOrganizationID',
    RELATED_TOKEN: 'RelatedToken'
  },
  paramsOfAPI: [
    'skip',
    'take',
    'searchAssetValue',
    'applyFilterCurrentOrganizationID',
    'applyFilterGender',
    'applyFilterJobtitle',
    'applyFilterSector',
    'applyFilterRelatedToken',
    'applyFilterRelatedOrganizationID',
    'filterCurrentOrganizationIDProjectID',
    'filterCurrentOrganizationIDVentureID',
    'filterJobtitleValue',
    'filterSectorValue',
    'filterRelatedTokenValue',
    'filterGenderValue',
    'filterTypeOfInvestorValue',
    'filterRelatedOrganizationIDValue'
  ],
  defaultParams: {
    skip: 0,
    take: 100,
    searchAssetValue: '',
    applyFilterCurrentOrganizationID: 0,
    applyFilterRelatedOrganizationID: 0,
    applyFilterGender: 0,
    applyFilterJobtitle: 0,
    applyFilterSector: 0,
    applyFilterRelatedToken: 0,
    filterCurrentOrganizationIDProjectID: [],
    filterCurrentOrganizationIDVentureID: [],
    filterJobtitleValue: [],
    filterSectorValue: [],
    filterRelatedTokenValue: [],
    filterRelatedOrganizationIDValue: [],
    filterGenderValue: '',
    filterTypeOfInvestorValue: ''
  },
  defaultParamsBuilder: {
    searchAssetValue: '',
    skip: 0,
    take: 100,
    wantFilter: true,
    filters: {
      CurrentOrganizationID: {
        // options: {
        //   VentureID: [],
        //   ProjectID: []
        // }
      },
      Gender: {
        // value: ''
      },
      TypeOfInvestor: {
        //value: ''
      },
      Jobtitle: {
        //value: []
      },
      // Localtion: {

      // },
      Sector: {
        //value: []
      },
      RelatedOrganizationID: {
        //value: []
      },
      RelatedToken: {
        //value: []
      }
    }
  },
  getBody: function (params: { skip: string | number; take: string | number }) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [
          encodeBodyWithParamsArrayNew(paramsBuild, [
            'Sector',
            'Jobtitle',
            'CurrentOrganizationIDProjectID',
            'CurrentOrganizationIDVentureID',
            'RelatedToken',
            'RelatedOrganizationID'
          ])
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [
      'primary_job_title',
      'organization',
      'relate_token',
      'portfolio_angel_investor',
      'sector'
    ])
  }
}

export const structMasterVentures: StructConfigType = {
  sid: '66164e8e9f4a288570048b12',
  name: 'vt_master_funds',
  targetNameData: 'master_ventures_demo',
  constant: {
    FINANCIALS: 'Financials',
    VENTURE_TYPE: 'VentureType',
    SECTOR: 'Sector',
    ACITVELY_INVESTING: 'AcitvelyInvesting',
    TOTAL_FUNDRAISED: 'TotalFundRaised',
    RELATED_TOKEN_ID: 'RelatedTokenID',
    RELATED_PEOPLE_ID: 'RelatedPeopleID',
    FUNDING_STAGE: 'FundingStage'
  },
  paramsOfAPI: [
    'skip',
    'take',
    'searchAssetValue',
    'applyFilterSector',
    'applyFilterFinancials',
    'applyFilterVentureType',
    'applyFilterAcitvelyInvesting',
    'applyFilterFundingStage',
    'applyFilterTotalFundRaised',
    'applyFilterRelatedTokenID',
    'applyFilterRelatedPeopleID',
    'filterSectorValue',
    'filterFinancialsOptionsUse',
    'filterFinancialsValue',
    'filterFinancialsCustomFrom',
    'filterFinancialsCustomTo',
    'filterFundingStageOptionsUse',
    'filterFundingStageValue',
    'filterFundingStageCustomFrom',
    'filterFundingStageCustomTo',
    'filterVentureTypeValue',
    'filterAcitvelyInvestingValue',
    'filterTotalFundRaisedOptionsUse',
    'filterTotalFundRaisedCustomFrom',
    'filterTotalFundRaisedCustomTo',
    'filterRelatedTokenIDValue',
    'filterRelatedPeopleIDValue'
  ],
  defaultParams: {
    skip: 0,
    take: 100,
    searchAssetValue: '',
    applyFilterSector: 0,
    applyFilterFinancials: 0,
    applyFilterVentureType: 0,
    applyFilterAcitvelyInvesting: 0,
    applyFilterFundingStage: 0,
    applyFilterTotalFundRaised: 0,
    applyFilterRelatedTokenID: 0,
    applyFilterRelatedPeopleID: 0,
    filterSectorValue: [],
    filterFinancialsOptionsUse: 0,
    filterFinancialsValue: 12,
    filterFinancialsCustomFrom: '',
    filterFinancialsCustomTo: '',
    filterFundingStageOptionsUse: 0,
    filterFundingStageValue: 1,
    filterFundingStageCustomFrom: '',
    filterFundingStageCustomTo: '',
    filterVentureTypeValue: [],
    filterAcitvelyInvestingValue: '',
    filterTotalFundRaisedOptionsUse: 0,
    filterTotalFundRaisedCustomFrom: '',
    filterTotalFundRaisedCustomTo: '',
    filterRelatedTokenIDValue: [],
    filterRelatedPeopleIDValue: []
  },
  defaultParamsBuilder: {
    searchAssetValue: '',
    skip: 0,
    take: 100,
    filters: {
      // Location: {},
      Financials: {
        // value: 'Financials'
        // options: {
        //   CustomFrom: 'CustomFrom',
        //   CustomTo: 'CustomTo'
        // }
      },
      VentureType: {
        // value: []
      },
      Sector: {
        // value: []
      },
      FundingStage: {
        // value: ''
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      AcitvelyInvesting: {
        // value: ''
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      // FundingAmount: {},
      TotalFundRaised: {
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      RelatedTokenID: {
        // value: []
      },
      RelatedPeopleID: {
        // value: []
      }
    }
  },
  getBody: function (params: { skip: string | number; take: string | number }) {
    // debugger
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [
          encodeBodyWithParamsArrayNew(paramsBuild, ['Sector', 'VentureType', 'RelatedTokenID', 'RelatedPeopleID'])
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['founder', 'employees', 'relate_project', 'sector'])
  }
}

export const structMasterFundraising: StructConfigType = {
  sid: '66178d1633d269041e0086c2',
  name: 'vt_master_fundraising',
  targetNameData: 'master_fundraising_demo',
  constant: {},
  paramsOfAPI: ['searchAssetValue', 'skip', 'take'],
  defaultParams: {
    searchAssetValue: '',
    skip: 0,
    take: 100
  },

  getBody: function (params: { skip: string | number; take: string | number }) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [encodeBodyWithParamsArrayNew(paramsBuild, [])]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['projects', 'organizations', 'people'])
  }
}

export const structMasterProjectsTotalAsset: StructMasterProjectsConfigType = {
  sid: '65eae4029e851ab1d9081242',
  name: 'master_projects_total_asset_demo',
  targetNameData: 'master_projects_total_asset_demo',
  constant: {
    NO_TOKEN: 'NoToken',
    FILTER_FINANCIALS: 'Financials',
    TOTAL_FUNDRAISED: 'TotalFundRaised',
    TOKEN_RELEASE_SCHEDULE: 'TokenReleaseSchedule',
    EVENTS: 'Events',
    SECTOR: 'Sector',
    RELATED_PEOPLE_ID: 'RelatedPeopleID',
    RELATED_ORGANIZATION_ID: 'RelatedOrganizationID'
  },
  paramsOfAPI: [
    'searchAssetValue',
    'applyFilterFinancials',
    'applyFilterTotalFundRaised',
    'applyFilterSector',
    'applyFilterNoToken',
    'applyFilterRelatedPeopleID',
    'applyFilterRelatedOrganizationID',
    'filterFinancialsOptionsUse',
    'filterFinancialsCustomFrom',
    'filterFinancialsCustomTo',
    'filterFinancialsValue',
    'filterNoTokenValue',
    'filterTotalFundRaisedOptionsUse',
    'filterTotalFundRaisedCustomFrom',
    'filterTotalFundRaisedCustomTo',
    'filterSectorValue',
    'filterRelatedPeopleIDValue',
    'filterRelatedOrganizationIDValue'
  ],
  defaultParamsBuilder: {
    searchAssetValue: '',
    wantFilter: true,
    filters: {
      NoToken: {
        // value: 1
      },
      Financials: {
        // value: 'Financials',
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // },
      },
      TotalFundRaised: {
        // value: 'TotalFundRaised',
        // options: {
        //   CustomFrom: 0,
        //   CustomTo: 100_000_000
        // }
      },
      TokenReleaseSchedule: {
        // value: 'TokenReleaseSchedule'
      },
      Events: {
        // value: 'Events',
      },
      Sector: {
        // value: []
      },
      RelatePeopleID: {
        // value: [1, 2]
      },
      RelateOrganizationID: {
        //
      }
    }
  },
  defaultParams: {
    searchAssetValue: '',
    applyFilterFinancials: 0,
    applyFilterSector: 0,
    applyFilterNoToken: 0,
    applyFilterRelatedPeopleID: 0,
    applyFilterRelatedOrganizationID: 0,
    filterFinancialsOptionsUse: 0,
    filterFinancialsCustomFrom: '',
    filterFinancialsCustomTo: '',
    filterFinancialsValue: 12,
    filterNoTokenValue: 1,
    filterTotalFundRaisedOptionsUse: 0,
    filterTotalFundRaisedCustomFrom: '',
    filterTotalFundRaisedCustomTo: '',
    filterSectorValue: [],
    filterRelatedPeopleIDValue: [],
    filterRelatedOrganizationIDValue: []
  },
  getBody: function (params: ParamsMasterProjectType) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [
          encodeBodyWithParamsArrayNew(paramsBuild, [
            'Sector',
            'RelatedPeopleID',
            'RelatedPeopleID',
            'RelatedOrganizationID'
          ])
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData) || []
    if (listData.length === 0) return 0
    return listData[0]?.total || 0
  }
}

export const structMasterPeoplesTotalAsset: StructConfigType = {
  sid: '65dd85f0c06ec23c8e000dc3',
  name: 'master_peoples_total_asset_demo',
  targetNameData: 'master_peoples_total_asset_demo',
  paramsOfAPI: [
    'searchAssetValue',
    'applyFilterCurrentOrganizationID',
    'applyFilterGender',
    'applyFilterJobtitle',
    'applyFilterSector',
    'applyFilterRelatedToken',
    'applyFilterRelatedOrganizationID',
    'filterCurrentOrganizationIDProjectID',
    'filterCurrentOrganizationIDVentureID',
    'filterJobtitleValue',
    'filterSectorValue',
    'filterRelatedTokenValue',
    'filterGenderValue',
    'filterTypeOfInvestorValue',
    'filterRelatedOrganizationIDValue'
  ],
  defaultParams: {
    searchAssetValue: '',
    applyFilterCurrentOrganizationID: 0,
    applyFilterRelatedOrganizationID: 0,
    applyFilterGender: 0,
    applyFilterJobtitle: 0,
    applyFilterSector: 0,
    applyFilterRelatedToken: 0,
    filterCurrentOrganizationIDProjectID: [],
    filterCurrentOrganizationIDVentureID: [],
    filterJobtitleValue: [],
    filterSectorValue: [],
    filterRelatedTokenValue: [],
    filterRelatedOrganizationIDValue: [],
    filterGenderValue: '',
    filterTypeOfInvestorValue: ''
  },
  defaultParamsBuilder: {
    searchAssetValue: '',
    wantFilter: true,
    filters: {
      CurrentOrganizationID: {
        // options: {
        //   VentureID: [],
        //   ProjectID: []
        // }
      },
      Gender: {
        //value: ''
      },
      TypeOfInvestor: {
        //value: ''
      },
      Jobtitle: {
        //value: []
      },
      // Localtion: {

      // },
      Sector: {
        // value: []
      },
      RelatedOrganizationID: {
        // value: []
      },
      RelatedToken: {
        // value: []
      }
    }
  },
  getBody: function (params: Object) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [
          encodeBodyWithParamsArrayNew(paramsBuild, [
            'Sector',
            'Jobtitle',
            'CurrentOrganizationIDProjectID',
            'CurrentOrganizationIDVentureID',
            'RelatedToken',
            'RelatedOrganizationID'
          ])
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData) || []
    if (listData.length === 0) return 0
    return listData[0]?.total || 0
  }
}

export const structMasterVenturesTotalAsset: StructConfigType = {
  sid: '65ec43cda134e4443704aa82',
  name: 'master_ventures_total_asset_demo',
  targetNameData: 'master_ventures_total_asset_demo',
  paramsOfAPI: [
    'searchAssetValue',
    'applyFilterSector',
    'applyFilterFinancials',
    'applyFilterVentureType',
    'applyFilterAcitvelyInvesting',
    'applyFilterFundingStage',
    'applyFilterTotalFundRaised',
    'applyFilterRelatedTokenID',
    'applyFilterRelatedPeopleID',
    'filterSectorValue',
    'filterFinancialsOptionsUse',
    'filterFinancialsValue',
    'filterFinancialsCustomFrom',
    'filterFinancialsCustomTo',
    'filterFundingStageOptionsUse',
    'filterFundingStageValue',
    'filterFundingStageCustomFrom',
    'filterFundingStageCustomTo',
    'filterVentureTypeValue',
    'filterAcitvelyInvestingValue',
    'filterTotalFundRaisedOptionsUse',
    'filterTotalFundRaisedCustomFrom',
    'filterTotalFundRaisedCustomTo',
    'filterRelatedTokenIDValue',
    'filterRelatedPeopleIDValue'
  ],
  defaultParams: {
    searchAssetValue: '',
    applyFilterSector: 0,
    applyFilterFinancials: 0,
    applyFilterVentureType: 0,
    applyFilterAcitvelyInvesting: 0,
    applyFilterFundingStage: 0,
    applyFilterTotalFundRaised: 0,
    applyFilterRelatedTokenID: 0,
    applyFilterRelatedPeopleID: 0,
    filterSectorValue: [],
    filterFinancialsOptionsUse: 0,
    filterFinancialsValue: 12,
    filterFinancialsCustomFrom: '',
    filterFinancialsCustomTo: '',
    filterFundingStageOptionsUse: 0,
    filterFundingStageValue: 1,
    filterFundingStageCustomFrom: '',
    filterFundingStageCustomTo: '',
    filterVentureTypeValue: [],
    filterAcitvelyInvestingValue: '',
    filterTotalFundRaisedOptionsUse: 0,
    filterTotalFundRaisedCustomFrom: '',
    filterTotalFundRaisedCustomTo: '',
    filterRelatedTokenIDValue: [],
    filterRelatedPeopleIDValue: []
  },
  defaultParamsBuilder: {
    searchAssetValue: '',
    skip: 0,
    take: 100,
    filters: {
      // Location: {},
      Financials: {
        // value: 'Financials'
        // options: {
        //   CustomFrom: 'CustomFrom',
        //   CustomTo: 'CustomTo'
        // }
      },
      VentureType: {
        // value: []
      },
      Sector: {
        // value: []
      },
      FundingStage: {
        // value: ''
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      AcitvelyInvesting: {
        // value: ''
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      // FundingAmount: {},
      TotalFundRaised: {
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // }
      },
      RelatedTokenID: {
        // value: []
      },
      RelatedPeopleID: {
        // value: []
      }
    }
  },
  getBody: function (params: Object) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [
          encodeBodyWithParamsArrayNew(paramsBuild, ['Sector', 'VentureType', 'RelatedTokenID', 'RelatedPeopleID'])
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData) || []
    if (listData.length === 0) return 0
    return listData[0]?.total || 0
  }
}

export const structMasterFundraisingTotalAsset: StructConfigType = {
  sid: '66176396f6022fc1d90e7ea8',
  name: 'master_fundraising_total_asset',
  targetNameData: 'master_fundraising_total_asset',
  paramsOfAPI: ['searchAssetValue'],
  defaultParams: {
    searchAssetValue: ''
  },
  getBody: function (params: Object) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [encodeBodyWithParamsArrayNew(paramsBuild, [])]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData) || []
    if (listData.length === 0) return 0
    return listData[0]?.total || 0
  }
}

export const structDetailsFundraisingProjects: StructConfigType = {
  sid: '65b8794645a7dbdfdc0f0806',
  name: 'details_fundraising_projects',
  targetNameData: 'details_fundraising_projects',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return {}
    if (listData.length === 0) return {}
    return encodeJsonObject(listData[0], ['investors'])
  }
}

export const structSearchOrganizationCurrent: StructConfigType = {
  sid: '65a7afcbf51108ab9b001628',
  name: 'search_organization_current',
  targetNameData: 'search_organization_current',
  paramsOfAPI: ['searchText', 'take', 'skip'],
  defaultParams: {
    searchText: '',
    skip: 0,
    take: 20
  },
  getBody: function (params: { skip: string | number; take: string | number }) {
    const paramsBuild = {
      ...this.defaultParams,
      ...params
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchJobtitle: StructConfigType = {
  sid: '65a7afcbf51108ab9b001629',
  name: 'search_jobtitle',
  targetNameData: 'search_jobtitle',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchSector: StructConfigType = {
  sid: '65b104fe1d37b61a380c4f5b',
  name: 'search_sector',
  targetNameData: 'search_sector',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchPeople: StructConfigType = {
  sid: '65eae1d2f60cb3f965007282',
  name: 'search_people',
  targetNameData: 'search_people',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchOrganization: StructConfigType = {
  sid: '65eae1d2f60cb3f965007283',
  name: 'search_organization',
  targetNameData: 'search_organization',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchToken: StructConfigType = {
  sid: '65eae1d3f60cb3f965007284',
  name: 'search_token',
  targetNameData: 'search_token',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structSearchGlobal: StructConfigType = {
  sid: '66024cd706024846d80b8be2',
  name: 'search_global',
  targetNameData: 'search_global',
  paramsOfAPI: ['searchValue', 'limit'],
  defaultParams: {
    searchValue: 'Bitcoin',
    limit: 30
  },
  getBody: function (params: Object) {
    const paramsBuild = {
      ...this.defaultParams,
      ...params
    }
    return {
      body: {
        sid: this.sid,
        params: [
          {
            ...paramsBuild,
            searchValue: !paramsBuild.searchValue.trim()
              ? 'Bitcoin'
              : paramsBuild.searchValue
                  .trim()
                  .split(' ')
                  .map((v) => `*${v}*`)
                  .join(' ')
          }
        ]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['project', 'people', 'venture'])
  }
}

export const structSearchVentureType: StructConfigType = {
  sid: '65ec34c0b20e20c4db07d972',
  name: 'search_venture_type',
  targetNameData: 'search_venture_type',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

//lasted update
export const structSearchCurrentOrganization: StructConfigType = {
  sid: '65ec2a90bc28e0d3800cf80d',
  name: 'search_current_organization',
  targetNameData: 'search_current_organization',
  paramsOfAPI: [],
  defaultParams: {},
  getBody: function () {
    const paramsBuild = {
      ...this.defaultParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, [])
  }
}

export const structVTMasterProjects: StructMasterProjectsConfigType = {
  sid: '661615a7b0872f9e29026d6d',
  targetNameData: 'master_projects_demo',
  paramsOfAPI: [
    'searchAssetValue',
    'applyFilterFinancials',
    'applyFilterTotalFundRaised',
    'applyFilterSector',
    'applyFilterNoToken',
    'applyFilterRelatedPeopleID',
    'applyFilterRelatedOrganizationID',
    'filterFinancialsOptionsUse',
    'filterFinancialsCustomFrom',
    'filterFinancialsCustomTo',
    'filterFinancialsValue',
    'filterNoTokenValue',
    'filterTotalFundRaisedOptionsUse',
    'filterTotalFundRaisedCustomFrom',
    'filterTotalFundRaisedCustomTo',
    'filterSectorValue',
    'filterRelatedPeopleIDValue',
    'filterRelatedOrganizationIDValue',
    'skip',
    'take'
  ],
  defaultParamsBuilder: {
    searchAssetValue: '',
    skip: 0,
    take: 100,
    wantFilter: true,
    filters: {
      NoToken: {
        // value: 1
      },
      Financials: {
        // value: 'Financials',
        // options: {
        //   CustomFrom: '',
        //   CustomTo: ''
        // },
      },
      TotalFundRaised: {
        // value: 'TotalFundRaised',
        // options: {
        //   CustomFrom: 0,
        //   CustomTo: 100_000_000
        // }
      },
      TokenReleaseSchedule: {
        // value: 'TokenReleaseSchedule'
      },
      Events: {
        // value: 'Events',
      },
      Sector: {
        // value: []
      },
      RelatePeopleID: {
        // value: [1, 2]
      },
      RelateOrganizationID: {
        // value: []
      }
    }
  },
  defaultParams: {
    searchAssetValue: '',
    applyFilterFinancials: 0,
    applyFilterSector: 0,
    applyFilterNoToken: 0,
    applyFilterRelatedPeopleID: 0,
    applyFilterRelatedOrganizationID: 0,
    filterFinancialsOptionsUse: 0,
    filterFinancialsCustomFrom: '',
    filterFinancialsCustomTo: '',
    filterFinancialsValue: 12,
    filterNoTokenValue: 1,
    filterTotalFundRaisedOptionsUse: 0,
    filterTotalFundRaisedCustomFrom: '',
    filterTotalFundRaisedCustomTo: '',
    filterSectorValue: [],
    filterRelatedPeopleIDValue: [],
    filterRelatedOrganizationIDValue: [],
    skip: 0,
    take: 100
  },

  getBody: function (params: { skip: string | number; take: string | number }) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [encodeBodyWithParamsArrayNew(paramsBuild, ['Sector', 'RelatedPeopleID', 'RelatedOrganizationID'])]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['fundraising_projects', 'organizations', 'peoples', 'sector'])
  }
}

export const structVTMasterVentures: StructMasterProjectsConfigType = {
  sid: '660d3f3c433e0dedec060828',
  targetNameData: 'master_ventures_demo',
  paramsOfAPI: ['skip', 'take'],
  defaultParamsBuilder: {
    skip: 0,
    take: 100
  },
  defaultParams: {
    skip: 0,
    take: 100
  },

  getBody: function (params) {
    let newParams = paramFilterBuilder(params, this.paramsOfAPI)
    const paramsBuild = {
      ...this.defaultParams,
      ...newParams
    }
    return {
      body: {
        sid: this.sid,
        params: [paramsBuild]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['founder', 'employees', 'relate_project', 'sector'])
  }
}

export const structProjectEvents: StructConfigType = {
  sid: '666a681e0eafa118950cf0b2',
  name: 'project_events',
  targetNameData: 'project_events',
  paramsOfAPI: ['slug'],
  getBody: function (params: { slug: string }) {
    return {
      body: {
        sid: this.sid,
        params: [params]
      }
    }
  },
  getData: function (dataFromAPI: Array<any> = []) {
    const listData = findData(dataFromAPI, this.targetNameData)
    if (listData === undefined) return []
    if (listData.length === 0) return []
    return encodeJsonArrayObject(listData, ['project'])
  }
}
