import inputStyle from './Input.module.css'
import { useEffect, useState } from 'react'
import { ValidateUtils } from '@src/util/Validate'
import SvgCommon from '../common/SvgCommon'
import iconElimate from '@src/assets/eliminate.svg'
import iconShowEyes from '@src/assets/iconShowHide.svg'
import iconHideEyes from '@src/assets/iconHide.svg'
import clsx from 'clsx'
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons'
const InputSigninSignup = ({
  showEyes,
  inputTitle,
  optional = '',
  inputCode = false,
  emailExist = '',
  rule = [],
  isValidation,
  onChange,
  onDeleteInput,
  onError,
  otherError = '',
  usernameError = '',
  isValidUsername = false,
  beginValueInput = '',
  customClassNameInput,
  keepDeleteBox = false,
  isLoading,
  ...propsInput
}) => {
  const [isShowEyes, setIsShowEyes] = useState(!!showEyes)
  const [messageError, setMessageError] = useState('')
  const [showError, setShowError] = useState(false)
  const [inputValue, setInputValue] = useState(beginValueInput)

  const handleHideShowEyes = () => {
    setIsShowEyes(!isShowEyes)
  }
  const handleInput = (e) => {
    setShowError(false)
    setInputValue(e.target.value)
    onChange && onChange(e)
  }

  const handleDeleteInput = () => {
    setInputValue('')
    setShowError(false)

    onDeleteInput && onDeleteInput()
  }
  useEffect(() => {
    if (isValidation) {
      const { messages } = ValidateUtils({
        rules: rule,
        value: inputValue
      })
      onError && onError(propsInput.name, messages)

      setMessageError(messages)
      setShowError(true)
    }
  }, [isValidation])
  return (
    <>
      {inputTitle ? (
        <div className={inputStyle.inputTypeTextContainer}>
          <span
            className={inputStyle.inputTypeText}
            style={
              (showError && (messageError !== '' || otherError !== '')) || (usernameError !== '' && inputValue !== '')
                ? { color: '#D03131' }
                : {}
            }
          >
            {inputTitle}
            <span
              style={{ font: 'Nunito', fontWeight: '400', fontSize: '15px', lineHeight: '20.46px', color: '#36373999' }}
            >
              {optional ? ` (${optional})` : null}
            </span>
          </span>
        </div>
      ) : null}
      <div
        className={inputCode ? inputStyle.inputCodeWrapper : inputStyle.loginPass}
        style={
          (showError && (messageError !== '' || otherError !== '')) || (usernameError !== '' && inputValue !== '')
            ? { borderColor: '#D03131' }
            : {}
        }
      >
        <input
          autoComplete='new-password'
          className={clsx(inputCode ? inputStyle.inputCode : inputStyle.inputLoginPass, customClassNameInput)}
          onChange={handleInput}
          value={inputValue}
          width={propsInput.width}
          {...propsInput}
          type={!isShowEyes && showEyes ? 'text' : propsInput.type}
        />

        {keepDeleteBox && !showEyes && inputValue === '' ? <div style={{ width: '16px', height: '16px' }} /> : null}
        {inputCode ? null : !showEyes && inputValue !== '' ? (
          isLoading ? (
            <LoadingOutlined className={inputStyle.eliminateIconWrapper} />
          ) : (
            <div style={{ width: '16px', height: '16px' }}>
              <div className={inputStyle.eliminateIconWrapper} onClick={handleDeleteInput}>
                <SvgCommon src={iconElimate} />
              </div>
            </div>
          )
        ) : null}
        {showEyes ? (
          <div onClick={handleHideShowEyes}>
            <SvgCommon alt='eye' src={isShowEyes ? iconShowEyes : iconHideEyes} />
          </div>
        ) : null}
      </div>
      {showError && messageError !== '' ? <div className={inputStyle.errorText}>{messageError}</div> : null}
      {showError && messageError === '' && otherError !== '' ? (
        <div className={inputStyle.errorText}>{otherError}</div>
      ) : null}
      {showError && emailExist && emailExist !== '' ? <div className={inputStyle.errorText}>{emailExist}</div> : null}
      {/* {usernameError && inputValue ? <div className={inputStyle.errorText}>{usernameError}</div> : null} */}
    </>
  )
}
export default InputSigninSignup
