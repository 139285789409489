import React from 'react'
import styleNoti from './Notifications.module.css'
import clsx from 'clsx'
import SvgCommon from '@src/components/common/SvgCommon'

const Notifications = ({ data = [] }) => {
  return (
    <div className={styleNoti.container_noti}>
      <div className={styleNoti.noti_header}>
        <span className={styleNoti.noti_header_title}>Notifications</span>
        <span className={clsx(styleNoti.noti_header_all_read, 'text_decoration_underline')}>Mask all as read</span>
      </div>
      <div className={styleNoti.list_noti}>
        {data?.length > 0 ? <></> : <SvgCommon src='/assets/images/Header/icon_empty_noti.svg' />}
      </div>
    </div>
  )
}

export default Notifications
