import { useEffect } from 'react'
import WebFont from 'webfontloader'

document.body.style.visibility = 'hidden'

const WebFontLoader = ({ config }) => {
  useEffect(() => {
    WebFont.load({
      ...config,

      active: function () {
        document.body.style.visibility = 'visible'
        console.log('Font is available')
      },
      inactive: function () {
        document.body.style.visibility = 'visible'
        console.log('Font is not available')
      }
    })

    return () => {
      document.body.style.visibility = 'hidden'
    }
  }, [config])

  return null
}

export default WebFontLoader
