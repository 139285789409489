// import useLoginPopup from '@src/hooks/useLoginPopup'
import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export const useModal = () => useContext(ModalContext)

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState({})
  // TODO: enable input when pass value from browser to modal
  // const [showPopup] = useLoginPopup()

  // const openModal = () => setModalOpen(true)
  // const closeModal = () => setModalOpen(false)

  // useEffect(() => {
  //   if (showPopup) {
  //     setModalOpen({ showPopup: true })
  //   }
  // }, [showPopup])

  const openModal = (name) => {
    setModalOpen({ ...isModalOpen, [name]: true })
  }

  const closeModal = (name) => {
    setModalOpen({ ...isModalOpen, [name]: false })
  }

  return (
    <ModalContext.Provider value={{ isModalOpen, openModal, closeModal, setModalOpen }}>
      {children}
    </ModalContext.Provider>
  )
}
