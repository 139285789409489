import { useEffect, useState } from 'react'

const Countdown = ({ initialTime, counting, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime)

  useEffect(() => {
    let intervalId

    if (counting) {
      intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1)
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [counting, initialTime])

  useEffect(() => {
    if (timeLeft === 0) {
      onFinish()
    }
  }, [timeLeft])

  return (
    <div>
      {counting ? (
        <div>
          <span
            style={{
              width: '37px',
              height: '19px',
              fontFamily: 'Nunito',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '19px',
              color: '#36373999'
            }}
          >
            00:{timeLeft < 10 ? '0' + timeLeft : timeLeft}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default Countdown
