/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { motion } from 'framer-motion'
import SignupStyles from '../contentRight/FourView.module.css'
import { Link } from 'react-router-dom'

const FourView = ({ handleChangeView }) => {
  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      initial={{ opacity: 0, y: -20 }}
      layout
      transition={{ duration: 0.2 }}
    >
      <>
        <div className={SignupStyles.formLoginTitleContainer}>
          {' '}
          <span className={SignupStyles.formLoginTitle}>Join Statistr</span>
        </div>
        <div className={SignupStyles.smallTextContainer}>
          <span className={SignupStyles.smallText}>
            Your Google account <span className={SignupStyles.smallBoldText}>thebec.workspace@gmail.com</span> will be
            connected to your new Statistr account.
          </span>
          <div className={SignupStyles.smallestTextContainer}>
            <span className={SignupStyles.smallestText}>Wrong identity? </span>
            <Link to='/signin'>
              <span className={SignupStyles.smallestLinkText}>Start Over</span>
            </Link>
          </div>
        </div>
        <div className={SignupStyles.form}>
          <div>
            <div>
              {/* <button className={SignupStyles.loginBtn} onClick={handleChangeView} type='button'>
                <span className={SignupStyles.loginSigninDesc}>Next</span>
              </button> */}
              <button className={SignupStyles.loginBtn} type='button'>
                <span className={SignupStyles.loginSigninDesc}>Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className={SignupStyles.formLoginFooter}>
          <div className={SignupStyles.signUp}>
            <span className={SignupStyles.signUpText}>Already have an account?</span>
            <Link to='/signin'>
              <span className={SignupStyles.TextLink}>Sign In</span>
            </Link>
          </div>
          <div className={SignupStyles.footerTextContainer}>
            <span className={SignupStyles.footerText}>
              Creating an account signifies your agreement to our
              <span className={SignupStyles.footerUnderlinedText}>Terms of Service</span> &&nbsp;
              <span className={SignupStyles.footerUnderlinedText}>Privacy Policy</span>
            </span>
          </div>
        </div>
      </>
    </motion.div>
  )
}

export default FourView
