import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import SignUpStyles from './SecondView.module.css'
import { useNavigate } from 'react-router-dom'
import InputTypeCheckBox from '@src/components/InputSigninSignup/InputTypeCheckBox'
// import Input from '../component/Input'
import Input from '../../InputSigninSignup/inputSignup'
import iconArrow from '@src/assets/ArrowBack.svg'
import { useLazySendCodeQuery, useLazyCheckExistQuery } from '@src/redux-toolkit/Signin&SignUp/SigninAndSignUp.service'
import ButtonStatistr from '@src/components/ButtonStatistr'
import { useDebounceFn } from '@src/hooks'
import SvgCommon from '@src/components/common/SvgCommon'

const SecondView = ({ gmailSignup, onChangeView, handleBack, beginData, type, onBacktoSignIn, referralID }) => {
  const navigate = useNavigate()
  // const [dataForm, setDataForm] = useState({
  //   name: beginData?.name || '',
  //   username: beginData?.username || '',
  //   // first_name: beginData?.first_name || '',
  //   // last_name: beginData?.last_name || '',
  //   email: beginData?.email || '',
  //   password: ''
  // })
  const dataFormRef = useRef({
    name: beginData?.name || '',
    username: beginData?.username || '',
    // first_name: beginData?.first_name || '',
    // last_name: beginData?.last_name || '',
    email: beginData?.email || '',
    password: ''
  })
  const [validate, setValidate] = useState(false)
  const [formError, setFormError] = useState({
    name: '',
    username: '',
    // first_name: '',
    // last_name: '',
    email: '',
    password: '',
    // confirmPassword: '',
    checkbox: true
  })

  const [sendCode, { isSuccess: sendCodeSuccess, isLoading, error }] = useLazySendCodeQuery()
  const [
    checkExist,
    { isSuccess: checkExistSuccess, error: checkExistError, isFetching: checkExistLoading, data: dataAccount }
  ] = useLazyCheckExistQuery()

  const [
    checkEmailExist,
    { isSuccess: checkEmailExistSuccess, isFetching: checkEmailExistLoading, data: dataAccountEmail }
  ] = useLazyCheckExistQuery()

  const handleChangeDataForm = (e) => {
    const { name, value } = e.target
    setValidate(false)
    dataFormRef.current = { ...dataFormRef.current, [name]: value }
  }

  const handleClearInput = (name) => {
    dataFormRef.current = { ...dataFormRef.current, [name]: '' }
  }

  const handleError = (name, message) => {
    setFormError((pre) => ({
      ...pre,
      [name]: message
    }))
    setValidate(false)
  }

  const handleCheckbox = (isCheck) => {
    setValidate(false)
    setFormError((pre) => ({
      ...pre,
      checkbox: !isCheck
    }))
  }

  const handleContinue = () => {
    setValidate(true)
    if (
      !Object.values(formError).some((value) => value === true) &&
      !checkExistLoading &&
      (!checkExistSuccess || (checkExistSuccess && dataAccount[1]?.username !== dataFormRef.current.username)) &&
      !checkEmailExistLoading &&
      (!checkEmailExistSuccess || (checkEmailExistSuccess && dataAccountEmail[1]?.email !== dataFormRef.current.email))
    ) {
      sendCode(dataFormRef.current.email)
      // onChangeView(dataForm)
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleContinue()
    }
  }

  const handleUsername = useDebounceFn(
    (e) => {
      checkExist({
        param: {
          username: e.target.value
        }
      })
      dataFormRef.current = { ...dataFormRef.current, username: e.target.value }
    },
    [1000]
  )

  const handleEmail = useDebounceFn(
    (e) => {
      checkEmailExist({
        param: {
          email: e.target.value
        }
      })
      dataFormRef.current = { ...dataFormRef.current, email: e.target.value }
    },
    [1000]
  )

  const handleBackToSignIn = () => {
    if (type === 'popup') {
      onBacktoSignIn && onBacktoSignIn()
      return
    }
    navigate('/signin')
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    if (
      sendCodeSuccess ||
      (error && error?.status !== 403 && error?.originalStatus !== 429 && error?.status !== 'FETCH_ERROR')
    ) {
      onChangeView(dataFormRef.current)
    }
  }, [sendCodeSuccess, error])

  return (
    <div style={type === 'popup' ? { width: '100%', maxWidth: '470px' } : null}>
      <div
        className={SignUpStyles.arrowBack}
        onClick={handleBack}
        style={type === 'popup' ? { top: '12px', left: '0 ' } : null}
      >
        <SvgCommon alt='arrow' src={iconArrow} />
      </div>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        initial={{ opacity: 0, y: -20 }}
        layout
        style={{ position: 'relative' }}
        transition={{ duration: 0.2 }}
      >
        <div className={SignUpStyles.formContainer}>
          <span className={SignUpStyles.formLoginTitle}>Sign up to Statistr</span>
          <div style={{ width: '100%' }}>
            <div className={SignUpStyles.form}>
              {gmailSignup ? (
                <div className={SignUpStyles.firstFormTextWrapper}>
                  <span className={SignUpStyles.firstFormText}>
                    Your Google account
                    <span className={SignUpStyles.firstFormBoldText}> workspace@gmail.com </span>
                    will be connected to your new Statistr account.
                  </span>
                </div>
              ) : null}
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputNameContainer}>
                  <div className={SignUpStyles.inputNameBox}>
                    <Input
                      beginValueInput={beginData?.name}
                      customClassNameInput={type === 'popup' ? SignUpStyles.customInput : ''}
                      inputTitle='Name'
                      isInPopup='true'
                      isValidation={validate}
                      keepDeleteBox={true}
                      maxLength={50}
                      name='name'
                      onChange={handleChangeDataForm}
                      onDeleteInput={handleClearInput}
                      onError={handleError}
                      rule={[
                        { required: true, message: 'This field can not be empty' },
                        { name: true, message: "Name can't include special characters" },
                        { ['min-length']: true, options: 3, message: 'Name must be at least 3 characters' },
                        { ['max-length']: true, options: 50, message: 'Name must be at most 50 characters' }
                      ]}
                      type='text'
                    />
                  </div>
                  {/* <div className={SignUpStyles.inputNameBox}>
                    <Input
                      beginValueInput={beginData?.last_name}
                      customClassNameInput={type === 'popup' ? SignUpStyles.customInput : ''}
                      inputTitle='Last Name'
                      isInPopup='true'
                      isValidation={validate}
                      keepDeleteBox={true}
                      name='last_name'
                      onChange={handleChangeDataForm}
                      onDeleteInput={handleClearInput}
                      onError={handleError}
                      rule={[
                        { required: true, message: 'This field can not be empty' },
                        { name: true, message: "Last name can't include special characters" }
                      ]}
                      type='text'
                    />
                  </div> */}
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputNameContainer}>
                  <div className={SignUpStyles.inputNameBox}>
                    <Input
                      beginValueInput={beginData?.username}
                      customClassNameInput={type === 'popup' ? SignUpStyles.customInput : ''}
                      inputTitle='Username'
                      isInPopup='true'
                      isLoading={checkExistLoading}
                      isValidUsername={
                        checkExistError && checkExistError.status === 403 ? checkExistError.status === 403 : false
                      }
                      isValidation={validate}
                      keepDeleteBox={true}
                      maxLength={50}
                      name='username'
                      onChange={handleUsername}
                      onError={handleError}
                      rule={[
                        { required: true, message: 'This field can not be empty' },
                        { ['min-length']: true, options: 3, message: 'Username must be at least 3 characters' },
                        { ['max-length']: true, options: 50, message: 'Username must be at most 50 characters' }
                      ]}
                      type='text'
                      usernameError={
                        checkExistSuccess && dataAccount[1]?.username === dataFormRef.current.username
                          ? 'This username has been taken'
                          : ''
                      }

                      // maxLength='100'
                    />
                  </div>
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputBox}>
                  <Input
                    beginValueInput={beginData?.email}
                    inputTitle='Email'
                    isInPopup='true'
                    isValidation={validate}
                    name='email'
                    onChange={handleEmail}
                    onError={handleError}
                    otherError={
                      checkEmailExistSuccess || (error && error.status === 403)
                        ? 'This email is already registered'
                        : ''
                    }
                    rule={[
                      {
                        required: true,
                        message: 'This field can not be empty'
                      },
                      {
                        email: true,
                        message: 'Invalid email'
                      }
                    ]}
                    type='text'
                  />
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputBox}>
                  <Input
                    inputTitle='Password'
                    isInPopup='true'
                    isValidation={validate}
                    name='password'
                    onChange={handleChangeDataForm}
                    onError={handleError}
                    placeholder='Type your password'
                    rule={[
                      {
                        required: true,
                        message: 'This field can not be empty'
                      },
                      {
                        'between-length': true,
                        options: { from: 8, to: 255 },
                        message: 'Password need at least 8 characters'
                      },
                      {
                        ['no-space']: true
                      }
                    ]}
                    showEyes
                    type='password'
                  />
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputBox}>
                  <Input
                    inputNeedToConfirmValue={dataFormRef.current.password}
                    inputTitle='Confirm Password'
                    isInPopup='true'
                    isValidation={validate}
                    name='confirmPassword'
                    onChange={handleChangeDataForm}
                    onError={handleError}
                    placeholder='Retype your password'
                    rule={[
                      { required: true, message: 'This field cannot be empty' },
                      {
                        duplicate: true,
                        options: {
                          correlateValue: dataFormRef.current.password
                        },
                        message: 'Passwords do not match'
                      }
                    ]}
                    showEyes
                    type='password'
                  />
                </div>
              </div>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputBox}>
                  <Input
                    beginValueInput={beginData?.ref_id}
                    inputTitle='Referral code'
                    isInPopup='true'
                    isValidation={validate}
                    name='ref_id'
                    onChange={handleChangeDataForm}
                    optional='optional'
                    type='text'
                    value={referralID}
                  />
                </div>
              </div>
              <div className={SignUpStyles.agreeTermContainer} style={type === 'popup' ? { height: 'auto' } : null}>
                <InputTypeCheckBox isValidation={validate} onCheck={handleCheckbox} />
                <span className={SignUpStyles.agreeTermText}>
                  I agreed with Statistr’s&nbsp;
                  <span className={SignUpStyles.agreeTermUnderlinedText}>
                    Terms of Service{type === 'popup' ? <br /> : null}
                  </span>
                  &nbsp;and&nbsp;
                  <span className={SignUpStyles.agreeTermUnderlinedText}>Privacy Policy</span>
                </span>
              </div>

              <div style={{ width: '100%' }}>
                <ButtonStatistr
                  height='50px'
                  loading={isLoading}
                  onClick={handleContinue}
                  styleButton={{ fontWeight: 600 }}
                  textButton='Continue'
                  type='primary'
                  width='100%'
                />
                {/* <Button onClick={handleContinue} text='Continue' /> */}
              </div>
            </div>
            {error && error?.status === 'FETCH_ERROR' ? (
              <span className={SignUpStyles.noInternet} style={type === 'popup' ? { width: '385px' } : {}}>
                Network error . Please check your internet connection
              </span>
            ) : null}
            {error && error?.originalStatus === 429 ? (
              <span
                className={SignUpStyles.noInternet}
                style={type === 'popup' ? { width: '385px', bottom: '-20px' } : {}}
              >
                Too many requests to our server. Please try again later
              </span>
            ) : null}
            <div className={SignUpStyles.formFooter} style={type === 'popup' ? { paddingTop: '32px' } : null}>
              <div className={SignUpStyles.signIn}>
                <span className={SignUpStyles.signInText}>Already have an account?</span>
                <div onClick={handleBackToSignIn}>
                  <span className={SignUpStyles.signInLinkText}>Sign In</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default SecondView
