import { ReactSVG } from 'react-svg'
import React, { memo } from 'react'
import './SvgCommon.css'

// eslint-disable-next-line react/prefer-stateless-function
const SvgCommon = memo((props) => {
  const { src, color, width, height } = props

  return (
    <ReactSVG
      afterInjection={(svg) => {
        // color !== undefined &&
        //   svg.querySelectorAll('path, rect').forEach((path) => {
        //     if (path.getAttribute('fill')) {
        //       path.setAttribute('fill', color)
        //     } else {
        //       path.getAttribute('stroke') !== null && path.setAttribute('stroke', color)
        //     }
        //   })

        width !== undefined && svg.setAttribute('width', width)
        height !== undefined && svg.setAttribute('height', height)
      }}
      beforeInjection={(svg) => {
        color !== undefined &&
          svg.querySelectorAll('path, rect').forEach((path) => {
            if (path.getAttribute('fill')) {
              path.setAttribute('fill', color)
            } else {
              path.getAttribute('stroke') !== null && path.setAttribute('stroke', color)
            }
          })

        width !== undefined && svg.setAttribute('width', width)
        height !== undefined && svg.setAttribute('height', height)
      }}
      className='wrapper-svg'
      src={src}
      wrapper={undefined}
    />
  )
})
SvgCommon.displayName = 'SvgCommon'
export default SvgCommon
