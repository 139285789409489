/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { motion } from 'framer-motion'
import LoginStyles from '../Signup.module.css'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/SigninSignupCommon/Button'

const FirstView = ({ handleChangeView, onBacktoSignIn, type }) => {
  const navigate = useNavigate()
  const handleBackToSignIn = () => {
    if (type === 'popup') {
      onBacktoSignIn && onBacktoSignIn()
      return
    }
    navigate('/signin')
  }

  const onChangeView = () => {
    // type === 'popup' && setResize(true)
    handleChangeView && handleChangeView()
  }
  // console.log(page,'afsfaswzfa')
  return (
    <motion.div
      animate={{ opacity: 1, y: 0 }}
      className={LoginStyles.form_first_signup}
      exit={{ opacity: 0, y: 20 }}
      initial={{ opacity: 0, y: -20 }}
      layout
      // style={type === 'popup' ? { height: '100%', display: 'flex', flexDirection: 'column', width: '400px' } : {}}
      transition={{ duration: 0.2 }}
    >
      <>
        <div
          className={LoginStyles.formLoginTitleContainer}
          style={type === 'popup' ? { textAlign: 'center', marginBottom: '138px' } : {}}
        >
          <span className={LoginStyles.formLoginTitle}>Join Statistr</span>
        </div>
        <div className={LoginStyles.form}>
          <div>
            <div>
              <Button onClick={onChangeView} text='Sign up with Email' />
              {/* <Button text='Sign up with Email' /> */}
            </div>

            {/* <div className={LoginStyles.loginOr}>
              <span className={LoginStyles.loginOrTitle}>or</span>
            </div>
            <div className={LoginStyles.inputLogin}>
              <button className={LoginStyles.inputLoginBtn} onClick={handleViewGgSignUp} type='button'>
                <img alt='a' height={16} srcSet='/assets/images/Rectangle518.png' width={16} />
                <span className={LoginStyles.SignInBtnText}>Sign up with Google</span>
              </button>
            </div> */}
          </div>
        </div>
        <div className={LoginStyles.formLoginFooter} style={type === 'popup' ? { marginTop: 'auto', flex: 0 } : {}}>
          <div className={LoginStyles.signUp}>
            <span className={LoginStyles.signUpText}>Already have an account?</span>
            <div>
              <span className={LoginStyles.signinLinkText} onClick={handleBackToSignIn}>
                Sign In
              </span>
            </div>
          </div>
          <div className={LoginStyles.footerTextContainer}>
            <span className={LoginStyles.footerText}>
              Creating an account signifies your agreement to our&nbsp;
              <span className={LoginStyles.footerUnderlinedText}>Terms of Service</span>&nbsp;&&nbsp;
              <span className={LoginStyles.footerUnderlinedText}>Privacy Policy</span>
            </span>
          </div>
        </div>
      </>
    </motion.div>
  )
}

export default FirstView
