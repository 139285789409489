/* eslint-disable react/jsx-sort-props */
import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import SignUpStyles from './ContentRight3.module.css'
// import InputCode from '../component/InputCode'
import Input from '../../InputSigninSignup/inputSignup'
import iconArrow from '@src/assets/ArrowBack.svg'
import { useLazySendCodeQuery } from '@src/redux-toolkit/Signin&SignUp/SigninAndSignUp.service'
import ButtonStatistr from '@src/components/ButtonStatistr'
import Countdown from '@src/components/Countdown/Countdown'
import { notification } from 'antd'

// import { Statistic } from 'antd'
let firstTimeSendCode = false

const ThirdView = ({ handleBack, handleCode, handleCofirm, email, hasError, loading, type, SignupError }) => {
  // const { Countdown } = Statistic
  // const [sendCodeError, setSendCodeError] = useState('')
  const [isSend, setIsSend] = useState(true)
  const [isValidation, setIsValidation] = useState(false)
  const [validateError, setValidateError] = useState()
  const [sendCodeSuccess, setSendCodeSuccess] = useState(false)
  const [isSendCodeError, setIsSendCodeError] = useState(false)
  const [sendCode] = useLazySendCodeQuery()

  const handleSendCode = () => {
    setIsSend(true)
    sendCode(email)
      .unwrap()
      .then(() => {
        setSendCodeSuccess(true)
      })
      .catch(() => {
        notification.error({
          message: 'Error',
          description: 'Code sending failed, please try again'
        })
        setIsSendCodeError(true)
      })

    // setSendCodeError(false)
    // clearTimeout(handleTimeOutMessCode)
    // setIsShowMessSendCode(false)
  }

  const handleValidateError = (name, error) => {
    setValidateError(error)
    setIsValidation(false)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleClickConfirm()
    }
  }

  const handleClickConfirm = () => {
    if (loading) {
      return
    }
    setIsValidation(true)
    if (!validateError) {
      handleCofirm && handleCofirm()
    }
  }

  const handleGetCode = (e) => {
    setIsValidation(false)
    handleCode && handleCode(e)
  }

  const onBack = () => {
    // type === 'popup' && setResize(false)
    handleBack && handleBack()
  }

  const handleFinishCountDown = () => {
    firstTimeSendCode = true
    setSendCodeSuccess(false)
    setIsSendCodeError(false)
    setIsSend(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  // useEffect(() => {
  //   if (sendCodeSuccess) {
  //     setIsShowMessSendCode(true)
  //     handleTimeOutMessCode = setTimeout(() => {
  //       setIsShowMessSendCode(false)
  //     }, 2000)
  //   } else {
  //     if (error) {
  //       setSendCodeError(true)
  //     }
  //   }
  // }, [isSend])

  // useEffect(() => {
  //   if (validateError === '' && isValidation) {
  //     handleCofirm && handleCofirm()
  //   }
  // }, [validateError, isValidation])

  return (
    <div>
      <div
        className={SignUpStyles.arrowBack}
        onClick={onBack}
        style={type === 'popup' ? { top: '32px', left: '0 ' } : null}
      >
        <img alt='arrow' src={iconArrow} />
      </div>
      <motion.div
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        initial={{ opacity: 0, y: -20 }}
        layout
        transition={{ duration: 0.2 }}
      >
        <div className={SignUpStyles.formContainer}>
          <span className={SignUpStyles.formLoginTitle}>Verify your email</span>
          <div style={type === 'popup' ? { width: '100%' } : {}}>
            {/* <div
              className='email'
              name='email'
              rules={[
                {
                  validator: (_, value) => {
                    const { messages: messError } = ValidateUtils({
                      rules: [{ required: true, message: MessagesUtils.get('E_0015', ['email']) }, { email: true }],
                      value
                    })
                    if (messError) return Promise.reject(messError)
                    return Promise.resolve()
                  }
                }
              ]}
              style={{ marginTop: '4px' }}
              > */}

            <div className={SignUpStyles.form}>
              <span className={SignUpStyles.firstFormText}>
                We already send a verified code to
                <br />
                <span className={SignUpStyles.firstFormBoldText}> {email}, </span>
                <br /> please check your inbox and insert the code to <br />
                verify your email.
              </span>
              <div style={{ width: '100%' }}>
                <div className={SignUpStyles.inputCodeContainer}>
                  <Input
                    isValidation={isValidation}
                    name='signup_code'
                    onChange={handleGetCode}
                    onError={handleValidateError}
                    otherError={hasError}
                    rule={[{ required: true, message: 'This field cannot be empty' }]}
                    inputCode={true}
                    // inputTitle='Reset Code'
                    maxLength='6 '
                  />
                  {/* {console.log('wrongCode', wrongCode)} */}
                  {/* <InputCode
                    isError={(wrongCode && wrongCode !== '') || sendCodeError}
                    name='signup_code'
                    onChange={handleCode}
                  /> */}

                  {/* {wrongCode && wrongCode !== '' ? <div className={SignUpStyles.errorCode}>{wrongCode}</div> : null} */}
                </div>
              </div>
              {SignupError && SignupError?.signup?.originalStatus === 429 ? (
                <div className={SignUpStyles.errorSignup}>
                  There are too many requests to our server . Please try again later
                </div>
              ) : null}
              <div style={{ width: '100%' }}>
                <ButtonStatistr
                  height='50px'
                  loading={loading}
                  onClick={handleClickConfirm}
                  textButton='Confirm'
                  type='primary'
                  width='100%'
                />
              </div>
            </div>

            <div className={SignUpStyles.formFooter}>
              <div className={SignUpStyles.footerTextWrapper}>
                <span className={SignUpStyles.notReceivedText}>Didn’t receive code?</span>
                {(isSend && sendCodeSuccess) || isSendCodeError || !firstTimeSendCode ? (
                  <>
                    <div>
                      <span className={SignUpStyles.disabledReSendText}> Resend Code</span>
                    </div>
                    <Countdown
                      counting={isSend}
                      initialTime={isSendCodeError ? 5 : 59}
                      onFinish={handleFinishCountDown}
                    />
                    {/* <Countdown
                      format='mm:ss'
                      onFinish={() => {
                        // setIsDisableResend(false)
                        setIsSend(false)
                      }}
                      value={Date.now() + 60 * 1000}
                      valueStyle={{
                        width: '37px',
                        height: '19px',
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '19px',
                        color: '#36373999'
                      }}
                    /> */}
                  </>
                ) : (
                  <div className={SignUpStyles.reSendTextWrapper} onClick={handleSendCode}>
                    <span className={SignUpStyles.reSendText}> Resend Code</span>
                  </div>
                )}
              </div>
              {(isSend && sendCodeSuccess) || !firstTimeSendCode ? (
                <span className={SignUpStyles.sendCodeMess}>{` Code has been sent ${
                  firstTimeSendCode ? 'again' : ''
                }, please check your mail`}</span>
              ) : null}
              {/* {sendCodeError && !sendCodeSuccess ? (
                <span className={SignUpStyles.errorText}>Can not send code. Please try later</span>
              ) : null} */}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default ThirdView
