import React, { useEffect, useMemo, useRef, useState } from 'react'
import styleNavMobile from './NavMobile.module.css'
import SvgCommon from '@src/components/common/SvgCommon'
import { useOutsideClick } from '@src/hooks'
import { useAuth } from '@src/ContextAPI/AuthProvider'
import clsx from 'clsx'
import ImageCommon from '@src/components/common/ImageCommon/ImageCommon'
import { EMPTY_DATA, NAVBAR_ITEMS, IconTag } from '@src/constants'
import { NavLink, Link } from 'react-router-dom'
import Search from '../NavRight/Search'

const NavMobile = () => {
  const [openPopup, setOpenPopup] = useState(false)
  const [typePopup, setTypePopup] = useState('')
  const [openNavList, setOpenNavList] = useState([])
  const { auth, userInfo, logoutAction } = useAuth()
  const containerRef = useRef(null)

  useOutsideClick(containerRef, () => setOpenPopup(false))
  const handleClickNavMobi = (type) => {
    setTypePopup(type)
    setOpenPopup(true)
    setOpenNavList([])
  }

  const handleCloseDrawer = () => {
    setOpenPopup(false)
  }

  const handleCleclNavList = (key) => {
    let index = openNavList.indexOf(key)
    setOpenNavList((prevState) => {
      if (index === -1) {
        return [...prevState, key]
      } else {
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)]
      }
    })
  }

  const handleLogout = () => {
    logoutAction()
  }

  const itemNav = useMemo(() => {
    let count = 2
    if (auth) count = 3
    return count
  }, [auth])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 900) {
        setOpenPopup(false)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <div className={styleNavMobile.nav_mobi}>
        <span className={styleNavMobile.nav_mobi_item} onClick={() => handleClickNavMobi('search')}>
          <SvgCommon height={20} src='/assets/images/Header/icon_search.svg' width={20} />
        </span>
        <span className={styleNavMobile.nav_mobi_item} onClick={() => handleClickNavMobi('menu')}>
          <SvgCommon src='/assets/images/Header/icon_nav_mobi.svg' />
        </span>
      </div>
      {openPopup ? (
        <>
          {typePopup === 'search' ? (
            <div className={styleNavMobile.overlay}>
              <div
                className={clsx(
                  styleNavMobile.drawer_search,
                  openPopup ? styleNavMobile.open_drawer : styleNavMobile.close_drawer
                )}
                ref={containerRef}
              >
                <Search active={true} closeDrawerMobile={handleCloseDrawer} type='popup' />
              </div>
            </div>
          ) : (
            <div className={styleNavMobile.overlay}>
              <div
                className={clsx(
                  styleNavMobile.drawer,
                  openPopup ? styleNavMobile.open_drawer : styleNavMobile.close_drawer
                )}
                ref={containerRef}
              >
                <div>
                  {auth ? (
                    <div className={styleNavMobile.user_info}>
                      <ImageCommon borderRadius={46} height={46} showInfo={false} src={userInfo?.face} width={46} />
                      <div>
                        <div className={styleNavMobile.name}>{userInfo?.name || EMPTY_DATA}</div>
                        <div className={styleNavMobile.email}>{userInfo?.email || EMPTY_DATA}</div>
                      </div>
                    </div>
                  ) : null}
                  <div className={styleNavMobile.container_navs}>
                    <span className={styleNavMobile.container_navs_title}>MENU</span>
                    <div
                      className={styleNavMobile.list_nav_moobi}
                      style={{ overflowY: openNavList?.length > 0 ? 'scroll' : 'hidden' }}
                    >
                      {NAVBAR_ITEMS?.slice(0, itemNav).map((item) => (
                        <div className={styleNavMobile.item_nav_moobi} key={item?.key}>
                          <div
                            className={styleNavMobile.item_nav_moobi_header}
                            onClick={() => handleCleclNavList(item?.key)}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '8px',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                              }}
                            >
                              <SvgCommon src={item?.icon} />
                              <span className={styleNavMobile.item_nav_moobi_title}>{item?.label}</span>
                            </div>
                            <span
                              style={{
                                transition: 'all 0.3s ease',
                                transform: `rotate(${openNavList.includes(item?.key) ? 180 : 0}deg)`
                              }}
                            >
                              <SvgCommon src='/assets/images/Header/icon_dropdown.svg' />
                            </span>
                          </div>
                          <div
                            className={clsx(
                              styleNavMobile.item_nav_moobi_content,
                              openNavList.includes(item?.key) && styleNavMobile.item_nav_moobi_content_open
                            )}
                          >
                            {item?.items?.map((item_nav) => (
                              <React.Fragment key={item_nav}>
                                {item_nav?.map((nav_item) => {
                                  return (
                                    <NavLink
                                      className={styleNavMobile.content_item}
                                      key={nav_item?._id}
                                      onClick={() => setOpenPopup(false)}
                                      to={
                                        nav_item?.label === 'Profile'
                                          ? `${nav_item?.link}?username=${userInfo?.username}`
                                          : nav_item?.link
                                      }
                                    >
                                      <SvgCommon height={32} src={nav_item?.icon} width={32} />
                                      <div
                                        style={{
                                          display: 'flex',
                                          gap: 4,
                                          flexDirection: 'column',
                                          justifyContent: 'center'
                                        }}
                                      >
                                        <div style={{ display: 'flex', alignItems: 'start', gap: 4 }}>
                                          <span className={styleNavMobile.item_nav_moobi_content_title}>
                                            {nav_item?.label}
                                          </span>
                                          {nav_item?.type ? (
                                            <span>
                                              <SvgCommon src={IconTag[nav_item?.type]} />
                                            </span>
                                          ) : null}
                                        </div>
                                        <span className={styleNavMobile.item_nav_moobi_content_desc}>
                                          {nav_item?.desc}
                                        </span>
                                      </div>
                                    </NavLink>
                                  )
                                })}
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {!auth ? (
                  <div className={styleNavMobile.container_navs_bottom}>
                    <Link className={styleNavMobile.btn_login} to='/signin'>
                      Log In
                    </Link>
                    <Link className={styleNavMobile.btn_signup} to='/signup'>
                      Sign Up
                    </Link>
                  </div>
                ) : (
                  <div className={styleNavMobile.container_navs_bottom}>
                    <div className={styleNavMobile.btn_signup} onClick={handleLogout}>
                      Log Out
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  )
}

export default NavMobile
