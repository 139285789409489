import React, { useState, useEffect } from 'react'
import LoginStyles from '../Login.module.css'
import clsx from 'clsx'
import { Cookies } from 'react-cookie'
// import Input from '../component/Input'
import iconArrow from '@src/assets/ArrowBack.svg'
import Input from '@src/components/InputSigninSignup/InputSigninSignup'
import ButtonStatistr from '@src/components/ButtonStatistr'
import SvgCommon from '@src/components/common/SvgCommon'
import { useNavigate } from 'react-router-dom'

const FirstView = ({
  handleChangeView,
  loading,
  onClickNext,
  email = '',
  closeModal,
  type,
  handleBackSignin,
  handleSignUp
}) => {
  const navigate = useNavigate()
  const cookies = new Cookies()
  const [emailValue, setEmailValue] = useState(() =>
    email
      ? email
      : cookies.get('statistr_checkbox') &&
        cookies.get('statistr_email') &&
        cookies.get('statistr_email') !== 'undefined'
      ? cookies.get('statistr_email')
      : ''
  )
  const [isValidation, setIsValidation] = useState(false)
  // const emailRemembered = localStorage.getItem('emailStatistr')

  const handleChangeEmailInput = (e) => {
    const value = e.target.value

    setIsValidation(false)
    setEmailValue(value)
    // onChange && onChange(value)
  }

  const handleClickNext = () => {
    setIsValidation(true)
    onClickNext && onClickNext(emailValue)

    cookies.set('statistr_isChangeEmail', emailValue !== cookies.get('statistr_email'), { path: '/' })
  }

  const handleError = (name, message) => {
    if (message === '') {
      handleChangeView && handleChangeView()
    }
  }

  const handleSignup = () => {
    if (type === 'popup') {
      handleSignUp && handleSignUp()
      return
    }
    closeModal && closeModal()
    navigate('/signup')
  }

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleClickNext()
      }
    }

    document.addEventListener('keydown', handleKeyPress)

    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [emailValue])

  return (
    <div
      style={
        type === 'popup'
          ? { width: '100%', height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }
          : { width: '100%' }
      }
    >
      <div
        className={type === 'popup' ? LoginStyles.arrowBackPopup : ''}
        onClick={handleBackSignin}
        style={type === 'popup' ? {} : { display: 'none' }}
      >
        <SvgCommon alt='arrow' src={iconArrow} />
      </div>
      <div
        className={LoginStyles.formLoginTitleContainer}
        style={
          type === 'popup' ? { display: 'flex', justifyContent: 'center', marginBottom: '64px', marginTop: '32px' } : {}
        }
      >
        <span className={LoginStyles.formLoginTitle} style={type === 'popup' ? { fontSize: '28px' } : {}}>
          {type === 'popup' ? 'Welcome back' : 'Sign in to Statistr'}
        </span>
      </div>
      <div style={type === 'popup' ? { height: '100%', display: 'flex', flexDirection: 'column' } : {}}>
        <div style={type === 'popup' ? {} : { marginBottom: '24px' }}>
          <Input
            beginValueInput={emailValue}
            inputTitle='Username or Email'
            isValidation={isValidation}
            name='email'
            onChange={handleChangeEmailInput}
            onDeleteInput={() => setEmailValue('')}
            onError={handleError}
            rule={[
              {
                required: true,
                message: 'This field can not be empty'
              },
              {
                email: true,
                message: 'Invalid Email!'
              }
            ]}
            type='text'
            value={emailValue}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          {loading ? (
            // eslint-disable-next-line react/button-has-type
            <button className={clsx('btn', LoginStyles.loginBtn)} disabled>
              <span className={LoginStyles.loginSigninDesc}>Sign in</span>
            </button>
          ) : (
            // eslint-disable-next-line react/button-has-type
            <ButtonStatistr height='50px' onClick={handleClickNext} textButton='Next' type='primary' width='100%' />
          )}
        </div>

        {type !== 'popup' ? (
          <>
            <div className={LoginStyles.loginOr}>
              <span className={LoginStyles.loginOrTitle}>or</span>
            </div>
            <a href='https://statistr.com/auth/google'>
              <button className={LoginStyles.inputLoginBtn} type='button'>
                <img alt='a' height={16} srcSet='/assets/images/Rectangle518.png' width={16} />
                <span className={LoginStyles.SignInBtnText}>Sign in with Google</span>
              </button>
            </a>
          </>
        ) : null}
        <div className={LoginStyles.formLoginFooter} style={type === 'popup' ? { flex: 0, marginTop: 'auto' } : {}}>
          <div className={LoginStyles.signUp}>
            <span className={LoginStyles.signUpText}>{`Don't have an account?`}&nbsp;</span>

            <span className={LoginStyles.linkText} onClick={handleSignup}>
              Sign Up
            </span>
            {/* <span className={`${LoginStyles.linkText} disabled-link`} onClick={handleSignup}>
              Sign Up
            </span> */}
          </div>
          <div className={LoginStyles.footerTextContainer}>
            <span className={LoginStyles.footerText}>
              {`By sign in, your're agree to Statistr’s`}&nbsp;
              <span className={LoginStyles.footerUnderlinedText}>Terms of Service</span>&nbsp; &&nbsp;
              <span className={LoginStyles.footerUnderlinedText}>Privacy Policy</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FirstView
