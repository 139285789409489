import { createSlice } from '@reduxjs/toolkit'
import { NewsAndResearch } from '@src/interface'

const initialState: NewsAndResearch = {
  dataTable: [],
  dataCol: [],
  isLoadding: false,
  current: 1,
  sort: { field: '', order: null }
}
const newsAndResearchSlice = createSlice({
  name: 'newsAndReasearch',
  initialState,
  reducers: {
    getDataTable: (state, action) => {
      state.dataTable = action.payload
    },
    resetDataTable: (state, action) => {
      state.dataTable = action.payload
    },
    getDataCol: (state, action) => {
      state.dataCol = action.payload
    },
    setLoadding: (state, action) => {
      state.isLoadding = action.payload
    },
    setCurrent: (state) => {
      state.current = state.current + 1
    },
    resetCurrent: (state) => {
      state.current = 1
    },
    setSortData: (state, action) => {
      state.sort = action.payload
    },
    setDataByFilter: (state, action) => {
      state.dataTable = action.payload
    }
  }
})

const newsAndResearchReducer = newsAndResearchSlice.reducer
export const {
  getDataTable,
  getDataCol,
  setLoadding,
  setCurrent,
  resetCurrent,
  resetDataTable,
  setSortData,
  setDataByFilter
} = newsAndResearchSlice.actions
export default newsAndResearchReducer
