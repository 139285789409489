import { Helmet } from 'react-helmet-async'
import { APP_NAME, AUTHOR_NAME, DEFAULT_LANG, DEFAULT_LOCALE } from '../config/env.config'
import { VIEW_PORT } from '@src/constants'
import { jsonLdData } from '@src/constants/jsonLdData'
import JsonLd from './JsonLd '

const MetaInfo = ({
  meta = [],
  defer = false,
  lang = DEFAULT_LANG,
  locale = DEFAULT_LOCALE,
  title,
  description,
  viewport = VIEW_PORT
}) => {
  const url = window?.location.href || 'unknown'
  const hostname = window.location.origin
  // var hostname =  `https://${hostname}`
  return (
    <>
      <Helmet
        defer={defer}
        htmlAttributes={{ lang }}
        link={[
          {
            rel: 'canonical',
            href: url
          },
          {
            rel: 'icon',
            href: `${hostname}/ico.svg`
          }
        ]}
        meta={[
          {
            name: 'viewport',
            content: viewport === 0 ? null : viewport
          },
          {
            name: 'description',
            content: description
          },
          {
            property: 'og:description',
            content: description
          },
          {
            property: 'twitter:description',
            content: description
          },
          {
            property: 'og:title',
            content: title
          },
          {
            property: 'twitter:title',
            content: title
          },
          {
            property: 'og:site_name',
            content: APP_NAME
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: url
          },
          {
            property: 'og:locale',
            content: locale
          },
          {
            property: 'og:image',
            content: `${hostname}/default-og.jpg`
          },
          // {
          //   property: 'og:image:height',
          //   content: 627
          // },
          // {
          //   property: 'og:image:width',
          //   content: 1254
          // },
          {
            name: 'author',
            content: AUTHOR_NAME
          },
          {
            name: 'theme-color',
            content: '#ffffff'
          },
          {
            name: 'twitter:image',
            content: `${hostname}/default-og.jpg`
          }
        ].concat(meta)}
        title={title}
        titleTemplate={`%s |  ${APP_NAME}`}
      />
      <JsonLd data={jsonLdData} />
    </>
  )
}

export default MetaInfo
