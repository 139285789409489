import queryString from 'query-string'
import { CustomError } from '@src/util/helpers'
import { api, makeApiCall } from '../api'
import { StorageParamType } from '@src/interface'

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Storage'] })

export const projectMasterApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getStorage: build.query<any[], any>({
      query: (params: StorageParamType) => {
        const queryStringParams: string = queryString.stringify(params || {})
        return makeApiCall(`api/users/storage/load?${queryStringParams}`)
      },

      providesTags: (result: any) =>
        result
          ? [
              ...result.data.map(({ storage_id }: { storage_id: any }) => ({ type: 'Storage' as const, storage_id })),
              { type: 'Storage', storage_id: 'LIST' }
            ]
          : [{ type: 'Storage', storage_id: 'LIST' }]
    }),
    /**
     * Chúng ta dùng mutation đối với các trường hợp POST, PUT, DELETE
     * Demo là response trả về và Omit<Demo, 'id'> là body gửi lên
     */
    addData: build.mutation<any, any>({
      query(body) {
        try {
          return makeApiCall('posts', null, 'POST', body)
        } catch (error: any) {
          throw new CustomError(error.message)
        }
      },
      invalidatesTags: (result, error, body) => (error ? [] : [{ type: 'Storage', id: 'LIST' }])
    }),
    getData: build.query<any, string>({
      query: (id) => ({
        url: `posts/${id}`,
        headers: {
          hello: 'DEMO'
        },
        params: {
          first_name: 'Demo',
          'last-name': 'Application'
        }
      })
    }),
    updateData: build.mutation<any, { id: string; body: any }>({
      query(data) {
        return {
          url: `posts/${data.id}`,
          method: 'PUT',
          body: data.body
        }
      },
      // Trong trường hợp này thì getPosts sẽ chạy lại
      invalidatesTags: (result, error, data) => (error ? [] : [{ type: 'Storage', id: data.id }])
    }),
    deleteData: build.mutation<{}, string>({
      query(id) {
        return {
          url: `posts/${id}`,
          method: 'DELETE'
        }
      },
      // Trong trường hợp này thì getPosts sẽ chạy lại
      invalidatesTags: (result, error, id) => [{ type: 'Storage', id }]
    })
  })
})

export const { useGetStorageQuery, useAddDataMutation, useGetDataQuery, useUpdateDataMutation, useDeleteDataMutation } =
  projectMasterApi
