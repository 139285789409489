import SvgCommon from '@src/components/common/SvgCommon'
import './UserOptionItem.css'
import { useNavigate } from 'react-router-dom'
import { IconTag, USERNAME } from '@src/constants'

const UserOptionItem = ({ link, icon, title, discount, closePopup, type }) => {
  const navigate = useNavigate()
  const usernameLocal = JSON.parse(localStorage.getItem(USERNAME))
  const handleOption = () => {
    if (title !== 'Profile') {
      navigate(link)
    } else {
      usernameLocal && navigate(`${link}?username=${usernameLocal}`)
    }
    closePopup && closePopup()
  }
  return (
    <div className='userSettingOption' onClick={() => handleOption()}>
      <div className='iconWrapper'>
        <SvgCommon src={icon} />
      </div>
      <div className='title_discount'>
        <span className='titleOption'>{title}</span>
        {discount ? (
          <div className='discountWrapper'>
            <span className='discount'>{discount}</span>
          </div>
        ) : null}
        {type ? (
          <span>
            <SvgCommon src={IconTag[type]} />
          </span>
        ) : null}
      </div>
    </div>
  )
}
export default UserOptionItem
