import { getKeyWatchList, parseKeyDataStruct } from '@src/util/common'
import { api } from '../api'
import { bodyStruct } from '@src/interface'
// import { cacher } from '../rtkQueryCacheUtils'

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Struct Data'] })

export const profilePage = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    getDataStruct: build.query<any, { body: bodyStruct }>({
      query({ body }) {
        const params = JSON.stringify(body?.params)
        return {
          url: `/api/data/struct`,
          method: 'GET',
          params: { ...body, params }
        }
      }
    }),
    getDataTableStruct: build.query<any, { body: bodyStruct; activeTab: string }>({
      query({ body }) {
        const params = JSON.stringify(body?.params)
        return {
          url: `/api/data/struct`,
          method: 'GET',
          params: { ...body, params }
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newItems, { arg }: { arg: any }) => {
        const skip: number = arg.body.params?.[0]?.skip ?? 0
        if (skip === 0) {
          currentCache[0] = newItems[0]
        } else {
          const keydataStruct = parseKeyDataStruct(arg.activeTab)
          currentCache[0][keydataStruct] = [
            ...(currentCache[0][keydataStruct] || []),
            ...(newItems[0][keydataStruct] || [])
          ]
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      }
    }),
    getDataWWatchlist: build.query<any, { body: bodyStruct; activeTab: string }>({
      query({ body }) {
        const params = JSON.stringify(body.params)
        return {
          url: `/api/users/watch-list/get`,
          method: 'GET',
          params: { ...body, params }
        }
      },
      transformResponse: (response: any, meta, arg: any) => {
        const key: string = getKeyWatchList(arg.activeTab)
        return (
          response?.data?.map((el: any) => {
            const { _id } = el
            return {
              ...el.ref_data[0][key][0],
              _id
            }
          }) ?? []
        )
      }
      // providesTags: cacher.providesList('WatchList')
    }),
    addWatchList: build.mutation<any, { body: bodyStruct }>({
      query({ body }) {
        const params = JSON.stringify(body.params)
        return {
          url: `/api/users/watch-list/add`,
          method: 'POST',
          params: { ...body, params }
        }
      }
    }),
    removeWWatchlist: build.query<any, { body: bodyStruct }>({
      query({ body }) {
        const params = JSON.stringify(body.params)
        return {
          url: `/api/users/watch-list/remove`,
          method: 'GET',
          params: { ...body, params }
        }
      }
    })
  }),
  overrideExisting: true
})

export const {
  useLazyGetDataStructQuery,
  useLazyGetDataTableStructQuery,
  useGetDataStructQuery,
  useLazyGetDataWWatchlistQuery,
  useGetDataWWatchlistQuery,
  useAddWatchListMutation,
  useLazyRemoveWWatchlistQuery
} = profilePage
