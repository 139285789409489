/* eslint-disable no-unused-vars */
import {
  useLazyGetActionLoginQuery,
  useLazyLoginQuery,
  useLazySignUpQuery
} from '@src/redux-toolkit/Signin&SignUp/SigninAndSignUp.service'
import { AUTH, USERNAME } from '@src/constants'
import { useLazyGetUserInfoQuery } from '@src/redux-toolkit/userProfile/user_profile.service'
import { setReverseString } from '@src/util/utils'
import { useContext, createContext, useState, useEffect, useMemo } from 'react'
import { Cookies } from 'react-cookie'
import { api } from '@src/redux-toolkit/api'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
const AuthContext = createContext()

export const useAuth = () => {
  return useContext(AuthContext)
}

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch()
  const cookies = new Cookies()
  const [token, setToken] = useState(cookies.get(AUTH.ACCESS_TOKEN) || '')
  const [userInfo, setUserInfo] = useState(null)
  const [loading, setLoading] = useState({
    login: false,
    signup: false
  })
  const [errors, setErrors] = useState({
    login: '',
    signup: ''
  })
  const [signupSuccess, setSignupSuccess] = useState(false)

  const [login, { isSuccess, isLoading, data: response, error }] = useLazyLoginQuery()
  const [
    SignUp,
    { isSuccess: isSuccessSignUp, isFetching: isFetchingSignUp, isLoading: isLoadingSignUp, error: errorSinup }
  ] = useLazySignUpQuery()
  const [
    getDataUser,
    { data: user, isLoading: isLoadingGetDataUser, isFetching: isFetchingGetDataUser, isSuccess: isSuccessGetDataUser }
  ] = useLazyGetUserInfoQuery()

  const [getActionLog] = useLazyGetActionLoginQuery()
  const { pathname: urlPathname, search: urlSearch } = useLocation()

  const loginAction = (param) => {
    setErrors({
      ...errors,
      login: ''
    })
    login({ param: param })
  }

  const logoutAction = () => {
    cookies.remove(AUTH.ACCESS_TOKEN, { path: '/' })
    window.location.reload()
  }

  const registerAction = (param) => {
    dispatch(api.util.resetApiState())
    setErrors({
      ...errors,
      signup: ''
    })
    setSignupSuccess(false)
    SignUp({
      param: param
    })
  }

  const auth = useMemo(() => {
    if (token) return true
    return false
  }, [token])

  useEffect(() => {
    if (auth && !userInfo) {
      getDataUser()
    }
  }, [auth])

  useEffect(() => {
    setLoading({
      ...loading,
      login: isLoading
    })
    if (isLoading) return
    if (isSuccess) {
      const expiresToken = new Date()
      expiresToken.setDate(expiresToken.getDate() + 1)
      expiresToken.setMinutes(expiresToken.getMinutes() - 10)
      cookies.set(AUTH.ACCESS_TOKEN, setReverseString(response.access_token), { path: '/', expires: expiresToken })
      setToken(setReverseString(response.access_token))
      getDataUser()
    } else {
      setErrors({
        ...errors,
        login: error
      })
    }
  }, [isLoading])

  useEffect(() => {
    setLoading({
      ...loading,
      signup: isLoadingSignUp
    })
    setSignupSuccess(false)
    if (isLoadingSignUp) return
    if (isSuccessSignUp) {
      setSignupSuccess(true)
    } else {
      setErrors({
        ...errors,
        signup: errorSinup
      })
    }
  }, [isFetchingSignUp, isSuccessSignUp])

  useEffect(() => {
    if (isSuccessGetDataUser) {
      localStorage.setItem(USERNAME, JSON.stringify(user?.username))
      setUserInfo(user)
    }
  }, [isFetchingGetDataUser])

  useEffect(() => {
    const action = {
      url: `${urlPathname}${urlSearch}`,
      platform: navigator.userAgent
    }
    getActionLog({ action: JSON.stringify(action) })
  }, [`${urlPathname}${urlSearch}`])

  return (
    <AuthContext.Provider
      value={{
        token,
        errors,
        userInfo,
        isFetchingGetDataUser,
        isLoadingGetDataUser,
        auth,
        loading,
        isSuccess,
        loginAction,
        logoutAction,
        registerAction,
        signupSuccess
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
