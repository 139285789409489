import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUser, faGear, faUsers, faKey } from '@fortawesome/free-solid-svg-icons'

import { v4 as uuidv4 } from 'uuid'
import { longDisplayStyle, mediumDisplayStyle, shortDisplayStyle, tagStyle } from '@src/settings/masterSetting'
export const SERVER_HOST = 'https://statistr.com/'
export const SERVER_PREVIEW_API = 'https://preview.statistr.com/api/'
export const DATE_TIME = 'DD/MM/YYYY'
export const DATE_TIMEDAY = 'MMM DD,YYYY'
export const VIEW_PORT = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
export const TypeStorage = {
  SCREENER: 'screener',
  MASTER: 'master'
}
export const OptionStorage = {
  VENTURE: 'Venture',
  PEOPLE: 'People',
  SECTOR: 'Sector'
}
export const StatusRequest = {
  Success: 200,
  ErAuth: 403,
  ErUnauthorized: 422,
  ErTooManyRequest: 429
}
export const statusScreener = {
  ACTIVE: 'active',
  DEACTIVE: 'deactive'
}
export const Pattern = {
  email: /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/,
  noSpace: /^(?!.*\s)$/,
  phone: /^(\+)?([0-9]*)$/,
  name: /^[^\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/u,
  url: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/,
  number: /^[0-9]*$/,
  numberPercent: /^([0-9]{1,2}([.][0-9]*)?|100([.]0*)?)$/,
  password: /^(?=.*[A-Za-z0-9]).{8,}$/
}

// Define constants for media queries
export const MEDIA_QUERIES = {
  MOBILE: '(max-width: 767px)',
  TABLET: '(min-width: 768px) and (max-width: 1023px)',
  LAPTOP: '(min-width: 1024px) and (max-width: 1439px)',
  DESKTOP: '(min-width: 1440px)'
}

export const ConvertParamNewResearch = (skip, take) => ({
  field_check: '_id',
  condition: '<>',
  value_check: '0',
  order_by_field: 'created_at',
  order_type: 'DESC',
  skip,
  take
})

export const ALT_IMAGE = 'images_alt_'
export const SOURCE = 'Statistr'
export const USERNAME = 'username'

export const DATA_POINT_INIT = [
  {
    id: 1,
    point: '',
    tier: ''
  },
  {
    id: 2,
    point: '50',
    tier: 'Tier 1'
  },
  {
    id: 3,
    point: '100',
    tier: 'Tier 2'
  },
  {
    id: 4,
    point: '150',
    tier: 'Tier 3'
  }
]

export const AUTH = {
  EMAIL: 'email',
  ACCESS_TOKEN: 'access_token',
  TOKEN_TYPE: 'token_type',
  SUB_STRING: 'statistr'
}

export const NEW_RESEARCH_MAS_TYPE = [
  {
    value: 'News',
    label: 'News'
  },
  {
    value: 'Research',
    label: 'Research'
  }
]

export const NEW_MAS_DATA = [
  {
    Stt: '1',
    Date: 'Jan 21, 2022',
    Tiltle: 'News',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '2',
    Date: 'Jan 21, 2022',
    Tiltle: 'News 2',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '3',
    Date: 'Jan 21, 2021',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '4',
    Date: 'Jan 21, 2022',
    Tiltle:
      'Steps Down Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Co-CEO of Crypto Trading',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '5',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '6',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '7',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '8',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '9',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '10',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '11',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '12',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '13',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '14',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '15',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '16',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '17',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '18',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '19',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '20',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '21',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '22',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '23',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '24',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '25',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '26',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '27',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  }
]

export const RESEARCH_MAS_DATA = [
  {
    Stt: '1',
    Date: 'Jan 21, 2022',
    Tiltle:
      'Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [
      { name: 'Defi' },
      { name: 'A16z' },
      { name: 'A16z' },
      { name: 'Liqu' },
      { name: 'Defi' },
      { name: 'Defi' },
      { name: 'Defi' },
      { name: 'Defi' }
    ],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '2',
    Date: 'Jan 21, 2022',
    Tiltle: 'Research 2 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [
      { name: 'Defi' },
      { name: 'A16z' },
      { name: 'A Liquidity' },
      { name: 'Defi' },
      { name: 'A16z' },
      { name: 'A Liquidity' },
      { name: 'A Liquidity' },
      { name: 'Defi' },
      { name: 'A16z' },
      { name: 'A Liquidity' },
      { name: 'Defi' },
      { name: 'A16z' },
      { name: 'A Liquidity' },
      { name: 'Defi' }
    ],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '3',
    Date: 'Jan 21, 2021',
    Tiltle:
      'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Research 1 Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '4',
    Date: 'Jan 21, 2022',
    Tiltle:
      'Steps Down Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down Co-CEO of Crypto Trading',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '5',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '6',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '7',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '8',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '9',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '10',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  },
  {
    Stt: '11',
    Date: 'Jan 21, 2022',
    Tiltle: 'Co-CEO of Crypto Trading Firm Alameda Research Sam Trabucco Steps Down',
    Tag: [{ name: 'Defi' }, { name: 'A16z' }, { name: 'A Liquidity' }],
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ],
    Author: {
      avatar: '/assets/images/rectangle_540.png',
      name: 'Esther Howard'
    }
  }
]

export const NEW_RESEARCH_MAS_TAG = [
  {
    value: 'Defi',
    label: 'Defi'
  },
  {
    value: 'A16z1',
    label: 'A16z1'
  },
  {
    value: 'A16z2',
    label: 'A16z2'
  },
  {
    value: 'A16z3',
    label: 'A16z3'
  },
  {
    value: 'A16z4',
    label: 'A16z4'
  },
  {
    value: 'Defi2',
    label: 'Defi2'
  },
  {
    value: 'A16z5',
    label: 'A16z5'
  },
  {
    value: 'A16z6',
    label: 'A16z6'
  },
  {
    value: 'A16z7',
    label: 'A16z7'
  },
  {
    value: 'A16z8',
    label: 'A16z8'
  }
]

export const NEW_RESEARCH_MAS_ASSETS = [
  {
    value: 'Binance',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Coinbase Exchange',
    label: 'Coinbase Exchange',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Crypto.com',
    label: 'Crypto.com',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Gate.io',
    label: 'Gate.io',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Huobi Global',
    label: 'Huobi Global',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Kraken',
    label: 'Kraken',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Kraken3',
    label: 'Kraken',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Kraken2',
    label: 'Kraken',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Kraken1',
    label: 'Kraken',
    image: '/assets/images/biannce.png'
  }
]

export const NEW_RESEARCH_MAS_AUTHOR = [
  {
    value: 'Esther Howard 1',
    label: 'Esther Howard 1'
  },
  {
    value: 'Esther Howard 2',
    label: 'Esther Howard 2'
  },
  {
    value: 'Esther Howard 3',
    label: 'Esther Howard 3'
  },
  {
    value: 'Esther Howard 4',
    label: 'Esther Howard 4'
  },
  {
    value: 'Esther Howard 5',
    label: 'Esther Howard 5'
  }
]

export const NEW_RESEARCH_MAS_SOURCE = [
  {
    value: 'Cointelegraph',
    label: 'Cointelegraph'
  },
  {
    value: 'Coindesk',
    label: 'Coindesk'
  },
  {
    value: 'Cointelegraph 3',
    label: 'Cointelegraph 3'
  },
  {
    value: 'Cointelegraph 4',
    label: 'Cointelegraph 4'
  },
  {
    value: 'Cointelegraph 2',
    label: 'Cointelegraph 2'
  }
]

export const MARKET_PAIR = [
  {
    value: 'USDT',
    label: 'USDT'
  },
  {
    value: 'BUSD',
    label: 'BUSD'
  },
  {
    value: 'BTC',
    label: 'BTC'
  }
]

export const MARKETPLACE = [
  {
    value: 'Binance1',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Binance2',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Binance3',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Binance4',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  },
  {
    value: 'Binance5',
    label: 'Binance',
    image: '/assets/images/biannce.png'
  }
]

export const sidebarList = [
  {
    title: 'My Account',
    path: '/profile',
    childrens: [
      {
        icon: <FontAwesomeIcon icon={faCircleUser} />,
        title: 'Account Overview',
        path: '/profile/overview'
      },
      {
        icon: <FontAwesomeIcon icon={faGear} />,
        title: 'Profile Settings',
        path: '/profile/profile-setting'
      },
      {
        icon: <FontAwesomeIcon icon={faKey} />,
        title: 'Password',
        path: '/profile/change-password'
      },
      {
        icon: (
          <svg
            className='rankingSvg'
            fill='none'
            height='24'
            viewBox='0 0 24 24'
            width='24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.5 2C8.94688 2 8.5 3.11719 8.5 4.5V19.5C8.5 20.8828 8.94688 22 9.5 22H13.5C14.0531 22 14.5 20.8828 14.5 19.5V4.5C14.5 3.11719 14.0531 2 13.5 2H9.5ZM2.5 7C1.94687 7 1.5 8.11719 1.5 9.5V19.5C1.5 20.8828 1.94687 22 2.5 22H6.5C7.05312 22 7.5 20.8828 7.5 19.5V9.5C7.5 8.11719 7.05312 7 6.5 7H2.5ZM15.5 14.5V19.5C15.5 20.8828 15.9469 22 16.5 22H20.5C21.0531 22 21.5 20.8828 21.5 19.5V14.5C21.5 13.1172 21.0531 12 20.5 12H16.5C15.9469 12 15.5 13.1172 15.5 14.5Z'
              fill='#F5F5F5'
            />
          </svg>
        ),
        title: 'Your Tier',
        path: '/profile/tier'
      },
      {
        icon: <FontAwesomeIcon icon={faUsers} />,
        title: 'Be Contributor',
        path: '/profile/contributor'
      }
    ]
  },
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'Observatory',
    path: '/observatory'
  },
  {
    title: 'Screener',
    path: '/screener/master-project'
  },
  {
    title: 'Token Compares',
    path: '/tokencompare'
  },
  {
    title: 'News',
    path: '/news'
  }
]

export const DATA_FAKE_LOADING = [
  {
    id: 1
  },
  {
    id: 2
  },
  {
    id: 3
  },
  {
    id: 4
  },
  {
    id: 5
  },
  {
    id: 6
  },
  {
    id: 7
  },
  {
    id: 8
  },
  {
    id: 9
  },
  {
    id: 10
  },
  {
    id: 11
  },
  {
    id: 12
  }
]

export const sliderData = [
  {
    name: 'One',
    image:
      'https://images.unsplash.com/photo-1502877338535-766e1452684a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80'
  },
  {
    name: 'Two',
    image:
      'https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=750&q=80'
  },
  {
    name: 'Three',
    image:
      'https://images.unsplash.com/photo-1526726538690-5cbf956ae2fd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
  },
  {
    name: 'Four',
    image:
      'https://images.unsplash.com/photo-1494976388531-d1058494cdd8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
  },
  {
    name: 'Five',
    image:
      'https://images.unsplash.com/photo-1493238792000-8113da705763?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80'
  }
]

export const DATA_Topcompare = [
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'BTC',
    fullname_1: 'Bitcoin',
    value_1: '-0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'ETH',
    fullname_2: 'Ethereum',
    value_2: '-0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'FLOW',
    fullname_1: 'Flow',
    value_1: '-0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'AVAX',
    fullname_2: 'Avax',
    value_2: '+0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'DOT',
    fullname_1: 'Dot',
    value_1: '+0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'GTM',
    fullname_2: 'Gtm',
    value_2: '-0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'LINK',
    fullname_1: 'Chainlink',
    value_1: '+0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'ETH',
    fullname_2: 'Ethereum',
    value_2: '+0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'BTC',
    fullname_1: 'Bitcoin',
    value_1: '+0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'ETH',
    fullname_2: 'Ethereum',
    value_2: '+0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'FLOW',
    fullname_1: 'Flow',
    value_1: '+0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'AVAX',
    fullname_2: 'Avax',
    value_2: '+0.79',
    totalvolume_2: '$31,286.47'
  },
  {
    id: uuidv4(),
    logo_1: '/assets/images/btc.png',
    name_1: 'DOT',
    fullname_1: 'Dot',
    value_1: '+0.79',
    totalvolume_1: '$31,286.47',

    logo_2: '/assets/images/btc.png',
    name_2: 'GTM',
    fullname_2: 'Gtm',
    value_2: '+0.79',
    totalvolume_2: '$31,286.47'
  }
]

export const DATA_Research = [
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/research1.png',
    title: 'Put your title goes here',
    description: 'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo1: '/assets/images/btc.png',
    logo2: '/assets/images/eth.png'
  }
]

export const DATA_SERIES = [
  {
    name: 'series1',
    color: '#00A7C1',
    data: [25, 30, 26, 23]
  },
  {
    name: 'series2',
    color: '#846CE5',
    data: [30, 35, 25, 20]
  },
  {
    name: 'series3',
    color: '#FFD962',
    data: [36, 45, 55, 44]
  }
]

export const OPTION = {
  chart: {
    height: 350,
    type: 'area'
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: '2'
  },
  legend: {
    show: false
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      shadeIntensity: 1,
      opacityFrom: 0.4,
      opacityTo: 0,
      stops: [10, 80, 10]
    }
  },
  yaxis: {
    labels: {
      style: {
        colors: '#fff'
      }
    }
  },
  xaxis: {
    labels: {
      style: {
        colors: '#fff'
      }
    },
    categories: ['13/6', '13/7', '13/8', '13/9']
  },
  tooltip: {
    theme: 'dark',
    x: {
      format: 'MM/dd'
    }
  }
}

export const dataAticle = [
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit,Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    date: moment().format(DATE_TIME),
    logo: '/assets/images/Frame 1401.png'
  }
]

export const dataTabNews = [
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  },
  {
    id: uuidv4(),
    image: '/assets/images/Rectangle 147.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. ',
    source: 'Messari'
  }
]

export const dataTable = [
  {
    id: uuidv4(),
    title: 'Price',
    description1: '$31,286.47',
    change1: 12.2,
    description2: '$11,286.47',
    change2: -12.2
  },
  {
    id: uuidv4(),
    title: 'Market Cap',
    description1: '$599,242,552,788',
    description2: '$230,150,925,532'
  },
  {
    id: uuidv4(),
    title: 'Fully Diluted Marketcap',
    description1: '$599,242,552,788',
    description2: '$230,150,925,532'
  },
  {
    id: uuidv4(),
    title: 'Trading Volume',
    description1: '$21,308,225,168',
    description2: '$13,098,264,488'
  },
  {
    id: uuidv4(),
    title: 'Total Value Locked',
    description1: '19.1 mil',
    description2: '121 mil'
  },
  {
    id: uuidv4(),
    title: 'Total value Locked/Marketcap',
    description1: '19.1 mil',
    description2: '121 mil'
  },
  {
    id: uuidv4(),
    title: 'ATH',
    description1: '$68,789.63',
    description2: '$68,789.63'
  },
  {
    id: uuidv4(),
    title: 'ATH date',
    description1: '11/18/2018',
    description2: '11/18/2021'
  },
  {
    id: uuidv4(),
    title: 'Audit',
    description1: '',
    description2: ''
  },
  {
    id: uuidv4(),
    title: 'Circulating Supply',
    description1: '304,121,121.52 BTC',
    description2: '122,373,866 ETH'
  },
  {
    id: uuidv4(),
    title: 'Max Supply',
    description1: '21.000.000 BTC',
    description2: '-'
  }
]

export const dataResearch = [
  {
    id: uuidv4(),
    img: '/assets/images/Rectangle492.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    tag: 'HUB',
    imgface: '/assets/images/imgface.png',
    author: 'Kat Jercich',
    date: moment().format(DATE_TIME)
  },
  {
    id: uuidv4(),
    img: '/assets/images/Rectangle492.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    tag: 'HUB',
    imgface: '/assets/images/imgface.png',
    author: 'Kat Jercich',
    date: moment().format(DATE_TIME)
  },
  {
    id: uuidv4(),
    img: '/assets/images/Rectangle492.png',
    title: 'Put your title goes here',
    description:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    tag: 'HUB',
    imgface: '/assets/images/imgface.png',
    author: 'Kat Jercich',
    date: moment().format(DATE_TIME)
  }
]

export const dataFullAticle = [
  {
    id: uuidv4(),
    title: 'Crystal Palace Football Club Blasts into The Metaverse',
    title2: 'Responsibility',
    description1:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    description2:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    description3:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    description4:
      'Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit, Lorem ipsum dolor sit amet, con sectetur adipiscing elit,',
    imgface: '/assets/images/imgface.png',
    author: 'Kat Jercich',
    office: 'AIBB research',
    date: moment().format(DATE_TIME)
  }
]

export const dataContacts = [
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant'
  }
]
export const dataEmployee = [
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant',
    img: '/assets/images/img-people.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'UI UX Designer',
    company: 'Executive',
    img: '/assets/images/img-people2.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant',
    img: '/assets/images/img-people2.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    company: 'Internet Security Assistant',
    img: '/assets/images/img-people2.png'
  }
]
export const dataBoard = [
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people2.png'
  },
  {
    id: uuidv4(),
    name: 'Brookyn Simmons',
    office: 'WordPress',
    date: moment().format(DATE_TIMEDAY),
    img: '/assets/images/img-people2.png'
  }
]
export const dataList = [
  {
    id: uuidv4(),
    img: '/assets/images/Group332.png',
    title: 'Founders',
    desc: 'Alain Chuard, Larry Page, Scott Tierney, Sergey Brin'
  },
  {
    id: uuidv4(),
    img: '/assets/images/Group332.png',
    title: 'Founders',
    desc: 'Alain Chuard, Larry Page, Scott Tierney, Sergey Brin'
  },
  {
    id: uuidv4(),
    img: '/assets/images/Group332.png',
    title: 'Founders',
    desc: 'Alain Chuard, Larry Page, Scott Tierney, Sergey Brin'
  },
  {
    id: uuidv4(),
    img: '/assets/images/Group332.png',
    title: 'Founders',
    desc: 'Alain Chuard, Larry Page, Scott Tierney, Sergey Brin'
  }
]
export const dataSub = [
  {
    id: uuidv4(),
    img: '/assets/images/image56.png',
    title: 'Zagat',
    desc: 'Subsidiary'
  },
  {
    id: uuidv4(),
    img: '/assets/images/image56.png',
    title: 'Zagat',
    desc: 'Subsidiary'
  },
  {
    id: uuidv4(),
    img: '/assets/images/image56.png',
    title: 'Zagat',
    desc: 'Subsidiary'
  },
  {
    id: uuidv4(),
    img: '/assets/images/image56.png',
    title: 'Zagat',
    desc: 'Subsidiary'
  }
]
export const formNewModal = [
  {
    title: 'Primary Organization',
    name: 'organization',
    layout: 1
  },
  {
    title: 'Avatar',
    name: 'avatar',
    layout: 1
  },
  {
    title: 'Type of Investor',
    name: 'investor',
    layout: 1
  },
  {
    title: 'Primary Job Title',
    name: 'title',
    layout: 1
  },
  {
    title: 'Gender',
    name: 'gender',
    layout: 1
  },
  {
    title: 'Location',
    name: 'location',
    layout: 1
  },
  {
    title: 'Background Summary',
    name: 'summary',
    layout: 2
  },
  {
    title: 'Experiences',
    name: 'experiences',
    layout: 2
  },
  {
    title: 'Events',
    name: 'events',
    layout: 2
  },
  {
    title: 'News',
    name: 'news',
    layout: 2
  },
  {
    title: 'LinkedIn',
    name: 'linkedin',
    layout: 2
  },
  {
    title: 'Twitter',
    name: 'twitter',
    layout: 2
  }
]
export const formNewVC = [
  {
    title: 'Name',
    name: 'name',
    layout: 1
  },
  {
    title: 'Summary',
    name: 'summary',
    layout: 1
  },
  {
    title: 'Founded Date',
    name: 'date',
    layout: 1
  },
  {
    title: 'Founder',
    name: 'founder',
    layout: 1
  },
  {
    title: 'Location',
    name: 'location',
    layout: 1
  },
  {
    title: 'Investor Type',
    name: 'investortype',
    layout: 1
  },
  {
    title: 'Operating Status',
    name: 'status',
    layout: 1
  },
  {
    title: 'Sector',
    name: 'sector',
    layout: 1
  },
  {
    title: 'Number of invesment',
    name: 'NOI',
    layout: 2
  },
  {
    title: 'The total of value',
    name: 'total',
    layout: 2
  },
  {
    title: 'Total funding Raised',
    name: 'total-funding',
    layout: 2
  },
  {
    title: 'Portfolio',
    name: 'portfolio',
    layout: 2
  },
  {
    title: 'Number of Employee',
    name: 'NOE',
    layout: 2
  },
  {
    title: 'Employees profile',
    name: 'employeesProfile',
    layout: 3
  },

  {
    title: 'Rounds',
    name: 'rounds',
    layout: 3
  },
  {
    title: 'Fund’s Recent news',
    name: 'FRN',
    layout: 3
  },
  {
    title: 'Website',
    name: 'web',
    layout: 3
  },
  {
    title: 'Email',
    name: 'email',
    layout: 3
  },
  {
    title: 'Twitter',
    name: 'twitter',
    layout: 3
  }
]
export const formEditVenture = [
  {
    title: 'VC Name',
    name: 'vcname',
    layout: 1
  },
  {
    title: 'Properties',
    name: 'properties',
    layout: 1
  },
  {
    title: 'Founder',
    name: 'founder',
    layout: 2
  },
  {
    title: 'Founded Date',
    name: 'date',
    layout: 2
  },
  {
    title: 'Summary',
    name: 'summary',
    layout: 3
  }
]
export const formResearch = [
  {
    title: 'VC Name',
    name: 'vcname'
  },
  {
    title: 'Screener',
    name: 'screener'
  },
  {
    title: 'Assets',
    name: 'assets'
  },
  {
    title: 'Content',
    name: 'content'
  },
  {
    title: 'Attachment',
    name: 'attachment'
  }
]

export const NEW_INFO = [
  // {
  //   value: 'new_project',
  //   label: 'New Project',
  // },
  {
    value: 'new_venture_capital',
    label: 'New Venture Capital'
  },
  {
    value: 'new_people',
    label: 'New People'
  }
]
export const EDIT_INFO = [
  {
    value: 'edit_project',
    label: 'Edit Project'
  },
  {
    value: 'edit_venture_capital',
    label: 'Edit Venture Capital'
  },
  {
    value: 'edit_people',
    label: 'Edit People'
  }
]
export const CREATE_INFO = [
  {
    value: 'create_your_research',
    label: 'Create your Research'
  }
]

export const RoundsModal = [
  {
    title: 'Source (Link)',
    name: 'source'
  },
  {
    title: 'Date',
    name: 'date'
  },
  {
    title: 'Money Raised',
    name: 'moneyraised'
  }
]
export const EmployeeModal = [
  {
    title: 'Name',
    name: 'name'
  },
  {
    title: 'Position',
    name: 'position'
  },
  {
    title: 'Twitter',
    name: 'twitter'
  }
]
export const RecentNewModal = [
  {
    title: 'Title',
    name: 'title'
  },
  {
    title: 'Source',
    name: 'source'
  },
  {
    title: 'Date',
    name: 'date'
  }
]
export const dataTableNews = [
  {
    key: 1,
    date: 'Jun 1, 2022',
    type: 'Activity',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 2,
    date: 'Jun 1, 2022',
    type: 'News',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      },
      {
        id: '7',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 3,
    date: 'Jun 1, 2022',
    type: 'Activity',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 4,
    date: 'Jun 1, 2022',
    type: 'News',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 5,
    date: 'Jun 1, 2022',
    type: 'Activity',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 6,
    date: 'Jun 1, 2022',
    type: 'News',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 7,
    date: 'Jun 1, 2022',
    type: 'Activity',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 8,
    date: 'Jun 1, 2022',
    type: 'News',
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '6/12/2022',
    source: 'Messari',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      },
      {
        id: '5',
        image: '/assets/images/2give.png'
      },
      {
        id: '6',
        image: '/assets/images/2give.png'
      }
    ]
  }
]

export const dataTableAuthor = [
  {
    key: 1,
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 2,
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 3,
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 4,
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 5,
    title:
      'San Francisco Companies With More Than $50M in Revenue San Francisco Companies With More Than $50M in Revenue',
    time: '12/6/2022',
    assets: [
      {
        id: '1',
        image: '/assets/images/2give.png'
      },
      {
        id: '2',
        image: '/assets/images/ampl.png'
      },
      {
        id: '3',
        image: '/assets/images/2give.png'
      },
      {
        id: '4',
        image: '/assets/images/2give.png'
      }
    ]
  }
]
export const datatTag = [
  {
    id: 1,
    value: 'collaboration',
    label: 'Collaboration'
  },
  {
    id: 2,
    value: 'searchengine',
    label: 'Search Engine'
  },
  {
    id: 3,
    value: 'enterprisesoftware',
    label: 'Enterprise Software'
  },
  {
    id: 4,
    value: 'informationtechnology',
    label: 'Information Technology'
  },
  {
    id: 5,
    value: 'informationtechnology',
    label: 'Information Technology'
  },
  {
    id: 6,
    value: 'informationtechnology',
    label: 'Information Technology'
  },
  {
    id: 7,
    value: 'informationtechnology',
    label: 'Information Technology'
  }
]
export const dataChartNote = [
  {
    value: 'ecosystem',
    label: 'Ecosystem',
    color: '#E045AB'
  },
  {
    value: 'team',
    label: 'Team',
    color: '#767676'
  },
  {
    value: 'staking_and_farming',
    label: 'Staking and farming',
    color: '#B7E5FF'
  },
  {
    value: 'liquidity_pool',
    label: 'Liquidity pool',
    color: '#7B40DD'
  },
  {
    value: 'advisor',
    label: 'Advisor',
    color: '#55D5DD'
  },
  {
    value: 'marketing',
    label: 'Marketing',
    color: '#3A1EE3'
  }
]
export const dataChartText = [
  {
    key: 1,
    text: 'Google has raised a total of $36.1M in funding over 4 rounds. Their latest funding was raised on Jun 1, 2022 from a Initial Coin Offering round'
  },
  {
    key: 2,
    text: 'Google has raised a total of $36.1M in funding over 4 rounds. Their latest funding was raised on Jun 1, 2022 from a Initial Coin Offering round'
  },
  {
    key: 3,
    text: 'Google is registered under the ticker NASDAQ:GOOG . Their stock opened with $85.00 in its Aug 19, 2004 IPO.'
  },
  {
    key: 4,
    text: 'Google has made 181 investments. Their most recent investment was on Aug 15, 2022, when Lori Systems raised'
  }
]
export const dataInvestor = [
  {
    _id: 1,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  },
  {
    _id: 2,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  },
  {
    _id: 3,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  },
  {
    _id: 4,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  },
  {
    _id: 5,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  },
  {
    _id: 6,
    name: 'Trevor Wright',
    img: '/assets/images/Rectangle542.png',
    img_round: '/assets/images/coinbase.png',
    funding_round: 'Axie SeedRound - Series A',
    money: '$50M',
    date: 'Wed Jun 07 2023 00:00:00 GMT+0700 (Indochina Time)'
  }
]
export const dataTableMarket = [
  {
    id: 1,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'low',
    marketplace: 'CEX'
  },
  {
    id: 2,
    exchange: 'ethereum',
    name: 'ETH',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'high',
    marketplace: 'DEX'
  },
  {
    id: 3,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'low',
    marketplace: 'CEX'
  },
  {
    id: 4,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'high',
    marketplace: 'CEX'
  },
  {
    id: 5,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'high',
    marketplace: 'DEX'
  },
  {
    id: 6,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'high',
    marketplace: 'CEX'
  },
  {
    id: 7,
    exchange: 'Bitcoin',
    name: 'BTC',
    pairs: 'USDT',
    price: '14.48',
    img: '/assets/images/2give.png',
    volume: '2.69B',
    volumeChange: '28',
    confidence: 'high',
    marketplace: 'CEX'
  }
]

export const dataEvents = [
  {
    key: 1,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/11/2023'
  },
  {
    key: 2,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/12/2023'
  },
  {
    key: 3,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/12/2023'
  },
  {
    key: 4,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/12/2023'
  },
  {
    key: 5,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/13/22023'
  },
  {
    key: 6,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/13/2023'
  },
  {
    key: 7,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/13/2023'
  },
  {
    key: 8,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/13/2023'
  },
  {
    key: 9,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/13/2023'
  },
  {
    key: 10,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/13/2023'
  },
  {
    key: 11,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/14/2023'
  },
  {
    key: 12,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/14/2023'
  },
  {
    key: 13,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/14/2023'
  },
  {
    key: 14,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/14/2023'
  },
  {
    key: 15,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/14/2023'
  },
  {
    key: 16,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/14/2023'
  },
  {
    key: 17,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/15/2023'
  },
  {
    key: 18,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/16/2023'
  },
  {
    key: 19,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/14/2023'
  },
  {
    key: 20,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/14/2023'
  },
  {
    key: 21,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/13/2023'
  },
  {
    key: 22,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/12/2023'
  },
  {
    key: 23,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/13/2023'
  },
  {
    key: 24,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/12/2023'
  },
  {
    key: 25,
    img: '/assets/images/sandbox.png',
    title: 'The Sandbox',
    name_event: 'Care Bears Avatars',
    desc: `"A collection of 3,060 unique @carebears are entering The Sandbox as playable #metaverse Avatars!"`,
    release: 'Release',
    like: 1,
    date: '01/13/2023'
  },
  {
    key: 26,
    img: '/assets/images/looksrare.png',
    title: 'LooksRare',
    name_event: 'Conditional Order Limit',
    desc: `"... We are limiting the size of conditional orders. This means you won't be able to create more than 20 orders sharing the same nonce..."`,
    release: 'Release',
    like: 3,
    date: '01/12/2023'
  }
]
export const dataJob = [
  {
    id: 1,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 1',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 2,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 2',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 3,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 3',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 4,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 4',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 5,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 5',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 6,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 6',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 7,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 7',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  },
  {
    id: 8,
    image: '/assets/images/person1.png',
    title: 'Alameda Research 8',
    desc: 'Co-CEO',
    time: 'Aug 2021'
  }
]
export const dataEvent = [
  {
    id: 1,
    image: '/assets/images/event1.png',
    title: 'The "Hidden Mechanisms" That Help Those Born Rich to Excel in Elite Jobs 1',
    desc: 'The Atlantic',
    time: 'Feb 26, 2019'
  },
  {
    id: 2,
    image: '/assets/images/event1.png',
    title: 'The "Hidden Mechanisms" That Help Those Born Rich to Excel in Elite Jobs 2',
    desc: 'The Atlantic',
    time: 'Feb 26, 2019'
  },
  {
    id: 3,
    image: '/assets/images/event1.png',
    title: 'The "Hidden Mechanisms" That Help Those Born Rich to Excel in Elite Jobs 3',
    desc: 'The Atlantic',
    time: 'Feb 26, 2019'
  },
  {
    id: 4,
    image: '/assets/images/event1.png',
    title: 'The "Hidden Mechanisms" That Help Those Born Rich to Excel in Elite Jobs 4',
    desc: 'The Atlantic',
    time: 'Feb 26, 2019'
  }
]
export const dataTimeline = [
  {
    id: 1,
    image: '/assets/images/timeline1.png',
    starttime: 'May 2012',
    endtime: 'Aug 2012',
    title: 'MIT',
    position: 'Undergraduate Researcher',
    org: '4 mos'
  },
  {
    id: 2,
    image: '/assets/images/timeline1.png',
    starttime: 'Apr 2011',
    endtime: 'May 2013',
    title: 'Art of Problem Solving',
    position: 'Teaching Assistant',
    org: '2 yrs 2 mos'
  },
  {
    id: 3,
    image: '/assets/images/timeline1.png',
    starttime: 'Jun 2013',
    endtime: 'Aug 2013',
    title: 'State Street',
    position: 'Software Development Intern',
    org: '3 mos'
  },
  {
    id: 4,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2015',
    endtime: 'Aug 2015',
    title: 'Susquehanna',
    position: 'Trader',
    org: '2 yrs 1 mo'
  },
  {
    id: 5,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 6,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 7,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 8,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 9,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 10,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 11,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  },
  {
    id: 12,
    image: '/assets/images/timeline1.png',
    starttime: 'Aug 2021',
    endtime: 'Now',
    title: 'Alameda Research',
    position: 'Co-CEO',
    org: '1 yr 4 mos'
  }
]

export const dataPersonal = [
  {
    key: 1,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 2,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 3,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 4,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 5,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  },
  {
    key: 6,
    name: 'Yahoo',
    img: '/assets/images/name.png',
    img_round: '/assets/images/Rectangle518.png',
    funding_round: 'Initial Coin Offering - Google',
    money: '$100M',
    assets: [
      {
        id: '1',
        img: '/assets/images/2give.png'
      },
      {
        id: '2',
        img: '/assets/images/ampl.png'
      },
      {
        id: '3',
        img: '/assets/images/2give.png'
      }
    ]
  }
]

export const VENTURE_MAS_DATA = [
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  },
  {
    Stt: '1',
    Avatar: '/assets/images/Sam_Trubacco_10.png',
    Name: 'Esther Howard',
    Typeof: 'Invesment firm',
    Status: 'Active',
    Location: 'Hong Kong, Asia',
    Founder: 'Sam TrabuccoSam, Fried-Bankman',
    Assets: [
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcbc.png'
      },
      {
        icon: '/assets/images/bcc.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      },
      {
        icon: '/assets/images/bcd.png'
      }
    ]
  }
]
export const JobOpening = [
  {
    id: 1,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 2,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 3,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 4,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 5,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 6,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 7,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 8,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 9,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 10,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 11,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  },
  {
    id: 12,
    titleJob: 'Freshers Research',
    img: '/assets/images/ico.svg',
    desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollitAmet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit',
    datepost: '10/15/2022',
    minsalary: '1000',
    maxsalary: '2400',
    country: 'vietnam'
  }
]
export const LOCATION = [
  {
    value: 'Department',
    label: 'Department'
  },
  {
    value: 'Company',
    label: 'Company'
  },
  {
    value: 'Location',
    label: 'Location'
  }
]
export const formPost = [
  {
    title: 'Job title',
    name: 'jobname'
  },
  {
    title: 'Department',
    name: 'department'
  },
  {
    title: 'Add your job description',
    name: 'jobdesc'
  },
  {
    title: 'Job location',
    name: 'location'
  },
  {
    title: 'Workplace type',
    name: 'workplacetype'
  },
  {
    title: 'Salary',
    name: 'Salary'
  },
  {
    title: 'Employment type',
    name: 'employment'
  },
  {
    title: 'Where can people apply?',
    name: 'whereapply'
  },
  {
    title: 'Add file detail',
    name: 'addfile'
  }
]
export const formCompanyPost = [
  { title: `What's your company name?`, name: 'nameCompany' },
  { title: `Your company logo`, name: 'logo' },
  { title: `Your company website`, name: 'website' }
]
export const formselectWorkplace = [
  {
    key: 1,
    title: 'Aalsmeer, Netherlands'
  },
  {
    key: 2,
    title: 'Addis Ababa, Ethiopia'
  },
  {
    key: 3,
    title: 'Devon, AB'
  },
  {
    key: 4,
    title: 'HoChiMinh, Vietnam'
  }
]
export const formselectWorkplaceType = [
  {
    key: 1,
    title: 'Remote'
  },
  {
    key: 2,
    title: 'On-site'
  },
  {
    key: 3,
    title: 'Hybrid'
  }
]
export const formselectEmployee = [
  {
    key: 1,
    title: 'Full-time employee'
  },
  {
    key: 2,
    title: 'Part time employee'
  },
  {
    key: 3,
    title: 'Freelance / Contract hire'
  }
]
export const CrytoJobData = [
  {
    key: 1,
    img: '/assets/images/avataJob.png',
    nameJob: 'Internet Security Assistant',
    company: 'Mu - Mu Group',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 2,
    img: '/assets/images/avataJob.png',
    nameJob: 'Freshers Research',
    company: 'NAW - Nationwide Corporation',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 3,
    img: '/assets/images/avataJob.png',
    nameJob: 'Internet Security Assistant',
    company: 'Mu - Mu Group',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 4,
    img: '/assets/images/avataJob.png',
    nameJob: 'Freshers Research',
    company: 'NAW - Nationwide Corporation',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 5,
    img: '/assets/images/avataJob.png',
    nameJob: 'Internet Security Assistant',
    company: 'Mu - Mu Group',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 6,
    img: '/assets/images/avataJob.png',
    nameJob: 'Freshers Research',
    company: 'NAW - Nationwide Corporation',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 7,
    img: '/assets/images/avataJob.png',
    nameJob: 'Internet Security Assistant',
    company: 'Mu - Mu Group',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 8,
    img: '/assets/images/avataJob.png',
    nameJob: 'Freshers Research',
    company: 'NAW - Nationwide Corporation',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  },
  {
    key: 9,
    img: '/assets/images/avataJob.png',
    nameJob: 'Freshers Research',
    company: 'NAW - Nationwide Corporation',
    datepost: '25/12/2122',
    minsalary: '900',
    maxsalary: '1200',
    country: 'Vietnam'
  }
]
export const ListSearch = [
  { value: 'department', label: 'Department' },
  { value: 'company', label: 'Company' },
  { value: 'location', label: 'Location' },
  { value: 'dateposted', label: 'Date posted' },
  { value: 'salary', label: 'Salary' }
]
export const ListSearchLocation = [
  { value: 'unitedstates', label: 'United States' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'thanhxuan,HN,VN', label: 'Thanh Xuan, Ha Noi, Viet Nam' },
  { value: 'myanmar', label: 'Myanmar' },
  { value: 'canada', label: 'Canada' }
]
export const ListSearchCompany = [
  { value: 'yates', label: 'yates' },
  { value: 'aibb', label: 'AIBB' },
  { value: 'echo', label: 'Echo Corporation' },
  { value: 'fpt', label: 'FPT' },
  { value: 'viettel', label: 'Viettel' }
]

export const AllCryptocurrencies = [
  {
    title: 'All Cryptocurrencies',
    option: [
      {
        value: 'Only Coins',
        label: 'Only Coins'
      },
      {
        value: 'Only Tokens',
        label: 'Only Tokens'
      }
    ]
  }
]

export const TopMarketCap = [
  {
    title: 'Top MarketCap',
    option: [
      {
        value: 'Top 1-5',
        label: 'Top 1-5'
      },
      {
        value: 'Top 6-10',
        label: 'Top 6-10'
      },
      {
        value: 'Top 11-20',
        label: 'Top 11-20'
      }
    ],
    from: '168',
    to: '221',
    placeholder_1: 'From',
    placeholder_2: 'To'
  }
]

export const Cap = [
  {
    title: 'Cap',
    option: [
      {
        value: 'Top Gainers',
        label: 'Top Gainers'
      },
      {
        value: 'Top Losers',
        label: 'Top Losers'
      }
    ],
    from: '$10,000,000',
    to: '$100,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]

export const Volume = [
  {
    title: 'Volume',
    option: [
      {
        value: 'Top Gainers',
        label: 'Top Gainers'
      },
      {
        value: 'Top Losers',
        label: 'Top Losers'
      }
    ],
    from: '$10,000,000',
    to: '$100,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]

export const Market = [
  {
    title: 'Market',
    childrens: [
      {
        title: 'Cex Listing:',
        option: [
          {
            value: 'Binance',
            label: 'Binance'
          },
          {
            value: 'Coinbase',
            label: 'Coinbase'
          }
        ]
      },
      {
        title: 'Dex Listing:',
        option: [
          {
            value: 'Uniswap',
            label: 'Uniswap'
          },
          {
            value: 'Pancake',
            label: 'Pancake'
          }
        ]
      }
    ]
  }
]

export const Research = [
  {
    title: 'Research',
    option: [
      {
        value: 'Yes',
        label: 'Yes'
      }
    ]
  }
]

export const Audit = [
  {
    title: 'Audit',
    option: [
      {
        value: 'Yes',
        label: 'Yes'
      }
    ]
  }
]

export const TotalSupply = [
  {
    title: 'Total Supply',
    option: [
      {
        value: 'Yes',
        label: 'Yes'
      }
    ]
  }
]

export const FinancialsMaster = [
  {
    title: 'Financials',
    option: [
      {
        value: 'past 1 mo',
        label: 'past 1 mo'
      },
      {
        value: 'past 3 mos',
        label: 'past 3 mos'
      },
      {
        value: 'past 6 mos',
        label: 'past 6 mos'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '08/2017',
    to: '06/2022',
    placeholder_1: 'Start date',
    placeholder_2: 'End date'
  }
]

export const TokenReleaseSchedule = [
  {
    title: 'Token Release Schedule',
    option: [
      {
        value: 'next month',
        label: 'next month'
      },
      {
        value: 'next 3 months',
        label: 'next 3 months'
      },
      {
        value: 'next 6 months',
        label: 'next 6 months'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '08/2017',
    to: '06/2022',
    placeholder_1: 'Start date',
    placeholder_2: 'End date'
  }
]

export const Events = [
  {
    title: 'Events',
    option: [
      {
        value: 'next month',
        label: 'next month'
      },
      {
        value: 'next 3 months',
        label: 'next 3 months'
      },
      {
        value: 'next 6 months',
        label: 'next 6 months'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '08/2017',
    to: '06/2022',
    placeholder_1: 'Start date',
    placeholder_2: 'End date'
  }
]

export const FundingAmount = [
  {
    title: 'Funding Amount',
    from: '$10,000,000',
    to: '$10,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]

export const Totalfundingraised = [
  {
    title: 'Total funding raised',
    from: '$10,000,000',
    to: '$10,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]
export const dataRadarChart = {
  series: [
    {
      name: 'Series 1',
      data: [80, 50, 30, 40, 100]
    },
    {
      name: 'Series 2',
      data: [20, 30, 40, 80, 20]
    }
  ],
  categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5']
}

export const dataTokenRelease = [
  {
    key: 1,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  },
  {
    key: 2,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  },
  {
    key: 3,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  },
  {
    key: 4,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  },
  {
    key: 5,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  },
  {
    key: 6,
    date: '6/1/2023',
    title: 'Released 5% total supply '
  }
]

export const dataInvestorCompare = [
  {
    key: 1,
    img: '/assets/images/au21.png',
    name: 'AU21 Capital',
    founder: 'Chandler Guo, Kenzi Wang',
    relateProject: [
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      },
      {
        logo: '/assets/images/eth.png',
        name: 'ETH',
        change: 4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: -4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      }
    ]
  },
  {
    key: 2,
    img: '/assets/images/au21.png',
    name: 'AU21 Capital',
    founder: 'Chandler Guo, Kenzi Wang',
    relateProject: [
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      },
      {
        logo: '/assets/images/eth.png',
        name: 'ETH',
        change: 4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: -4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      }
    ]
  },
  {
    key: 3,
    img: '/assets/images/au21.png',
    name: 'AU21 Capital',
    founder: 'Chandler Guo, Kenzi Wang',
    relateProject: [
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      },
      {
        logo: '/assets/images/eth.png',
        name: 'ETH',
        change: 4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: -4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      }
    ]
  },
  {
    key: 4,
    img: '/assets/images/au21.png',
    name: 'AU21 Capital',
    founder: 'Chandler Guo, Kenzi Wang',
    relateProject: [
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      },
      {
        logo: '/assets/images/eth.png',
        name: 'ETH',
        change: 4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: -4000
      },
      {
        logo: '/assets/images/btc.png',
        name: 'BTC',
        change: 4000
      }
    ]
  }
]

export const dataRelateCompare = [
  {
    key: 1,
    img: '/assets/images/people-detail.png',
    name: 'Eleanor Pena',
    office: 'CFO at Company ',
    experience: '2 years',
    twiter: 'twiter.com',
    linkedin: 'linkedin.com',
    email: 'gmail.com',
    phone: '0123456789'
  },
  {
    key: 2,
    img: '/assets/images/people-detail.png',
    name: 'Eleanor Pena',
    office: 'CFO at Company ',
    experience: '2 years',
    twiter: 'twiter.com',
    linkedin: 'linkedin.com',
    email: 'gmail.com',
    phone: '0123456789'
  },
  {
    key: 3,
    img: '/assets/images/people-detail.png',
    name: 'Eleanor Pena',
    office: 'CFO at Company ',
    experience: '2 years',
    twiter: 'twiter.com',
    linkedin: 'linkedin.com',
    email: 'gmail.com',
    phone: '0123456789'
  },
  {
    key: 4,
    img: '/assets/images/people-detail.png',
    name: 'Eleanor Pena',
    office: 'CFO at Company ',
    experience: '2 years',
    twiter: 'twiter.com',
    linkedin: 'linkedin.com',
    email: 'gmail.com',
    phone: '0123456789'
  }
]
export const dataTwitter = [
  {
    key: 1,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  },
  {
    key: 2,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  },
  {
    key: 3,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  },
  {
    key: 4,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  },
  {
    key: 5,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  },
  {
    key: 6,
    img: '/assets/images/people-detail.png',
    username: '@Sam Trabucco',
    date: '2/12/2023',
    twit: 'For athletes, high altitude produces two contradictory effects on performance. For explosive events (sprints up to 400 metres, long jump, triple jump) the reduction in atmospheric pressure means there is less resistance from the atmosphere and ... ',
    like: 112,
    dislike: 12
  }
]

export const VentureMasterFounded = [
  {
    title: 'Founded',
    option: [
      {
        value: 'Part 1 yr',
        label: 'Part 1 yr'
      },
      {
        value: 'Part 2 yrs',
        label: 'Part 2 yrs'
      },
      {
        value: 'Part 3 yrs',
        label: 'Part 3 yrs'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '08/2017',
    to: '06/2022',
    placeholder_1: 'Start date',
    placeholder_2: 'End date'
  }
]

export const VentureMasterFundingStage = [
  {
    title: 'Funding Stage',
    option: [
      {
        value: 'past 1 mo',
        label: 'past 1 mo'
      },
      {
        value: 'past 6 mos',
        label: 'past 6 mos'
      },
      {
        value: 'past 1 yr',
        label: 'past 1 yr'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '08/2017',
    to: '06/2022',
    placeholder_1: 'Start date',
    placeholder_2: 'End date'
  }
]

export const VentureMasterFundingAmount = [
  {
    title: 'Funding Amount',
    from: '$10,000,000',
    to: '$10,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]

export const VentureMasterTotalfundingraised = [
  {
    title: 'Total funding raised',
    from: '$10,000,000',
    to: '$10,000,000',
    placeholder_1: 'Enter amount',
    placeholder_2: 'Enter amount'
  }
]

export const VentureMasterInvestment = [
  {
    title: 'Investment in',
    option: [
      {
        value: 'Top 10',
        label: 'Top 10'
      },
      {
        value: 'Top 50',
        label: 'Top 50'
      },
      {
        value: 'Top 100',
        label: 'Top 100'
      },
      {
        value: 'Custom date range',
        label: 'Custom date range'
      }
    ],
    from: '150',
    to: '700',
    placeholder_1: 'Start top',
    placeholder_2: 'End top'
  }
]

export const PeopleMasterTypeOfInvestor = [
  {
    title: 'Type Of Investor',
    option: [
      {
        value: 'Invidual Investor',
        label: 'Invidual Investor'
      },
      {
        value: 'Person',
        label: 'Person'
      }
    ]
  }
]

export const PeopleMasterGender = [
  {
    title: 'Gender',
    option: [
      {
        value: 'Male',
        label: 'Male'
      },
      {
        value: 'Female',
        label: 'Female'
      }
    ]
  }
]

export const listProjectMasters = [
  {
    icon: '/assets/images/Icon_Token1.svg',
    name: 'Project',
    key: 'project'
  },
  {
    icon: '/assets/images/Icon_Token2.svg',
    name: 'People',
    key: 'people'
  },
  {
    icon: '/assets/images/Icon_Token3.svg',
    name: 'Venture',
    key: 'venture'
  }
]
export const listFilterMasters = [
  {
    name: 'Fundarising Index',
    key: 'fundarising_index',
    childrens: 'Fundarising 1'
  },
  {
    name: 'People',
    key: 'people',
    childrens: 'people 1'
  },
  {
    name: 'Social',
    key: 'social'
  },
  {
    name: 'Related',
    key: 'related'
  }
]

export const CONTRIBUTE_MODE = {
  EDIT: 'edit',
  VIEW: 'view'
}

export const FORM_CONTRIBUTE = {
  EDIT: 'edit',
  NEW: 'new'
}

export const TYPE_RENDER_ITEM = {
  TABLE: 'table',
  GRID: 'grid'
}

export const TYPE_FORM_CONTRIBUTE = {
  NEW: 'new',
  EDIT: 'edit'
}

export const DUMMY_DATA = 'DUMMY_DATA'

export const DUMMY_DATA_DAO = [
  {
    _id: '16baf4e0-5813-43f9-a288-e3ed3796103d',
    type: 'people',
    data_ref: {
      author: {
        name: 'Harri Manninen',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1123amHarri Manninen.jpg'
      }
    },
    data_contributor: {
      name: 'Harri Manninen',
      logo: 'media/10/20_2023/10_data_05-15-2023_1110amRoderick Purwana.jpg',
      description: 'AI driven sybil detection.'
    },
    update: 'new',
    count_vote: 1000,
    count_rejected: 3000,
    total_count: 100,
    status: 'Pending',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  },
  {
    _id: 'c393442b-6e1f-4e3f-8863-443fb5db0cc4',
    type: 'people',
    data_ref: {
      author: {
        name: 'Casper Johansen',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1109amCasper Johansen.jpg'
      }
    },
    origin_data: {
      _id: '64ed7db191ec6642f207b9ef',
      slug: 'david-j-namdar-individual-investor-ce77bb',
      _id_fake: 'rec01hFXKWFOMWWda',
      name: 'David J. Namdar',
      avatar: 'media/10/20_2023/10_data_05-15-2023_1112amDavid J. Namdar.jpg',
      twitter: 'https://twitter.com/namdar',
      data_ref: {
        primary_organization: {
          ref_table: 't_venture_capital_list',
          ref_to_field: 'funds_founders_id',
          ref_type: 2,
          ref_ids: ['64ed7d312d4e3a3ef3029432'],
          ref_document: [
            {
              _id: '64ed7d312d4e3a3ef3029432',
              slug: 'galaxy-digital-fund-4753a7',
              _id_fake: 'recMcBSXnTeV892Du',
              ventures_name: 'Galaxy Digital',
              logo: 'media/10/20_2023/10_data_05-15-2023_1038amGalaxy Digital.jpeg',
              type: 'Fund',
              actively_investing: 'Active',
              website: 'https://www.galaxydigital.io/',
              twitter: 'https://twitter.com/galaxydigitalhq',
              data_ref: {
                fundraising_projects_id: {
                  ref_table: 'm_fundraising_projects',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: [
                    '64ed7da4825fbf5c2208a504',
                    '64ed7d9c36dc0799f209c862',
                    '64ed7d9c36dc0799f209c8c9',
                    '64ed7d9c36dc0799f209c878',
                    '64ed7d9c36dc0799f209c792',
                    '64ed7d8eae9bb20bf30ee307',
                    '64ed7d9c36dc0799f209c9dc',
                    '64ed7d8eae9bb20bf30ee404',
                    '64ed7d9c36dc0799f209c908',
                    '64ed7d8eae9bb20bf30ee12d',
                    '64ed7d9478519e0d2803ee77',
                    '64ed7d9c36dc0799f209c94f',
                    '64ed7d9478519e0d2803efd7',
                    '64ed7d8eae9bb20bf30ee4d5',
                    '64ed7da4825fbf5c2208a52c',
                    '64ed7da4825fbf5c2208a517',
                    '64ed7d8eae9bb20bf30ee353',
                    '64ed7d8eae9bb20bf30ee2f0',
                    '64ed7d9478519e0d2803f0a1',
                    '64ed7d9c36dc0799f209c7a2',
                    '64ed7d9c36dc0799f209c7cd',
                    '64ed7d9c36dc0799f209c93a',
                    '64ed7d9478519e0d2803f0b2',
                    '64ed7d9c36dc0799f209c77d',
                    '64ed7d9478519e0d2803efbe',
                    '64ed7d8eae9bb20bf30ee1db',
                    '64ed7da4825fbf5c2208a729',
                    '64ed7d8eae9bb20bf30ee1c8',
                    '64ed7da4825fbf5c2208a646',
                    '64ed7d9478519e0d2803ee47',
                    '64ed7d9c36dc0799f209c6dc',
                    '64ed7d9478519e0d2803ee49',
                    '64ed7d8eae9bb20bf30ee430',
                    '64ed7d9478519e0d2803eff4',
                    '64ed7d9c36dc0799f209c6e6',
                    '64ed7d9c36dc0799f209c776',
                    '64ed7d8eae9bb20bf30ee4ba',
                    '64ed7d9478519e0d2803ee33',
                    '64ed7d9c36dc0799f209c662',
                    '64ed7da4825fbf5c2208a56d',
                    '64ed7d8eae9bb20bf30ee1eb',
                    '64ed7d9c36dc0799f209c9d5',
                    '64ed7d8eae9bb20bf30ee414',
                    '64ed7d9c36dc0799f209c674',
                    '64ed7d9c36dc0799f209ca21',
                    '64ed7d9478519e0d2803ef9a',
                    '64ed7d9c36dc0799f209c877',
                    '64ed7d9478519e0d2803eecf',
                    '64ed7da4825fbf5c2208a6ea',
                    '64ed7d9478519e0d2803f16b',
                    '64ed7da4825fbf5c2208a57f',
                    '64ed7d8eae9bb20bf30ee223',
                    '64ed7d9c36dc0799f209c943',
                    '64ed7d9c36dc0799f209c83d',
                    '64ed7d9478519e0d2803ef1a',
                    '64ed7d9478519e0d2803edd0',
                    '64ed7d8eae9bb20bf30ee35e',
                    '64ed7da4825fbf5c2208a62e',
                    '64ed7d8eae9bb20bf30ee39d',
                    '64ed7d8eae9bb20bf30ee321',
                    '64ed7d9478519e0d2803ee7b',
                    '64ed7d9478519e0d2803ee8d',
                    '64ed7d9c36dc0799f209c853',
                    '64ed7d8eae9bb20bf30ee118',
                    '64ed7d8eae9bb20bf30ee318',
                    '64ed7d8eae9bb20bf30ee146',
                    '64ed7d8eae9bb20bf30ee1ab',
                    '64ed7d8eae9bb20bf30ee43e',
                    '64ed7d9c36dc0799f209ca1b',
                    '64ed7d9478519e0d2803ef30',
                    '64ed7d9c36dc0799f209c72c',
                    '64ed7d8eae9bb20bf30ee3a8',
                    '64ed7da4825fbf5c2208a89e',
                    '64ed7da90950eae80e0d0c2a',
                    '64ed7da4825fbf5c2208a80d',
                    '64ed7da90950eae80e0d0c25',
                    '64ed7da4825fbf5c2208a82e'
                  ]
                },
                portfolio_companies_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'investors_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7d1a0950eae80e0d06b1',
                    '64ed7d1a0950eae80e0d0697',
                    '64ed7d22a5aef65f93087fc5',
                    '64ed7d12825fbf5c22089d86',
                    '64ed7d22a5aef65f93087ddd',
                    '64ed7d22a5aef65f93087e85',
                    '64ed7d0c36dc0799f209c3bd',
                    '64ed7d1a0950eae80e0d05a1',
                    '64ed7d22a5aef65f93087e02',
                    '64ed7d1a0950eae80e0d065d',
                    '64ed7d22a5aef65f93087f66',
                    '64ed7d12825fbf5c22089d39',
                    '64ed7d22a5aef65f93087e5c',
                    '64ed7d0c36dc0799f209c615',
                    '64ed7d22a5aef65f93087eec',
                    '64ed7d1a0950eae80e0d0693',
                    '64ed7d0c36dc0799f209c514',
                    '64ed7d1a0950eae80e0d06a7',
                    '64ed7d22a5aef65f93087da5',
                    '64ed7d0c36dc0799f209c5ea',
                    '64ed7d12825fbf5c22089f94',
                    '64ed7d0c36dc0799f209c2eb',
                    '64ed7d0c36dc0799f209c26f',
                    '64ed7d0c36dc0799f209c511',
                    '64ed7d12825fbf5c22089f97',
                    '64ed7d12825fbf5c22089fe1',
                    '64ed7d12825fbf5c22089ef4',
                    '64ed7d12825fbf5c22089d2c',
                    '64ed7d12825fbf5c22089e31',
                    '64ed7d1a0950eae80e0d0610',
                    '64ed7d12825fbf5c2208a06b',
                    '64ed7d1a0950eae80e0d0635',
                    '64ed7d22a5aef65f93087e42',
                    '64ed7d12825fbf5c22089f40',
                    '64ed7d12825fbf5c2208a08f',
                    '64ed7d12825fbf5c22089f4c',
                    '64ed7d12825fbf5c22089d77',
                    '64ed7d0c36dc0799f209c60c',
                    '64ed7d22a5aef65f93087efb',
                    '64ed7d0c36dc0799f209c41f',
                    '64ed7d1a0950eae80e0d0681',
                    '64ed7d12825fbf5c22089dd1',
                    '64ed7d1a0950eae80e0d0734',
                    '64ed7d0c36dc0799f209c395',
                    '64ed7d0c36dc0799f209c39a',
                    '64ed7d0c36dc0799f209c29f',
                    '64ed7d0c36dc0799f209c3f3',
                    '64ed7d22a5aef65f93087ee3',
                    '64ed7d0c36dc0799f209c5bf',
                    '64ed7d0c36dc0799f209c4cd',
                    '64ed7d12825fbf5c22089fa9',
                    '64ed7d1a0950eae80e0d05e3',
                    '64ed7d1a0950eae80e0d06b8',
                    '64ed7d0c36dc0799f209c3fe',
                    '64ed7d12825fbf5c22089ed6',
                    '64ed7d12825fbf5c22089e21',
                    '64ed7d1a0950eae80e0d049e',
                    '64ed7d1a0950eae80e0d05cb',
                    '64ed7d22a5aef65f9308802e',
                    '64ed7d1a0950eae80e0d07f7',
                    '64ed7d12825fbf5c22089d67',
                    '64ed7d1a0950eae80e0d07a3',
                    '64ed7d12825fbf5c2208a026',
                    '64ed7d1a0950eae80e0d0645',
                    '64ed7d12825fbf5c22089e37',
                    '64ed7d0c36dc0799f209c3e5',
                    '64ed7d0c36dc0799f209c493',
                    '64ed7d12825fbf5c22089f76',
                    '64ed7d1a0950eae80e0d07f2',
                    '64ed7d0c36dc0799f209c385',
                    '64ed7d1a0950eae80e0d06a5',
                    '64ed7d12825fbf5c22089e7d',
                    '64ed7d0c36dc0799f209c300'
                  ]
                },
                fundraising_funds_id: {
                  ref_table: 'm_fundraising_funds',
                  ref_to_field: 'investors_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7cedd1aeb3a7050b5a44',
                    '64ed7cedd1aeb3a7050b5a6b',
                    '64ed7cedd1aeb3a7050b59d8',
                    '64ed7cedd1aeb3a7050b596e',
                    '64ed7cedd1aeb3a7050b5972',
                    '64ed7cedd1aeb3a7050b59d5'
                  ]
                },
                employees_profile_id: {
                  ref_table: 'm_funds_employees',
                  ref_to_field: 'investors_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7ea30950eae80e0d1611',
                    '64ed7ea30950eae80e0d1530',
                    '64ed7ea30950eae80e0d1706',
                    '64ed7ea30950eae80e0d15ca',
                    '64ed7ea30950eae80e0d14b1',
                    '64ed7ea30950eae80e0d1739',
                    '64ed7eaaa5aef65f93088d53',
                    '64ed7ea30950eae80e0d14a6',
                    '64ed7ea30950eae80e0d1643',
                    '64ed7eaaa5aef65f93088d0b'
                  ]
                },
                fund_recent_news_id: {
                  ref_table: 'm_funds_recents_news',
                  ref_to_field: 'investors_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7e132d4e3a3ef3029e45',
                    '64ed7e132d4e3a3ef3029e89',
                    '64ed7e1aea56bcf30202f662',
                    '64ed7e132d4e3a3ef3029c2a',
                    '64ed7e132d4e3a3ef3029e49',
                    '64ed7e1aea56bcf30202f498',
                    '64ed7e132d4e3a3ef3029cba',
                    '64ed7e132d4e3a3ef3029d74',
                    '64ed7e132d4e3a3ef3029cc5',
                    '64ed7e1aea56bcf30202f451'
                  ]
                },
                funds_founders_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'primary_organization',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7db191ec6642f207b9ef',
                    '64ed7db191ec6642f207ba3b',
                    '64ed7db191ec6642f207babd',
                    '64ed7db191ec6642f207bc2c',
                    '64ed7db191ec6642f207bcef'
                  ]
                }
              },
              summary:
                'Galaxy Digital is a technology-driven investment firm that provides financial solutions spaning the digital assets ecosystem.',
              crunchbase_link: 'https://www.crunchbase.com/organization/galaxy-digital-lp',
              year_founded: 2017,
              linkedin: 'https://www.linkedin.com/company/galaxydigital/',
              email_address: 'compliance@galaxydigital.io',
              location: 'United States',
              assets_under_management: '>$250M',
              created_at: '2023-08-29T05:07:59.975000Z',
              updated_at: '2023-08-29T05:07:59.975000Z'
            }
          ]
        },
        fundraising_rounds_funds: {
          ref_table: 'm_fundraising_funds',
          ref_to_field: 'funds_founders_id',
          ref_type: 2,
          ref_ids: ['64ed7cedd1aeb3a7050b5a44'],
          ref_document: [
            {
              _id: '64ed7cedd1aeb3a7050b5a44',
              slug: 'galaxy-digital---fund-of-fund-7272021-88b394',
              _id_fake: 'recvEQ1XVFTLivD7e',
              fundraising_round: 'Galaxy Digital - Fund of Fund',
              date: '2021-07-27T00:00:00Z',
              lps: 'Franklin Templeton Investments',
              partner: 'David J. Namdar,Michael Novogratz,Sam Englebardt',
              name: 'Fund of Fund',
              announcement:
                'https://www.theblockcrypto.com/linked/112562/galaxy-digital-venture-fund-franklin-templeton?utm_source=twitter&utm_medium=social',
              data_ref: {
                funds_founders_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'fundraising_rounds_funds',
                  ref_type: 2,
                  ref_ids: ['64ed7db191ec6642f207b9ef', '64ed7db191ec6642f207ba3b', '64ed7db191ec6642f207babd']
                },
                investors_id: {
                  ref_table: 't_venture_capital_list',
                  ref_to_field: 'fundraising_funds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d312d4e3a3ef3029432', '64ed7d40ae9bb20bf30eddc1']
                }
              },
              created_at: '2023-08-29T05:06:53.131000Z',
              updated_at: '2023-08-29T05:06:53.131000Z'
            }
          ]
        },
        peoples_experience: {
          ref_table: 'm_people_experience',
          ref_to_field: 'people_id',
          ref_type: 2,
          ref_ids: [
            '64ed8005ae9bb20bf30efd7c',
            '64ed8005ae9bb20bf30efc7a',
            '64ed7ffeea56bcf302030784',
            '64ed8005ae9bb20bf30eff7c',
            '64ed801c825fbf5c2208bd1d',
            '64ed800c36dc0799f209dd25',
            '64ed80240950eae80e0d23c9',
            '64ed80240950eae80e0d259b',
            '64ed801c825fbf5c2208bdee',
            '64ed80240950eae80e0d23d2',
            '64ed800c36dc0799f209dcb7',
            '64ed800c36dc0799f209de8e',
            '64ed800c36dc0799f209dc42',
            '64ed800c36dc0799f209ded9',
            '64ed801c825fbf5c2208be31',
            '64ed801478519e0d28040c87',
            '64ed801478519e0d280409c6',
            '64ed801478519e0d28040969',
            '64ed800c36dc0799f209de22',
            '64ed801c825fbf5c2208bd00'
          ],
          ref_document: [
            {
              _id: '64ed7ffeea56bcf302030784',
              slug: 'percival-ventures-2020-0b7a46',
              _id_fake: 'rec1LCUS5YuDVdZCk',
              primary_job_organization: 'Percival Ventures',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2020-01-01T00:00:00Z',
              position: 'PartnerPartner',
              created_at: '2023-08-29T05:19:57.588000Z',
              updated_at: '2023-08-29T05:19:57.588000Z'
            },
            {
              _id: '64ed8005ae9bb20bf30efc7a',
              slug: 'nftcom-2021-482804',
              _id_fake: 'rec8klnzcprnfcVEn',
              primary_job_organization: 'NFT.com',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-01-01T00:00:00Z',
              to_date: '2021-09-01T00:00:00Z',
              position: 'Co-Founder, CEO',
              created_at: '2023-08-29T05:20:04.181000Z',
              updated_at: '2023-08-29T05:20:04.181000Z'
            },
            {
              _id: '64ed8005ae9bb20bf30efd7c',
              slug: 'coral-defi-2021-3e9e21',
              _id_fake: 'recYrvgLMwwSRbT0K',
              primary_job_organization: 'Coral DeFi',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-01-01T00:00:00Z',
              position: 'General Partner',
              created_at: '2023-08-29T05:20:04.181000Z',
              updated_at: '2023-08-29T05:20:04.181000Z'
            },
            {
              _id: '64ed8005ae9bb20bf30eff7c',
              slug: 'coral-defi-2021-2bcef1',
              _id_fake: 'rec1UWOsGlhOAODT5',
              primary_job_organization: 'Coral DeFi',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-01-01T00:00:00Z',
              position: 'General Partner',
              created_at: '2023-08-29T05:20:04.181000Z',
              updated_at: '2023-08-29T05:20:04.181000Z'
            },
            {
              _id: '64ed800c36dc0799f209dc42',
              slug: 'solidx-partners-inc-2014-135488',
              _id_fake: 'rec6Z9l3Cf2O0bWCE',
              primary_job_organization: 'SolidX Partners Inc.',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2014-01-01T00:00:00Z',
              to_date: '2018-01-01T00:00:00Z',
              position: 'Co-Founder',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed800c36dc0799f209dcb7',
              slug: 'au21-capital-2020-f595bc',
              _id_fake: 'rec8fWsahLPbW5HQc',
              primary_job_organization: 'AU21 Capital',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2020-01-01T00:00:00Z',
              to_date: '2021-01-01T00:00:00Z',
              position: 'VP',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed800c36dc0799f209dd25',
              slug: 'nftcom-jan-2021-8a0b84',
              _id_fake: 'recAQbC7pJbRIEeXt',
              primary_job_organization: 'NFT.com',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-01-01T00:00:00Z',
              to_date: '2021-09-01T00:00:00Z',
              position: 'CEO',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed800c36dc0799f209de22',
              slug: 'ubs-2007-6b85d4',
              _id_fake: 'recEO8RKhoVONI2ki',
              primary_job_organization: 'UBS',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2007-01-01T00:00:00Z',
              to_date: '2009-01-01T00:00:00Z',
              position: 'International Trading',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed800c36dc0799f209de8e',
              slug: 'galaxy-digital-2017-a90da7',
              _id_fake: 'recFsOOlxy5wGznpK',
              primary_job_organization: 'Galaxy Digital',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2017-01-01T00:00:00Z',
              to_date: '2019-01-01T00:00:00Z',
              position: 'Co-Founder',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed800c36dc0799f209ded9',
              slug: 'mirror-labs-2016-52e3c3',
              _id_fake: 'recHDT0SEyIHLrXdW',
              primary_job_organization: 'Mirror Labs',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2016-01-01T00:00:00Z',
              to_date: '2017-01-01T00:00:00Z',
              position: 'Product, Business Operations and Strategy',
              created_at: '2023-08-29T05:20:10.708000Z',
              updated_at: '2023-08-29T05:20:10.708000Z'
            },
            {
              _id: '64ed801478519e0d28040969',
              slug: 'first-new-york-securities-jan-2010-93aa69',
              _id_fake: 'recMEHbRS8NE280Al',
              primary_job_organization: 'First New York Securities',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2010-01-01T00:00:00Z',
              to_date: '2010-12-01T00:00:00Z',
              position: 'International Trading',
              created_at: '2023-08-29T05:20:18.232000Z',
              updated_at: '2023-08-29T05:20:18.232000Z'
            },
            {
              _id: '64ed801478519e0d280409c6',
              slug: 'millennium-partners-2011-2e5b32',
              _id_fake: 'recO3aj77adsUwPjs',
              primary_job_organization: 'Millennium Partners',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2011-01-01T00:00:00Z',
              to_date: '2014-01-01T00:00:00Z',
              position: 'International Trading',
              created_at: '2023-08-29T05:20:18.232000Z',
              updated_at: '2023-08-29T05:20:18.232000Z'
            },
            {
              _id: '64ed801478519e0d28040c87',
              slug: 'acordiq-2015-30cd1d',
              _id_fake: 'recZZGghTOJeVpTuT',
              primary_job_organization: 'AcordIQ',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2015-01-01T00:00:00Z',
              to_date: '2016-01-01T00:00:00Z',
              position: 'Advisor',
              created_at: '2023-08-29T05:20:18.232000Z',
              updated_at: '2023-08-29T05:20:18.232000Z'
            },
            {
              _id: '64ed801c825fbf5c2208bd00',
              slug: 'goldman-sachs-2006-d371a2',
              _id_fake: 'recdR6V30l4EHjGB2',
              primary_job_organization: 'Goldman Sachs',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2006-01-01T00:00:00Z',
              position: 'Investment Banking',
              created_at: '2023-08-29T05:20:25.598000Z',
              updated_at: '2023-08-29T05:20:25.598000Z'
            },
            {
              _id: '64ed801c825fbf5c2208bd1d',
              slug: 'nftcom-2021-14a3ce',
              _id_fake: 'recdrtX7D2GergNQh',
              primary_job_organization: 'NFT.com',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-01-01T00:00:00Z',
              position: 'Co-Founder',
              created_at: '2023-08-29T05:20:25.598000Z',
              updated_at: '2023-08-29T05:20:25.598000Z'
            },
            {
              _id: '64ed801c825fbf5c2208bdee',
              slug: 'abound-capital-2015-9f8f74',
              _id_fake: 'rechJfheQIrz7RyHO',
              primary_job_organization: 'Abound Capital',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2015-01-01T00:00:00Z',
              position: 'Partner',
              created_at: '2023-08-29T05:20:25.598000Z',
              updated_at: '2023-08-29T05:20:25.598000Z'
            },
            {
              _id: '64ed801c825fbf5c2208be31',
              slug: 'medpilot-inc-2014-d22c73',
              _id_fake: 'reciLOEVmKTw45b8X',
              primary_job_organization: 'MedPilot Inc.',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2014-01-01T00:00:00Z',
              to_date: '2017-01-01T00:00:00Z',
              position: 'Advisor',
              created_at: '2023-08-29T05:20:25.598000Z',
              updated_at: '2023-08-29T05:20:25.598000Z'
            },
            {
              _id: '64ed80240950eae80e0d23c9',
              slug: 'percival-ventures-2020-1a447c',
              _id_fake: 'recqtimw4sCzF4nIZ',
              primary_job_organization: 'Percival Ventures',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2020-01-01T00:00:00Z',
              position: 'Partner',
              created_at: '2023-08-29T05:20:33.487000Z',
              updated_at: '2023-08-29T05:20:33.487000Z'
            },
            {
              _id: '64ed80240950eae80e0d23d2',
              slug: 'sheesha-finance-feb-2021-452fc0',
              _id_fake: 'recr6FI3aEUuR2Hp9',
              primary_job_organization: 'Sheesha Finance',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2021-02-01T00:00:00Z',
              to_date: '2022-03-01T00:00:00Z',
              position: 'Advisor',
              created_at: '2023-08-29T05:20:33.487000Z',
              updated_at: '2023-08-29T05:20:33.487000Z'
            },
            {
              _id: '64ed80240950eae80e0d259b',
              slug: 'coinroutes-2020-35ea14',
              _id_fake: 'recxurYKMXfmWCrFJ',
              primary_job_organization: 'CoinRoutes',
              data_ref: {
                people_id: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: 'peoples_experience',
                  ref_type: 2,
                  ref_ids: ['rec01hFXKWFOMWWda']
                }
              },
              from_date: '2020-01-01T00:00:00Z',
              position: 'Advisor, Business and Strategy',
              created_at: '2023-08-29T05:20:33.487000Z',
              updated_at: '2023-08-29T05:20:33.487000Z'
            }
          ]
        }
      },
      type_of_investor: 'Individual Investor',
      job_title: ['Co-Founder'],
      gender: 'Male',
      location: 'San Juan, NA - Puerto Rico, Puerto Rico',
      background_summary:
        'David J. Namdar is the Co-Founder of NFT.com. Attended at University of Southern California.',
      linkedin: 'http://www.linkedin.com/in/namdar',
      peoples_news: ['recsjrmYlpN1x4NFW'],
      crunchbase: 'https://www.crunchbase.com/person/david-j-namdar',
      created_at: '2023-08-29T05:10:09.160000Z',
      updated_at: '2023-08-29T05:10:09.160000Z'
    },
    data_contributor: {
      name: 'David J. Namdarhello',
      twitter: 'https://twitter.com/namdar23',
      job_title: ['Co-Founder', 'CEO'],
      background_summary:
        'Multicoin Capital is a thesis-driven investment firm that invests in cryptocurrencies, tokens, and blockchain companies reshaping trillion-dollar markets.'
    },
    count_vote: 25,
    count_rejected: 2,
    total_count: 20,
    update: 'edit',
    status: 'Accepted',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  },
  {
    _id: '16baf4e0-5813-43f9-a288-e3ed3796103b',
    type: 'venture',
    data_ref: {
      author: {
        name: 'Harri Manninen',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1123amHarri Manninen.jpg'
      }
    },
    data_contributor: {
      ventures_name: 'Trusta Labs',
      logo: 'media/10/20_2023/10_data_05-16-2023_0235amep1n5a2B_400x400.jpg',
      description: 'AI driven sybil detection.'
    },
    update: 'new',
    count_vote: 1000,
    count_rejected: 3000,
    total_count: 100,
    status: 'Pending',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  },
  {
    _id: '56181927-c1fb-4c06-98a7-fb55b609dc20',
    type: 'venture',
    update: 'edit',
    data_ref: {
      author: {
        name: 'Kelvin Koh',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1116amKelvin Koh.jpg'
      }
    },
    origin_data: {
      _id: '64ed7d2ad1aeb3a7050b5cef',
      slug: 'red-dao-dao-e61bbc',
      _id_fake: 'rec00FxfOH2sBbLUk',
      ventures_name: 'RED DAO',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      type: 'DAO',
      actively_investing: 'Active',
      website: 'https://reddao.xyz/',
      twitter: 'https://twitter.com/red___dao',
      data_ref: {
        fundraising_projects_id: {
          ref_table: 'm_fundraising_projects',
          ref_to_field: null,
          ref_type: 1,
          ref_ids: [
            '64ed7d9478519e0d2803ef1f',
            '64ed7d9478519e0d2803ee69',
            '64ed7d9478519e0d2803f03f',
            '64ed7d8eae9bb20bf30ee22a',
            '64ed7da4825fbf5c2208a83c'
          ],
          ref_document: [
            {
              _id: '64ed7d8eae9bb20bf30ee22a',
              slug: 'unxd---seed-382022-e3234c',
              _id_fake: 'rec4g2W8d5R9FiMzz',
              project: 'UNXD - Seed',
              date: '2022-03-08T00:00:00Z',
              amount_quantity: 4000000,
              amount_quantity_unit: 'USD',
              investors_id: [
                'recQXVWsVhqH1rw9r',
                'recmduMo2xpehmqEH',
                'rec00FxfOH2sBbLUk',
                'recDv4NxipRw4tiO8',
                'recQrKDhCLtXR5Jm4',
                'rec5IrXYiUU7BMBAA',
                'rec8HNKzF7fe38ZFd',
                'rectTWHSC3b0Ja9eJ',
                'recy3jetliQHEs7x6',
                'rec9sX7H4CncRMUAM',
                'reckj633Lh3K9sS6g',
                'recKYeu6I7wqT1d10',
                'rectFW6GC1n284Ell',
                'recZDX7EO6hUhHlRO',
                'recMTmUkNsImPBjZ5',
                'recCJfFJb7H11pTEI',
                'recSmi2QPnWAirKPg',
                'reca09ktSHHu5ksAw',
                'recAVB4efdlqbdGBb'
              ],
              website: 'https://unxd.com/',
              data_ref: {
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                },
                project_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'fundraising_rounds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d1a0950eae80e0d06bd']
                }
              },
              category: ['Web3'],
              subcategories: ['Metaverse', 'Marketplace'],
              description: 'Digital luxury and culture',
              stages: 'Seed',
              announcement:
                'https://notes.unxd.com/creating-the-future-of-luxury-and-culture-in-the-metaverse-2256a89d3311',
              logo: 'media/10/20_2023/10_data_05-16-2023_0237amunxd.jpg',
              created_at: '2023-08-29T05:09:33.437000Z',
              updated_at: '2023-08-29T05:09:33.437000Z'
            },
            {
              _id: '64ed7d9478519e0d2803ee69',
              slug: 'the-fabricant---series-a-472022-266758',
              _id_fake: 'recKl7NjI5rHH7Qvw',
              project: 'The Fabricant - Series A',
              date: '2022-04-07T00:00:00Z',
              amount_quantity: 14000000,
              amount_quantity_unit: 'USD',
              investors_id: [
                'recWMcmAMIeeTkiiw',
                'recRnazmULw0GuU9U',
                'rec00FxfOH2sBbLUk',
                'recUrEAo99Q9WUcw5',
                'recJP4pH6eICXKw4P',
                'recUfJO7W4HXOkQZT',
                'rechXj3n8IOU5MZaT',
                'rechOlRS5To8tw7PX'
              ],
              website: 'https://www.thefabricant.com/',
              data_ref: {
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                },
                project_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'fundraising_rounds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d12825fbf5c22089dab']
                }
              },
              category: ['Web3'],
              subcategories: ['Metaverse', 'Fashion'],
              description: 'A digital fashion house for the metaverse',
              stages: 'Series A',
              announcement:
                'https://venturebeat.com/2022/04/07/fabricant-raises-14m-to-build-the-wardrobe-for-the-metaverse/',
              logo: 'media/10/20_2023/10_data_05-16-2023_0230amfabricant.jpg',
              created_at: '2023-08-29T05:09:39.778000Z',
              updated_at: '2023-08-29T05:09:39.778000Z'
            },
            {
              _id: '64ed7d9478519e0d2803ef1f',
              slug: 'tribute-brand---seed-7122022-ebedb5',
              _id_fake: 'recNeMgsY44VascMO',
              project: 'Tribute Brand - Seed',
              date: '2022-07-12T00:00:00Z',
              amount_quantity: 4500000,
              amount_quantity_unit: 'USD',
              investors_id: [
                'recgSRlK6nGmvMkRc',
                'recUJRXLqsT0kUS2H',
                'rec3jvzDN1JpQorkf',
                'rec00FxfOH2sBbLUk',
                'reccYjmVlqLYX33GY',
                'recUqqxERhla2Vdm9',
                'recce4R02YzHrIdeV',
                'recEvUXTNgeQlbvNx',
                'recAVB4efdlqbdGBb'
              ],
              website: 'https://tribute-brand.com/',
              data_ref: {
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                },
                project_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'fundraising_rounds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d1a0950eae80e0d04fa']
                }
              },
              category: ['Web3'],
              subcategories: ['Fashion', 'AR/VR'],
              description: 'This is the platform for contactless & cyber fashion',
              stages: 'Seed',
              announcement: 'https://twitter.com/tribute_brand/status/1546896779949625348',
              logo: 'media/10/20_2023/10_data_05-16-2023_0227amTRIBUTE BRAND.jpg',
              created_at: '2023-08-29T05:09:39.778000Z',
              updated_at: '2023-08-29T05:09:39.778000Z'
            },
            {
              _id: '64ed7d9478519e0d2803f03f',
              slug: 'rstlss---seed-3172022-a24ab2',
              _id_fake: 'recSLM3hzZOB4ofPd',
              project: 'RSTLSS - Seed',
              date: '2022-03-17T00:00:00Z',
              amount_quantity: 3500000,
              amount_quantity_unit: 'USD',
              investors_id: [
                'recm7wfecgFRB2xQT',
                'rectpizLHPfiKFusF',
                'recgATon5gnvtpW0G',
                'rec00FxfOH2sBbLUk',
                'recxZoOh0f38JzEAf'
              ],
              website: 'https://rstlss.xyz/',
              data_ref: {
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                },
                project_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'fundraising_rounds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d0c36dc0799f209c443']
                }
              },
              category: ['Web3'],
              subcategories: ['Metaverse', 'Marketplace'],
              description:
                'Making it seamless for fashion creators to access Metaverse in a scalable way while controlling their IP',
              stages: 'Seed',
              announcement:
                'https://tech.eu/2022/03/17/wheres-paris-hilton-investing-charli-cohens-rstlss-wears-in-35-million-to-create-and-sell-multiverse-fashion',
              logo: 'media/10/20_2023/10_data_05-16-2023_0224amrst.jpg',
              created_at: '2023-08-29T05:09:39.778000Z',
              updated_at: '2023-08-29T05:09:39.778000Z'
            },
            {
              _id: '64ed7da4825fbf5c2208a83c',
              slug: 'dressx---series-a-3142023-a06f16',
              _id_fake: 'recKeQZShHf1wAl34',
              project: 'DressX - Series A',
              date: '2023-03-14T00:00:00Z',
              amount_quantity: 15000000,
              amount_quantity_unit: 'USD',
              investors_id: ['rec6Ty0D1ziHx8sY3', 'recnnj4nUlP9b1adf', 'rec00FxfOH2sBbLUk', 'recZ2EhSX42dculZq'],
              website: 'https://dressx.com/',
              data_ref: {
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                },
                project_id: {
                  ref_table: 't_project_list',
                  ref_to_field: 'fundraising_rounds_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d12825fbf5c22089fcb', '64ed7d22a5aef65f93088061']
                }
              },
              category: ['Web3'],
              subcategories: ['Fashion'],
              description: 'Digital fashion store that carries 3D clothing collections.',
              stages: 'Series A ',
              announcement:
                'https://www.theblock.co/post/219565/digital-fashion-firm-dressx-raises-15-million-in-series-a-funding',
              created_at: '2023-08-29T05:09:54.635000Z',
              updated_at: '2023-08-29T05:09:54.635000Z'
            }
          ]
        },
        portfolio_companies_id: {
          ref_table: 't_project_list',
          ref_to_field: 'investors_id',
          ref_type: 2,
          ref_ids: [
            '64ed7d0c36dc0799f209c443',
            '64ed7d12825fbf5c22089dab',
            '64ed7d1a0950eae80e0d04fa',
            '64ed7d1a0950eae80e0d06bd'
          ],
          ref_document: [
            {
              _id: '64ed7d0c36dc0799f209c443',
              slug: 'rstlss-no-token-bf3674',
              _id_fake: 'rec8FLNvC2Ml6Pycy',
              name: 'RSTLSS',
              logo: 'media/10/20_2023/10_data_05-16-2023_0224amrst.jpg',
              description:
                'Making it seamless for fashion creators to access Metaverse in a scalable way while controlling their IP',
              website: 'https://rstlss.xyz/',
              funding_received: [3500000, 'USD'],
              data_ref: {
                fundraising_rounds_id: {
                  ref_table: 'm_fundraising_projects',
                  ref_to_field: 'project_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d9478519e0d2803f03f']
                },
                investors_id: {
                  ref_table: 't_venture_capital_list',
                  ref_to_field: 'portfolio_companies_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7d40ae9bb20bf30ee073',
                    '64ed7d4978519e0d2803ecc1',
                    '64ed7d40ae9bb20bf30ede83',
                    '64ed7d2ad1aeb3a7050b5cef',
                    '64ed7d4978519e0d2803eb84'
                  ]
                },
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                }
              },
              symbol: 'No token',
              ecosystem: 'N/A',
              year_founded: 2022,
              twitter: 'https://twitter.com/RSTLSSxyz',
              location: 'United Kingdom',
              created_at: '2023-08-29T05:07:23.578000Z',
              updated_at: '2023-08-29T05:07:23.578000Z'
            },
            {
              _id: '64ed7d12825fbf5c22089dab',
              slug: 'the-fabricant-no-token-6f19e2',
              _id_fake: 'recJ2pqwQWpVDrsbX',
              name: 'The Fabricant',
              logo: 'media/10/20_2023/10_data_05-16-2023_0230amfabricant.jpg',
              description: 'A digital fashion house for the metaverse',
              website: 'https://www.thefabricant.com/',
              funding_received: [14000000, 'USD'],
              data_ref: {
                fundraising_rounds_id: {
                  ref_table: 'm_fundraising_projects',
                  ref_to_field: 'project_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d9478519e0d2803ee69']
                },
                investors_id: {
                  ref_table: 't_venture_capital_list',
                  ref_to_field: 'portfolio_companies_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7d38ea56bcf30202ee8f',
                    '64ed7d38ea56bcf30202ee0e',
                    '64ed7d40ae9bb20bf30edeef',
                    '64ed7d40ae9bb20bf30edefb',
                    '64ed7d38ea56bcf30202ee23',
                    '64ed7d312d4e3a3ef302932d',
                    '64ed7d2ad1aeb3a7050b5cef',
                    '64ed7d38ea56bcf30202ed26'
                  ]
                },
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                }
              },
              symbol: 'No token',
              ecosystem: 'Flow',
              year_founded: 2018,
              twitter: 'https://twitter.com/the_fab_ric_ant',
              location: 'Netherlands',
              created_at: '2023-08-29T05:07:29.906000Z',
              updated_at: '2023-08-29T05:07:29.906000Z'
            },
            {
              _id: '64ed7d1a0950eae80e0d04fa',
              slug: 'tribute-brand-no-token-5273a9',
              _id_fake: 'recamVTcE9ivp25qt',
              name: 'Tribute Brand',
              logo: 'media/10/20_2023/10_data_05-16-2023_0227amTRIBUTE BRAND.jpg',
              description: 'This is the platform for contactless & cyber fashion',
              website: 'https://tribute-brand.com/',
              funding_received: [4500000, 'USD'],
              data_ref: {
                fundraising_rounds_id: {
                  ref_table: 'm_fundraising_projects',
                  ref_to_field: 'project_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d9478519e0d2803ef1f']
                },
                investors_id: {
                  ref_table: 't_venture_capital_list',
                  ref_to_field: 'portfolio_companies_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7d40ae9bb20bf30edea3',
                    '64ed7d38ea56bcf30202ede8',
                    '64ed7d2ad1aeb3a7050b5e05',
                    '64ed7d40ae9bb20bf30edd66',
                    '64ed7d2ad1aeb3a7050b5cef',
                    '64ed7d312d4e3a3ef30291ba',
                    '64ed7d38ea56bcf30202ee21',
                    '64ed7d40ae9bb20bf30edd6c',
                    '64ed7d53825fbf5c2208a114'
                  ]
                },
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                }
              },
              symbol: 'No token',
              ecosystem: 'N/A',
              year_founded: 2020,
              twitter: 'https://twitter.com/tribute_brand',
              location: 'Croatia',
              created_at: '2023-08-29T05:07:37.076000Z',
              updated_at: '2023-08-29T05:07:37.076000Z'
            },
            {
              _id: '64ed7d1a0950eae80e0d06bd',
              slug: 'unxd-no-token-010455',
              _id_fake: 'recifCJu8c5Yu7rPu',
              name: 'UNXD',
              logo: 'media/10/20_2023/10_data_05-16-2023_0237amunxd.jpg',
              description: 'Digital luxury and culture',
              website: 'https://unxd.com/',
              funding_received: [4000000, 'USD'],
              data_ref: {
                fundraising_rounds_id: {
                  ref_table: 'm_fundraising_projects',
                  ref_to_field: 'project_id',
                  ref_type: 2,
                  ref_ids: ['64ed7d8eae9bb20bf30ee22a']
                },
                investors_id: {
                  ref_table: 't_venture_capital_list',
                  ref_to_field: 'portfolio_companies_id',
                  ref_type: 2,
                  ref_ids: [
                    '64ed7d40ae9bb20bf30ee0a4',
                    '64ed7d38ea56bcf30202ecd1',
                    '64ed7d2ad1aeb3a7050b5cef',
                    '64ed7d2ad1aeb3a7050b5fe8',
                    '64ed7d40ae9bb20bf30edffb',
                    '64ed7d38ea56bcf30202eced',
                    '64ed7d2ad1aeb3a7050b5e80',
                    '64ed7d312d4e3a3ef3029381',
                    '64ed7d4978519e0d2803ec92',
                    '64ed7d4978519e0d2803eb52',
                    '64ed7d38ea56bcf30202ef6b',
                    '64ed7d312d4e3a3ef3029424',
                    '64ed7d2ad1aeb3a7050b608d',
                    '64ed7d4978519e0d2803eb68',
                    '64ed7d38ea56bcf30202ed7a',
                    '64ed7d38ea56bcf30202efb9',
                    '64ed7d312d4e3a3ef3029162',
                    '64ed7d53825fbf5c2208a114'
                  ]
                },
                founder: {
                  ref_table: 'm_funds_founders',
                  ref_to_field: null,
                  ref_type: 1,
                  ref_ids: []
                }
              },
              symbol: 'No token',
              ecosystem: 'N/A',
              year_founded: 2021,
              twitter: 'https://twitter.com/unxd_nft',
              location: 'United States',
              created_at: '2023-08-29T05:07:37.076000Z',
              updated_at: '2023-08-29T05:07:37.076000Z'
            }
          ]
        }
      },
      summary: 'Red DAO is a DAO for digital fashion.',
      crunchbase_link: 'https://www.crunchbase.com/organization/red-dao',
      created_at: '2023-08-29T05:07:53.731000Z',
      updated_at: '2023-08-29T05:07:53.731000Z'
    },
    data_contributor: {
      ventures_name: 'RED DAO234',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      description: 'Red DAO is a DAO for digital fashion. Hello'
    },
    count_vote: 7,
    count_rejected: 1,
    total_count: 20,
    status: 'Rejected',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  },
  {
    _id: 'e687e58c-f188-46cf-8fea-08e7798b576f',
    type: 'people',
    update: 'edit',
    data_ref: {
      author: {
        name: 'Bobby Bao',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1124amBobby Bao.jpg'
      }
    },
    origin_data: {
      _id: '64ed7d2ad1aeb3a7050b5cef',
      slug: 'red-dao-dao-e61bbc',
      _id_fake: 'rec00FxfOH2sBbLUk',
      ventures_name: 'RED DAO',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      type: 'DAO',
      actively_investing: 'Active',
      website: 'https://reddao.xyz/',
      twitter: 'https://twitter.com/red___dao',
      summary: 'Red DAO is a DAO for digital fashion.',
      crunchbase_link: 'https://www.crunchbase.com/organization/red-dao',
      created_at: '2023-08-29T05:07:53.731000Z',
      updated_at: '2023-08-29T05:07:53.731000Z',
      roadmap: [
        {
          time: 'Q3 2017',
          icon: '',
          title: 'Phase 1',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q2 2018',
          icon: '',
          title: 'Phase 2',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q1 2019',
          icon: '',
          title: 'Phase 3',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool', 'MXM Protocol'],
          status: '1'
        },
        {
          time: 'Q2 2023',
          icon: '',
          title: 'Phase 4',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2024',
          icon: '',
          title: 'Phase 5',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2025',
          icon: '',
          title: 'Phase 6',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2026',
          icon: '',
          title: 'Phase 7',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        }
      ]
    },
    data_contributor: {
      ventures_name: 'RED DAO234',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      description: 'Red DAO is a DAO for digital fashion. Hello',
      roadmap: [
        {
          time: 'Q3 2017',
          icon: '',
          title: 'Phase 1',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q2 2018',
          icon: '',
          title: 'Phase 2',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q1 2019',
          icon: '',
          title: 'Phase 3',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool', 'MXM Protocol'],
          status: '1'
        },
        {
          time: 'Q2 2023',
          icon: '',
          title: 'Phase 4',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2024',
          icon: '',
          title: 'Phase 5',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2025',
          icon: '',
          title: 'Phase 6',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2026',
          icon: '',
          title: 'Phase 7',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        }
      ]
    },
    count_vote: 1000,
    count_rejected: 2000,
    total_count: 20,
    status: 'Rejected',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  },
  {
    _id: 'e687e58c-f188-46cf-8fea-08e7798b578f',
    type: 'token',
    update: 'edit',
    data_ref: {
      author: {
        name: 'Bobby Bao',
        avatar: 'media/10/20_2023/10_data_05-15-2023_1124amBobby Bao.jpg'
      }
    },
    origin_data: {
      _id: '64ed7d2ad1aeb3a7050b5cef',
      slug: 'red-dao-dao-e61bbc',
      _id_fake: 'rec00FxfOH2sBbLUk',
      ventures_name: 'RED DAO',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      type: 'DAO',
      actively_investing: 'Active',
      website: 'https://reddao.xyz/',
      twitter: 'https://twitter.com/red___dao',
      summary: 'Red DAO is a DAO for digital fashion.',
      crunchbase_link: 'https://www.crunchbase.com/organization/red-dao',
      created_at: '2023-08-29T05:07:53.731000Z',
      updated_at: '2023-08-29T05:07:53.731000Z',
      roadmap: [
        {
          time: 'Q3 2017',
          icon: '',
          title: 'Phase 1',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q2 2018',
          icon: '',
          title: 'Phase 2',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q1 2019',
          icon: '',
          title: 'Phase 3',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool', 'MXM Protocol'],
          status: '1'
        },
        {
          time: 'Q2 2023',
          icon: '',
          title: 'Phase 4',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2024',
          icon: '',
          title: 'Phase 5',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2025',
          icon: '',
          title: 'Phase 6',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2026',
          icon: '',
          title: 'Phase 7',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        }
      ]
    },
    data_contributor: {
      ventures_name: 'RED DAO234',
      logo: 'media/10/20_2023/10_data_05-15-2023_1047amred dao.jpg',
      description: 'Red DAO is a DAO for digital fashion. Hello',
      roadmap: [
        {
          time: 'Q3 2017',
          icon: '',
          title: 'Phase 1',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q2 2018',
          icon: '',
          title: 'Phase 2',
          content: [
            'Release official Website',
            'Launch Airdrop Event',
            'Announce BCA Partner and Contributor',
            'Release white developer team and backers'
          ],
          status: '1'
        },
        {
          time: 'Q1 2019',
          icon: '',
          title: 'Phase 3',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool', 'MXM Protocol'],
          status: '1'
        },
        {
          time: 'Q2 2023',
          icon: '',
          title: 'Phase 4',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2024',
          icon: '',
          title: 'Phase 5',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2025',
          icon: '',
          title: 'Phase 6',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        },
        {
          time: 'Q2 2026',
          icon: '',
          title: 'Phase 7',
          content: ['Release Mobile App', 'Add 100,000,000 DCM to Global Pool'],
          status: '0'
        }
      ]
    },
    count_vote: 1000,
    count_rejected: 2000,
    total_count: 20,
    status: 'Rejected',
    create_at: '2023-11-15T10:12:13Z',
    start_date: '2023-11-15T10:12:13Z',
    end_date: '2023-11-21T10:12:13Z'
  }
]
export const CONST_CONTRIBUTE = {
  TOKEN: 'contribute_token',
  PEOPLE: 'contribute_people',
  VENTURE: 'contribute_venture'
}

export const DUMMY_DATA_CONTRIBUTOR_TABLE = [
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 0,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_2.svg',
    name: 'Audius',
    status: 1,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_3.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 1,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_2.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_3.svg',
    name: 'Audius',
    status: 0,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  },
  {
    avatar: '/assets/images/UserProfile/avatarDummy_1.svg',
    name: 'Audius',
    status: 2,
    approve: '10%',
    reject: '10%',
    point: '8'
  }
]

export const STATUS_CONTRIBUTOR = {
  0: 'Rejected',
  1: 'in Review',
  2: 'Verified'
}

export const PROFILE_DROPDOWN = [
  {
    icon: '/assets/images/UserProfile/profile.svg',
    title: 'Profile',
    link: '/user_profile'
  },
  {
    icon: '/assets/images/UserProfile/quest_profile_dropdown_icon.svg',
    title: 'Quests',
    link: '/quest',
    type: 'new'
  },
  {
    icon: '/assets/images/UserProfile/setting.svg',
    title: 'Setting',
    link: '/profile-settings/profile'
  },

  {
    icon: '/assets/images/UserProfile/change_plan.svg',
    title: 'Change Plan',
    discount: '15%',
    link: '/profile-settings/change_plan'
  },
  {
    icon: '/assets/images/UserProfile/be_contributor.svg',
    title: 'Be Contributor',
    link: '/profile-settings/contributor'
  }
]

export const DummyDataScreener = [
  'Good Shitcoin June 2023',
  'My Screener',
  'OP Chain - A8',
  'OP Chain - A8',
  'OP Chain - A8',
  'OP Chain - A8',
  'OP Chain - A8',
  'OP Chain - A8',
  'Good Shitcoin June 2023'
]

export const PLAN = {
  STANDARD: 'standard',
  PRO: 'pro',
  ENTERPRISE: 'enterprise'
}

export const PLAN_DATA = {
  standard: {
    icon: '/assets/images/plan_standard.png',
    title: 'Standard',
    sub_title: 'For a tatse our product',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.',
    price: '0',
    sub_price_monthly: '',
    benefits: [
      'Daily crypto news and insights in your inbox',
      'Exclusive long-form daily research',
      'Advanced screener, charting and watchlist features'
    ]
  },
  pro: {
    icon: '/assets/images/plan_pro.png',
    title: 'Pro',
    sub_title: 'Make individual product',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.',
    price: '10.45',
    benefits: [
      'Daily crypto news and insights in your inbox',
      'Exclusive long-form daily research',
      'Advanced screener, charting and watchlist features',
      'Access to curated sets of charts and metrics',
      '100 CSV data exports'
    ],
    recomend: true
  },
  enterprise: {
    icon: '/assets/images/plan_enterprise.png',
    title: 'Enterprise',
    sub_title: 'For companies',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.',
    price: '19.99',
    benefits: [
      'Daily crypto news and insights in your inbox',
      'Exclusive long-form daily research',
      'Advanced screener, charting and watchlist features',
      'Access to curated sets of charts and metrics',
      '100 CSV data exports',
      '200 CSV data exports'
    ]
  }
}

export const CONTRIBUTE_TIER = {
  TIER0: 'Tier 0',
  TIER1: 'Tier 1',
  TIER2: 'Tier 2',
  TIER3: 'Tier 3'
}

export const CONTRIBUTE_TIER_DATA = [
  'Daily  crypto news and insights in your inbox',
  'Exclusive long-form daily research',
  'Advanced screnner, charting and watchlist features',
  'Access to curated sets of charts and metrics',
  'CSV data exports'
]

// master

export const styleCol = (value) => {
  if (
    value === 'Price (USD)' ||
    value === 'Volumn 24h' ||
    value === 'Market Cap' ||
    value === 'People type' ||
    value === 'Gender' ||
    value === 'Founded year' ||
    value === 'vol 24h' ||
    value === 'Score' ||
    value === 'change3D' ||
    value === 'change1W' ||
    value === 'change1Y' ||
    value === 'totalvaluelocked' ||
    value === 'totalSupply' ||
    value === 'maxSupply'
  ) {
    return shortDisplayStyle
  }
  if (
    value === 'Relate Organization' ||
    value === 'Relate People' ||
    value === 'Organization' ||
    value === 'Venture Type' ||
    value === 'Founder' ||
    value === 'change 24h' ||
    value === 'Relate project' ||
    value === 'Trading Volumn' ||
    value === 'circulatingSupply' ||
    value === 'smart_contracts' ||
    value === 'twitter' ||
    value === 'Linkdl'
  ) {
    return mediumDisplayStyle
  }
  if (
    value === 'Location' ||
    value === 'Summary' ||
    value === 'Average Liquidity' ||
    value === 'decription' ||
    value === 'Primary Job Title' ||
    value === 'backgroundSummary'
  ) {
    return longDisplayStyle
  }
  if (value === 'Sector' || value === 'Relate Token') {
    return tagStyle
  }
}

export const dymmyDataSector = ['Web3', 'Wallet', 'Stupid Contract', 'Protocol', 'Top Server NA', 'Wallet']

export const sortLabel = ['desc', 'asc', null]

export const tableNames = { project: 't_project_list', people: 'm_funds_founders', venture: 't_venture_capital_list' }

// AI

export const DUMMY_DATA_HISTORY = [
  {
    _id: '0e246860-e4a2-4004-9418-3b1de366b74c',
    last_update: '2023-11-03 06:15:06',
    topic: 'Who is founder of Binance? and who is current CEO ?',
    messages: [
      {
        _id: 'd1a399de-5f35-40b2-8320-c8095a69b7cf',
        time_message: '06:15:06',
        question: 'Who is founder of Binance? and who is current CEO ?',
        user: 'Monticello',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      },
      {
        _id: '5a5ed1b8-8bbc-4dbe-b114-41265eb19009',
        time_message: '03:22:54',
        question: 'Who is founder of Binance ?',
        user: 'Lyon',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      }
    ]
  },
  {
    _id: '012013b0-6614-4cba-9f95-d1b1a57f0dd1',
    last_update: '2023-11-04 02:28:36',
    topic: 'Who is founder of Binance? and who is current CEO ?',
    messages: [
      {
        _id: 'd1a399de-5f35-40b2-8320-c8095a69b7cf',
        time_message: '02:28:36',
        question: 'Who is founder of Binance? and who is current CEO ?',
        user: 'Monticello',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      },
      {
        _id: '5a5ed1b8-8bbc-4dbe-b114-41265eb19009',
        time_message: '01:28:36',
        question: 'Who is founder of Binance ?',
        user: 'Lyon',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      }
    ]
  },
  {
    _id: 'f0fd2c00-f8ed-405a-b383-adbf86dfb532',
    last_update: '2023-06-29 11:47:14',
    topic: 'Who is founder of Binance? and who is current CEO ?',
    messages: [
      {
        _id: 'd1a399de-5f35-40b2-8320-c8095a69b7cf',
        time_message: '11:47:14',
        question: 'Who is founder of Binance? and who is current CEO ?',
        user: 'Monticello',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      },
      {
        _id: '5a5ed1b8-8bbc-4dbe-b114-41265eb19009',
        time_message: '9:47:14',
        question: 'Who is founder of Binance ?',
        user: 'Lyon',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      }
    ]
  },
  {
    _id: '343d2eb8-22f6-427a-bd6a-ea19f6451357',
    last_update: '2024-01-25 16:57:53',
    topic: 'Who is founder of Binance? and who is current CEO ?',
    messages: [
      {
        _id: 'd1a399de-5f35-40b2-8320-c8095a69b7cf',
        time_message: '16:57:53',
        question: 'Who is founder of Binance? and who is current CEO ?',
        user: 'Monticello',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      },
      {
        _id: '5a5ed1b8-8bbc-4dbe-b114-41265eb19009',
        time_message: '9:47:14',
        question: 'Who is founder of Binance ?',
        user: 'Lyon',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      }
    ]
  },
  {
    _id: '83007c45-6cc8-4c8d-bc62-9346bd593963',
    last_update: '2023-10-07 22:23:53',
    topic: 'Who is founder of Binance? and who is current CEO ?',
    messages: [
      {
        _id: 'd1a399de-5f35-40b2-8320-c8095a69b7cf',
        time_message: '22:23:53',
        question: 'Who is founder of Binance? and who is current CEO ?',
        user: 'Monticello',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      },
      {
        _id: '5a5ed1b8-8bbc-4dbe-b114-41265eb19009',
        time_message: '12:23:53',
        question: 'Who is founder of Binance ?',
        user: 'Lyon',
        answer:
          "Binance, a cryptocurrency exchange, was founded by Changpeng Zhao, commonly known as CZ. He is a Chinese-Canadian entrepreneur with a background in cryptocurrency and finance. CZ founded Binance in July 2017, and the exchange quickly gained popularity due to its user-friendly interface, a wide range of supported cryptocurrencies, and low trading fees. Under CZ's leadership, Binance has become one of the largest and most influential cryptocurrency exchanges globally",
        found: [
          {
            _id: 1,
            project: 'Binance',
            symbol: 'BNB',
            logo: '/assets/images/AI/icon_bnb.svg'
          },
          {
            _id: 2,
            name: 'Changpeng ZHao',
            position: 'Founder',
            avatar: '/assets/images/acc-avt.png'
          },
          {
            _id: 3,
            title: 'The raise of the Bianance doimanate the whole world',
            link: 'https://cryptonews.com/binance/uastA%FAuiysf'
          },
          {
            _id: 3,
            venture_name: 'Patek Optimism',
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/AI/OP.svg'
          },
          {
            _id: 4,
            funding_round: 'Serial 1C',
            funding_raised: 12345670000,
            logo: '/assets/images/CoinGeckgo.png',
            logo_round: '/assets/images/axs.png'
          }
        ]
      }
    ]
  },
  {
    _id: '9d54cf89-4859-4c65-9386-d17d3d0b870d',
    last_update: '2023-12-04 03:25:39',
    topic: 'Who is founder of Binance? and who is current CEO ?'
  },
  {
    _id: '928bae13-26c7-4f11-87b2-c1a0ce523f5b',
    last_update: '2024-07-03 15:01:16',
    topic: 'Who is founder of Binance? and who is current CEO ?'
  },
  {
    _id: '0c2fe714-3656-4c11-8aa1-4b257e362be6',
    last_update: '2023-03-06 16:31:08',
    topic: 'Who is founder of Binance? and who is current CEO ?'
  }
]

export const DUMMY_TIPS = [
  {
    text: 'Who is Co-founder of Binance ?'
  },
  {
    text: `Who is CZ's current wife ?`
  },
  {
    text: 'Where does Changpeng ZHao live now ?'
  },
  {
    text: 'Who is Co-founder of Binance ?'
  }
]

export const KEY_CHECK = ['watchlist']

export const Dummy_Assets = [
  { logo: '/assets/images/flow.png' },
  { logo: '/assets/images/OP.png' },
  { logo: '/assets/images/eth.png' },
  { logo: '/assets/images/btc.png' },
  { logo: '/assets/images/bcc.png' },
  { logo: '/assets/images/bcd.png' }
]

export const EMPTY_DATA = '--'

export const SHARE_MASTER = {
  PUBLIC: '0',
  PRIVATE: '1'
}

export const COLUMNS_MASTER = {
  projects: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: '_id',
      width: 80,
      textAlign: 'center',
      view: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Assets',
      width: 264,
      view: true
    },
    {
      id: 3,
      label: 'Price (USD)',
      titletable: 'Price (USD)',
      dataIndex: 'price_usd',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 4,
      label: 'change 24h',
      titletable: 'Change 24h',
      dataIndex: 'change24h',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 5,
      label: 'Market Cap',
      titletable: 'M. Cap',
      dataIndex: 'market_cap_usd',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 6,
      label: 'vol 24h',
      titletable: 'Vol. 24h',
      dataIndex: 'volume',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 7,
      label: 'Relate People',
      titletable: 'Rel. People',
      dataIndex: 'peoples',
      width: 144,
      textAlign: 'right',
      view: true
    },
    {
      id: 8,
      label: 'Relate Organization',
      titletable: 'Rel. ORG',
      dataIndex: 'organizations',
      width: 128,
      textAlign: 'right',
      view: true
    },

    {
      id: uuidv4(),
      label: 'change 24h',
      titletable: 'Change 30D',
      dataIndex: 'change30d',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: uuidv4(),
      label: 'change 24h',
      titletable: 'Change 7D',
      dataIndex: 'change7d',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: uuidv4(),
      label: 'change 24h',
      titletable: 'Change 1h',
      dataIndex: 'change1h',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: uuidv4(),
      label: 'totalvaluelocked',
      titletable: 'Total Value Locked',
      dataIndex: 'total_val_locked',
      textAlign: 'right',
      width: 140,
      view: false
    },
    {
      id: uuidv4(),
      label: 'circulatingSupply',
      titletable: 'Circulating Supply',
      dataIndex: 'circulating_supply',
      textAlign: 'right',
      width: 140,
      view: false
    },
    {
      id: uuidv4(),
      label: 'maxSupply',
      titletable: 'Max Supply',
      dataIndex: 'max_supply',
      width: 128,
      textAlign: 'right',
      view: false
    },
    {
      id: uuidv4(),
      label: 'totalSupply',
      titletable: 'Total Supply',
      dataIndex: 'total_supply',
      width: 128,
      textAlign: 'right',
      view: false
    },
    {
      id: uuidv4(),
      label: 'description',
      titletable: 'Description',
      dataIndex: 'description',
      width: 380,
      textAlign: 'left',
      view: false
    },
    {
      id: 9,
      label: 'Sector',
      titletable: 'Sector',
      dataIndex: 'sector',
      width: 420,
      textAlign: 'right',
      view: true
    }
  ],
  people: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: 'id',
      width: 80,
      textAlign: 'center',
      view: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Name',
      width: 264,
      view: true
    },
    {
      id: 3,
      label: 'Primary Job Title',
      titletable: 'Primary Job Title',
      width: 315,
      textAlign: 'right',
      view: true
    },
    {
      id: 4,
      label: 'Organization',
      titletable: 'Organization',
      dataIndex: 'organization',
      textAlign: 'right',
      width: 144,
      view: true
    },
    {
      id: 5,
      label: 'People type',
      titletable: 'People Type',
      dataIndex: 'type_of_investor',
      textAlign: 'right',
      width: 144,
      view: false
    },
    {
      id: 6,
      label: 'Relate Token',
      titletable: 'Related Token',
      dataIndex: 'relate_token',
      textAlign: 'right',
      width: 144,
      view: false
    },
    {
      id: 7,
      label: 'Location',
      titletable: 'Location',
      dataIndex: 'location',
      textAlign: 'right',
      width: 406,
      view: true
    },
    {
      id: 8,
      label: 'Gender',
      titletable: 'Gender',
      dataIndex: 'gender',
      textAlign: 'right',
      width: 144,
      view: true
    },

    {
      id: uuidv4(),
      label: 'backgroundSummary',
      titletable: 'Background Summary',
      dataIndex: 'background_summary',
      width: 240,
      view: false
    },
    {
      id: uuidv4(),
      label: 'social',
      titletable: 'Social',
      dataIndex: 'social',
      textAlign: 'right',
      width: 135,
      view: true
    }
    // {
    //   id: uuidv4(),
    //   label: 'twitter',
    //   titletable: 'Twitter',
    //   dataIndex: 'twitter',
    //   width: 135,
    //   view: true
    // },
    // {
    //   id: uuidv4(),
    //   label: 'linkedIn',
    //   titletable: 'Linkedin',
    //   dataIndex: 'linkedin',
    //   width: 135,
    //   view: true
    // },
    // {
    //   id: uuidv4(),
    //   label: 'crunchbase',
    //   titletable: 'Crunchbase',
    //   dataIndex: 'crunchbase',
    //   width: 135,
    //   view: false
    // }
  ],
  venture: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: '_id',
      width: 80,
      textAlign: 'center',
      view: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Names',
      width: 264,
      view: true
    },
    {
      id: 3,
      label: 'Venture Type',
      titletable: 'Venture Type',
      dataIndex: 'type',
      width: 166,
      textAlign: 'right',
      view: true
    },
    {
      id: 4,
      label: 'Founded year',
      titletable: 'Founded Year',
      dataIndex: 'year_founded',
      width: 166,
      textAlign: 'right',
      view: true
    },
    {
      id: 5,
      label: 'Founder',
      titletable: 'Founder',
      dataIndex: 'founder',
      width: 162,
      textAlign: 'right',
      view: true
    },
    {
      id: 6,
      label: 'Location',
      titletable: 'Location',
      dataIndex: 'location',
      width: 219,
      textAlign: 'right',
      view: true
    },
    {
      id: 7,
      label: 'Summary',
      titletable: 'Summary',
      dataIndex: 'summary',
      width: 325,
      textAlign: 'left',
      view: true
    },
    {
      id: 8,
      label: 'Relate Project',
      titletable: 'Related Projects',
      dataIndex: 'relate_project',
      width: 162,
      textAlign: 'right',
      view: true
    },
    {
      id: uuidv4(),
      label: 'activelyInvesting',
      titletable: 'Actively Investing',
      dataIndex: 'actively_investing',
      textAlign: 'right',
      width: 162,
      view: true
    },
    {
      id: uuidv4(),
      label: 'totalInvestment',
      titletable: 'Total Investment',
      dataIndex: 'total_investment_amount',
      textAlign: 'right',
      width: 162,
      view: true
    },
    {
      id: uuidv4(),
      label: 'numberOfInvestment',
      titletable: 'Number Investment',
      dataIndex: 'number_of_investment',
      textAlign: 'right',
      width: 162,
      view: true
    },
    // {
    //   id: uuidv4(),
    //   label: 'email',
    //   titletable: 'Email Address',
    //   dataIndex: 'email_address',
    //   width: 168,
    //   view: false
    // },
    // {
    //   id: uuidv4(),
    //   label: 'website',
    //   titletable: 'Website',
    //   dataIndex: 'website',
    //   width: 168,
    //   view: false
    // },
    // {
    //   id: uuidv4(),
    //   label: 'twitter',
    //   titletable: 'Twitter',
    //   dataIndex: 'twitter',
    //   textAlign: 'right',
    //   width: 162,
    //   view: false
    // },
    // {
    //   id: uuidv4(),
    //   label: 'linkedIn',
    //   titletable: 'Linkedin',
    //   dataIndex: 'linkedin',
    //   textAlign: 'right',
    //   width: 162,
    //   view: false
    // },
    // {
    //   id: uuidv4(),
    //   label: 'numberofemployee',
    //   titletable: 'Number of Employee',
    //   dataIndex: 'employees',
    //   textAlign: 'right',
    //   width: 162,
    //   view: false
    // },
    {
      id: uuidv4(),
      label: 'social',
      titletable: 'Social',
      dataIndex: 'social',
      textAlign: 'right',
      width: 135,
      view: true
    }
  ]
}

export const LOGO_CONTRACTS = {
  ethereum: 'https://assets.coingecko.com/asset_platforms/images/279/small/ethereum.png?1694050123',
  'polygon-pos': 'https://assets.coingecko.com/asset_platforms/images/15/small/polygon_pos.png?1694050211',
  energi: 'https://assets.coingecko.com/asset_platforms/images/76/small/energi.jpeg?1671721477',
  'harmony-shard-0': '',
  avalanche: 'https://assets.coingecko.com/asset_platforms/images/12/small/avalanche.png?1694050274',
  fantom: 'https://assets.coingecko.com/asset_platforms/images/17/small/fantom.png?1692350311',
  'binance-smart-chain': 'https://assets.coingecko.com/asset_platforms/images/1/small/bnb_smart_chain.png?1694050220',
  xdai: '',
  aurora: 'https://assets.coingecko.com/asset_platforms/images/53/small/Aurora-logo-token-darkBG.png?1691396902',
  smartbch: '',
  'near-protocol': '',
  'arbitrum-one': 'https://assets.coingecko.com/asset_platforms/images/33/small/AO_logomark.png?1695026226',
  solana: 'https://assets.coingecko.com/asset_platforms/images/5/small/solana.png?1694050227',
  'klay-token': 'https://assets.coingecko.com/asset_platforms/images/9672/small/klatyn.png?1694050280',
  bitgert: 'https://assets.coingecko.com/asset_platforms/images/83/small/Bitgert_Brise.jpeg?1657090765',
  tron: 'https://assets.coingecko.com/asset_platforms/images/1094/small/TRON_LOGO.png?1699345110',
  cardano: '',
  'optimistic-ethereum': '',
  sora: '',
  'huobi-token': '',
  conflux: '',
  aptos: 'https://assets.coingecko.com/asset_platforms/images/116/small/aptos_round.png?1673959745',
  polkadot: 'https://assets.coingecko.com/asset_platforms/images/12171/small/polkadot.jpeg?1695265803',
  moonbeam:
    'https://assets.coingecko.com/asset_platforms/images/56/small/cropped-Moonbeam-Favicon-550px.png?1642585035',
  chiliz: '',
  boba: 'https://assets.coingecko.com/asset_platforms/images/47/small/BOBA.png?1636812129',
  komodo: '',
  base: 'https://assets.coingecko.com/asset_platforms/images/131/small/base.jpeg?1684806195',
  Bitcichain: '',
  zksync: 'https://assets.coingecko.com/asset_platforms/images/121/small/zksync.jpeg?1679897283',
  'metis-andromeda': 'https://assets.coingecko.com/asset_platforms/images/54/small/metis.png?1640927614',
  elrond: '',
  ardor: '',
  qtum: '',
  stellar: '',
  cronos: 'https://assets.coingecko.com/asset_platforms/images/46/small/cronos.jpeg?1636714736',
  osmosis: '',
  syscoin: 'https://assets.coingecko.com/asset_platforms/images/63/small/xDiIFaKO_400x400.jpg?1645757802',
  stacks: '',
  algorand: '',
  moonriver: '',
  celo: '',
  eos: '',
  astar: 'https://assets.coingecko.com/asset_platforms/images/71/small/Astar.jpeg?1649770724',
  kusama: '',
  'the-open-network': '',
  terra: '',
  'polygon-zkevm': 'https://assets.coingecko.com/asset_platforms/images/122/small/polygonzkevm.jpg?1680085332',
  telos: 'https://assets.coingecko.com/asset_platforms/images/7588/small/TLOS_200.png?1647572974',
  pulsechain: '',
  core: '',
  evmos: '',
  'arbitrum-nova': 'https://assets.coingecko.com/asset_platforms/images/93/small/AN_logomark.png?1695026131',
  cosmos: 'https://assets.coingecko.com/asset_platforms/images/1481/small/cosmos.png?1701407444',
  kardiachain: 'https://assets.coingecko.com/asset_platforms/images/31/small/5453.png?1657009677',
  'okex-chain': '',
  songbird: '',
  'terra-2': '',
  'proof-of-memes': '',
  velas: 'https://assets.coingecko.com/asset_platforms/images/62/small/velas.jpg?1645632256',
  sui: '',
  oasis: '',
  secret: '',
  kava: '',
  ronin: 'https://assets.coingecko.com/asset_platforms/images/45/small/ronin.png?1694050266',
  linea: '',

  icon: '',
  ordinals: '',
  fuse: 'https://assets.coingecko.com/asset_platforms/images/55/small/2023-02-14_11.03.01.jpg?1676353127',
  nem: '',
  binancecoin: '',
  thundercore: 'https://assets.coingecko.com/asset_platforms/images/86/small/thundercore.jpg?1658729859',
  iotex: 'https://assets.coingecko.com/asset_platforms/images/16/small/iotex.png?1696734004',
  elastos: 'https://assets.coingecko.com/asset_platforms/images/69/small/elastos.jpeg?1649051100',
  'milkomeda-cardano': 'https://assets.coingecko.com/asset_platforms/images/67/small/milkomeda-cardano.png?1648688103',
  theta: 'https://assets.coingecko.com/asset_platforms/images/59/small/jxOQobOB_400x400.jpeg?1643452587',
  meter: '',
  'hedera-hashgraph': 'https://assets.coingecko.com/asset_platforms/images/30/small/hedera.jpeg?1695813773',
  hoo: '',
  'kucoin-community-chain': '',
  bittorrent: 'https://assets.coingecko.com/asset_platforms/images/108/small/bttc.png?1669094956',
  'xdc-network': '',
  zilliqa: '',
  oasys: '',
  nuls: '',
  rootstock: '',
  'mixin-network': '',
  canto: 'https://assets.coingecko.com/asset_platforms/images/92/small/canto-network.png?1661214697',
  'fusion-network': '',
  hydra: 'https://assets.coingecko.com/asset_platforms/images/85/small/hydra.jpeg?1658284970',
  xrp: '',
  tomochain: '',
  neo: '',
  tezos: '',
  'step-network': 'https://assets.coingecko.com/asset_platforms/images/98/small/step.jpeg?1663046916',
  'defi-kingdoms-blockchain': '',
  'bitkub-chain': '',
  factom: '',
  dogechain: 'https://assets.coingecko.com/asset_platforms/images/89/small/dogechain.jpeg?1660293234',
  'ethereum-classic': '',
  vechain: '',
  waves: '',
  'bitcoin-cash': '',
  empire: '',
  kujira: '',
  everscale: '',
  exosama: '',
  findora: '',
  godwoken: '',
  'coinex-smart-chain': '',
  'trustless-computer': '',
  ethereumpow: 'https://assets.coingecko.com/asset_platforms/images/102/small/ETHW.png?1663928509',
  stratis: '',
  cube: '',
  'shiden network': '',
  tombchain: '',
  'sx-network': 'https://assets.coingecko.com/asset_platforms/images/80/small/yIxICJL7_400x400.jpeg?1654674439',
  mantle: 'https://assets.coingecko.com/asset_platforms/images/140/small/mantle.jpeg?1689416551',
  ontology: '',
  'eos-evm': '',
  omni: '',
  onus: '',
  bitshares: '',
  'flare-network': '',
  rollux: 'https://assets.coingecko.com/asset_platforms/images/136/small/logo_rollux_%281%29.png?1688041919',
  wanchain: '',
  'function-x': '',
  skale: '',
  callisto: '',
  'wemix-network': '',
  tenet: '',
  'neon-evm': 'https://assets.coingecko.com/asset_platforms/images/139/small/neon.jpeg?1689316908',
  thorchain: '',
  gochain: '',
  'celer-network': '',
  vite: ''
}

export const RANK = {
  PRO: 0,
  NORMAL: 1
}

export const HIGHTLIGHT_TAGS_HOME = [
  {
    name: 'Casper',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5899.png',
    children: [
      {
        name: 'Web3',
        symbol: 'Web3'
      },
      {
        name: 'PoS',
        symbol: 'PoS'
      },
      {
        name: 'Platform',
        symbol: 'Platform'
      }
    ],
    priority: true,
    rank_priority: '2',
    slug: 'Casper-Network-cspr-c8ec197c-2d67-4519-aef6-1ebfdf82c319-a18071'
  },
  {
    name: 'Ravencoin',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2577.png',
    children: [
      {
        name: 'Crowdfunding',
        symbol: 'Crowdfunding'
      },
      {
        name: 'PoW',
        symbol: 'PoW'
      }
    ],
    priority: true,
    rank_priority: '4',
    slug: 'Ravencoin-rvn-4e83e8e1-4c92-4228-a8cb-eca9e28c905b-4f2388'
  },
  {
    name: 'Tron',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
    children: [
      {
        name: 'Media',
        symbol: 'Media'
      },
      {
        name: 'Payments',
        symbol: 'Payments'
      }
    ],
    priority: true,
    rank_priority: '1',
    slug: 'TRON-trx-a9eebfcb-d68d-471f-ba58-4db6b7a228fc-f8fe8c'
  },
  {
    name: 'The Graph',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6719.png',
    children: [
      {
        name: 'Analytics',
        symbol: 'Analytics'
      },
      {
        name: 'AI & Big Data',
        symbol: 'AI & Big Data'
      },
      {
        name: 'DeFi',
        symbol: 'DeFi'
      }
    ],
    priority: true,
    rank_priority: '3',
    slug: 'The-Graph-grt-5f78632a-a230-441a-ba39-93d5f69ac5d6-109dc8'
  },
  {
    name: 'XYO',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4679.png',
    children: [
      {
        name: 'Logistics',
        symbol: 'Logistics'
      },
      {
        name: 'Oracles',
        symbol: 'Oracles'
      }
    ],
    priority: true,
    rank_priority: '3',
    slug: 'XYO-Network-xyo-62606d93-a501-4256-81c5-d843955822f7-2674c0'
  }
]

export const DUMMY_SUGGETIONS = [
  {
    label: 'tag1',
    id: 1
  },
  {
    label: 'tag2asdasdasdasd',
    id: 2
  },
  {
    label: 'tag3',
    id: 3
  }
]

export const COLUMNS_MASTER_DEFAULT = {
  projects: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: '_id',
      width: 80,
      textAlign: 'center',
      view: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Assets',
      width: 264,
      view: true
    },
    {
      id: 3,
      label: 'Price (USD)',
      titletable: 'Price (USD)',
      dataIndex: 'price_usd',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 4,
      label: 'change 24h',
      titletable: 'Change 24h',
      dataIndex: 'change24h',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 5,
      label: 'Market Cap',
      titletable: 'M. Cap',
      dataIndex: 'market_cap_usd',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 6,
      label: 'vol 24h',
      titletable: 'Vol. 24h',
      dataIndex: 'volume',
      width: 128,
      textAlign: 'right',
      view: true
    },
    {
      id: 7,
      label: 'Relate People',
      titletable: 'Rel. People',
      dataIndex: 'peoples',
      width: 144,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 8,
      label: 'Relate Organization',
      titletable: 'Rel. ORG',
      dataIndex: 'organizations',
      width: 128,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 9,
      label: 'Sector',
      titletable: 'Sector',
      dataIndex: 'sector',
      width: 420,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 10,
      label: 'description',
      titletable: 'Description',
      dataIndex: 'description',
      width: 380,
      textAlign: 'left',
      view: false
    },
    {
      id: 11,
      label: 'change 24h',
      titletable: 'Change 30D',
      dataIndex: 'change30d',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: 12,
      label: 'change 24h',
      titletable: 'Change 7D',
      dataIndex: 'change7d',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: 13,
      label: 'change 24h',
      titletable: 'Change 1h',
      dataIndex: 'change1h',
      textAlign: 'right',
      width: 128,
      view: false
    },
    {
      id: 14,
      label: 'totalvaluelocked',
      titletable: 'Total Value Locked',
      dataIndex: 'total_val_locked',
      textAlign: 'right',
      width: 140,
      view: false
    },
    {
      id: 15,
      label: 'circulatingSupply',
      titletable: 'Circulating Supply',
      dataIndex: 'circulating_supply',
      textAlign: 'right',
      width: 140,
      view: false
    },
    {
      id: 16,
      label: 'maxSupply',
      titletable: 'Max Supply',
      dataIndex: 'max_supply',
      width: 128,
      textAlign: 'right',
      view: false
    },
    {
      id: 17,
      label: 'totalSupply',
      titletable: 'Total Supply',
      dataIndex: 'total_supply',
      width: 128,
      textAlign: 'right',
      view: false
    }
  ],
  people: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: '_id',
      width: 80,
      textAlign: 'center',
      view: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Name',
      width: 264,
      view: true
    },
    {
      id: 3,
      label: 'Primary Job Title',
      titletable: 'Primary Job Title',
      dataIndex: 'primary_job_title',
      width: 315,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 4,
      label: 'Organization',
      titletable: 'Organization',
      dataIndex: 'organization',
      textAlign: 'right',
      width: 144,
      view: true,
      sort: false
    },
    {
      id: 5,
      label: 'People type',
      titletable: 'People Type',
      dataIndex: 'type_of_investor',
      textAlign: 'right',
      width: 144,
      view: false
    },
    {
      id: 6,
      label: 'Relate Token',
      titletable: 'Related Token',
      dataIndex: 'relate_token',
      textAlign: 'right',
      width: 144,
      view: false,
      sort: false
    },
    {
      id: 7,
      label: 'Location',
      titletable: 'Location',
      dataIndex: 'location',
      textAlign: 'right',
      width: 406,
      view: true,
      sort: false
    },
    {
      id: 8,
      label: 'Gender',
      titletable: 'Gender',
      dataIndex: 'gender',
      textAlign: 'right',
      width: 144,
      view: true
    },

    {
      id: 9,
      label: 'backgroundSummary',
      titletable: 'Background Summary',
      dataIndex: 'background_summary',
      width: 240,
      view: false
    },
    {
      id: 10,
      label: 'social',
      titletable: 'Social',
      dataIndex: 'social',
      textAlign: 'right',
      width: 135,
      view: true,
      sort: false
    },
    {
      id: 11,
      label: 'Sector',
      titletable: 'Sector',
      dataIndex: 'sector',
      width: 420,
      textAlign: 'right',
      view: true,
      sort: false
    }
  ],
  venture: [
    {
      id: 1,
      label: 'Numerical Order',
      titletable: '#',
      dataIndex: '_id',
      width: 80,
      textAlign: 'center',
      view: true,
      sort: true
    },
    {
      id: 2,
      label: 'Assets',
      titletable: 'Assets',
      width: 264,
      view: true,
      sort: true
    },
    {
      id: 3,
      label: 'Venture Type',
      titletable: 'Venture Type',
      dataIndex: 'type',
      width: 166,
      textAlign: 'right',
      view: true
    },
    {
      id: 4,
      label: 'Founded year',
      titletable: 'Founded Year',
      dataIndex: 'year_founded',
      width: 166,
      textAlign: 'right',
      view: true
    },
    {
      id: 5,
      label: 'Founder',
      titletable: 'Founder',
      dataIndex: 'founder',
      width: 162,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 6,
      label: 'Location',
      titletable: 'Location',
      dataIndex: 'location',
      width: 219,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 7,
      label: 'Summary',
      titletable: 'Summary',
      dataIndex: 'summary',
      width: 325,
      textAlign: 'left',
      view: true
    },
    {
      id: 8,
      label: 'Relate Project',
      titletable: 'Related Projects',
      dataIndex: 'relate_project',
      width: 162,
      textAlign: 'right',
      view: true,
      sort: false
    },
    {
      id: 9,
      label: 'activelyInvesting',
      titletable: 'Actively Investing',
      dataIndex: 'actively_investing',
      textAlign: 'right',
      width: 162,
      view: true
    },
    {
      id: 10,
      label: 'totalInvestment',
      titletable: 'Total Investment',
      dataIndex: 'total_investment_amount',
      textAlign: 'right',
      width: 162,
      view: true
    },
    {
      id: 11,
      label: 'Sector',
      titletable: 'Sector',
      dataIndex: 'sector',
      width: 420,
      textAlign: 'right',
      view: true,
      sort: false
    },
    // {
    //   id: 12,
    //   label: 'email',
    //   titletable: 'Email Address',
    //   dataIndex: 'email_address',
    //   width: 168,
    //   view: false,
    //   sort: false
    // },
    // {
    //   id: 13,
    //   label: 'website',
    //   titletable: 'Website',
    //   dataIndex: 'website',
    //   width: 168,
    //   view: false,
    //   sort: false
    // },
    {
      id: 14,
      label: 'social',
      titletable: 'Social',
      dataIndex: 'social',
      textAlign: 'right',
      width: 135,
      view: true,
      sort: false
    },
    {
      id: 15,
      label: 'numberofemployee',
      titletable: 'Number of Employee',
      dataIndex: 'employees',
      textAlign: 'right',
      width: 162,
      view: false
    }
  ]
}

export const ROUTER_LINK = {
  Token: '/directory/projects',
  People: '/directory/people',
  Organization: '/directory/organizations'
}

export const KEY_MASTER = {
  projects: 'projects',
  people: 'people',
  organizations: 'organizations',
  fundraising: 'fundraising'
}

export const masterPageSids = {
  [KEY_MASTER.projects]: '65aa021b71e835f46a0abb8c',
  [KEY_MASTER.people]: '65a24546d85c1f5502083398',
  [KEY_MASTER.organizations]: '65aa021b71e835f46a0abb8b'
}

export const masterWatchlistKey = {
  [KEY_MASTER.projects]: 'watchlist_project',
  [KEY_MASTER.people]: 'watchlist_user',
  [KEY_MASTER.organizations]: 'watchlist_venture',
  'profile-token': 'watchlist_project',
  'profile-venture': 'watchlist_venture',
  'profile-people': 'watchlist_user'
}

export const KEY_MERGE_DATA_MASTER = {
  [KEY_MASTER.projects]: 'master_projects_demo',
  [KEY_MASTER.people]: 'master_peoples_demo',
  [KEY_MASTER.organizations]: 'master_ventures_demo',
  [KEY_MASTER.fundraising]: 'master_fundraising_demo'
}

export const DEFAULT_COLUMNS_MASTER = {
  [KEY_MASTER.projects]: [
    {
      title: '#',
      dataIndex: 'index',
      columnIndex: 'ordinal_number',
      width: 90,
      textAlign: 'left',
      custom_type: 'add_watchlist',
      sticky: true,
      className: 'stt',
      default: true
    },
    {
      title: 'Assets',
      dataIndex: 'name',
      columnIndex: 'name',
      width: 265,
      textAlign: 'left',
      custom_type: 'image_name',
      sticky: true,
      search: true,
      className: 'assets',
      default: true
    },
    {
      title: 'Price (USD)',
      dataIndex: 'price_usd',
      columnIndex: 'price_usd',
      width: 128,
      custom_type: 'price',
      default: true
    },
    {
      title: 'Change 24h',
      dataIndex: 'change24h',
      columnIndex: 'change24h',
      width: 128,
      custom_type: 'change',
      default: true
    },
    {
      title: 'M. Cap',
      dataIndex: 'market_cap_usd',
      columnIndex: 'market_cap_usd',
      width: 128,
      custom_type: 'number_curentcy',
      default: true
    },
    {
      title: 'Vol. 24h',
      dataIndex: 'volume',
      columnIndex: 'volume',
      width: 128,
      custom_type: 'number_curentcy',
      default: true
    },
    {
      title: 'Rel. People',
      dataIndex: 'peoples',
      columnIndex: 'peoples',
      width: 144,
      custom_type: 'img_group',
      sort: false,
      default: true,
      merge_with: ['angel_investor']
    },
    {
      title: 'Rel. ORG',
      dataIndex: 'organizations',
      columnIndex: 'organizations',
      width: 128,
      custom_type: 'img_group',
      sort: false,
      default: true
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      columnIndex: 'sector',
      width: 420,
      custom_type: 'tags_array',
      sort: false,
      default: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      columnIndex: 'description',
      width: 380,
      custom_type: 'string_large',
      default: true
    },
    {
      title: 'Change 30D',
      dataIndex: 'change30d',
      columnIndex: 'change30d',
      width: 128,
      custom_type: 'change'
    },
    {
      title: 'Change 7D',
      dataIndex: 'change7d',
      columnIndex: 'change7d',
      width: 128,
      custom_type: 'change'
    },
    {
      title: 'Change 1H',
      dataIndex: 'change1h',
      columnIndex: 'change1h',
      width: 128,
      custom_type: 'change'
    },
    {
      title: 'Total Value Locked',
      dataIndex: 'tvl',
      columnIndex: 'tvl',
      width: 140
    },
    {
      title: 'Circulating Supply',
      dataIndex: 'circulating_supply',
      columnIndex: 'circulating_supply',
      width: 140,
      custom_type: 'number_full_curentcy'
    },
    {
      title: 'Max Supply',
      dataIndex: 'max_supply',
      columnIndex: 'max_supply',
      width: 140,
      custom_type: 'number_full_curentcy'
    },
    {
      title: 'Total Supply',
      dataIndex: 'total_supply',
      columnIndex: 'total_supply',
      width: 140,
      custom_type: 'number_full_curentcy'
    }
  ],
  [KEY_MASTER.people]: [
    {
      title: '#',
      dataIndex: 'index',
      width: 90,
      textAlign: 'left',
      custom_type: 'add_watchlist',
      className: 'stt',
      sticky: true,
      default: true,
      columnIndex: 'ordinal_number'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 265,
      textAlign: 'left',
      custom_type: 'image_name',
      sticky: true,
      search: true,
      className: 'assets',
      default: true,
      columnIndex: 'name'
    },
    {
      title: 'Primary Job Title',
      dataIndex: 'primary_job_title',
      width: 315,
      custom_type: 'tags_arr_two',
      default: true,
      sort: false,
      columnIndex: 'primary_job_title'
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: 144,
      custom_type: 'img_group',
      default: true,
      sort: false,
      columnIndex: 'organization'
    },
    {
      title: 'People Type',
      dataIndex: 'type_of_investor',
      width: 144,
      custom_type: 'tags_radius',
      columnIndex: 'type_of_investor'
    },
    {
      title: 'Related Token',
      dataIndex: 'relate_token',
      width: 144,
      custom_type: 'img_group',
      sort: false,
      columnIndex: 'relate_token',
      merge_with: ['portfolio_angel_investor']
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 406,
      custom_type: 'location',
      default: true,
      sort: false,
      columnIndex: 'location'
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 144,
      custom_type: 'gender',
      default: true,
      columnIndex: 'gender'
    },
    {
      title: 'Background Summary',
      dataIndex: 'background_summary',
      width: 240,
      custom_type: 'string_large',
      columnIndex: 'background_summary'
    },
    {
      title: 'Social',
      dataIndex: 'index',
      width: 135,
      custom_type: 'socicals',
      default: true,
      sort: false,
      columnIndex: 'social'
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      width: 420,
      custom_type: 'tags_array',
      default: true,
      columnIndex: 'sector'
    }
  ],
  [KEY_MASTER.organizations]: [
    {
      title: '#',
      dataIndex: 'index',
      width: 90,
      textAlign: 'left',
      custom_type: 'add_watchlist',
      className: 'stt',
      sticky: true,
      default: true,
      columnIndex: 'ordinal_number'
    },
    {
      title: 'Assets',
      dataIndex: 'ventures_name',
      width: 265,
      textAlign: 'left',
      custom_type: 'image_name',
      sticky: true,
      search: true,
      className: 'assets',
      default: true,
      columnIndex: 'name'
    },
    {
      title: 'Venture Type',
      dataIndex: 'type',
      width: 160,
      custom_type: 'tags_radius',
      default: true,
      columnIndex: 'type'
    },
    {
      title: 'Founded Year',
      dataIndex: 'year_founded',
      width: 160,
      default: true,
      columnIndex: 'year_founded'
    },
    {
      title: 'Founder',
      dataIndex: 'founder',
      width: 160,
      custom_type: 'img_group',
      sort: false,
      default: true,
      columnIndex: 'founder'
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: 220,
      custom_type: 'tags_shape',
      sort: false,
      default: true,
      columnIndex: 'location'
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      width: 325,
      custom_type: 'string_large',
      default: true,
      columnIndex: 'summary'
    },
    {
      title: 'Related Projects',
      dataIndex: 'relate_project',
      width: 160,
      custom_type: 'img_group',
      sort: false,
      default: true,
      columnIndex: 'relate_project'
    },
    {
      title: 'Actively Investing',
      dataIndex: 'actively_investing',
      width: 160,
      custom_type: 'tag_active',
      default: true,
      columnIndex: 'actively_investing'
    },
    {
      title: 'Total Investment',
      dataIndex: 'total_investment_amount',
      width: 160,
      custom_type: 'number_full_curentcy',
      default: true,
      columnIndex: 'total_investment_amount'
    },
    {
      title: 'Sector',
      dataIndex: 'sector',
      width: 420,
      custom_type: 'tags_array',
      default: true,
      columnIndex: 'sector'
    },
    {
      title: 'Social',
      dataIndex: 'record',
      width: 135,
      custom_type: 'socicals',
      sort: false,
      default: true,
      columnIndex: 'socicals'
    },
    {
      title: 'Number of Employee',
      dataIndex: 'number_of_investment',
      width: 160,
      columnIndex: 'number_of_investment'
    }
  ],
  [KEY_MASTER.fundraising]: [
    {
      title: '#',
      dataIndex: 'index',
      width: 90,
      textAlign: 'center',
      sticky: true,
      default: true,
      columnIndex: 'ordinal_number'
    },
    {
      title: 'Project name',
      dataIndex: 'projects',
      width: 265,
      textAlign: 'left',
      custom_type: 'image_name_arr',
      sticky: true,
      search: true,
      className: 'assets',
      default: true,
      columnIndex: 'name'
    },
    {
      title: 'Round',
      dataIndex: 'stages',
      width: 194,
      default: true,
      custom_type: 'tags_shape_gray',
      columnIndex: 'stages'
    },
    {
      title: 'Money Raised',
      dataIndex: 'amount_quantity',
      width: 214,
      default: true,
      custom_type: 'number_full_curentcy',
      columnIndex: 'amount_quantity'
    },
    {
      title: 'Rel. ORG',
      dataIndex: 'organizations',
      width: 162,
      default: true,
      columnIndex: 'organizations',
      custom_type: 'img_group'
    },
    {
      title: 'Rel. People',
      dataIndex: 'people',
      width: 162,
      default: true,
      columnIndex: 'people',
      custom_type: 'img_group'
    },
    {
      title: 'Source',
      dataIndex: 'announcement',
      width: 100,
      default: true,
      columnIndex: 'source',
      custom_type: 'link_extend'
    },
    {
      title: 'Announced Date',
      dataIndex: 'date',
      width: 214,
      default: true,
      columnIndex: 'date',
      custom_type: 'date_type_one'
    }
  ]
}

export const KEY_PRI_PUB = {
  private: '0',
  public: '1'
}

export const dataSelect = [
  {
    icon: '/assets/images/icon_public_default.svg',
    label: 'Public',
    key: KEY_PRI_PUB.public
  },
  {
    icon: '/assets/images/icon_lock.svg',
    label: 'Private',
    key: KEY_PRI_PUB.private
  }
]

export const CONST_SEARCH = {
  All: '2',
  'No Token': '1',
  'Tokens included': '0',
  'Past 1 month': '1',
  'Past 3 months': '3',
  'Past 6 months': '6',
  'Past 1 year': '1',
  'Past 2 years': '2',
  'Past 3 years': '3'
}

export const QUEST = [
  {
    id: 1,
    title: 'The first step',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 150,
    thumbnail: 'assets/images/Quest-point/Dummy/img_1.png'
  },
  {
    id: 2,
    title: 'Set up all Social and Personal Info',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 125,
    thumbnail: 'assets/images/Quest-point/Dummy/img_2.png'
  },
  {
    id: 3,
    title: 'Change avatar to Statistr Mascot',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      }
      // ,
      // {
      //   value: `Visit AIBB company's website`,
      //   status: '1',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/visit_icon.svg'
      // }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_3.png',
    tag: 'DONE'
  },
  {
    id: 4,
    title: 'Quest',
    requirements: [
      {
        value: 'How to getting level up Layer2-S',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_4.png'
  },
  {
    id: 5,
    title: 'Quest',
    requirements: [
      {
        value: 'How to getting level up Layer2-S',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      }
      // ,
      // {
      //   value: 'Add bio description to let people know more about you',
      //   status: '0',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/add_icon.svg'
      // },
      // {
      //   value: `Visit AIBB company's website`,
      //   status: '1',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/visit_icon.svg'
      // }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_5.png'
  },
  {
    id: 6,
    title: 'How to getting level up Layer2-S',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 50,
    thumbnail: 'assets/images/Quest-point/Dummy/img_6.png'
  },
  {
    id: 7,
    title: 'Be Social All Connected',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 30,
    thumbnail: 'assets/images/Quest-point/Dummy/img_7.png'
  }
]

export const QUEST_2 = [
  {
    id: 1,
    title: 'The first step',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 150,
    thumbnail: 'assets/images/Quest-point/Dummy/img_1.png',
    tag: 'OPENED'
  },
  {
    id: 2,
    title: 'Set up all Social and Personal Info',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 125,
    thumbnail: 'assets/images/Quest-point/Dummy/img_2.png',
    tag: 'CLOSED'
  },
  {
    id: 3,
    title: 'Change avatar to Statistr Mascot',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      }
      // ,
      // {
      //   value: `Visit AIBB company's website`,
      //   status: '1',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/visit_icon.svg'
      // }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_3.png',
    tag: 'CLOSED'
  },
  {
    id: 4,
    title: 'Quest',
    requirements: [
      {
        value: 'How to getting level up Layer2-S',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_4.png'
  },
  {
    id: 5,
    title: 'Quest',
    requirements: [
      {
        value: 'How to getting level up Layer2-S',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      }
      // ,
      // {
      //   value: 'Add bio description to let people know more about you',
      //   status: '0',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/add_icon.svg'
      // },
      // {
      //   value: `Visit AIBB company's website`,
      //   status: '1',
      //   note: 'Check out our official website to find all the treasures Morph has to offer !',
      //   icon: 'assets/images/Quest-point/visit_icon.svg'
      // }
    ],
    point: 25,
    thumbnail: 'assets/images/Quest-point/Dummy/img_5.png'
  },
  {
    id: 6,
    title: 'How to getting level up Layer2-S',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg',
        type: 'connect'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 50,
    thumbnail: 'assets/images/Quest-point/Dummy/img_6.png'
  },
  {
    id: 7,
    title: 'Be Social All Connected',
    requirements: [
      {
        value: 'Connect Twitter account',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Follow @Statistr on Twitter',
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Contribute/twitterIcon.svg'
      },
      {
        value: 'Add bio description to let people know more about you',
        status: '0',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/add_icon.svg'
      },
      {
        value: `Visit AIBB company's website`,
        status: '1',
        note: 'Check out our official website to find all the treasures Morph has to offer !',
        icon: 'assets/images/Quest-point/visit_icon.svg'
      }
    ],
    point: 30,
    thumbnail: 'assets/images/Quest-point/Dummy/img_7.png'
  }
]

export const NAVBAR_ITEMS = [
  {
    key: 'features',
    label: 'Features',
    icon: '/assets/images/Header/icon_Feature.svg',
    items: [
      [
        {
          _id: 1,
          icon: '/assets/images/Header/icon_ai.svg',
          label: 'Statistr AI',
          link: '/ai-home',
          desc: 'New version AI, Advanced Search Engine with AI',
          type: 'Coming soon'
        },
        {
          _id: 2,
          icon: '/assets/images/Header/icon_Statistr_DAO.svg',
          label: 'Statistr DAO',
          link: '/statistr_dao',
          desc: 'Contribute value by verifying data, ascend to DAO membership',
          type: 'Coming soon'
        },
        {
          _id: 3,
          icon: '/assets/images/Header/icon_dashboard.svg',
          label: 'Observatory',
          link: '/observatory',
          desc: 'Immersive market exploration across multiple avenues.'
        }
      ],
      [
        {
          _id: 4,
          icon: '/assets/images/Header/icon_Project_Directory.svg',
          label: 'Project Directory',
          link: '/directory/projects',
          desc: 'Gather all Project Data & Insight all in one place'
          // type: 'new'
        },
        {
          _id: 5,
          icon: '/assets/images/Header/icon_People_Directory.svg',
          label: 'People Directory',
          link: '/directory/people',
          desc: 'Aggregate all People Data & Insights into a single repository.'
          // type: 'new'
        },
        {
          _id: 6,
          icon: '/assets/images/Header/icon_venture_Directory.svg',
          label: 'Organizations Directory',
          link: '/directory/organizations',
          desc: 'Compile all Organization Data & Insights into a centralized hub'
          // type: 'new'
        },
        {
          _id: 7,
          icon: '/assets/images/fundrasing.svg',
          label: 'Fundraising Directory',
          link: '/directory/fundraising',
          desc: 'Compilation of fundraising rounds and detailed information',
          type: 'new'
        }
      ],
      [
        {
          _id: 8,
          icon: '/assets/images/Header/icon_Intelligence.svg',
          label: 'Intelligence',
          link: '/news-research?type=news&view=default',
          desc: 'Crypto News integrity verification'
        }
      ]
    ]
  },
  {
    key: 'about',
    label: 'About',
    icon: '/assets/images/Header/icon_About_DD_2.svg',
    items: [
      [
        {
          _id: 1,
          icon: '/assets/images/Header/icon_Statistr_Team.svg',
          label: 'About Us',
          link: '/about',
          desc: 'Meet our hustle members!'
        },
        {
          _id: 2,
          icon: '/assets/images/Header/icon_Statistr_Careers.svg',
          label: 'Statistr Careers',
          link: '/career',
          desc: 'Find your potential future career '
        },
        {
          _id: 3,
          icon: '/assets/images/Header/Icon_Pricing.svg',
          label: 'Pricing',
          link: '/',
          desc: 'Price & Payments',
          type: 'Coming soon'
        }
      ],
      [
        {
          _id: 5,
          icon: '/assets/images/Header/icon_Help_Center.svg',
          label: 'Help Center',
          link: '/support/help-center',
          desc: 'Learning, Guilding & FAQs'
        },
        {
          _id: 4,
          icon: '/assets/images/Header/Icon_API.svg',
          label: 'API',
          link: '/',
          desc: 'API Documentation',
          type: 'Coming soon'
        }
      ]
    ]
  },
  {
    key: 'user',
    label: 'User',
    icon: '/assets/images/Header/icon_User_DD.svg',
    items: [
      [
        {
          _id: 1,
          icon: '/assets/images/Header/icon_profile.svg',
          label: 'Profile',
          link: '/user_profile'
        },
        {
          _id: 5,
          icon: '/assets/images/Header/icon_setting.svg',
          label: 'Settings',
          link: '/profile-settings/profile'
        },
        {
          _id: 2,
          icon: '/assets/images/Header/icon_quests.svg',
          label: 'Quests',
          link: '/quest',
          type: 'new'
        },
        {
          _id: 3,
          icon: '/assets/images/Header/icon_pro.svg',
          label: 'Change Plan',
          link: '/profile-settings/change_plan'
        },
        {
          _id: 4,
          icon: '/assets/images/Header/icon_contribute.svg',
          label: 'Be Contributor',
          link: '/profile-settings/contributor'
        }
      ]
    ]
  }
]

export const IconTag = {
  new: '/assets/images/Header/tag_new.svg',
  'Coming soon': '/assets/images/Header/tag_soon.svg'
}

export const CONST_KEY_SHARE = {
  private: '1',
  public: '0'
}

export const GetLinkProfile = {
  organizations: '/organization/',
  people: '/people/',
  projects: '/project/'
}

export const SupportHelp = {
  'getting-started': [
    {
      name: 'INTRODUCE',
      content: [
        {
          title: 'Welcome to Statistr',
          slug: 'welcome-to-statistr',
          description:
            'Statistr stands out as a prominent ecosystem for consolidating and disseminating comprehensive crypto-related data. By leveraging Statistr Sifter, users can efficiently navigate and monitor a vast array of information pertaining to diverse projects, investment funds, and notable individuals within the dynamic crypto landscape'
        },
        {
          title: 'The data of Statistr',
          slug: 'the-data-of-statistr',
          description:
            "Statistr's database is built and developed by global contributors from all over the world. This dynamic team gathers and updates information limitlessly, ensuring our database is always comprehensive and up-to-date. Each contributor brings their own expertise and knowledge, contributing to the diversity and richness of our database. "
        }
      ]
    },
    {
      name: 'PRODUCT & FEATURES',
      content: [
        {
          title: 'Crypto Obsavatory',
          slug: 'crypto-obsavatory',
          description:
            'The Observatory is a sophisticated dashboard system, intelligently designed to optimize providing users with a comprehensive view of the market'
        },
        {
          title: 'Blockchain Directory Introduction',
          slug: 'blockchain-directory-introduction',
          description:
            'The Statistr Directory is a place to delve into detailed rankings for tokens, individuals, and organizations in the crypto market, accompanied by a comprehensive and modern filtering system to help users easily find the groups they need.'
        },
        {
          title: 'Project Profile Introduction',
          slug: 'project-profile-introduction',
          description:
            'Our Project Pages contain a wealth of qualitative and quantitative data to help you make more informed decisions.'
        },
        {
          title: 'Organization Profile Introduction',
          slug: 'organization-profile-introduction',
          description:
            'Our Organization Profile provide the most objective and comprehensive data about organizations and investment funds in the industry.'
        },
        {
          title: 'People Profile Introduction',
          slug: 'people-profile-introduction',
          description:
            'Our People Profile provides the most objective and comprehensive data about individuals, founders of organizations and projects, showcasing their trends and influence in the industry.'
        },
        {
          title: 'Statistr Dao',
          slug: 'statistr-dao',
          description:
            'The DAO page is the place where you can explore and access all proposals from Statistr DAO. You can see the detailed progress of each proposal as well as the voting status and remaining time for each proposal.'
        },
        // {
        //   title: 'Statistr AI',
        //   slug: 'statistr-ai',
        //   description:
        //     ""
        // },
        {
          title: 'Crypto Intelligence',
          slug: 'crypto-intelligence',
          description:
            "The world of cryptocurrency is a rapidly evolving and changing landscape, making manual tracking a challenging task. Statistr's news aggregation tool simplifies this process by gathering all the latest news and events from the cryptocurrency industry from various verified sources. As a result, Statistr users can stay updated quickly and continuously, aiding them in identifying trends promptly. With Crypto Intelligence."
        }
      ]
    }
  ],
  'account-management': [
    {
      name: '',
      content: [
        {
          title: 'Create Account',
          slug: 'create-account',
          description: "Learn how to create Statistr's account"
        },
        // {
        //   title: 'KYC',
        //   slug: 'kyc',
        //   description:
        //     "Learn how to KYC your account"
        // },
        {
          title: 'Edit Profile & Settings',
          slug: 'edit-profile-settings',
          description: 'Learn how to edit or setting your profile'
        }
      ]
    }
  ],
  contribution: [
    {
      name: '',
      content: [
        {
          title: 'Contribute VC data',
          slug: 'contribute-vc-data',
          description:
            'Statistr stands out as a prominent ecosystem for consolidating and disseminating comprehensive crypto-related data. By leveraging Statistr Sifter, users can efficiently navigate and monitor a vast array of information pertaining to diverse projects, investment funds, and notable individuals within the dynamic crypto landscape'
        },
        {
          title: 'Contribute People data',
          slug: 'contribute-people-data',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          title: 'Contribute Project data',
          slug: 'contribute-project-data',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          title: 'Contribute research and reports',
          slug: 'contribute-research-and-reports',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          title: 'Contribute news and events',
          slug: 'contribute-news-and-events',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          title: 'Voting',
          slug: 'voting',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        },
        {
          title: 'Contribution history',
          slug: 'contribution-history',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ]
    }
  ],
  'pricing-plans': [
    {
      name: '',
      content: [
        {
          title: 'Choose Plan',
          slug: 'choose-plan',
          description:
            'Statistr stands out as a prominent ecosystem for consolidating and disseminating comprehensive crypto-related data. By leveraging Statistr Sifter, users can efficiently navigate and monitor a vast array of information pertaining to diverse projects, investment funds, and notable individuals within the dynamic crypto landscape'
        },
        {
          title: 'Statistr Pro',
          slug: 'statistr-pro',
          description: `To subscribe to the Statistr Pro plan, you need to select the desired plan in the 'Change Plan' section.`
        },
        {
          title: 'Enterprise',
          slug: 'enterprise',
          description:
            'The Enterprise plan is the highest premium offering from Statistr. We do not provide specific pricing for this plan but will negotiate directly with customers regarding the specific services and benefits they desire. If you wish to apply for Enterprise, simply select the plan in the Choose Plan section and leave your information. Statistr will proactively reach out to initiate negotiations.'
        }
      ]
    }
  ],
  'api-help-guide': [
    {
      name: '',
      content: [
        {
          title: 'Discover API',
          slug: 'discover-api',
          description:
            'Statistr stands out as a prominent ecosystem for consolidating and disseminating comprehensive crypto-related data. By leveraging Statistr Sifter, users can efficiently navigate and monitor a vast array of information pertaining to diverse projects, investment funds, and notable individuals within the dynamic crypto landscape'
        },
        {
          title: 'Enrichment API',
          slug: 'enrichment-api',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ]
    }
  ],
  careers: [
    {
      name: '',
      content: [
        {
          title: 'Apply Job',
          slug: 'apply-job',
          description:
            'Statistr stands out as a prominent ecosystem for consolidating and disseminating comprehensive crypto-related data. By leveraging Statistr Sifter, users can efficiently navigate and monitor a vast array of information pertaining to diverse projects, investment funds, and notable individuals within the dynamic crypto landscape'
        },
        {
          title: 'Post Job',
          slug: 'post-job',
          description:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
        }
      ]
    }
  ]
}

export const FAQ_SUPPORT = [
  {
    title: 'General FAQ',
    questions: [
      {
        quest: 'What is Statistr?',
        answer:
          'Statistr is an Off-chain Data Library, providing the most detailed data on the potential cryptocurrency market.'
      },
      {
        quest: 'What Types of Data are Available on Website?',
        answer:
          'Statistr provides the most comprehensive data on projects, investment funds, organizations, individuals, and founders in the web3 industry. Along with that are the latest updates on news and events happening in the market.'
      },
      {
        quest: 'Is the data of Statistr updated continuously or not?',
        answer: `
          <p>Statistr's data is consistently updated and rigorously vetted by a powerful and innovative system.</p>
          <div style="display: flex; flex-direction: column; margin-left: 12px">
            <span>- The Statistr DAO ensures that all data updated on Statistr is guaranteed for authenticity and transparency. Every user can become a contributor and contribute data to the DAO. </span>
            <span>- A professional research team and contributors from around the world provide data and ensure the accuracy of the database. </span>
          </div>
        `
      },
      {
        quest: 'Do I need to pay to access the data?',
        answer: `Free users have access to all of Statistr's data; however, they are limited in using advanced features. You can expand these limitations by upgrading your plan or becoming an active contributor to gain access to these features. Additionally, Statistr offers a Free Trial for users who want to experience the full range of services provided by Statistr.`
      },
      {
        quest: 'How can I receive notifications from Statistr?',
        answer:
          'To receive notifications from Statistr, you need to check the "Receive notifications" button in your personal profile settings.'
      },
      {
        quest: 'Can I create an account using a web3 wallet?',
        answer:
          'You can use a Web3 Wallet to create a Statistr account. However, we only limit the use of certain new Web3 wallets for this feature.'
      }
    ]
  },
  {
    title: 'Subscription Plan & Billing FAQ',
    questions: [
      {
        quest: 'How is the free plan different from the Enterprise plans?',
        answer:
          'The free plan, represented by the Standard Subscription, provides limited access compared to the Enterprise plans. While the Standard Subscription grants access to basic features excluding Exclusive content, offers daily crypto news and insights, allows creation of up to 3 datasets, and provides free AI chat interactions 3 times a day, the Enterprise plans offer a more comprehensive set of features. These include access to all basic and Exclusive content, export functionality to CSV, custom reports and insights, unlimited dataset creation, unlimited AI chat interactions, and high-quality API access.'
      },
      {
        quest: `Can I try Statistr's premium packages?`,
        answer:
          'Statistr encourages customers to try out our premium packages for 7 days. During this trial period, you will have full access to the platform with all the privileges of the Statistr Pro package. However, please note that you are only eligible for one trial per account; subsequent registrations will incur charges.'
      },
      {
        quest: 'Can I use the Enterprise plan for a trial?',
        answer: 'No, you can only use our service with the free trial provided for the Pro package.'
      },
      {
        quest: 'Can I pay on a monthly basis?',
        answer:
          'Certainly, however, you will not be eligible for discounts when subscribing to long-term packages. We offer discounts of up to 30% for long-term subscriptions.'
      },
      {
        quest: 'Can I pay with cryptocurrency?',
        answer:
          'Yes, you can pay with cryptocurrency by sending payment to our wallet address. The system will also automatically update your payment status.'
      },
      {
        quest: 'Can I cancel at anytime?',
        answer:
          'There is no long-term commitment after the 7-days. Users can continue with their Messari subscription monthly, or cancel at anytime with 30 day notice'
      }
    ]
  },
  {
    title: 'Contribution FAQs',
    questions: [
      {
        quest: `If I don't subscribe to any plan, can I still contribute?`,
        answer:
          'All Statistr users can become contributors. We aim to create value in the Statistr user community, where everyone can receive rewards for contributing value to Statistr.'
      },
      {
        quest: 'Can I register as a contributor for Statistr?',
        answer: `Certainly, it's possible. We always welcome contributors who want to create value within our ecosystem.`
      },
      {
        quest: 'How can I participate in Voting?',
        answer:
          'To participate in voting, you first need to become a DAO member of Statistr. In the near future, we will provide specific conditions for parties to become Statistr DAO members. Please stay tuned and closely follow Statistr updates.'
      },
      {
        quest: 'If I contribute to Statistr, can I get a discount when signing up for a plan?',
        answer:
          'Becoming a Contributor of Statistr comes with many benefits depending on your contribution. We categorize the level of contribution of contributors into Tiers. The more you contribute, the higher your Tier, and the more benefits you receive, including discounts on plans.'
      },
      {
        quest: 'What will I get when I contribute to Statistr?',
        answer: `We categorize contributors' levels of contribution into Tiers; the more you contribute, the higher your Tier, and the more benefits you receive. Basic benefits include discounts on subscription plans, access to premium features, participation in an exclusive group consisting of project founders and industry influencers...`
      },
      {
        quest: `What are the benefits of Statistr's Tiers?`,
        answer:
          'To learn about the specific benefits of each tier and other contribution information, you can visit the Profile/Contribution section on Statistr. There, you can find detailed information about benefits, contribution points, contribution history, and more.'
      },
      {
        quest: 'Is there any other way to increase tier besides contributing?',
        answer: 'No, you can only increase your tier by contributing significant value to Statistr.'
      }
    ]
  }
]

export const DETAIL_TOPIC_SUPPORT = {
  'welcome-to-statistr': {
    title: 'Welcome to Statistr',
    content: `
    <h4>Crypto Observatory</h4>

    <p>The task at hand involves the collection and continuous updating of foundational information concerning cryptocurrencies operating within the cryptocurrency market. This information encompasses key metrics such as the prevailing market price, market capitalization, coin/token type, and project contact information, among others. Given the volatile nature of the cryptocurrency market, ensuring that such information is up-to-date and accurate is crucial. Therefore, it is imperative to have robust data management protocols in place, including frequent data scraping, validation, and verification procedures. The ultimate aim of this effort is to provide reliable and comprehensive data to enable stakeholders to make informed decisions regarding their cryptocurrency investment strategies.</p>

    <h4>Blockchain Directory</h4>

    <p>This tool incorporates diversified portfolios, including DeFi, Web3, NFTs, and portfolios from large funds, to classify and sort various cryptocurrency projects. This advanced functionality allows users to filter and view projects based on their specific interests and preferences, providing a clearer and more focused view of the market segments that they are most interested in. </p>
    <p>By enabling users to easily access and analyze projects within specific portfolios, the Atlas enhances the efficiency of cryptocurrency investment decision-making. The ability to quickly and accurately assess the performance of specific market segments is an invaluable feature, enabling users to stay abreast of the latest trends and developments within their areas of interest.</p>

    <h4>Crypto Intelligence</h4>

    <p>Statistr's team of experts carefully curates and compiles noteworthy market news and projects from a multitude of reliable sources within the cryptocurrency industry. This rigorous process ensures that users have access to the most up-to-date and relevant information, enabling them to stay informed of the latest trends and developments in the cryptocurrency world. Through a diverse range of sources, the team is able to provide a comprehensive and multifaceted perspective on the cryptocurrency landscape, catering to the varying interests and needs of users. By offering a curated and regularly updated information repository, Statistr seeks to create a valuable resource for cryptocurrency investors and enthusiasts alike.</p>
    <p>Statistr's research team conducts in-depth and thorough research on a variety of cryptocurrency projects, with the aim of providing readers with new perspectives and deeper insights into the latest developments and trends within the industry. By leveraging a combination of advanced analytical tools and expert domain knowledge, the team is able to produce high-quality research reports that offer valuable insights into the strengths and weaknesses of different projects. Through this process, the team seeks to uncover hidden opportunities and risks within the cryptocurrency market, and provide readers with the information they need to make informed investment decisions.</p>

    <h4>Statistr Sifter</h4>

    <p>Statistr's Sifter system employs an advanced tagging and classification methodology that serves to streamline the research process for cryptocurrency investors and enthusiasts. By categorizing and labeling vast amounts of data, the Smart Sifter system provides a user-friendly and intuitive research experience. Particularly noteworthy is its ability to facilitate research on specific topics or projects within the cryptocurrency industry, thereby enhancing the efficiency and effectiveness of research efforts.</p>

    <h4>Decentralized Database</h4>

    <p>Statistr includes a decentralized autonomous organization (DAO) system that enables users to contribute data and vote for potential modifications or adjustments to the information within the database. Users can submit data via a designated form or interface, which is stored within the database. They are also permitted to propose modifications or edits to current data, which are subject to voting by other users, upon approval of a proposal, the adjustments or edits are automatically executed within the database.</p>

    <h4>Statistr AI</h4>

    <p>Statistr is revolutionizing data access and retrieval with its Intelligent Search feature, designed to provide users with a seamless and efficient data querying experience. Gone are the days of manual database searches and sifting through vast amounts of information. With Intelligent Search, users can now simply type in their questions or queries, and the system will swiftly retrieve relevant data from the project's database, eliminating the need for users to perform time-consuming searches themselves.</p>
    <p>The foundation of Intelligent Search lies in its advanced search engine capabilities. By harnessing cutting-edge algorithms and techniques, Statistr's search engine understands natural language queries, allowing users to input questions in a human-like manner. This ensures that users do not need to learn complex query languages or database structures, making data access more accessible to a broader audience.</p>
    <p>However, the Intelligent Search feature doesn't stop there. To further enhance the user experience, Statistr will integrate AI technologies, enabling the system to learn and improve over time. As more queries are processed, the AI algorithms continuously refine their understanding of user intent and data relevance, leading to increasingly accurate and personalized search results. The AI-powered enhancements also facilitate predictive suggestions. As users begin typing their queries, the system anticipates their intent, presenting auto-suggestions and relevant completion options. This predictive capability not only accelerates the search process but also helps users discover data they might not have considered otherwise.</p>
    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0217amwelcome_statistr.mp4'
  },
  'the-data-of-statistr': {
    title: 'The Data of Statistr',
    content: `
    <h4>The Data of Statistr</h4>

    <p>Statistr's database is built and developed by global contributors from all over the world. This dynamic team gathers and updates information limitlessly, ensuring our database is always comprehensive and up-to-date. Each contributor brings their own expertise and knowledge, contributing to the diversity and richness of our database. </p>
    <p>Statistr has built a rich and diverse database comprising a large number of entities in the crypto market.</p>

    <img src='/assets/images/support/welcom_to_statistr.png' alt='img' />
    `
  },
  'crypto-obsavatory': {
    title: 'Statistr Observatory Introduction',
    content: `
    <h4>Statistr Observatory Introduction</h4>

    <p>The Observatory is a sophisticated dashboard system, intelligently designed to optimize providing users with a comprehensive view of the market</p>

    <img src='/assets/images/support/dashboard.png' alt='img' />

    <p>The task at hand involves the collection and continuous updating of foundational information concerning cryptocurrencies operating within the cryptocurrency market. This information encompasses key metrics such as the prevailing market price, market capitalization, coin/token type, and project contact information, among others. Given the volatile nature of the cryptocurrency market, ensuring that such information is up-to-date and accurate is crucial. Therefore, it is imperative to have robust data management protocols in place, including frequent data scraping, validation, and verification procedures. The ultimate aim of this effort is to provide reliable and comprehensive data to enable stakeholders to make informed decisions regarding their cryptocurrency investment strategies.</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0235amobservatory.mp4'
  },
  'blockchain-directory-introduction': {
    title: 'Blockchain Directory Introduction',
    content: `
    <p>The Statistr Directory is a place to delve into detailed rankings for tokens, individuals, and organizations in the crypto market, accompanied by a comprehensive and modern filtering system to help users easily find the groups they need.</p>

    <img src='/assets/images/support/directory.png' alt='img' />

    <p>This tool incorporates diversified portfolios, including DeFi, Web3, NFTs, and portfolios from large funds, to classify and sort various cryptocurrency projects. This advanced functionality allows users to filter and view projects based on their specific interests and preferences, providing a clearer and more focused view of the market segments that they are most interested in. </p>

    <p>By enabling users to easily access and analyze projects within specific portfolios, the Atlas enhances the efficiency of cryptocurrency investment decision-making. The ability to quickly and accurately assess the performance of specific market segments is an invaluable feature, enabling users to stay abreast of the latest trends and developments within their areas of interest.</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0236ammaster01.mp4'
  },
  'project-profile-introduction': {
    title: 'Project Profile Introduction',
    content: `
    <p>Our Project Pages contain a wealth of qualitative and quantitative data to help you make more informed decisions.</p>

    <p>Use the search bar or click on any project throughout the site to visit its Project Page.</p>

    <p>The data on the profile page is divided into multiple sections.</p>

    <ul>
      <li>The Overview section provides a comprehensive summary including project description, market data, fundraising rounds, and latest news, price analysis charts, supported exchanges, and project activity tracking on Twitter.</li>

      <li>The Token Distribution section provides information about the tokenomics of the project and the token release schedule through charts.</li>

      <li>The Team section provides data about the founders and team members involved in the project.</li>

      <li>The Backers section provides information about the project's investors, along with details of all fundraising rounds, including comprehensive information for each round.</li>

      <li>The News section is where you can update the latest news related to the project. The news is continuously updated promptly and timely by us.</li>
    </ul>
    <p></p>
    <p>Token Profile Demo</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0236amtoken.mp4'
  },
  'organization-profile-introduction': {
    title: 'Organization Profile Introduction',
    content: `
    <p>Our Organization Profile provide the most objective and comprehensive data about organizations and investment funds in the industry.</p>

    <p>Use the search bar or click on any project throughout the site to visit its Organization Page.</p>

    <p>The data on the Organization page is divided into multiple sections.</p>

    <ul>
      <li>The Summary section provides basic information about the organization, social media addresses, and related projects and individuals, along with the organization's activity tracking framework on Twitter.</li>

      <li>The Financials section provides information about projects, transactions in which the organization has invested or participanted, and details about each investment round.</li>

      <li>The Company Profile section provides information about the members, personnel of the organization, and their positions within the organization.</li>

      <li>The news section will provide continuously updated and timely news and events related to organizations.</li>
    </ul>
    <p></p>
    <p>Organization Profile Demo</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0237amventure.mp4'
  },
  'people-profile-introduction': {
    title: 'People Profile Introduction',
    content: `
    <p>Our People Profile provides the most objective and comprehensive data about individuals, founders of organizations and projects, showcasing their trends and influence in the industry.</p>

    <p>Use the search bar or click on any project throughout the site to visit its People Page.</p>

    <p>The data on the People page is divided into multiple sections.</p>

    <ul>
      <li>The Summary section provides basic information about the individual, social media addresses, and related projects and organizations, along with the individual's activity tracking framework on Twitter.</li>

      <li>The Personal Investment section provides information about projects, transactions in which the individual has participated, and details about each investment round.</li>

      <li>The News & Activities section will provide continuously updated and timely news and events related to individuals.</li>
    </ul>
    <p></p>
    <p>People Profile Demo</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0238ampeople.mp4'
  },
  'statistr-dao': {
    title: 'Statistr DAO',
    content: `
    <h4>Statistr DAO</h4>

    <p>The DAO page is the place where you can explore and access all proposals from Statistr DAO. You can see the detailed progress of each proposal as well as the voting status and remaining time for each proposal.</p>

    <img src='/assets/images/support/dao_1.png' alt='img' style="margin-top: 12px"/>

    <p>The figures in the left-hand panel provide an <strong>overview</strong> of the proposals of Statistr DAO.</p>

    <img src='/assets/images/support/dao_2.png' alt='img' style="margin-top: 12px"/>

    <p>You can easily filter proposals by status by selecting <strong>Status</strong>.</p>

    <img src='/assets/images/support/dao_3.png' alt='img' style="margin-top: 12px"/>

    <p>To display detailed information about a specific Proposal, you need to click <strong>See Profile</strong>.</p>

    <img src='/assets/images/support/dao_4.png' alt='img' style="margin-top: 12px"/>

    <p>The screen will display information about the proposal such as contributors, number of votes, contribution content, and voting deadline.</p>

    <img src='/assets/images/support/dao_5.png' alt='img' style="margin-top: 12px"/>

    <p>If you want to participate in voting, you need to scroll to the end of the article. The screen will display two options: <strong>Accept</strong> and <strong>Reject</strong>, for you to choose from.</p>

    <img src='/assets/images/support/dao_6.png' alt='img' style="margin-top: 12px"/>

    <p>
    Statistr DAO is a groundbreaking initiative in managing and updating the Statistr Database. We empower the community, allowing anyone to become a data contributor to Statistr. We always acknowledge their contributions as long as they add value to our ecosystem. By opening up opportunities for users and empowering DAO members to make decisions, we are confident that the Statistr database will soon become one of the most valuable and diverse data repositories in the crypto market.    </p>

    <p>The DAO system of Statistr operates according to the following mechanism:</p>

    <div style="display: flex; flex-direction: column; margin-left: 12px">
      <span>- When a contributor submits data, a proposal is created on the smart contract.</span>
      <span>- After being approved by Statistr, the smart contract will publish it on Statistr DAO.</span>
      <span>- DAO members will vote on the proposal within a certain period.</span>
      <span>- If the vote passes, with DAO members voting positively, the contributor will receive a reward. If it fails, the proposal will be canceled.</span>
    </div>

    <img src='/assets/images/support/dao_7.jpg' alt='img' style="margin-top: 12px"/>

    <p>Dao demo</p>

    `,
    video: 'media/1/15_2024/1_data_04-08-2024_0753amStatistr_DAO.mp4'
  },
  // 'statistr-ai': {
  //   title: 'Subcription Plan',
  //   content: `
  //   <h4>Standard Subscription</h4>

  //   <div style="paddingLeft:24px;">
  //     <span>- Access to all basic features except Exclusive content</span>
  //     <span>- Daily crypto news and insights delivered to your inbox</span>
  //     <span>- Ability to create up to 3 datasets</span>
  //     <span>- Free AI chat 3 times a day</span>
  //   </div>

  //   <p></p>

  //   <h4>Pro Subscription</h4>

  //   <div style="paddingLeft:24px;">
  //     <span>- Access to all basic and Exclusive content</span>
  //     <span>- Daily crypto news and insights delivered to your inbox</span>
  //     <span>- Ability to create up to 10 datasets</span>
  //     <span>- Export data to CSV</span>
  //     <span>- Custom reports and insights</span>
  //     <span>- Unlimited AI chat interactions</span>
  //   </div>

  //   <p></p>

  //   <h4>Enterprise</h4>

  //   <div style="paddingLeft:24px;">
  //     <span>- Access to all basic and Exclusive content</span>
  //     <span>- Export data to CSV</span>
  //     <span>- Custom reports and insights</span>
  //     <span>- Unlimited dataset creation</span>
  //     <span>- Unlimited AI chat interactions</span>
  //     <span>- High-quality API access provided</span>
  //   </div>
  //   `,
  //   video: ''
  // },
  'crypto-intelligence': {
    title: 'Crypto Intelligence',
    content: `
    <h4>Crypto Intelligence</h4>

    <p>The world of cryptocurrency is a rapidly evolving and changing landscape, making manual tracking a challenging task. Statistr's news aggregation tool simplifies this process by gathering all the latest news and events from the cryptocurrency industry from various verified sources. As a result, Statistr users can stay updated quickly and continuously, aiding them in identifying trends promptly. With Crypto Intelligence, you can:</p>

    <div style="paddingLeft:24px;">
      <p>- Summarize noteworthy news and events daily.</p>
      <p>- Track news by specific categories.</p>
      <p>- Filter news by token, organization, or time using a modern viewing tool.</p>
    </div>

    <img src='/assets/images/support/new_1.png' alt='img' style="paddingTop:24px;"//>

    <img src='/assets/images/support/new_2.png' alt='img' style="paddingTop:24px;"/>
    `,
    video: ''
  },
  'create-account': {
    title: 'Create Account',
    content: `
    <p>The world of cryptocurrency is a rapidly evolving and changing landscape, making manual tracking a challenging task. Statistr's news aggregation tool simplifies this process by gathering all the latest news and events from the cryptocurrency industry from various verified sources. As a result, Statistr users can stay updated quickly and continuously, aiding them in identifying trends promptly. With Crypto Intelligence, you can:</p>

    <img src='/assets/images/support/nav_login.png' alt='img' />

    <p>On the new screen that appears, select <strong>"Sign Up"</strong></p>

    <img src='/assets/images/support/acc_2.png' alt='img' />

    <p> Next, choose <strong>"Sign up with Email"</strong></p>

    <img src='/assets/images/support/acc_4.png' alt='img' />

    <p> In the new screen, you need to fill in the required information in the form.</p>

    <img src='/assets/images/support/acc_3.png' alt='img' />

    <p>After completing the information input, you need to enter the verification code sent by Statistr to your email. </p>

    <img src='/assets/images/support/acc_5.png' alt='img' />

    <p>After verification, your account creation process is complete, and you can log in to Statistr.</p>
    `,
    video: ''
  },
  'edit-profile-settings': {
    title: 'Edit Profile & Settings',
    content: `
    <p>To edit and set up your profile page, first, you need to log in to Statistr and select <strong>"Settings"</strong> in the top right corner of the screen.</p>

    <img src='/assets/images/support/set_1.png' alt='img' />

    <p>The screen will display information about your account. You can edit basic information such as <strong>Username</strong>, <strong>Display name</strong>, <strong>Password</strong> or add <strong>descriptions</strong> and <strong>social media links</strong>.</p>

    <img src='/assets/images/support/set_2.png' alt='img' />
    `,
    video: ''
  },
  'choose-plan': {
    title: 'Subcription Plan',
    content: `
    <h4>Standard Subscription</h4>

    <div style="paddingLeft:24px;">
      <p>- Access to all basic features except Exclusive content</p>
      <p>- Daily crypto news and insights delivered to your inbox</p>
      <p>- Ability to create up to 3 datasets</p>
      <p>- Free AI chat 3 times a day</p>
    </div>

    <p></p>

    <h4>Pro Subscription</h4>

    <div style="paddingLeft:24px;">
      <p>- Access to all basic and Exclusive content</p>
      <p>- Daily crypto news and insights delivered to your inbox</p>
      <p>- Ability to create up to 10 datasets</p>
      <p>- Export data to CSV</p>
      <p>- Custom reports and insights</p>
      <p>- Unlimited AI chat interactions</p>
    </div>

    <p></p>

    <h4>Enterprise</h4>

    <div style="paddingLeft:24px;">
      <p>- Access to all basic and Exclusive content</p>
      <p>- Export data to CSV</p>
      <p>- Custom reports and insights</p>
      <p>- Unlimited dataset creation</p>
      <p>- Unlimited AI chat interactions</p>
      <p>- High-quality API access provided</p>
    </div>

    <img src='/assets/images/support/plan_1.png' alt='img' />
    `,
    video: ''
  },
  'statistr-pro': {
    title: 'Purchase Statistr Pro',
    content: `
    <h4>Purchase Statistr Pro</h4>

    <p>To subscribe to the Statistr Pro plan, you need to select the desired plan in the <strong>Change Plan</strong> section.</p>

    <img src='/assets/images/support/Purchase_3.png' alt='img' />

    <p>Next, you need to choose the payment method and enter transaction details. You can select a saved method or a new one.</p>

    <img src='/assets/images/support/Purchase_2.png' alt='img' />

    <p>After entering all the required information, click <strong>Confirm</strong>. The system will automatically upgrade your account.</p>

    <img src='/assets/images/support/Purchase_1.png' alt='img' />
    `,
    video: ''
  },
  enterprise: {
    title: 'Enterprise',
    content: `
    <h4>Enterprise</h4>

    <p>The Enterprise plan is the highest premium offering from Statistr. We do not provide specific pricing for this plan but will negotiate directly with customers regarding the specific services and benefits they desire. If you wish to apply for Enterprise, simply select the plan in the <strong>Change Plan</strong> section and leave your information. Statistr will proactively reach out to initiate negotiations.</p>

    <img src='/assets/images/support/Enterprise_1.png' alt='img' />
    `,
    video: ''
  }
}
export const KEY_COMPLETED_QUEST = 'fe_completed_quests'

export const DUMMY_CLOSED_QUESTS = [
  {
    _id: 1,
    name: 'Submit Proposal with StatistrDAO',
    description: 'Unlock Layer 2: Egypt Furutistic 1',
    point: 150,
    type: 'Dummy',
    quest: [
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      }
    ],
    tag: 'CLOSED'
  },
  {
    _id: 2,
    name: 'Utilize the features of Statistr',
    description: 'The Rhino Run - Polygon zkEVM',
    point: 150,
    type: 'Dummy',
    quest: [
      {
        name: 'The Rhino Run - Polygon zkEVM',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'The Rhino Run - Polygon zkEVM',
        description: 'The Rhino Run - Polygon zkEVM',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'The Rhino Run - Polygon zkEVM',
        description: 'The Rhino Run - Polygon zkEVM',
        more: { points: 10, type_check: 'Closed' }
      }
    ],
    tag: 'CLOSED'
  },
  {
    _id: 3,
    name: 'Experience StatisrAI',
    description: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
    point: 150,
    type: 'Dummy',
    quest: [
      {
        name: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        description: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        description: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        description: 'Rhino Run: Dyson Finance Find the Alternative source sdhsd',
        more: { points: 10, type_check: 'Closed' }
      }
    ],
    tag: 'CLOSED'
  }
]

export const DUMMY_CLOSED_QUESTS_CO_LAB = [
  {
    _id: 1,
    name: 'Statistr Partners',
    description: 'Unlock Layer 2: Egypt Furutistic 1',
    point: 150,
    type: 'Dummy',
    quest: [
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      },
      {
        name: 'Unlock Layer 2: Egypt Furutistic',
        description: 'Unlock Layer 2: Egypt Furutistic',
        more: { points: 10, type_check: 'Closed' }
      }
    ],
    tag: 'CLOSED'
  }
]

export const DUMMY_HORMONIX_QUESTS = [
  {
    name: '@harmonix_finance',
    description: `Reward pool: 300$ USDT An efficient hedge fund on-chain derivatives pools`,
    point: 500,
    type: 'Dummy',
    quest: [
      {
        name: 'Twitter Task',
        description: 'Use your twitter account to follow [Harmonix] https://x.com/harmonixfi ',
        more: { points: 100, type_check: 'twitter', link: ['https://x.com/harmonixfi'] },
        image: '/assets/images/Quest-point/HMN-Logo'
      },
      {
        name: `Quotes/Retweet & Like Campaign Task`,
        description: `Quotes/Retweet & Like Harmonix Campaign Post: {https://t.me/harmonix_announcement}`,
        more: { points: 100, type_check: 'twitter', link: ['https://t.me/harmonix_announcement'] },
        image: '/assets/images/Quest-point/HMN-Logo'
      },
      {
        name: 'Telegram Task',
        description: 'Join [ https://t.me/harmonix_announcement ] & Enter Telegram username below',
        more: { points: 100, type_check: 'Telegram', link: ['https://discord.gg/nMYAjmFYpz'] },
        image: '/assets/images/Quest-point/HMN-Logo'
      },
      {
        name: 'Discord Task',
        description: 'Join [https://discord.gg/nMYAjmFYpz] & Enter Discord username below',
        more: { points: 100, type_check: 'Discord', link: ['https://x.com/harmonixfi'] },
        image: '/assets/images/Quest-point/HMN-Logo'
      },
      {
        name: 'On-chain Task',
        description: ` Join Harmonix Vaults at https://app.harmonix.fi/products with min $10 deposit (on ARB/ETH) 
Submit your wallet in the box below`,
        more: { points: 100, type_check: 'Harmonix Finance' },
        image: '/assets/images/Quest-point/HMN-Logo'
      },
      {
        name: 'Eligble $STAST Airdrop',
        description: 'Eligble $STAST Airdrop',
        more: {
          point: 0,
          type_check: 'local',
          how: 'server'
        },
        image: '/assets/images/Quest-point/HMN-Logo'
      }
    ],
    more: [
      {
        image: '/assets/images/Quest-point/HMN-Logo'
      }
    ],
    _id: 1
  }
]

export const TYPE_EVENTS = [
  'All Events',
  'General',
  'AMA',
  'Exchange',
  'Partnership',
  'Release',
  'DAO',
  'Announcement',
  'Hard Fork',
  'NFT',
  'Other'
]

export const TYPE_SWITCH_GET_EVENTS = ['Upcoming Events', 'Past Events']

export const DATA_QUEST_PAGE = [
  {
    _id: '66792799e7649fd5030ff6e2',
    pageParam: 'harmonix'
  }
]
