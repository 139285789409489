import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProjectMasterState } from '@src/interface'

const initialState: ProjectMasterState = {
  name: 'Project Master',
  masterKeyTable: 'project',
  filtersAPI: {},
  heightLayout: 0,
  heightActiveFilter: 0,
  getFilters: false,
  filtersDateAPI: [['_id', '<>', '0']],
  filters: {},
  isLoadingFilter: false,
  sortState: { field: '', order: '' }
}

const projectMasterSlice = createSlice({
  name: 'project.master',
  initialState,
  reducers: {
    startEdit: (state, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    cancelEdit: (state) => {
      state.name = ''
    },
    getMasterKeyTable: (state, action) => {
      state.masterKeyTable = action.payload
    },
    getHeightLayout: (state, action) => {
      state.heightLayout = action.payload
    },
    setFiltersAPI: (state, action) => {
      state.filtersAPI = action.payload
    },
    setLoadingFilter: (state, action) => {
      state.isLoadingFilter = action.payload
    },
    setFiltersDateAPI: (state, action) => {
      const updateFilters = [...state.filtersDateAPI]
      const filtersDate = [...action.payload]
      filtersDate.forEach((filter) => {
        const indexToReplace = updateFilters.findIndex((item) => item[0] === filter[0] && item[1] === filter[1])
        if (indexToReplace !== -1) {
          updateFilters[indexToReplace] = filter
        } else {
          updateFilters.push(filter)
        }
      })
      state.filtersDateAPI = updateFilters
    },
    resetFiltersAPI: (state) => {
      state.filtersAPI = [['_id', '<>', '0']]
    },
    resetFiltersDateAPI: (state) => {
      state.filtersDateAPI = [['_id', '<>', '0']]
    },
    getFitlters: (state) => {
      state.getFilters = !state.getFilters
    },
    getHeightActiveFilter: (state, action) => {
      state.heightActiveFilter = action.payload
    },
    saveFilterRedux: (state, action) => {
      state.filters = action.payload
    },
    saveSortState: (state, action) => {
      // console.log('saveSortState', action.payload)
      state.sortState = action.payload
    }
  }
})

const projectMasterReducer = projectMasterSlice.reducer
export const {
  cancelEdit,
  startEdit,
  getMasterKeyTable,
  setFiltersAPI,
  getHeightLayout,
  getFitlters,
  setFiltersDateAPI,
  resetFiltersDateAPI,
  resetFiltersAPI,
  getHeightActiveFilter,
  saveFilterRedux,
  saveSortState,
  setLoadingFilter
} = projectMasterSlice.actions
export default projectMasterReducer

// Try to define reusable selectors alongside their corresponding reducers.
// input selectors
export const selectMasterKeyTable = (state: ProjectMasterState) => state.masterKeyTable
