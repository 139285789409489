import React, { useState } from 'react'
import LoginStyles from './Signup.module.css'
import clsx from 'clsx'
import MetaInfo from '../MetaInfo'
import { getRouteMetaInfo } from '@src/config/routes.config'

import FormAuth from './contentRight/FormAuth'
import { Link } from 'react-router-dom'
import SvgCommon from '../common/SvgCommon'

const CURRENT_PAGE = {
  SIGN_UP: 'signup',
  GMAIL_SIGN_UP: 'ggSignUp',
  SIGN_UP_FORM: 'signUpForm',
  ENTER_CODE: 'enterCode'
}

const Signup = ({ type, handleSignupSuccess }) => {
  const [currentOption, setCurrentOption] = useState(CURRENT_PAGE.SIGN_UP)
  const handleSignup = () => {
    handleSignupSuccess && handleSignupSuccess()
  }
  return (
    <>
      <MetaInfo {...getRouteMetaInfo('Login')} />
      <div
        className={clsx(LoginStyles.Logincontainer, {
          [LoginStyles.Logincontainer_SignupForm]: currentOption === CURRENT_PAGE.SIGN_UP_FORM
        })}
        style={type === 'popup' ? { height: '100%', borderRadius: '16px ' } : {}}
      >
        <div
          className={clsx(LoginStyles.containerLogin, 'container', {
            [LoginStyles.containerLogin_SignupForm]: currentOption === CURRENT_PAGE.SIGN_UP_FORM
          })}
          style={type === 'popup' ? { height: '100%' } : {}}
        >
          <div className={LoginStyles.leftLoginContainer}>
            <div className={type === 'popup' ? LoginStyles.formLogoTabletPopup : LoginStyles.formLogo}>
              <Link className={LoginStyles.ImgContainer} to='/'>
                <SvgCommon height={32} src='/assets/images/Login/logo.svg' width={32} />
              </Link>
            </div>
          </div>

          <div
            className={
              type === 'popup'
                ? clsx(LoginStyles.formLoginPopup, {
                    [LoginStyles.formLogin_SignUpFormPopup]: currentOption === CURRENT_PAGE.SIGN_UP_FORM
                  })
                : clsx(LoginStyles.formLogin, {
                    [LoginStyles.formLogin_SignupForm]: currentOption === CURRENT_PAGE.SIGN_UP_FORM
                  })
            }
          >
            <div
              className={type === 'popup' ? LoginStyles.formLoginChildPopup : LoginStyles.formLoginChild}
              style={type === 'popup' ? { width: '100%', height: '100%' } : {}}
            >
              {/* <div
                className={
                  type === 'popup'
                    ? LoginStyles.formLogoTabletPopup
                    : clsx(LoginStyles.formLogo, LoginStyles.formLogoRes)
                }
              >
                <Link className={LoginStyles.ImgContainer} to='/'>
                  <SvgCommon height='100%' src='/assets/images/Login/logo.svg' width='100%' />
                </Link>
              </div> */}
              <FormAuth
                currentOption={currentOption}
                handleSignupSuccess={() => handleSignup()}
                setCurrentOption={setCurrentOption}
                type={type}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Signup
