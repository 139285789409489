import { useEffect } from 'react'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

export default function TopProgressBar() {
  useEffect(() => {
    NProgress.configure({ showSpinner: false, easing: 'ease', speed: 500 })
    NProgress.start()

    return () => {
      NProgress.done()
    }
  })

  return ''
}
