import React, { useRef, useState } from 'react'
import styleNav from './NavRight.module.css'
import SvgCommon from '@src/components/common/SvgCommon'
import { EMPTY_DATA, PROFILE_DROPDOWN } from '@src/constants'
import { useAuth } from '@src/ContextAPI/AuthProvider'
import { Skeleton } from 'antd'
import ImageCommon from '@src/components/common/ImageCommon/ImageCommon'
import UserOptionItem from '../UserOptionItem/UserOptionItem'
import { configButton } from '@src/constants/designSystem'
import useClickOutside from '@src/hooks/useClickOutside'
import { useNavigate } from 'react-router-dom'
// import UserOptionItem from '@src/components/Navbar/components/UserOptionItem/UserOptionItem'
import ButtonStatistr from '@src/components/common/Button/ButtonStatistr'
import Notifications from './Notifications'
import Search from './Search'
import { useHover } from '@src/hooks/useHover'
const NavRight = () => {
  const [isOpenUserOption, setIsOpenUserOption] = useState(false)
  const context = useAuth()
  const { auth, userInfo, logoutAction, isFetchingGetDataUser } = context
  const containsRef = useRef(null)
  const UserOptionRef = useRef(null)
  const navigate = useNavigate()

  const [hoverRef, isHovering] = useHover()
  const handleOpenDropDown = () => {
    setIsOpenUserOption(!isOpenUserOption)
  }

  const handleLogout = () => {
    logoutAction()
  }

  useClickOutside(containsRef, UserOptionRef, () => {
    setIsOpenUserOption(false)
  })

  return (
    <div className={styleNav.container_nav_right}>
      <Search />
      {auth ? null : (
        <>
          <ButtonStatistr onClick={() => navigate('/signin')} styleButton={configButton.S_Primary} text='Sign in' />
        </>
      )}
      {auth ? (
        <>
          <span className={styleNav.container_noti} ref={hoverRef}>
            <SvgCommon
              height={40}
              src={!isHovering ? '/assets/images/Header/icon_noti.svg' : '/assets/images/Header/icon_noti_active.svg'}
              width={40}
            />
            <div className={styleNav.popup_noti}>
              <Notifications />
            </div>
          </span>
          <div className={styleNav.userAccountArea} ref={containsRef}>
            {!isFetchingGetDataUser ? (
              <div className={styleNav.userAccountArea_itemAvt} onClick={handleOpenDropDown}>
                <ImageCommon
                  borderRadius='50%'
                  cursor='pointer'
                  height='100%'
                  src={userInfo?.face}
                  styleContainer={{ width: '100%', height: '100%' }}
                  width='100%'
                />
              </div>
            ) : (
              <Skeleton.Avatar active size={36} />
            )}
            {isOpenUserOption ? (
              <div className={styleNav.UserOption} ref={UserOptionRef}>
                <div className={styleNav.UserInfo}>
                  <div className={styleNav.avatarWrapper}>
                    <ImageCommon
                      borderRadius='50%'
                      height='100%'
                      src={userInfo?.face}
                      styleContainer={{ width: '100%', height: '100%' }}
                      width='100%'
                    />
                  </div>

                  <div className={styleNav.name_emailWrapper}>
                    <div className={styleNav.name}>{userInfo?.name || EMPTY_DATA}</div>
                    <div className={styleNav.email}>{userInfo?.email || EMPTY_DATA}</div>
                  </div>
                </div>
                <div className={styleNav.userSettingWrapper}>
                  {PROFILE_DROPDOWN.map((item, key) => (
                    <UserOptionItem
                      closePopup={() => setIsOpenUserOption(false)}
                      discount={item.discount}
                      icon={item.icon}
                      key={key}
                      link={item.link}
                      title={item.title}
                      type={item?.type}
                    />
                  ))}
                </div>
                <div className={styleNav.dividerProfileDropDown} />
                <div className={styleNav.userSettingOption} onClick={handleLogout}>
                  <div className={styleNav.iconWrapper}>
                    <SvgCommon src='/assets/images/UserProfile/log_out.svg' />
                  </div>
                  <div className={styleNav.title_discount}>
                    <span className={styleNav.titleOption}>Log out</span>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  )
}

export default NavRight
