import { Pattern } from '@src/constants'
import MessagesUtils from '@src/util/MessagesUtils'

export const ValidateRules = {
  'no-space': [
    (value) => {
      if (!value) return false
      return !value.includes(' ')
    },
    MessagesUtils.get('E_0009')
  ],
  'between-length': [
    (value, options) => {
      if (!value) return false
      return value.length >= options.from && value.length <= options.to
    },
    MessagesUtils.get('E_0010')
  ],
  required: [
    (value) => {
      return Boolean(value)
    },
    MessagesUtils.get('E_0011', ['field'])
  ],
  email: [
    (value) => {
      if (!value) return false
      const regex = Pattern.email
      return regex.test(value)
    },
    MessagesUtils.get('E_0003', ['email'])
  ],
  name: [
    (value) => {
      if (!value) return false
      const regex = Pattern.name
      return regex.test(value)
    },
    MessagesUtils.get('E_0003', ['name'])
  ],
  phoneNumber: [
    (value) => {
      if (!value) return false
      const regex = Pattern.phone
      return regex.test(value)
    },
    MessagesUtils.get('E_0003', ['phone'])
  ],
  'required-correlate': [
    (value, options) => {
      value = value || ''
      return value !== '' || (value !== '' && options.correlateValue !== '')
    },
    'Them Message'
    // MessagesUtils.get('E_0003', ['email']),
  ],
  url: [
    (value) => {
      if (!value) return true
      const regex = Pattern.url
      return regex.test(value)
    },
    'Wrong Url Fommat'
  ],
  number: [
    (value) => {
      if (!value) return false
      const regex = Pattern.number
      return regex.test(value)
    },
    'Wrong Number Fommat'
  ],
  numberPercent: [
    (value) => {
      if (!value) return false
      const regex = Pattern.numberPercent
      return regex.test(value)
    },
    'Wrong Number Percent Fommat'
  ],
  password: [
    (value) => {
      if (!value) return false
      const regex = Pattern.password
      return regex.test(value)
    },
    'Wrong Password Fommat'
  ],
  duplicate: [
    (value, options) => {
      if (!value) return false
      return value === options.correlateValue
    },
    'Duplicate'
  ],
  'min-length': [
    (value, options) => {
      if (!value) return false
      return value.length >= options
    },
    'Min Length'
  ],
  'max-length': [
    (value, options) => {
      if (!value) return false
      return value.length <= options
    },
    'Max Length'
  ]
}

export const ValidateUtils = ({ rules = [], value }) => {
  let messages = ''
  // const isExistRequiredRule = rules.some((ruleConfig) => {
  //   const { message: messConfig, options: optionsConfig, ...ruleNameConfig } = ruleConfig
  //   const [ruleName] = Object.keys(ruleNameConfig)
  //   const wantRule = ruleNameConfig[ruleName]
  //   return ruleName === 'required' && wantRule
  // })

  rules.every((rule) => {
    const { message: messConfig, options: optionsConfig, ...ruleNameConfig } = rule
    const [ruleName] = Object.keys(ruleNameConfig)
    const wantRule = ruleNameConfig[ruleName]

    if (!wantRule) return true
    const { [ruleName]: configRule } = ValidateRules
    const [validation, messRule] = configRule

    if (ruleName === 'duplicate' && !optionsConfig.correlateValue) return true

    // Gặp lại thì fix
    // if (!isExistRequiredRule && !value) return true;
    if (!validation(value, optionsConfig)) {
      messages = messRule
      if (messages && messConfig) messages = messConfig
      return false
    }
    return true
  })
  return { messages }
}
