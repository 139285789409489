import { createSlice } from '@reduxjs/toolkit'
// import { slideBarProjectMaster } from '@src/interface'

const initialState = {
  watchList: {},
  screener: {
    data: {
      screenerSearch: {
        searchValue: '',
        suggestion: [
          {
            key: '1',
            name: 'Good Shitcoin June 2023'
          },
          {
            key: '2',
            name: 'OP Chain - A8'
          },
          {
            key: '3',
            name: 'Pablo Decarto Sidewave'
          },
          {
            key: '4',
            name: 'Pablo Sidewave m4'
          },
          {
            key: '5',
            name: 'Mito Trading Gr_24%'
          }
        ],
        yourLists: [
          {
            key: '1',
            name: 'Good Shitcoin June 2023'
          },
          {
            key: '2',
            name: 'OP Chain - A8'
          },
          {
            key: '3',
            name: 'Pablo Decarto Sidewave'
          },
          {
            key: '4',
            name: 'Pablo Sidewave m4'
          },
          {
            key: '5',
            name: 'Mito Trading Gr_24%'
          },
          {
            key: '6',
            name: 'Pablo Decarto Sidewave'
          },
          {
            key: '7',
            name: 'Pablo Sidewave m4'
          },
          {
            key: '8',
            name: 'Mito Trading Gr_24%'
          }
        ]
      },
      screenerAdd: []
    },
    toggleAdd: false,
    toggleSearch: false,
    toggleUpgrade: false
  },
  filter: {}
}

const slideBarMasterSlice = createSlice({
  name: 'slideBarMaster',
  initialState,
  reducers: {
    openAddScreener: (state, action) => {
      state.screener.toggleAdd = action.payload
    },
    closeAddScreener: (state, action) => {
      state.screener.toggleAdd = action.payload
    },
    openSearchScreener: (state, action) => {
      state.screener.toggleSearch = action.payload
    },
    closeSearchScreener: (state, action) => {
      state.screener.toggleSearch = action.payload
    },

    getSearchValue: (state, action) => {
      state.screener.data.screenerSearch.searchValue = action.payload
    },
    clearSearchValue: (state) => {
      state.screener.data.screenerSearch.searchValue = ''
    },
    openUpgrade: (state, action) => {
      state.screener.toggleUpgrade = action.payload
    },
    closeUpgrade: (state, action) => {
      state.screener.toggleUpgrade = action.payload
    }
  }
})

const slideBarMasterReducer = slideBarMasterSlice.reducer
export const {
  openAddScreener,
  closeAddScreener,
  openSearchScreener,
  closeSearchScreener,
  getSearchValue,
  clearSearchValue,
  openUpgrade,
  closeUpgrade
} = slideBarMasterSlice.actions
export default slideBarMasterReducer
