/* eslint-disable react/no-unescaped-entities */
import Login from '@src/components/Login'
import Modal from '../Modal/Modal'
import SvgCommon from '../SvgCommon'
import style from './ModalLogin.module.css'
import React from 'react'
import Signup from '@src/components/SignUp'
import ImageCommon from '../ImageCommon/ImageCommon'
import icon_close from '@src/assets/Master/icon_close_popup_login.svg'
import logo_default from '@src/assets/logo_image_default.svg'
import logo_google from '@src/assets/logo_google.svg'
import ButtonStatistr from '@src/components/ButtonStatistr'
import ResetPass from '@src/components/ResetPass'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
// import { useModal } from '@src/ContextAPI/ModalContext'

const PAGE = {
  start_page: 'start_page',
  sign_in: 'sign_in',
  sign_up: 'sign_up',
  reset_password: 'reset_password'
}
const ModalLogin = ({
  showPopupLogin = false,
  onClose,
  type,
  textConfirm = 'Sign in with Statistr Account',
  showTextPanner = false
}) => {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(PAGE.start_page)
  const [email, setEmail] = React.useState('')
  const handleClose = (closeWithX = false) => {
    if (type === 'private' && closeWithX) {
      navigate('/')
    }
    setPage(PAGE.start_page)
    onClose && onClose()
  }
  return (
    <Modal
      classNameModal={clsx(style.popupLogin, style.popup_sign)}
      // iconClose={
      //   <div style={{zIndex: 999999}}>
      //     <SvgCommon alt='' height={12.602} src={icon_close} width={12.602} color='#E4E8F0B2' />
      //   </div>
      // }

      // footer={null}

      visible={showPopupLogin}
      // width={450}
    >
      <>
        <div className={style.iconCloseWrapper} onClick={() => handleClose(true)}>
          <SvgCommon color='#E4E8F0B2' src={icon_close} style={{ cursor: 'pointer' }} />
        </div>
        <div className={style.iconCloseWrapperSmallScreen} onClick={() => handleClose(true)}>
          <SvgCommon color='#363739' src={icon_close} style={{ cursor: 'pointer' }} />
        </div>
      </>
      <div className={style.leftLoginModal}>
        <div className={style.leftLoginModalContent}>
          {page === PAGE.start_page ? (
            <div className={style.startPageContainer}>
              <div className={style.startPage}>
                <div className={style.welcomeBackWrapper}>
                  <span className={style.welcomeBackText}>Welcome back</span>
                </div>
                <div className={style.loginBtnContainer}>
                  <ButtonStatistr height='50px' onClick={() => setPage(PAGE.sign_in)} type='primary' width='100%'>
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <SvgCommon height='20px' src={logo_default} width='20px' />
                      <span className={style.loginBtnText}>{textConfirm}</span>
                    </div>
                  </ButtonStatistr>
                  <a href='https://statistr.com/auth/google'>
                    <ButtonStatistr classNameButton={style.SigninGoogleBtn} height='50px' type='secondary' width='100%'>
                      <div style={{ display: 'flex', gap: '12px' }}>
                        <SvgCommon height='20px' src={logo_google} width='20px' />
                        <span className={style.loginBtnBlackText}>Sign in with Google</span>
                      </div>
                    </ButtonStatistr>
                  </a>
                </div>
              </div>
              <div className={style.startPageFooter}>
                <span className={style.loginBtnBlackText} style={{ fontWeight: 500, textAlign: 'center' }}>
                  Don't have an account?&nbsp;
                  <span className={style.SignupText} onClick={() => setPage(PAGE.sign_up)}>
                    Sign Up
                  </span>
                  {/* <span className={`${style.SignupText} disabled-link`} onClick={() => null}>
                    Sign Up
                  </span> */}
                </span>
                <div className={style.footerTextContainer}>
                  <span className={style.footerText}>
                    By sign in, your're agree to Statistr’s&nbsp;
                    <span className={style.footerUnderlinedText}>Terms of Service</span>&nbsp; &&nbsp;
                    <span className={style.footerUnderlinedText}>Privacy Policy</span>
                  </span>
                </div>
              </div>
            </div>
          ) : page === PAGE.sign_in ? (
            <Login
              closeModal={handleClose}
              handleBackSignin={() => setPage(PAGE.start_page)}
              handleSignUp={() => setPage(PAGE.sign_up)}
              handleToPopupResetPass={(email) => {
                setPage(PAGE.reset_password)
                setEmail(email)
              }}
              previousPathname={localStorage.getItem('previousPath')}
              type='popup'
            />
          ) : page === PAGE.sign_up ? (
            <Signup closeModal={handleClose} handleSignupSuccess={() => setPage(PAGE.sign_in)} type='popup' />
          ) : page === PAGE.reset_password ? (
            <ResetPass
              closeModal={() => setPage(PAGE.start_page)}
              handleBackPopupResetpass={() => setPage(PAGE.sign_in)}
              popupEmail={email}
              type='popup'
            />
          ) : null}
        </div>
      </div>
      {/* {page === PAGE.sign_in ? (
        <Login closeModal={handleClose} previousPathname={localStorage.getItem('previousPath')} type='popup' />
      ) : (
        <Signup />
      )} */}
      <div className={style.rightLoginModal}>
        {showTextPanner ? (
          <div className={style.banner_content}>
            <div className={style.banner_description}>
              <span className={style.banner_text} style={{ fontWeight: 600, fontSize: '32px', lineHeight: '56px' }}>
                Sign In Now
              </span>
              <span
                className={style.banner_text}
                style={{
                  fontSize: '14px',
                  lineHeight: '19.1px',
                  color: '#E4E8F0B2',
                  maxWidth: '100%'
                }}
              >
                Unlock the full potential of Statistr's data insights by signing in. <br /> Gain access to a vast array
                of statistics and news tailored to your needs
              </span>
            </div>
          </div>
        ) : null}
        <ImageCommon
          borderRadius='0px 16px 16px 0px'
          height='100%'
          src='/assets/images/Login/popup_login.svg'
          styleContainer={{ width: '100%', height: '100%' }}
          width='100%'
        />
      </div>
    </Modal>
  )
}
export default ModalLogin
