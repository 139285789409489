import { isRejectedWithValue, isRejected } from '@reduxjs/toolkit'

function isPayloadErrorMessage(payload) {
  return typeof payload === 'object' && payload !== null && 'data' in payload && typeof payload.data?.error === 'string'
}

export const rtkQueryErrorLogger = () => (next) => (action) => {
  /**
   * `isRejectedWithValue` is a function that helps us check for actions that have `rejectedWithValue` set to true by `createAsyncThunk`
   * Since RTK Query uses `createAsyncThunk` internally, we can use `isRejectedWithValue` to check for errors 🎉
   */

  // Option: In practice, it's not mandatory to this extent!
  if (isRejected(action)) {
    if (action.error.name === 'CustomError') {
      // Errors related to the execution process
      console.log(action.error.message)
    }
  }

  if (isRejectedWithValue(action)) {
    // Whenever a query or mutation fails, it will enter here
    // Errors from the server will have `rejectedWithValue` set to true
    // Actions related to caching that are rejected will have `rejectedWithValue` set to false, so don't worry, they won't fall into here
    if (isPayloadErrorMessage(action.payload)) {
      // Server rejection errors only have a message!
      console.log(action.payload.data.error)
    }
  }

  return next(action)
}
