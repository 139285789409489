import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import type { BaseQueryApi, BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { BASE_URL } from '@src/config/env.config'
import { Cookies } from 'react-cookie'
import { AUTH } from '@src/constants'
import { getReverseString } from '@src/util/utils'
import { cacher } from './rtkQueryCacheUtils'
// import { logout } from './authen/authen.slice'

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    const cookies = new Cookies()
    const token = cookies.get(AUTH.ACCESS_TOKEN)
    if (
      token &&
      ![
        'getDataTable',
        'getDataFiledDateTable',
        'getDataProject',
        'getDataPeople',
        'getDataProfilePage',
        'getDataStruct',
        'getDataTableStruct'
      ].includes(endpoint)
    ) {
      headers.set('Authorization', `Bearer ${getReverseString(token)}`)
    }
    headers.set('Protected_code', localStorage.getItem('protected_code') || '')
    return headers
  }
})

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result: any = await baseQuery(args, api, extraOptions)
  if (result.error) {
    const { status, data }: { status: any; data: any } = result.error
    const cookies = new Cookies()
    if (data?.message?.includes('Network Error')) {
      const errorMessage = 'Network Error!'
      alert(errorMessage)
    } else if (status === 401) {
      const refreshResult = await refreshToken()
      if (refreshResult) {
        return await retryQuery(args, api, extraOptions)
      } else {
        cookies.remove(AUTH.ACCESS_TOKEN, { path: '/' })
        window.location.reload()
      }
    }
  }
  return result
}

async function refreshToken(): Promise<boolean> {
  // Implement token refresh logic here
  // This is a placeholder and should be replaced with actual implementation
  return false
}

async function retryQuery(args: string | FetchArgs, api: BaseQueryApi, extraOptions: any) {
  // Re-run the base query after obtaining a fresh token
  // const newResult = await baseQuery(args, api, extraOptions)
  return await baseQuery(args, api, extraOptions)
}

const baseQueryWithRetry = retry(baseQueryWithReauth, { maxRetries: 0 })

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'StatistrApi',
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [...cacher.defaultTags],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 60
})

export const enhancedApi = api.enhanceEndpoints({
  endpoints: () => ({
    // getPost: () => 'test'
  })
})

export const makeApiCall = (url: string | any, header?: any, method?: string, body?: any) => ({
  url,
  header,
  method,
  body
})
