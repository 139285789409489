import { createSlice } from '@reduxjs/toolkit'
import { CareerPostJobState } from '@src/interface'

const initialState: CareerPostJobState = {
  dataGetForm: {}
}

const careerPostJobSlice = createSlice({
  name: 'CareerPostJob',
  initialState,
  reducers: {
    getDataGetForm: (state, action) => {
      state.dataGetForm = { ...state.dataGetForm, ...action.payload }
    }
  }
})

const CareerPostJobReducer = careerPostJobSlice.reducer
export const { getDataGetForm } = careerPostJobSlice.actions
export default CareerPostJobReducer
