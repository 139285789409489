import React, { useRef, useState, useEffect } from 'react'
import styleSearch from './Search.module.css'
import SvgCommon from '@src/components/common/SvgCommon'
import { useOutsideClick } from '@src/hooks'
import clsx from 'clsx'
import ImageCommon from '@src/components/common/ImageCommon/ImageCommon'
import { getUrlMediaFileServer } from '@src/util/utils'
import { structSearchGlobal } from '@src/util/Struct'
import { useLazyGetDataStructQuery } from '@src/redux-toolkit/StructAPI/struct_api.service'
import { useDebounceFn } from '@src/hooks'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'
import { useModal } from '@src/ContextAPI/ModalContext'

// const LINK_TYPE = {
//   project: 'project/',
//   people: 'people/',
//   ventures: 'organization/'
// }

const SearchItem = ({ data_item, type, onClick }) => {
  return (
    <>
      {type === 'project' ? (
        <Link className={styleSearch.divider_item} onClick={onClick} to={`project/${data_item.slug}`}>
          <ImageCommon
            borderRadius='50%'
            height={32}
            showInfo={false}
            src={getUrlMediaFileServer(data_item?.logo)}
            width={32}
          />
          <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
            <span
              className={styleSearch.item_title}
              style={{
                fontWeight: 600,
                display: 'inline-block',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {data_item?.name}
            </span>
            <span className={styleSearch.item_desc} style={{ fontWeight: 400, textTransform: 'uppercase' }}>
              {data_item?.symbol}
            </span>
            <div className={styleSearch.item_tag}>{type?.toUpperCase()}</div>
          </div>
        </Link>
      ) : null}
      {type === 'people' ? (
        <Link className={styleSearch.divider_item} onClick={onClick} to={`people/${data_item.slug}`}>
          <ImageCommon
            borderRadius={2}
            height={32}
            showInfo={false}
            src={getUrlMediaFileServer(data_item?.avatar)}
            width={32}
          />
          <span
            className={styleSearch.item_title}
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {data_item?.name}
          </span>
          <div className={styleSearch.item_tag}>{type?.toUpperCase()}</div>
        </Link>
      ) : null}
      {type === 'venture' ? (
        <Link className={styleSearch.divider_item} onClick={onClick} to={`organization/${data_item.slug}`}>
          <ImageCommon
            borderRadius={2}
            height={32}
            showInfo={false}
            src={getUrlMediaFileServer(data_item?.logo)}
            width={32}
          />
          <span
            className={styleSearch.item_title}
            style={{
              display: 'inline-block',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {data_item?.ventures_name}
          </span>
          <div className={styleSearch.item_tag}>ORGANIZATION</div>
        </Link>
      ) : null}
      {type === 'news' ? <></> : null}
    </>
  )
}

const Search = ({ type, active = false, closeDrawerMobile }) => {
  const [valueSearch, setValueSearch] = useState('')
  const [isFocused, setIsFocused] = useState(false)
  const isFirstFocus = useRef(true)
  const [dataRender, setDataRender] = useState([])
  const { isModalOpen } = useModal()
  const [getDataSearch, { data: dataSearchGlobal = [], isFetching, isSuccess }] = useLazyGetDataStructQuery(
    structSearchGlobal.getBody({
      searchValue: valueSearch
    })
  )

  const containerRef = useRef()
  useOutsideClick(containerRef, () => setIsFocused(false))

  const handleFocus = () => {
    setIsFocused(true)
    let valuePreSearch = localStorage.getItem('searchValue')
    if (isFirstFocus.current) {
      isFirstFocus.current = false
      getDataSearch(
        structSearchGlobal.getBody({
          searchValue: valuePreSearch || ''
        })
      )
    }
  }

  const _handleSearch = useDebounceFn((valueSearch = '') => {
    getDataSearch(
      structSearchGlobal.getBody({
        searchValue: valueSearch
      })
    )
    localStorage.setItem('searchValue', valueSearch)
  }, 300)

  const handleChangeValueSearch = (e) => {
    const newValueSearch = e.target.value || ''
    setValueSearch(newValueSearch)
    _handleSearch(newValueSearch)
  }

  // const handleClearSearch = () => {
  //   setValueSearch('')
  // }

  useEffect(() => {
    if (isSuccess) {
      let dataFromAPi = structSearchGlobal.getData(dataSearchGlobal)
      const groupItemsByType = (dataArray) => {
        const groupedItems = { project: [], venture: [], people: [] }

        dataArray.forEach((item) => {
          if (item.type === 'project') {
            groupedItems.project.push(item)
          } else if (item.type === 'venture') {
            groupedItems.venture.push(item)
          } else if (item.type === 'people') {
            groupedItems.people.push(item)
          }
        })
        return Object.keys(groupedItems).map((type) => ({
          type: type,
          listData: groupedItems[type]
        }))
      }

      const groupedData = groupItemsByType(dataFromAPi)

      setDataRender(groupedData)
    }
  }, [isFetching, isSuccess])

  useEffect(() => {
    if (active) {
      handleFocus()
    }
  }, [active])

  return (
    <div
      className={clsx(
        styleSearch.container_input_search,
        isFocused || active ? styleSearch.focused : null,
        type === 'popup' ? styleSearch.active_popup : null
      )}
      ref={containerRef}
    >
      <SvgCommon
        src={
          isFocused || active ? '/assets/images/Header/icon_search_active.svg' : '/assets/images/Header/icon_search.svg'
        }
      />
      <input
        className={styleSearch.input_search}
        disabled={Object.values(isModalOpen).find((value) => value)}
        onChange={handleChangeValueSearch}
        onFocus={handleFocus}
        placeholder={isFocused ? 'Search your topic, token, investors or ventures, etc...' : 'Search'}
        type='text'
        value={valueSearch}
      />
      {/* {isFocused || active ? (
        <span className={styleSearch.clear_value_search} onClick={handleClearSearch} style={{ cursor: 'pointer' }}>
          <SvgCommon src='/assets/images/Header/icon_eliminate.svg' />
        </span>
      ) : null} */}
      {isFocused || active ? (
        <div
          className={clsx(
            styleSearch.divider,
            dataRender?.length > 0 ? null : styleSearch.divider_noData,
            type === 'popup' ? styleSearch.divider_popup : null
          )}
        >
          {isFetching ? (
            <div style={{ height: 295, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </div>
          ) : (
            <>
              {structSearchGlobal.getData(dataSearchGlobal)?.length > 0 && isSuccess ? (
                <>
                  {dataRender.map((item_type, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item_type?.listData?.map((item_list_data, index) => {
                          return (
                            <React.Fragment key={index}>
                              <SearchItem
                                data_item={item_list_data[item_type?.type]}
                                key={index}
                                onClick={() => {
                                  setIsFocused(false)
                                  closeDrawerMobile && closeDrawerMobile()
                                }}
                                type={item_type?.type}
                              />
                            </React.Fragment>
                          )
                        })}
                      </React.Fragment>
                    )
                  })}
                </>
              ) : (
                // <>
                //   {dataRender.map((item, index) => (
                //     <SearchItem
                //       data_item={item[item?.type]}
                //       key={index}
                //       onClick={() => {
                //         setIsFocused(false)
                //         closeDrawerMobile && closeDrawerMobile()
                //       }}
                //       type={item?.type}
                //     />
                //   ))}
                // </>
                <div className={styleSearch.divider_item_noHover}>
                  <span className={styleSearch.item_desc} style={{ fontWeight: 600, textTransform: 'uppercase' }}>
                    NO RESULT FOUND
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default Search
