import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
// import FirstView from './FirstView'
import SecondView from './SecondView'
import ThirdView from './ThirdView'
import { useDebounceFn } from '@src/hooks'

const FormAuth = ({ type, popupEmail = '', handleBackPopupResetpass, closeModal }) => {
  const CURRRENT_PAGE = {
    ENTER_CODE: 'enterCode',
    ENTER_NEW_PW: 'enterPw',
    DONE: 'done'
  }
  let { state } = useLocation()
  const [currentOption, setCurrentOption] = useState(CURRRENT_PAGE.ENTER_NEW_PW)

  const [dataForm, setDataForm] = useState({ ...state })

  // const [confirmPsw, setconfirmPsw] = useState()
  // const [resetPass, { isSuccess }] = useLazyResetPassQuery()
  // const [isSend] = useState(false)

  const navigate = useNavigate()

  const getDataSignUp = useDebounceFn((e) => {
    setDataForm({ ...dataForm, [e.target.name]: e.target.value })
  }, 1000)

  const handleChangeDataForm = (e) => {
    getDataSignUp(e)
  }

  const handleResetPass = (resetSuccess) => {
    if (resetSuccess) {
      setCurrentOption(CURRRENT_PAGE.DONE)
    }
  }

  const handleDoneResetPass = () => {
    if (type === 'popup') {
      closeModal && closeModal()
      return
    }
    navigate('/signin')
  }
  // useEffect(() => {
  //   if (isSuccess) {
  //     setCurrentOption(CURRRENT_PAGE.DONE)
  //   }
  // }, [isSuccess])
  return (
    <div>
      <AnimatePresence custom={currentOption} mode='wait'>
        {/* {currentOption === CURRRENT_PAGE.ENTER_CODE ? (
          <FirstView
            handleBack={() => navigate(-1)}
            handleChangeView={() => setCurrentOption(CURRRENT_PAGE.ENTER_NEW_PW)}
            handleCode={handleChangeDataForm}
            handleSendCode={handleSendCode}
            isSend={isSend}
            key='FirstView'
          />
        ) : null} */}
        {currentOption === CURRRENT_PAGE.ENTER_NEW_PW ? (
          <SecondView
            handleBack={() => navigate(-1)}
            handleBackPopupResetpass={handleBackPopupResetpass}
            // handleConfirmPw={(e) => setconfirmPsw(e.target.value)}
            handlePw={handleChangeDataForm}
            key='SecondView'
            onConfirm={handleResetPass}
            popupEmail={popupEmail}
            type={type}
          />
        ) : null}
        {currentOption === CURRRENT_PAGE.DONE ? (
          <ThirdView
            handleBack={() => navigate('/signin')}
            handleChangeView={handleDoneResetPass}
            key='ThirdView'
            type={type}
          />
        ) : null}
      </AnimatePresence>
    </div>
  )
}

export default FormAuth
