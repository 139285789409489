import { useEffect } from 'react'

const useClickOutside = (containsRef, childRef, handler) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        childRef.current &&
        !childRef.current.contains(e.target) &&
        containsRef.current &&
        !containsRef.current.contains(e.target)
      ) {
        handler()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [containsRef, childRef, handler])
}

export default useClickOutside
