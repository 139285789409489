/* eslint-disable no-case-declarations */
import React from 'react'
import iconVerification from '../../../assets/Master/icon__Verification.svg'
import iconPortfolio from '../../../assets/Master/icon_portfolio.svg'
import iconNews from '../../../assets/Master/icon_news.svg'
import iconBackers from '../../../assets/Master/icon_Backers.svg'
import iconPriceChangeUp from '../../../assets/Master/icon_PriceChangeUp.svg'
import iconPriceChangeDown from '../../../assets/Master/icon_PriceChangeDown.svg'
import './DropdownInfoCommon.css'
import GroupImage from '../GroupImage/GroupImage'
import { getImageSource, getUrlMediaFileServer } from '@src/util/utils'
import ImageCommon from '../ImageCommon/ImageCommon'
import FormatCommon from '@src/util/formatCommon'
import { Spin } from 'antd'
import EmptyComponent from '../EmptyComponent/EmptyComponent'
import parse from 'html-react-parser'
import {
  structPeopleSumary,
  structVentureSumary,
  structDetailsProjectSummary,
  structDetailsFundraisingProjects
} from '@src/util/Struct'
import { useGetDataStructQuery } from '@src/redux-toolkit/StructAPI/struct_api.service'
import moment from 'moment'
import SvgCommon from '../SvgCommon'
import { EMPTY_DATA, KEY_MASTER } from '@src/constants'
// import iconMaleDefault from '../../../assets/male_default.svg'
// import iconFemaleDefault from '../../../assets/female_default.svg'

// import svg
import svgShare from '@src/assets/Master/icon_share.svg'
import { Link } from 'react-router-dom'
import { isUndefined } from 'lodash'
// import { ConsoleSqlOutlined } from '@ant-design/icons'

const CONTANT_TABLE = {
  people: structPeopleSumary,
  venture: structVentureSumary,
  token: structDetailsProjectSummary,
  fund: structDetailsProjectSummary,
  fundVenture: structDetailsFundraisingProjects,

  [KEY_MASTER.projects]: structDetailsProjectSummary,
  [KEY_MASTER.people]: structPeopleSumary,
  [KEY_MASTER.organizations]: structVentureSumary
}

const DropdownInfoCommon = ({
  type,
  slug,
  fundraisingRound,
  logoProjectFund = '',
  nameProjectFund = '',
  venturesName = '',
  date = ''
}) => {
  // const [data, setData] = useState()

  const { data: dataStruct, isLoading } = useGetDataStructQuery(
    CONTANT_TABLE[type]?.getBody({ slug: slug ? slug : '' }),
    {
      skip: !slug
    }
  )
  // console.log('dataStruct', dataStruct)
  const newData = CONTANT_TABLE?.[type].getData(dataStruct)

  // get dataFund

  const dataFundingFunds = newData?.funding_funds || []

  const groupedData = dataFundingFunds.reduce((result, item) => {
    const dateKey = item.date
    const fundraisingRoundKey = item.fundraising_round

    if (!result[dateKey]) {
      result[dateKey] = {}
    }

    if (!result[dateKey][fundraisingRoundKey]) {
      result[dateKey][fundraisingRoundKey] = {
        amount_quantity: item.amount_quantity, // Giữ giá trị amount_quantity của mức fundraisingRound
        items: [] // Mảng chứa các phần tử thuộc mức fundraisingRound
      }
    }

    // Thêm phần tử vào mảng items của mức fundraisingRound
    result[dateKey][fundraisingRoundKey].items.push({
      id: item.id,
      date: item.date,
      logo: item.logo,
      name: item.name,
      slug: item.slug,
      description: item.description,
      amount_quantity: item.amount_quantity,
      fundraising_round: item.fundraising_round,
      announcement: item.announcement
    })

    return result
  }, {})

  // Chuyển đổi kết quả thành mảng
  const dataFundraising = Object.entries(groupedData).map(([date, fundraisingRounds]) => ({
    date,
    fundraisingRounds: Object.entries(fundraisingRounds).map(([fundraisingRound, { amount_quantity, items }]) => ({
      fundraisingRound,
      amount_quantity,
      items
    }))
  }))

  // Lọc ra các items có fundraising_round === fundraisingRound
  const filteredItemFundraising = dataFundraising.filter(
    (item) => item?.fundraisingRounds[0]?.fundraisingRound === fundraisingRound
  )

  let filteredItemFundraisingByDate = [
    filteredItemFundraising?.find((item) => item?.date === moment(date).format('YYYY-MM-DD'))
  ]
  // lọc item có venturename

  const renderLayoutDropDownInfo = (data) => {
    let price = data?.price_usd?.toString().includes('e')
      ? data?.price_usd?.toFixed(15).replace(/0+$/, '')
      : data?.price_usd

    const listTag = data?.sector || []

    let announcementFund = []
    let html = null
    switch (type) {
      case 'venture':
      case KEY_MASTER.organizations:
        html = (
          <div style={{ textAlign: 'start' }}>
            <Link target='_blank' to={`/organization/${slug}`}>
              <div className='contanerHead' style={{ alignItems: 'center', cursor: 'pointer' }}>
                <ImageCommon borderRadius='50%' height={48} src={getImageSource(data, type)} width={48} />
                <div style={{ paddingLeft: 16 }}>
                  <div className='nameContainer'>
                    <span className='nameTitle'>{data?.ventures_name || 'Multicoin Capital'}</span>
                    <img alt='' src={iconVerification} style={{ marginLeft: 6 }} />
                  </div>
                </div>
              </div>
            </Link>
            <div className='containerContent'>
              <div className='containerTwoContent'>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle'>Total Funding Amount</span>
                  <span className='itemTitlePrice' style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                    {data?.total_funding_amount ? FormatCommon.formatCurrency(data?.total_funding_amount) : EMPTY_DATA}
                  </span>
                </div>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle'>Number of Investment</span>
                  <span className='itemTitlePrice' style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
                    {data?.related_token && data?.related_token?.length > 0 ? data?.related_token?.length : EMPTY_DATA}
                  </span>
                </div>
              </div>
              <div style={{ marginBottom: 27 }}>
                <span className='itemTitle'>Founder</span>
                {data?.founder?.length > 0 ? (
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 8, marginTop: 8 }}>
                    {data?.founder?.map((item, index) => {
                      return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                          <Link target='_blank' to={`/people/${item?.slug}`}>
                            <img
                              alt=''
                              className='avatar'
                              height={24}
                              src={item?.avatar ? getUrlMediaFileServer(item?.avatar) : getImageSource(item, 'people')}
                              style={{ borderRadius: '50%' }}
                              width={24}
                            />
                            <span
                              className='text_decoration_underline'
                              style={{
                                marginLeft: 8,
                                color: '#363739',
                                fontSize: 13,
                                fontWeight: 600,
                                cursor: 'pointer'
                              }}
                            >
                              {item?.name}
                            </span>
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <span>--</span>
                )}
              </div>
              {data?.summary ? <span className='description'>{data?.summary}</span> : null}
              <div className='listTagContainer'>
                {listTag && listTag.length > 5 ? (
                  <>
                    {listTag.slice(0, 5).map((item, index) => {
                      return (
                        <Link className='tagItemDropDown' key={index} to='/directory/projects'>
                          {item}
                        </Link>
                      )
                    })}
                    <div className='tagItemDropDown'>+{listTag.length - 5} more</div>
                  </>
                ) : (
                  <>
                    {listTag.map((item, index) => {
                      return (
                        <Link className='tagItemDropDown' key={index} target='_blank' to='/directory/organizations'>
                          {item}
                        </Link>
                      )
                    })}
                  </>
                )}
              </div>
              <div className='containerTwoContent'>
                <div className='containerTwoContentItem' style={{ paddingRight: 40 }}>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconPortfolio} style={{ marginRight: 8 }} />
                    <span>Portfolios</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.related_token?.length || '0'}</span>{' '}
                    <span>{data?.related_token?.length > 1 ? 'assets' : 'asset'}</span>
                  </div>
                </div>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconNews} style={{ marginRight: 8 }} />
                    <span>News & Activities</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.recent_news?.length || EMPTY_DATA}</span> <span>News</span>{' '}
                    <div className='linePeople' />{' '}
                    <span className='spanBold'>
                      {data?.data_ref?.fund_recent_news_id?.ref_ids?.length || EMPTY_DATA}
                    </span>{' '}
                    <span>{data?.related_token?.length > 1 ? 'Activities' : 'Activity'}</span>
                  </div>
                </div>
              </div>
              {/* <div className='containerTwoContent'>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconBackers} style={{ marginRight: 8 }} />
                    <span>Backers</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.data_ref?.portfolio_companies_id?.ref_ids?.length || 5}</span>{' '}
                    <span>investors</span>
                  </div>
                </div>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconNews} style={{ marginRight: 8 }} />
                    <span>News & Activities</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>3</span> <span>News</span> - <span className='spanBold'>3</span>{' '}
                    <span>Actitivites</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        )
        break
      case 'people':
      case KEY_MASTER.people:
        html = (
          <div>
            <div className='contanerHead' style={{ alignItems: 'center' }}>
              <ImageCommon borderRadius='50%' height={48} src={getImageSource(data, type)} width={48} />
              <div style={{ paddingLeft: 8 }}>
                <Link
                  style={{ textAlign: 'start', cursor: 'pointer' }}
                  target='_blank'
                  to={slug ? `/people/${slug}` : `/people/david-j-namdar-individual-investor-ce77bb`}
                >
                  <div className='nameContainer'>
                    <span className='nameTitle'>{data?.name}</span>
                    <img alt='' src={iconVerification} style={{ marginLeft: 4 }} />
                  </div>
                </Link>
                {data?.job_title ? (
                  <>
                    {data?.job_title?.length > 3 ? (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        {data?.job_title?.slice(0, 3).map((item, index) => {
                          return (
                            <span className='description' key={index}>
                              {item}
                            </span>
                          )
                        })}
                        <span className='description'>+{data?.job_title?.length - 3}more</span>
                      </div>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                        {data?.job_title?.map((item, index) => {
                          return (
                            <span className='description' key={index} style={{ color: '#36373999' }}>
                              {item}
                            </span>
                          )
                        })}
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            </div>
            <div className='containerContent'>
              {listTag && listTag.length > 0 ? (
                <div className='listTagContainer' style={{ marginBottom: 18 }}>
                  {listTag && listTag.length > 5 ? (
                    <>
                      {listTag.slice(0, 5).map((item, index) => {
                        return (
                          <div className='tagItemDropDown' key={index}>
                            {item}
                          </div>
                        )
                      })}
                      <div className='tagItemDropDown'>+{listTag.length - 5} more</div>
                    </>
                  ) : (
                    <>
                      {listTag.map((item, index) => {
                        return (
                          <div className='tagItemDropDown' key={index}>
                            {item}
                          </div>
                        )
                      })}
                    </>
                  )}
                </div>
              ) : null}

              {data?.background_summary ? <span className='description'>{data?.background_summary}</span> : null}
              <div className='containerTwoContent' style={{ marginTop: 32 }}>
                <div className='containerTwoContentItem' style={{ border: 'none', flex: 'unset' }}>
                  <span className='itemTitle'>Related Token</span>
                  <div style={{ marginTop: 8 }}>
                    {data?.portfolio_angel_investor || data?.projects_join ? (
                      <GroupImage
                        images={[...(data?.portfolio_angel_investor || []), ...(data?.projects_join || [])]}
                        options={{ size: 24, maxShow: 5 }}
                        showInfo={false}
                        title='Tokens'
                        type='token'
                      />
                    ) : (
                      EMPTY_DATA
                    )}
                  </div>
                </div>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle'>Related Organization</span>
                  <div style={{ marginTop: 8 }}>
                    {data?.ventures ? (
                      <GroupImage
                        images={data?.ventures}
                        options={{ size: 24, maxShow: 5 }}
                        showInfo={false}
                        title='Ventures'
                        type='venture'
                      />
                    ) : (
                      EMPTY_DATA
                    )}
                  </div>
                </div>
              </div>
              <div className='containerTwoContent'>
                {/* <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconPortfolio} style={{ marginRight: 8 }} />
                    <span>Portfolios</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.projects_join?.length || EMPTY_DATA}</span>{' '}
                    <span>{data?.projects_join?.length > 1 ? 'assets' : 'asset'}</span>
                  </div>
                </div>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconNews} style={{ marginRight: 8 }} />
                    <span>News & Activities</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.recent_news?.length || EMPTY_DATA}</span>{' '}
                    <span>{data?.recent_news?.length > 1 ? 'news' : 'new'}</span> <div className='linePeople' />{' '}
                    <span className='spanBold'>{data?.recent_news?.length || EMPTY_DATA}</span>{' '}
                    <span>{data?.recent_news?.length > 1 ? 'activities' : 'activity'}</span>{' '}
                  </div>
                </div> */}
                <div>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconNews} style={{ marginRight: 8 }} />
                    <span>News & Activities</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.recent_news?.length || EMPTY_DATA}</span> <span>News</span>{' '}
                    <div className='linePeople' />{' '}
                    <span className='spanBold'>{data?.recent_news?.length || EMPTY_DATA}</span>{' '}
                    <span>{data?.recent_news?.length > 1 ? 'Activities' : 'Activity'}</span>{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'token':
      case KEY_MASTER.projects:
        html = (
          <div>
            <div className='contanerHead'>
              <ImageCommon borderRadius='50%' height={48} src={getImageSource(data, type)} width={48} />
              <div style={{ paddingLeft: 16 }}>
                <div className='nameContainer'>
                  <Link style={{ textAlign: 'start', cursor: 'pointer' }} target='_blank' to={`/project/${slug}`}>
                    <span className='nameTitle'>
                      {data?.name?.length > 20 ? `${data?.name?.slice(0, 16)}...` : data?.name}
                    </span>
                  </Link>
                  {data?.symbol ? (
                    <div className='nameTag'>
                      <span>{data?.symbol}</span>
                    </div>
                  ) : null}
                  <img alt='' src={iconVerification} />
                </div>
                <div className='priceContainer'>
                  {data?.price_usd ? (
                    <>
                      <span className='priceTitle'>
                        {data?.price_usd ? `$` : null}
                        {data?.price_usd ? FormatCommon.formatNumberZero(price, 4) : EMPTY_DATA}
                      </span>
                      <div
                        className={`priceChange ${data?.change24h < 0 ? 'colorRed' : 'colorGreen'}`}
                        style={{ display: 'inline-block' }}
                      >
                        <img alt='' src={data?.change24h < 0 ? iconPriceChangeDown : iconPriceChangeUp} />
                        <span style={{ marginLeft: '-8px', whiteSpace: 'nowrap' }}>
                          {Math.abs(data?.change24h).toFixed(2)} %
                        </span>
                      </div>
                    </>
                  ) : (
                    <span className='priceTitle'>--</span>
                  )}
                </div>
              </div>
            </div>
            <div className='containerContent'>
              <div className='containerTwoContent'>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle' style={{ marginBottom: 4 }}>
                    Market Cap
                  </span>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 6 }}>
                    {data?.market_cap_usd ? (
                      <>
                        <span className='market_cap' style={{ marginTop: 4, flex: 1 }}>
                          {data?.market_cap_usd ? `$` : null}
                          {data?.market_cap_usd
                            ? FormatCommon.formatAddCommas(parseInt(data?.market_cap_usd))
                            : EMPTY_DATA}
                        </span>
                        <div
                          className={`priceChange ${data?.market_cap_rate_24h < 0 ? 'colorRed' : 'colorGreen'}`}
                          style={{ width: 'max-content' }}
                        >
                          <img
                            alt=''
                            src={data?.market_cap_rate_24h < 0 ? iconPriceChangeDown : iconPriceChangeUp}
                            style={{ marginRight: 4 }}
                          />
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {Math.abs(data?.market_cap_rate_24h).toFixed(2)} %
                          </span>
                        </div>
                      </>
                    ) : (
                      <span className='market_cap'>--</span>
                    )}
                  </div>
                </div>
                <div className='containerTwoContentItem' style={{ width: 250 }}>
                  <span className='itemTitle' style={{ marginBottom: 8 }}>
                    Founder
                  </span>
                  {data?.founders && data?.founders?.length > 0 ? (
                    <>
                      {data?.founders?.length > 1 ? (
                        <GroupImage
                          images={data?.founders}
                          options={{ size: 24, maxShow: 5 }}
                          showInfo={false}
                          title='Founders'
                          type='people'
                        />
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: 8, flexWrap: 'wrap' }}>
                          {data?.founders?.map((item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <Link target='_blank' to={`/people/${item?.slug}`}>
                                  <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                    <ImageCommon
                                      borderRadius='50%'
                                      height={24}
                                      src={getImageSource(item, 'people')}
                                      width={24}
                                    />
                                    <span className='market_cap text_decoration_underline'>{item?.name}</span>
                                  </div>
                                </Link>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      )}
                    </>
                  ) : (
                    <span>{EMPTY_DATA}</span>
                  )}
                </div>
              </div>
              <span className='description'>{parse(data?.description || '')}</span>

              <div className='listTagContainer'>
                {listTag && listTag.length > 5 ? (
                  <>
                    {listTag.slice(0, 5).map((item, index) => {
                      return (
                        <div className='tagItemDropDown' key={index}>
                          {item}
                        </div>
                      )
                    })}
                    <div className='tagItemDropDown'>+{listTag.length - 5} more</div>
                  </>
                ) : (
                  <>
                    {listTag.map((item, index) => {
                      return (
                        <div className='tagItemDropDown' key={index}>
                          {item}
                        </div>
                      )
                    })}
                  </>
                )}
              </div>
              <div className='containerTwoContent'>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconBackers} style={{ marginRight: 8 }} />
                    <span>Backer</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.funding_funds?.length || EMPTY_DATA}</span>{' '}
                    <span>{data?.funding_funds?.length > 1 ? 'investors' : 'investor'}</span>
                  </div>
                </div>
                <div className='containerTwoContentItem'>
                  <div className='TwoContentItemHead'>
                    <img alt='' src={iconNews} style={{ marginRight: 8 }} />
                    <span>News & Activities</span>
                  </div>
                  <div className='TwoContentItemContent'>
                    <span className='spanBold'>{data?.news_proj?.length || EMPTY_DATA}</span> <span>News</span> -{' '}
                    <span className='spanBold' style={{ whiteSpace: 'nowrap' }}>
                      {EMPTY_DATA}
                    </span>{' '}
                    <span>{data?.news_proj?.length > 1 ? 'Activites' : 'Activity'}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        break
      case 'fund':
        html = (
          // <Link style={{ textAlign: 'start', cursor: 'pointer' }} to={`/project/${slug}`} target='_blank'>
          <div>
            <div className='contanerHead_fund'>
              {/* <ImageCommon borderRadius='50%' height={48} src='/assets/images/CoinGeckgo.png' width={48} /> */}
              <ImageCommon borderRadius='50%' height={48} src={getUrlMediaFileServer(data?.logo)} width={48} />
              <div className='name_fund_container'>
                <span className='nameTitle'>{data?.name || 'Name Project'}</span>
                <span className='date_fund'>{moment(filteredItemFundraising[0]?.date).format('DD/MM/YYYY')}</span>
              </div>
            </div>
            <div className='containerContent containerContent_fund'>
              {filteredItemFundraisingByDate?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item?.fundraisingRounds?.map((el, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className='containerTwoContent containerTwoContent_fund'>
                            <div className='containerTwoContentItem' style={{ width: '50%', flex: 'unset' }}>
                              <span className='itemTitle'>Round</span>
                              <span className='itemDesc_fund'>{el?.fundraisingRound}</span>
                            </div>
                            <div className='containerTwoContentItem' style={{ width: '50%' }}>
                              <span className='itemTitle'>Amount</span>
                              <span className='itemDesc_fund'>
                                {el?.amount_quantity > 0
                                  ? FormatCommon.formatCurrency([el?.amount_quantity, 'USD'])
                                  : EMPTY_DATA}
                              </span>
                            </div>
                          </div>
                          <div className='container_investors'>
                            <div className='container_investors_item'>
                              <span className='itemTitle'>Investors</span>
                            </div>
                            {el?.items?.map((item, index) => {
                              if (item?.announcement) {
                                announcementFund.push(item?.announcement)
                              }
                              return (
                                <div className='container_investors_item' key={index}>
                                  <div className='item_investor'>
                                    <ImageCommon
                                      borderRadius='50%'
                                      height={24}
                                      src={getUrlMediaFileServer(item?.logo)}
                                      width={24}
                                    />
                                    <Link
                                      className='item_investor_name text_decoration_underline'
                                      target='_blank'
                                      to={`/organization/${item?.slug}`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </div>
                                </div>
                              )
                            })}
                            {data?.funding_peoples?.map((item, index) => {
                              return (
                                <div className='container_investors_item' key={index}>
                                  <div className='item_investor'>
                                    <ImageCommon
                                      borderRadius='50%'
                                      height={24}
                                      src={getImageSource(item, 'people')}
                                      width={24}
                                    />
                                    <Link
                                      className='item_investor_name text_decoration_underline'
                                      target='_blank'
                                      to={`/people/${item?.slug}`}
                                    >
                                      {item?.name}
                                    </Link>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </React.Fragment>
                )
              })}
              {announcementFund?.length > 0 ? (
                <>
                  <div className='line_fund' />
                  <div className='container_relavent'>
                    <div className='container_relavent_item'>
                      <span className='itemTitle'>Relevent Informations</span>
                    </div>
                    {[...new Set(announcementFund)].map((item, index) => (
                      <Link
                        className='container_relavent_item'
                        key={index}
                        target='_blank'
                        to={`${item || item?.relevent_informations}`}
                      >
                        <SvgCommon color='#36373999' height={14} src={svgShare} width={14} />
                        <span className='relavent_item_name'>{item || item?.relevent_informations}</span>
                      </Link>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            {/* </Link> */}
          </div>
        )
        break
      case 'fundVenture':
        let filteredArray = data ? data?.investors.filter((item) => item.ventures_name !== venturesName) : []

        let filteredArrayVentureMain = data ? data?.investors.filter((item) => item.ventures_name === venturesName) : []

        let announcement = data?.announcement ? data?.announcement.split(',') : []
        html = (
          // <Link style={{ textAlign: 'start', cursor: 'pointer' }} to={`/project/${slug}`} target='_blank'>
          <div>
            <div className='contanerHead_fund'>
              <ImageCommon borderRadius='50%' height={48} src={getUrlMediaFileServer(logoProjectFund)} width={48} />
              <div className='name_fund_container'>
                <span className='nameTitle'>{nameProjectFund || 'Name Project'}</span>
                <span className='date_fund'>{moment(data?.date).format('DD/MM/YYYY')}</span>
              </div>
            </div>
            <div className='containerContent containerContent_fund'>
              <div className='containerTwoContent containerTwoContent_fund'>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle'>Round</span>
                  <span className='itemDesc_fund'>{data?.stages || data?.fundraising_round}</span>
                </div>
                <div className='containerTwoContentItem'>
                  <span className='itemTitle'>Amount</span>
                  <span className='itemDesc_fund'>
                    {data?.amount_quantity > 0
                      ? FormatCommon.formatCurrency([data?.amount_quantity, 'USD'])
                      : EMPTY_DATA}
                  </span>
                </div>
              </div>
              {filteredArrayVentureMain.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                  <ImageCommon borderRadius='50%' height={40} src={getUrlMediaFileServer(item?.logo)} width={40} />
                  <Link
                    className='item_investor_name text_decoration_underline'
                    style={{ fontSize: 15, fontWeight: 600 }}
                    target='_blank'
                    to={`/organization/${item?.slug}`}
                  >
                    {item?.ventures_name}
                  </Link>
                </div>
              ))}
              <div className='container_investors'>
                <div className='container_investors_item'>
                  <span className='itemTitle'>Co-Investors</span>
                </div>
                {filteredArray?.map((item, index) => (
                  <div className='container_investors_item' key={index}>
                    <div className='item_investor'>
                      <ImageCommon borderRadius='50%' height={24} src={getUrlMediaFileServer(item?.logo)} width={24} />
                      <Link
                        className='item_investor_name text_decoration_underline'
                        target='_blank'
                        to={`/organization/${item?.slug}`}
                      >
                        {item?.ventures_name}
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
              {announcement && announcement?.length > 0 ? (
                <>
                  <div className='line_fund' />
                  <div className='container_relavent'>
                    <div className='container_relavent_item'>
                      <span className='itemTitle'>Relevant Informations</span>
                    </div>
                    {announcement.map((item, index) => (
                      <Link
                        className='container_relavent_item text_decoration_underline'
                        key={index}
                        target='_blank'
                        to={`${item}`}
                      >
                        <SvgCommon color='#36373999' height={14} src={svgShare} width={14} />
                        <span className='relavent_item_name'>{item}</span>
                      </Link>
                    ))}
                  </div>
                </>
              ) : null}
            </div>
            {/* </Link> */}
          </div>
        )
        break
      default:
        html = null
        break
    }
    return html
  }
  if (isUndefined(dataStruct) || isLoading)
    return (
      <div className='showInfoContainer'>
        <div style={{ width: '100%', height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </div>
      </div>
    )
  return (
    <div className='showInfoContainer'>
      {(newData && Object.keys(newData).length > 0) || type === 'fund' ? (
        renderLayoutDropDownInfo(newData)
      ) : (
        <EmptyComponent />
      )}
    </div>
  )
}

export default DropdownInfoCommon
