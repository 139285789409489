import { lazy } from 'react'
import ErrorPage from '@src/components/ErrorPage'
export const Home = lazy(() =>
  import('./Home').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return {
      default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' />
    }
  })
)
export const NewsResearch = lazy(() =>
  import('./News&Research').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
// export const PageDetail = lazy(() => import('./PageDetail'))
export const PageResearchDetail = lazy(() =>
  import('./PageResearchDetail').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
// export const Dashboard = lazy(() => import('./Dashboard').catch(err => console.error(err)))
export const Dashboard = lazy(() =>
  import('./Dashboard').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const Contributor = lazy(() =>
  import('./Contributor').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const ResetPass = lazy(() =>
  import('@src/components/ResetPass/index').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const Watchlist = lazy(() =>
  import('./Watchlist').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
// export const PeopleMaster = lazy(() => import('./PeopleMaster/PeopleMaster').catch(err => console.error(err)))
export const ProjectMaster = lazy(() =>
  import('./ProjectMaster/ProjectMaster').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
// export const VentureMaster = lazy(() => import('./VentureMaster/VentureMaster').catch(err => console.error(err)))
// export const Careers = lazy(() => import('./Careers').catch(err => console.error(err)))
// export const JobOpening = lazy(() => import('./JobOpening').catch(err => console.error(err)))
// export const JobDetail = lazy(() => import('./JobDetail').catch(err => console.error(err)))
export const PostJob = lazy(() =>
  import('./PostJob').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const CrytoJob = lazy(() =>
  import('./CrytoJob').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const About = lazy(() =>
  import('./About').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const ProfileToken = lazy(() =>
  import('./ProfileToken').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const ProfileVenture = lazy(() =>
  import('./ProfileVenture/ProfileVenture').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const ProfilePeople = lazy(() =>
  import('./profilePeople').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const PostDetail = lazy(() =>
  import('./PostDetail').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const ProfileSettings = lazy(() =>
  import('./ProfileSettings').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)

export const SupportHelp = lazy(() =>
  import('./SupportHelp/SupportHelp').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const _renderContent = lazy(() =>
  import('./SupportHelp/components/LayoutSupport/LayoutSupport').catch((err) => console.error(err))
)

export const CareerAllJobs = lazy(() =>
  import('./CareersAllJobs').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const CareersPostJob = lazy(() =>
  import('./CareersPostJob').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const Career = lazy(() =>
  import('./Careers').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const UserProfileViewerMode = lazy(() =>
  import('./UserProfileViewerMode').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const Dao = lazy(() =>
  import('./Dao').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const PageDirectory = lazy(() =>
  import('./PageDirectory').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const AIHome = lazy(() =>
  import('./AIHome').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const AIStatistr = lazy(() =>
  import('./AIStatistr').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const PageMasterDemo = lazy(() =>
  import('./PageMasterDemo').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const QuestPoint = lazy(() =>
  import('./QuestPoint').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
export const DaoHomePage = lazy(() =>
  import('./DaoHomePage').catch((error) => {
    console.error('Failed to load MyComponent', error)
    // Return a dummy module or handle the error appropriately
    return { default: () => <ErrorPage status='500' subTitle='Sorry, something went wrong.' title='500' /> }
  })
)
