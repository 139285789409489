import React from 'react'
import style from './ButtonStatistr.module.css'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'

const ButtonStatistr = ({
  classNameButton,
  textButton = 'Button',
  type = 'primary || secondary || text || solid || upgrade || discard',
  onClick,
  disable = false,
  stopFunction = false,
  loading = false,
  width,
  height,
  children,
  styleButton
}) => {
  const handleClickButton = () => {
    if (stopFunction) return
    !disable && onClick && onClick()
  }

  return (
    <button
      className={clsx(
        style[`button_${type}`],
        {
          [style.button_disable]: disable
        },
        classNameButton,
        style.button
      )}
      onClick={handleClickButton}
      style={{
        width: width || 'max-content',
        ...(height && { height: height }),
        ...styleButton
      }}
      type='button'
    >
      <div className={style.button_content}>
        {children || textButton}
        <AnimatePresence>
          {loading ? (
            <motion.div
              animate={{ opacity: 1, width: 18, height: 18, marginLeft: 10 }}
              className={style.button_loading}
              exit={{ opacity: 0, width: 0, height: 18, marginLeft: 10 }}
              initial={{ opacity: 0, width: 0, height: 18, marginLeft: 10 }}
              transition={{
                duration: 0.1
              }}
            />
          ) : null}
        </AnimatePresence>
      </div>
    </button>
  )
}

export default ButtonStatistr
