import { createSlice } from '@reduxjs/toolkit'
import { ProjectActionContentMasterState } from '@src/interface'
const initialState: ProjectActionContentMasterState = {
  visibleModal: false,
  type: '',
  data: [],
  pagination: 100,
  current: 1,
  showActiveFilter: false,
  dataColMaster: {}
}

const projectActionContentMasterSlice = createSlice({
  name: 'project.master',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.visibleModal = action.payload
    },
    closeModal: (state, action) => {
      state.visibleModal = action.payload
    },
    getData: (state, action) => {
      state.data = action.payload
    },
    getTypeModal: (state, action) => {
      state.type = action.payload
    },
    handleAcviteFilter: (state, action) => {
      state.showActiveFilter = action.payload
    },
    handleGetDataColMaster: (state, action) => {
      state.dataColMaster = action.payload
    },
    handleSetDataColMaster: (state, action) => {
      state.dataColMaster = action.payload
    },
    getPaginationTable: (state, action) => {
      state.pagination = action.payload
    },
    getCurrentTable: (state, action) => {
      if (action.payload) {
        state.current = action.payload
      } else {
        state.current = state.current + 1
      }
    },
    resetCurrentTable: (state) => {
      state.current = 1
    },
    setColumnDefaultByKeyTable: (state, action) => {
      state.dataColMaster = action.payload
    }
  }
})

const actionContentMasterReducer = projectActionContentMasterSlice.reducer
export const {
  openModal,
  closeModal,
  getData,
  getTypeModal,
  handleAcviteFilter,
  handleGetDataColMaster,
  handleSetDataColMaster,
  getPaginationTable,
  getCurrentTable,
  resetCurrentTable,
  setColumnDefaultByKeyTable
} = projectActionContentMasterSlice.actions
export default actionContentMasterReducer
