/* eslint-disable react/jsx-max-depth */
import { Suspense, useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './styles/main.css'
import './constants/designSystem.css'
import NavBarNew from './components/NavbarNew'
import Footer from './components/Footer'
import { usePageTracker, useScrollToTop } from './hooks'
import MetaInfo from '@src/components/MetaInfo'
import { getRouteMetaInfo } from './config/routes.config'
import Router from './components/Router'
import GlobalStyles from './components/GlobalStyles'
import CustomStyles from './components/CustomStyles'
import setting from './settings/routerSetting'
import TopProgressBar from './components/ProgressBar/TopProgressBar'
import { GlobalDebug } from './util/remove-consoles'
// import { LoadScript } from '@react-google-maps/api'
// import { GOOGLE_MAP_API_KEY_LOCATION, GOOGLE_MAP_API_KEY_PREVIEW } from '@src/config/env.config'
// import isDev from '@src/util/utils'
import useCheckViewModal from './hooks/useCheckViewModal'
// import CookieConsent from './components/CookieConsent'
import Consent from './components/AcceptDataConsent'
import { initGA, logPageView } from './util/ga'

// import useCountdown from './hooks/useCountdown'
// import ModalLogin from './components/common/ModalLogin/ModalLogin'
// import { useWindowSize } from '@uidotdev/usehooks'

const App = () => {
  const [hideFooter, setHideFooter] = useState(false)
  useScrollToTop()
  usePageTracker()
  const location = useLocation()
  const navigate = useNavigate()

  const modalContent = useCheckViewModal(location.pathname.split('/')[1], ['directory'])
  // const size = useWindowSize()

  const previousPathname = useMemo(() => {
    let previousPath = localStorage.getItem('previousPath')
    //Tai sao lai bo trang home
    // if (location.pathname !== '/signin' && location.pathname !== '/') {
    //   previousPath = location.pathname
    // }
    if (location.pathname !== '/signin') {
      previousPath = location.pathname
    }
    localStorage.setItem('previousPath', previousPath)
    return previousPath
  }, [location.pathname])

  const RouteChangeTracker = () => {
    const location = useLocation()

    useEffect(() => {
      logPageView()
    }, [location])

    return null
  }

  useEffect(() => {
    initGA()
    logPageView()
  }, [])

  useEffect(() => {
    ;(process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'STAGING') && GlobalDebug(false)
  }, [])

  useEffect(() => {
    for (const path of setting.routerNoFooter) {
      if (location.pathname.includes(path)) {
        setHideFooter(true)
        break
      } else {
        setHideFooter(false)
      }
    }
    if (location.pathname.includes('referral')) {
      // Chuyển hướng đến '/signup'
      navigate(`/signup?referral=${location.pathname.split('/')[2]}`)
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.pathname.includes('twitter-connect-success') || location.pathname.includes('twitter-connect-error')) {
      const redirectRouter = localStorage.getItem('previousPath') || '/user_profile'
      navigate(redirectRouter)
    }
  }, [location.pathname])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const view = params.get('view')
    if (location.pathname === '/news-research') {
      if (view === 'all') {
        setHideFooter(true)
      } else {
        setHideFooter(false)
      }
    }
  }, [location.search])

  useEffect(() => {
    const containerHeaderMain = document.querySelector('#mainHeader')
    // eslint-disable-next-line no-unused-expressions
    containerHeaderMain ? (containerHeaderMain.style.display = 'flex') : null
  }, [location.pathname])

  return (
    <GlobalStyles>
      <CustomStyles>
        <MetaInfo {...getRouteMetaInfo('Home')} />

        {location.pathname === '/signin' ||
        location.pathname === '/signup' ||
        location.pathname === '/forgot-password' ? null : (
          <>
            <NavBarNew />
          </>
        )}
        <div
          className={
            location.pathname === '/signin' ||
            location.pathname === '/signup' ||
            location.pathname === '/forgot-password'
              ? ''
              : 'container_main'
          }
          style={{
            minHeight: 'calc(100dvh)'
          }}
        >
          <Consent />
          <Suspense fallback={<TopProgressBar />}>
            <RouteChangeTracker />
            <Router previousPathname={previousPathname} />
            {modalContent}
          </Suspense>
        </div>

        {hideFooter ? null : <Footer />}
        {/* {isCountDown ? <ModalLogin showPopupLogin={isCountDown} type='popup' /> : null} */}
      </CustomStyles>
    </GlobalStyles>
  )
}

export default App
