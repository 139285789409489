// src/ga.js
import ReactGA from 'react-ga4'

export const initGA = () => {
  ReactGA.initialize('G-YC5MM3YSHE')
}

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
}

export const logEvent = (category = '', action = '') => {
  if (category && action) {
    ReactGA.event({ category, action })
  }
}
