import buttonStyle from './Button.module.css'

const Button = ({ text, ...propsInput }) => {
  return (
    <button {...propsInput} className={buttonStyle.button} type='button'>
      <span className={buttonStyle.buttonText}>{text}</span>
    </button>
  )
}

export default Button
