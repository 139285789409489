// import { APP_NAME } from './env.config'

// Page 404
export const NOT_FOUND_TITLE = '404: Not Found'
export const NOT_FOUND_DESCRIPTION = 'The requested page could not be found.'

// Page ProfilePeople
export const PROFILE_PEOPLE_DESCRIPTION = 'The page PROFILE_PEOPLE_DESCRIPTION.'
//Page ProfileVenture
export const PROFILE_VENTURE_TITLE = ':ventures_name '
export const PROFILE_VENTURE_DESCRIPTTON = 'Detailed information about ventures.'
// Page NewResearch
export const NEW_RESEARCH_DESCRIPTION = 'Aggregate all news and research reports from various sources quickly.'
export const NEW_RESEARCH_TITLE = 'Intelligence'

// Page AI
export const AI_HOME_DESCRIPTION =
  'Empower your crypto journey with our AI-driven search support. Effortlessly find and explore cryptocurrency data, backed by advanced artificial intelligence. Navigate the world of crypto seamlessly with our intelligent assistance.'
export const AI_HOME_TITLE = 'Statistr AI'
export const AI_ROUTER_NAME = 'AIStatistr'

// Page Watchlist

// Page Profile Token
export const PROFILE_TOKEN_DESCRIPTION = `Detailed information about cryptocurrency.`
export const PROFILE_TOKEN_ROUTER_NAME = 'Projects'
export const PROFILE_ORGANIZATION_TITLE = 'Organization'
export const PROFILE_PEOPLE_TITLE = 'People'
export const PROFILE_TOKEN_TITLE = `${PROFILE_TOKEN_ROUTER_NAME}`

// Page Profile Token aboput slug
//Page dao...
export const TITLE_DAO = 'All DAOs'
export const DESCRIPTION_DAO =
  'Participate in data governance by becoming a member of the DAO, contributing to the construction of a transparent and diverse decentralized system'
//Page career. CareersPostJob

export const CAREERSPOSTJOB_TAITLE = 'Careers at Statistr'
export const CAREERSPOSTJOB_DESCRIPTION =
  'Embark on a career of possibilities with us to dive into the diverse world of blockchain.'

//Page SupportHelp...

export const TITLE_SUPPORTHELP = 'Help Center'
export const DESCRIPTION_SUPPORTHELP = ''

//Page Observatory
export const OBSERVATORY_TITLE = 'Observatory'
export const OBSERVATORY_DESCRIPTTON =
  'Overview of the entire market through metrics on Crypto Currency, Venture Capital, and People.'

//Page PeopleMaster
export const PEOPLEMASTER_TITLE = 'People Directory '
export const PEOPLEMASTER_DESCRIPTTON =
  'Analyze, customize, and research information on celebrities, influencers, and individuals active in the crypto market.'

//Page ProjectsMaster
export const PROJECT_SMASTER_TITLE = 'Projects Directory'
export const PROJECTS_MASTER_DESCRIPTTON = 'Track market metrics for the entire cryptocurrency market.'

//Page VenturesMaster
export const VENTURESMASTER_TITLE = 'Ventures Directory '
export const VENTURESMASTER_DESCRIPTTON =
  'Analyze, customize, and provide market data for the entire investment fund, organizational sector.'
//Page Setting
export const SETTING_TITLE = 'Profile Settings'
export const SETTING_DESCRIPTTON = 'Track market metrics for the entire cryptocurrency market.'
//Page Profile
export const PROFILE_TITLE = 'Account Overview'
export const PROFILE_DESCRIPTTON = 'Track market metrics for the entire cryptocurrency market.'
//Page About
export const ABOUT_TITLE = 'About | Statistr'
export const ABOUT_DESCRIPTTON = 'Track market metrics for the entire cryptocurrency market.'

//Page SupportHelp
export const SUPPORTHELP_TITLE = 'Help Center'
export const SUPPORTHELP_DESCRIPTTON = 'Track market metrics for the entire cryptocurrency market.'
//Page CareerAllJobs
export const CAREERALLJOBS_TITLE = 'Crypto Jobs'
export const CAREERALLJOBS_DESCRIPTTON =
  'Embark on a career of possibilities with us to dive into the diverse world of blockchain.'
//Page Dao
export const DAO_TITLE = 'All DAOs | Statistr'
export const DAO_DESCRIPTTON =
  'Participate in data governance by becoming a member of the DAO, contributing to the construction of a transparent and diverse decentralized system.'
//Page AIHome
export const AIHOME_TITLE = 'Statistr AI'
export const AIHOME_DESCRIPTTON =
  'Empower your crypto journey with our AI-driven search support. Effortlessly find and explore cryptocurrency data, backed by advanced artificial intelligence. Navigate the world of crypto seamlessly with our intelligent assistance.'
//Page AIStatistr
export const AISTATISTR_TITLE = 'New Chat | Statistr AI'
export const AISTATISTR_DESCRIPTTON =
  'Empower your crypto journey with our AI-driven search support. Effortlessly find and explore cryptocurrency data, backed by advanced artificial intelligence. Navigate the world of crypto seamlessly with our intelligent assistance.'
//Page master
export const TITLE_MASTER = 'Directory'
export const DESCRIPTTON_MASTER = 'Directory'

export const renderMetaTitle = (title) => `${title}`
export const renderMetaDescription = (description) => description
