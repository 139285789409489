/* eslint-disable react/button-has-type */
import React, { useState } from 'react'
import stylesFooter from './Footer.module.css'
import { configText, background, colorPalette } from '@src/constants/designSystem'
import { Link } from 'react-router-dom'
const Footer = () => {
  // const [showDrop, setShowDrop] = useState(false)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const contentFooter = {
    title: 'Statistr',
    subtitle: `Thank you for joining us as we collectively chart the course for tomorrow's data landscape`
  }
  // const DropDownFooter = [
  //   {
  //     value: 'VietNam',
  //     title: 'VietNam'
  //   },
  //   {
  //     value: 'VietNam2',
  //     title: 'VietNam'
  //   },
  //   {
  //     value: 'VietNam3',
  //     title: 'VietNam'
  //   }
  // ]
  const listSocialNet = [
    {
      name: 'twitter',
      logo: '/assets/images/Footer/footer_twit.svg',
      link: 'https://twitter.com/statistr_'
    },
    {
      name: 'email',
      logo: '/assets/images/Footer/footer_mail.svg',
      link: 'partner@statistr.com',
      subject: 'Partner with Statistr',
      body: 'Hi, I would like to partner with Statistr.'
    },
    {
      name: 'telegram',
      logo: '/assets/images/icon_telegram.svg',
      link: 'https://t.me/statistr_group'
    }
  ]
  const listLink = [
    //start footer hide
    {
      title: 'Features',
      path: '',
      child: [
        {
          id: 0,
          sublink: 'Statistr AI',
          link: '/ai-home'
        },
        {
          id: 2,
          sublink: 'Statistr DAO',
          link: '/statistr_dao'
        },
        {
          id: 3,
          sublink: 'Observatory',
          link: '/observatory'
        },
        {
          id: 4,
          sublink: 'Project Directory',
          link: '/directory/projects'
        },
        {
          id: 5,
          sublink: 'People Directory',
          link: '/directory/people'
        },
        {
          id: 6,
          sublink: 'Organizations Directory',
          link: '/directory/organizations'
        },
        {
          id: 7,
          sublink: 'Intelligence',
          link: '/news-research?type=news&view=default'
        }
      ]
    },
    {
      title: 'About',
      path: '',
      child: [
        {
          id: 1,
          sublink: 'About Us',
          link: '/about'
        },
        {
          id: 2,
          sublink: 'Statistr Careers',
          link: '/career'
        },
        {
          id: 3,
          sublink: 'Pricing',
          link: ''
        },
        {
          id: 4,
          sublink: 'API',
          link: ''
        }
      ]
    },
    {
      title: 'Support',
      path: '/',
      child: [
        {
          id: 2,
          sublink: 'Help Center',
          link: '/support/help-center'
        },
        {
          id: 3,
          sublink: 'Terms of Service',
          link: ''
        }
      ]
    }
  ]
  const render = (subitem) => {
    let html = null
    switch (subitem.sublink) {
      case 'Watch List':
        html = (
          <div
            className={stylesFooter.contentListLi}
            key={subitem.sublink}
            style={{
              marginBottom: 12
            }}
          >
            {subitem.link ? (
              <Link to={subitem.link}>
                <span
                  className={stylesFooter.contentListLiLink}
                  style={{
                    ...configText.bodyMedium,
                    color: colorPalette.white_blue_op70,
                    fontWeight: 'normal',
                    padding: '8.5px 0'
                  }}
                >
                  {subitem.sublink}
                </span>
              </Link>
            ) : (
              <div to={subitem.link}>
                <span
                  className={stylesFooter.contentListLiLink}
                  style={{
                    ...configText.bodyMedium,
                    color: colorPalette.white_blue_op70,
                    fontWeight: 'normal',
                    padding: '8.5px 0'
                  }}
                >
                  {subitem.sublink}
                </span>
              </div>
            )}
          </div>
        )
        break
      default:
        html = (
          <div
            className={stylesFooter.contentListLi}
            key={subitem.sublink}
            style={{
              marginBottom: 12
            }}
          >
            {subitem.link ? (
              <Link to={subitem.link}>
                <span
                  className={stylesFooter.contentListLiLink}
                  style={{
                    ...configText.bodyMedium,
                    color: colorPalette.white_blue_op70,
                    fontWeight: 'normal',
                    padding: '8.5px 0'
                  }}
                >
                  {subitem.sublink}
                </span>
              </Link>
            ) : (
              <div style={{ cursor: 'pointer' }} to={subitem.link}>
                <span
                  className={stylesFooter.contentListLiLink}
                  style={{
                    ...configText.bodyMedium,
                    color: colorPalette.white_blue_op70,
                    fontWeight: 'normal',
                    padding: '8.5px 0'
                  }}
                >
                  {subitem.sublink}
                </span>
              </div>
            )}
          </div>
        )
        break
    }
    return html
  }

  function handleInput(event) {
    setEmail(event.target.value)
  }

  function handleSubmit(event) {
    event.preventDefault()

    if (email === '' || !/\S+@\S+\.\S+/.test(email)) {
      setIsEmailValid(false)
      setTimeout(() => {
        setIsEmailValid(true)
      }, 2000)
    } else {
      setIsEmailValid(true)
      alert(`Thank you for subscribing with ${email}`)
      setEmail('')
    }
  }

  const renderInput = () => (
    <div
      className={stylesFooter.containerInput}
      style={{
        position: 'relative'
      }}
    >
      <form onSubmit={handleSubmit}>
        <input
          className={stylesFooter.input}
          onChange={handleInput}
          placeholder='Enter your email'
          style={{ fontWeight: 'normal', ...configText.bodyMedium, color: colorPalette.white_blue }}
          type='email'
          value={email}
        />
        <button
          className={stylesFooter.inputBtn}
          style={{ ...configText.bodyMedium, color: '#FFFFFF', fontWeight: 'normal', padding: '10px 24px' }}
          type='submit'
        >
          Subscribe
        </button>
      </form>
    </div>
  )
  return (
    <div
      className={stylesFooter.container}
      style={{
        backgroundColor: background.background_footer
      }}
    >
      <div className={stylesFooter.contentLayout}>
        <div className={stylesFooter.containerContent}>
          <div className={stylesFooter.contentTagline}>
            <img alt='statistr' height={64} src='/assets/images/Footer/logoFooter.svg' width={64} />
            <span
              className={stylesFooter.contentTaglineTitle}
              style={{
                ...configText.titleLarge,
                color: colorPalette.white_blue,
                fontWeight: 600
              }}
            >
              {contentFooter.title}
            </span>
            <span
              className={stylesFooter.contentTaglineSubtitle}
              style={{
                ...configText.bodyMedium,
                color: colorPalette.white_blue_op70,
                fontWeight: 'normal'
              }}
            >
              {contentFooter.subtitle}
            </span>
          </div>
          <div className={stylesFooter.contentListMenu}>
            {listLink.map((item, index) => (
              <div className={stylesFooter.contentListMenuItem} key={index}>
                <span
                  style={{
                    ...configText.bodyMedium,
                    fontWeight: 600,
                    color: colorPalette.white_blue,
                    padding: '8.5px 0'
                  }}
                >
                  {item.title}
                </span>
                <div className={stylesFooter.contentListUl}>
                  {item.child.map((subitem) => {
                    return <div key={subitem.id}>{render(subitem)}</div>
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className={stylesFooter.contentInputNew}>
            <span
              className={stylesFooter.contentInputNewTitle}
              style={{
                ...configText.bodyMedium,
                fontWeight: 600,
                color: colorPalette.white_blue,
                padding: '8.5px 0'
              }}
            >
              Subscribe to newsletter!
            </span>
            {!isEmailValid ? (
              <p style={{ color: 'red', marginTop: 8, fontSize: 14, fontWeight: 'normal', lineHeight: '20px' }}>
                Please enter a valid email address
              </p>
            ) : null}
            <div>
              {renderInput()}
              <div className={stylesFooter.network}>
                {listSocialNet.map((item, index) => {
                  return (
                    <Link
                      className={stylesFooter.networkItem}
                      key={index}
                      onClick={(e) => {
                        if (item.name === 'email') {
                          const mailto = `mailto:${item.link}?subject=${item.subject}&body=${item.body}`
                          window.location.href = mailto
                          e.preventDefault()
                        }
                      }}
                      target={item.name !== 'email' ? '_blank' : null}
                      to={item.link}
                    >
                      <img alt='' srcSet={item.logo} />
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={stylesFooter.container_layout_mobile}>
        <div className={stylesFooter.contentTagline}>
          <img alt='statistr' height={64} src='/assets/images/Footer/logoFooter.svg' width={64} />
          <span
            className={stylesFooter.contentTaglineTitle}
            style={{
              ...configText.titleLarge,
              color: colorPalette.white_blue,
              fontWeight: 600
            }}
          >
            {contentFooter.title}
          </span>
          <span
            className={stylesFooter.contentTaglineSubtitle}
            style={{
              ...configText.bodyMedium,
              color: colorPalette.white_blue_op70,
              fontWeight: 'normal'
            }}
          >
            {contentFooter.subtitle}
          </span>
        </div>
        <div
          className={stylesFooter.content_bottom}
          style={{ display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: 700, gap: 40 }}
        >
          <div className={stylesFooter.contentListMenu}>
            {listLink.map((item, index) => (
              <div className={stylesFooter.contentListMenuItem} key={index}>
                <span
                  style={{
                    ...configText.bodyMedium,
                    fontWeight: 600,
                    color: colorPalette.white_blue,
                    padding: '8.5px 0'
                  }}
                >
                  {item.title}
                </span>
                <div className={stylesFooter.contentListUl}>
                  {item.child.map((subitem) => {
                    return <div key={subitem.id}>{render(subitem)}</div>
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className={stylesFooter.contentInputNew}>
            <span
              className={stylesFooter.contentInputNewTitle}
              style={{
                ...configText.bodyMedium,
                fontWeight: 600,
                color: colorPalette.white_blue,
                padding: '8.5px 0'
              }}
            >
              Subscribe to newsletter!
            </span>
            {!isEmailValid ? (
              <p style={{ color: 'red', marginTop: 8, fontSize: 14, fontWeight: 'normal', lineHeight: '20px' }}>
                Please enter a valid email address
              </p>
            ) : null}
            <div>
              {renderInput()}
              <div className={stylesFooter.network}>
                {listSocialNet.map((item, index) => {
                  return (
                    <Link
                      className={stylesFooter.networkItem}
                      key={index}
                      onClick={(e) => {
                        if (item.name === 'email') {
                          const mailto = `mailto:${item.link}?subject=${item.subject}&body=${item.body}`
                          window.location.href = mailto
                          e.preventDefault()
                        }
                      }}
                      target={item.name !== 'email' ? '_blank' : null}
                      to={item.link}
                    >
                      <img alt='' srcSet={item.logo} />
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={stylesFooter.containerCopy}
        style={{
          backgroundColor: background.background_footer_copy
        }}
      >
        <div className={stylesFooter.contentCopy}>
          <span
            className={stylesFooter.contentCopySubtitle}
            style={{
              ...configText.bodySmall,
              fontWeight: 'normal',
              color: colorPalette.white_blue_op70,
              lineHeight: '23px'
            }}
          >
            Copyright © 2024 All Rights Served by{' '}
            <strong style={{ fontWeight: 600, color: colorPalette.white_blue }}>Statistr</strong>
          </span>
          {/* <div
            className={stylesFooter.contentCopyDropDown}
            onClick={() => setShowDrop(!showDrop)}
            style={{
              ...configText.bodySmall,
              fontWeight: 'normal',
              color: colorPalette.white_blue_op70,
              ineHeight: '23px'
            }}
          >
            <span>English</span>
            <img
              alt=''
              src='/assets/images/Footer/arrow_down.svg'
              style={{ marginLeft: 8, transform: showDrop ? 'rotate(-180deg)' : null }}
            />
            {showDrop ? (
              <div className={stylesFooter.dropdown}>
                {DropDownFooter.map((item) => {
                  return (
                    <div
                      className={stylesFooter.dropdownItem}
                      key={item.value}
                      style={{
                        ...configText.bodyMedium,
                        color: colorPalette.black
                      }}
                    >
                      {item.title}
                    </div>
                  )
                })}
              </div>
            ) : (
              <></>
            )}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Footer
