export const jsonLdData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Statistr',
  url: 'https://statistr.com/',
  '@id': 'https://statistr.com/',
  logo: 'https://statistr.com/ico.svg',
  image: 'https://statistr.com/default-og.jpg',
  slogan: `Join us as we collectively chart the course for tomorrow's data landscape.`,
  contactPoint: {
    '@type': 'ContactPoint',
    contactType: 'Customer Service',
    areaServed: 'VN',
    availableLanguage: ['Vietnamese', 'English']
  },
  sameAs: ['https://x.com/statistr_', 'https://t.me/statistr_group', 'https://www.linkedin.com/company/statistr/'],
  email: 'partner@statistr.com',
  description:
    'Enter the names of organizations and individuals in the search box to find their information. Powered by /cryptoinfo & Statistr.com'
}
