import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

interface ContributorState {
  dataContribute: { [key: string]: { [key: string]: any } }
}

interface SetDataEditContributePayload {
  type: string
  key: string
  data: { key?: string; data: any }
}

const initialState: ContributorState = {
  dataContribute: {}
}

const ContributorSlice = createSlice({
  name: 'newsAndReasearch',
  initialState,
  reducers: {
    setDataEditContribute: (state, action: PayloadAction<SetDataEditContributePayload>) => {
      const { type, key, data } = action.payload
      const { data: subData, key: subKey } = data

      const typeData = state.dataContribute[type]

      state.dataContribute[type] = {
        ...typeData,
        [key]: {
          data: subKey ? { ...typeData?.[key]?.data, [subKey]: subData } : data,
          time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        }
      }

      if (Array.isArray(data) && data.length === 0) {
        delete state.dataContribute[type]?.[key]
      } else if (subKey && Object.keys(subData).length === 0) {
        if (typeData?.[key]) {
          delete typeData[key]
        } else if (typeData[key]?.data[subKey]) {
          delete typeData[key]?.data[subKey]
        }
      }
    },
    resetDataEditContribute: (state) => {
      state.dataContribute = {}
    }
  }
})

// Extract the reducer and actions from the slice
const ContributorReducer = ContributorSlice.reducer
export const { setDataEditContribute, resetDataEditContribute } = ContributorSlice.actions

export default ContributorReducer
